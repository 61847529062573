/*eslint-disable*/
import CircularLoading from "../../../components/CircularLoading";
import { Select } from "@material-ui/core";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  FormText,
  Row,
  Col,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from "../../../components/AlertDialog";
import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "common";
import CustomInput from "components/CustomInput/CustomInput";
import MenuItem from "@material-ui/core/MenuItem";
function PatientSurgery({ route, navigation, props }) {
  const { api } = useContext(FirebaseContext);
  const { editMedicalHistory } = api;
  const dispatch = useDispatch();
  const medical = useSelector(
    (state) => state.medicalRecords.medicalHistoryRecords
  );
  const auth = useSelector((state) => state.auth);
  const [medicalData, setMedicalData] = useState(medical);

  //Immunisation and dates
  const [Tetanus, setTetanus] = useState({
    checked: false,
    Date: null,
  });
  const [Pneumonia, setPneumonia] = useState({
    checked: false,
    Date: null,
  });
  const [Hepatitis_A, setHepatitis_A] = useState({
    checked: false,
    Date: null,
  });
  const [Hepatitis_B, setHepatitis_B] = useState({
    checked: false,
    Date: null,
  });
  const [Chickenpox2, setChickenpox2] = useState({
    checked: false,
    Date: null,
  });
  const [influenza, setinfluenza] = useState({
    checked: false,
    Date: null,
  });
  const [MMR, setMMR] = useState({
    checked: false,
    Date: null,
  });
  const [Meningococcal, setMeningococcal] = useState({
    checked: false,
    Date: null,
  });
  const [None2, setNone2] = useState({
    checked: false,
  });
  ///////////////////////////////////////////////////////////////
  //Surgies
  const [Surgry1st, setSurgry1st] = useState({
    YearOfSurgry: " ",
    ReasonOfSurgry: " ",
    HospitalOfSurgry: " ",
  });
  const [Surgry2nd, setSurgry2nd] = useState({
    YearOfSurgry: " ",
    ReasonOfSurgry: " ",
    HospitalOfSurgry: " ",
  });
  const [Surgry3rd, setSurgry3rd] = useState({
    YearOfSurgry: " ",
    ReasonOfSurgry: " ",
    HospitalOfSurgry: " ",
  });
  const [NoSurgeries, setNoSurgeries] = useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setMedicalData(medical);
    }
  }, [medical]);

  const OnSave = () => {
    let payload = {
      Medical_Information: {
        ...medical,
        Surgries: medicalData?.Surgries ? medicalData?.Surgries : null,
      },
    };
    console.log("this is surgery payload:", payload);
    dispatch(editMedicalHistory(payload, "Add medical history"));
    setCommonAlert({ open: true, msg: "Your Surgery History Has Been Saved" });
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };
  return auth.loading ? (
    <CircularLoading />
  ) : (
    <>
      <div className="content">
        <div className="medicalhistory-form">
          <Row>
            <Col md="12">
              <AlertDialog
                open={commonAlert.open}
                onClose={handleCommonAlertClose}
              >
                {commonAlert.msg}
              </AlertDialog>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Surgery</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col mb="4">
                      <FormGroup inline>
                        <FormGroup inline>
                          <Label style={{ padding: "5px" }}>
                            Your First Surgery
                          </Label>
                        </FormGroup>
                        <CustomInput
                          labelText={"Year of Surgery"}
                          formControlProps={{
                            fullWidth: false,
                          }}
                          inputProps={{
                            type: "text",
                            required: true,
                            margin: "5px",
                          }}
                          onChange={(event) => {
                            setMedicalData({
                              ...medicalData,
                              Surgries: {
                                ...medicalData.Surgries,
                                Surgry1st: {
                                  ...medicalData.Surgries.Surgry1st,
                                  YearOfSurgry: event.target.value,
                                },
                              },
                            });
                          }}
                          value={medicalData?.Surgries?.Surgry1st?.YearOfSurgry}
                        />
                      </FormGroup>
                      <FormGroup>
                        <CustomInput
                          labelText={"Reason for Surgery"}
                          formControlProps={{
                            fullWidth: false,
                          }}
                          inputProps={{
                            type: "text",
                          }}
                          onChange={(event) => {
                            setMedicalData({
                              ...medicalData,
                              Surgries: {
                                ...medicalData.Surgries,
                                Surgry1st: {
                                  ...medicalData.Surgries.Surgry1st,
                                  ReasonOfSurgry: event.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            medicalData?.Surgries?.Surgry1st?.ReasonOfSurgry
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <CustomInput
                          labelText={"Hospital"}
                          formControlProps={{
                            fullWidth: false,
                          }}
                          inputProps={{
                            type: "text",
                          }}
                          onChange={(event) => {
                            setMedicalData({
                              ...medicalData,
                              Surgries: {
                                ...medicalData.Surgries,
                                Surgry1st: {
                                  ...medicalData.Surgries.Surgry1st,
                                  HospitalOfSurgry: event.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            medicalData?.Surgries?.Surgry1st?.HospitalOfSurgry
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col mb="4">
                      <FormGroup inline>
                        <Label style={{ padding: "5px" }}>
                          Your Second Surgery
                        </Label>
                      </FormGroup>
                      <FormGroup inline>
                        <CustomInput
                          labelText={"Year of Surgery"}
                          formControlProps={{
                            fullWidth: false,
                          }}
                          inputProps={{
                            type: "text",
                            required: true,
                            margin: "10px",
                          }}
                          onChange={(event) => {
                            setMedicalData({
                              ...medicalData,
                              Surgries: {
                                ...medicalData.Surgries,
                                Surgry2nd: {
                                  ...medicalData.Surgries.Surgry2nd,
                                  YearOfSurgry: event.target.value,
                                },
                              },
                            });
                          }}
                          value={medicalData?.Surgries?.Surgry2nd?.YearOfSurgry}
                        />
                      </FormGroup>
                      <FormGroup>
                        <CustomInput
                          labelText={"Reason for Surgery"}
                          formControlProps={{
                            fullWidth: false,
                          }}
                          inputProps={{
                            type: "text",
                          }}
                          onChange={(event) => {
                            setMedicalData({
                              ...medicalData,
                              Surgries: {
                                ...medicalData.Surgries,
                                Surgry2nd: {
                                  ...medicalData.Surgries.Surgry2nd,
                                  ReasonOfSurgry: event.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            medicalData?.Surgries?.Surgry2nd?.ReasonOfSurgry
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <CustomInput
                          labelText={"Hospital"}
                          formControlProps={{
                            fullWidth: false,
                          }}
                          inputProps={{
                            type: "text",
                          }}
                          onChange={(event) => {
                            setMedicalData({
                              ...medicalData,
                              Surgries: {
                                ...medicalData.Surgries,
                                Surgry2nd: {
                                  ...medicalData.Surgries.Surgry2nd,
                                  HospitalOfSurgry: event.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            medicalData?.Surgries?.Surgry2nd?.HospitalOfSurgry
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col mb="4">
                      <FormGroup inline>
                        <Label style={{ padding: "5px" }}>
                          Your Third Surgery
                        </Label>
                      </FormGroup>
                      <FormGroup inline>
                        <CustomInput
                          labelText={"Year of Surgery"}
                          formControlProps={{
                            fullWidth: false,
                          }}
                          inputProps={{
                            type: "text",
                            required: true,
                            margin: "10px",
                          }}
                          onChange={(event) => {
                            setMedicalData({
                              ...medicalData,
                              Surgries: {
                                ...medicalData.Surgries,
                                Surgry3rd: {
                                  ...medicalData.Surgries.Surgry3rd,
                                  YearOfSurgry: event.target.value,
                                },
                              },
                            });
                          }}
                          value={medicalData?.Surgries?.Surgry3rd?.YearOfSurgry}
                        />
                      </FormGroup>
                      <FormGroup>
                        <CustomInput
                          labelText={"Reason for Surgery"}
                          formControlProps={{
                            fullWidth: false,
                          }}
                          inputProps={{
                            type: "text",
                          }}
                          onChange={(event) => {
                            setMedicalData({
                              ...medicalData,
                              Surgries: {
                                ...medicalData.Surgries,
                                Surgry3rd: {
                                  ...medicalData.Surgries.Surgry3rd,
                                  ReasonOfSurgry: event.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            medicalData?.Surgries?.Surgry3rd?.ReasonOfSurgry
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <CustomInput
                          labelText={"Hospital"}
                          formControlProps={{
                            fullWidth: false,
                          }}
                          inputProps={{
                            type: "text",
                          }}
                          onChange={(event) => {
                            setMedicalData({
                              ...medicalData,
                              Surgries: {
                                ...medicalData.Surgries,
                                Surgry3rd: {
                                  ...medicalData.Surgries.Surgry3rd,
                                  HospitalOfSurgry: event.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            medicalData?.Surgries?.Surgry3rd?.HospitalOfSurgry
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <FormGroup inline>
                      <Input
                        type="checkbox"
                        onChange={() => {
                          setNoSurgeries(!medical?.Surgries?.NoSurgeries);
                          setSurgry1st({
                            YearOfSurgry: " ",
                            ReasonOfSurgry: " ",
                            HospitalOfSurgry: " ",
                          });
                          setSurgry2nd({
                            YearOfSurgry: " ",
                            ReasonOfSurgry: " ",
                            HospitalOfSurgry: " ",
                          });
                          setSurgry3rd({
                            YearOfSurgry: " ",
                            ReasonOfSurgry: " ",
                            HospitalOfSurgry: " ",
                          });
                        }}
                      />
                      <Label style={{ padding: "5px" }}>
                        I Have Had No Surgeries
                      </Label>
                    </FormGroup>
                  </Row>
                  <Col>
                    <Button variant="gradient" color="primary" onClick={OnSave}>
                      SAVE
                    </Button>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default PatientSurgery;
