/*eslint-disable*/
import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Input, Button, Label, DropdownToggle, UncontrolledDropdown, DropdownMenu, DropdownItem, Dropdown } from "reactstrap";
import GoogleMapsAutoComplete from "components/GoogleMapsAutoComplete";
import { FormGroup, Select } from "@mui/material";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
const FamilyPlanning = () => {
  const [state, setstate] = useState({
    surname: "",
    datetime: "",
    name: "",
    address: "",
    contraceptive: "",
    bloodpressure: "",
    weight: "",
    urine: "",
    lmp: "",
  });
  return (
    <div>
      <GridContainer spacing={4}>
      <GridItem xs={3} sm={3} md={3} lg={3}>
          <Label>Surname: </Label>
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
          <Input
            type="text"
            value={state.surname}
            onChange={(value) => {
              setstate({ ...state, surname: value });
            }}
          />
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
          <Label>D.O.B:</Label>
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
          <Input
            type="datetime"
            value={state.datetime}
            onChange={(value) => {
              setstate({ ...state, datetime: value });
            }}
          />
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
          <Label>Name:</Label>
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
          <Input
            type="text"
            value={state.name}
            onChange={(value) => {
              setstate({ ...state, name: value });
            }}
          />
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
          <Label>Marital Status:</Label>
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
        <Dropdown>
      <DropdownMenu>
          <DropdownItem>Single</DropdownItem>
          <DropdownItem>Married</DropdownItem>
          <DropdownItem>Widowed</DropdownItem>
          <DropdownItem>Divorced</DropdownItem>
          <DropdownItem>Seperated</DropdownItem>
          <DropdownItem>Other</DropdownItem>
        </DropdownMenu>
        </Dropdown>
        </GridItem>
        <GridItem xs={6} sm={6} md={6} lg={6}>
          <Label>Address:</Label>
        </GridItem>
        <GridItem xs={6} sm={6} md={6} lg={6}>
          <Input
            type="text"
            value={state.address}
            onChange={(value) => {
              setstate({ ...state, address: value });
            }}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} lg={6}>
          <Label>Type of Contraceptive:</Label>
        </GridItem>
        <GridItem xs={6} sm={6} md={6} lg={6}>
          <Input
            fullWidth
            type="text"
            value={state.contraceptive}
            onChange={(value) => {
              setstate({ ...state, contraceptive: value });
            }}
          />
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
          <Label>Medical History:</Label>
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
        <FormControl>
  <RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
    defaultValue="Epilepsy"
    name="radio-buttons-group"
  >
    <FormControlLabel value="Epilepsy" control={<Radio />} label="Epilepsy" />
    <FormControlLabel value="Hypertension" control={<Radio />} label="Hypertension" />
    <FormControlLabel value="Diabetes" control={<Radio />} label="Diabetes" />
    <FormControlLabel value="Tuberculosis" control={<Radio />} label="Tuberculosis" />
    <FormControlLabel value="Varicose Veins" control={<Radio />} label="Varicose Veins" />
    <FormControlLabel value="Allergies" control={<Radio />} label="Allergies" />
    <FormControlLabel value="Medication" control={<Radio />} label="Medication" />
    <FormControlLabel value="Smoking" control={<Radio />} label="Smoking" />
    <FormControlLabel value="other" control={<Radio />} label="Other" />
  </RadioGroup>
        </FormControl>
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
          <Label>Observations:</Label>
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
          <Label>Blood Pressure</Label>
          <Input
            id="standard-basic"
            type="text"
            value={state.bloodpressure}
            onChange={(value) => {
              setstate({ ...state, bloodpressure: value });
            }}
          />
          <Label>Weight</Label>
          <Input
            fullWidth
            type="text"
            value={state.weight}
            onChange={(value) => {
              setstate({ ...state, weight: value });
            }}
          />
          <Label>Urine</Label>
          <Input
            fullWidth
            type="text"
            value={state.urine}
            onChange={(value) => {
              setstate({ ...state, urine: value });
            }}
          />
          <Label>LMP</Label>
          <Input
            fullWidth
            type="text"
            value={state.lmp}
            onChange={(value) => {
              setstate({ ...state, lmp: value });
            }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default FamilyPlanning;
