import {
  FETCH_ALL_DOCTORS,
  FETCH_ALL_DOCTORS_SUCCESS,
  FETCH_ALL_DOCTORS_FAILED,
} from "../store/types";

export const INITIAL_STATE = {
  users: null,
  loading: false,
  error: {
    flag: false,
    msg: null,
  },
};

export const Doctorsreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_DOCTORS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_DOCTORS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case FETCH_ALL_DOCTORS_FAILED:
      return {
        ...state,
        users: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
      };
    default:
      return state;
  }
};
