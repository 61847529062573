import {
  FETCH_DOCTOR_TYPES,
  FETCH_DOCTOR_TYPES_SUCCESS,
  FETCH_DOCTOR_TYPES_FAILED,
  EDIT_DOCTOR_TYPE,
} from "../store/types";
import { language } from "config";

export const fetchDoctorTypes = () => (dispatch) => (firebase) => {
  const { doctorTypesRef } = firebase;

  dispatch({
    type: FETCH_DOCTOR_TYPES,
    payload: null,
  });
  doctorTypesRef.on("value", (snapshot) => {
    if (snapshot.val()) {
      let data = snapshot.val();
      const arr = Object.keys(data).map((i) => {
        data[i] = i;
        return data[i];
      });
      dispatch({
        type: FETCH_DOCTOR_TYPES_SUCCESS,
        payload: arr,
      });
    } else {
      dispatch({
        type: FETCH_DOCTOR_TYPES_FAILED,
        payload: language.no_specialitys,
      });
    }
  });
};

export const editDoctorType =
  (doctortypes, method) => (dispatch) => (firebase) => {
    const { doctorTypesRef, doctorTypesEditRef } = firebase;
    dispatch({
      type: EDIT_DOCTOR_TYPE,
      payload: { method, doctortypes },
    });
    if (method === "Add") {
      doctorTypesRef.push(doctortypes);
    } else if (method === "Delete") {
      doctorTypesEditRef(doctortypes.id).remove();
    } else {
      doctorTypesEditRef(doctortypes.id).set(doctortypes);
    }
  };
