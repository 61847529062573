/*eslint-disable*/
import AlertDialog from "../../components/AlertDialog";
import CircularLoading from "../../components/CircularLoading";
import MaterialTable from "material-table";
import React, { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "common";
import {
  features,
  language,
  otc,
  prescription as prescript,
  condition,
} from "config";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { Add } from "@material-ui/icons";
import UsersCombo from "components/UsersCombo";

import {
  Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
} from "@material-ui/core";

export default function DrugExposure({ Booking }) {
  const { api } = useContext(FirebaseContext);
  const {
    addUser,
    editUser,
    deleteUser,
    checkUserExists,
    emailSignUp,
    clearLoginError,
    updateProfile,
    updateBooking,
    fetchOtc,
  } = api;
  const [state, setState] = useState({
    drug_exposure_id: "",
    person_id: "",
    drug_concept_id: "",
    drug_exposure_start_date: "",
    drug_exposure_start_datetime: "",
    drug_exposure_end_date: "",
    drug_exposure_end_datetime: "",
    verbatim_end_date: "",
    drug_type_concept_id: "",
    stop_reason: "",
    refills: "",
    quantity: "",
    days_supply: "",
    sig: "",
    route_concept_id: "",
    lot_number: "",
    provider_id: "",
    visit_occurrence_id: "",
    visit_detail_id: "",
    drug_source_value: "",
    drug_source_concept_id: "",
    route_source_value: "",
    dose_unit_source_value: "",
  });
  const [isdepdata, setIsdepData] = useState({
    isIndependent: false,
  });
  const location = useLocation();
  const specialities = useSelector(
    (state) => state.specialitytypes.specialities
  );
  const [updatebooking, setupdatebooking] = useState(Booking);
  const [details, setdetails] = useState(location.state.Booking);
  const [specialityTypes, setSpecialityTypes] = useState({});
  const auth = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const usersdata = useSelector((state) => state.usersdata);
  const otcdata = useSelector((state) => state.drugdata);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openFPModal, setOpenFPModal] = React.useState(false);
  // const [otc, setotc] = useState([]);
  const [otcT, setotcT] = useState([]);
  const [prescripts, setprescripts] = useState([]);
  const [conditions, setconditions] = useState([]);
  useEffect(() => {
    if (details) {
      setData(Object.values(details.prescription));
    } else {
      setData([]);
    }
  }, [details]);
  useEffect(() => {
    console.log("otcdata", prescript);
  }, [prescript]);

  useEffect(() => {
    if (specialities) {
      let obj = {};
      for (let i = 0; i < specialities.length; i++) {
        specialities.map((speciality) => (obj[speciality] = speciality));
      }

      setSpecialityTypes(obj);
    }
    if (otc) {
      let arr = {};
      for (let i = 0; i < otc.length; i++) {
        otc.map((otcs) => (arr[otcs.concept_name] = otcs.concept_name));
      }
      setotcT(Object.values(arr));
    }
    if (prescript) {
      let arr = {};
      for (let i = 0; i < prescript.length; i++) {
        prescript.map((pres) => (arr[pres.concept_name] = pres.concept_name));
      }
      setprescripts(Object.values(arr));
    }
    if (condition) {
      let arr = {};
      for (let i = 0; i < condition.length; i++) {
        condition.map((con) => (arr[con.concept_name] = con.concept_name));
      }
      setconditions(Object.values(arr));
    }
  }, []);

  const columns = [
    // {
    //   title: language.drug_exposure_id,
    //   field: "drug_exposure_id",
    //   initialEditValue: "",
    // },
    {
      title: language.personid,
      field: "person_id",
      initialEditValue: details.patient,
    },
    {
      title: language.drug_concept_id,
      field: "drug_concept_id",
      lookup: otcT,

      // filterComponent: ({ columnDef, onFilterChanged }) => <UsersCombo />,
    },
    {
      title: language.startdate,
      field: "drug_exposure_start_date",
      initialEditValue: new Date(),
      type: "date",
    },
    {
      title: language.start_datetime,
      field: "drug_exposure_start_datetime",
      initialEditValue: new Date(),
      type: "time",
    },
    // {
    //   title: language.enddate,
    //   field: "drug_exposure_end_date",
    //   initialEditValue: "",
    // },
    // {
    //   title: language.end_datetime,
    //   field: "drug_exposure_end_datetime",
    //   initialEditValue: "",
    // },
    // {
    //   title: language.verbatim_end_date,
    //   field: "verbatim_end_date",
    //   initialEditValue: "",
    // },
    // {
    //   title: language.drug_type_concept_id,
    //   field: "drug_type_concept_id",
    //   lookup: otcT,
    // },
    // {
    //   title: language.stop_reason,
    //   field: "stop_reason",
    //   initialEditValue: "",
    // },
    // { title: language.refills, field: "refills", initialEditValue: "" },
    // { title: language.quantity, field: "quantity", initialEditValue: "" },
    // {
    //   title: language.days_supply,
    //   field: "days_supply",
    //   initialEditValue: "",
    // },
    // {
    //   title: language.sig,
    //   field: "sig",
    //   initialEditValue: "",
    // },
    // {
    //   title: language.route_concept_id,
    //   field: "route_concept_id",
    //   initialEditValue: "",
    // },
    // {
    //   title: language.lot_number,
    //   field: "lot_number",
    //   initialEditValue: "",
    // },
    // {
    //   title: language.provider_id,
    //   field: "provider_id",
    //   initialEditValue: "",
    // },
    // {
    //   title: language.visit_occurrence_id,
    //   field: "visit_occurrence_id",
    //   initialEditValue: "",
    // },
    // {
    //   title: language.visit_detail_id,
    //   field: "visit_detail_id",
    //   initialEditValue: "",
    // },
    {
      title: language.drug_source_value,
      field: "drug_source_value",
      lookup: prescripts,
    },
    {
      title: language.drug_source_concept_id,
      field: "drug_source_concept_id",
      initialEditValue: "",
    },
    {
      title: language.route_source_value,
      field: "route_source_value",
      lookup: conditions,
    },
    {
      title: language.dose_unit_source_value,
      field: "dose_unit_source_value",
      initialEditValue: "",
    },
  ];
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
    if (auth.error.flag) {
      dispatch(clearLoginError());
    }
  };
  const handleCloseEvent = (e) => {
    e.preventDefault();
    setOpenFPModal(false);
  };
  // const onChangeHandle = (e) => {
  //   // setotcT(e);
  //   let otcdata = e;
  //   dispatch(fetchOtc(otcdata));
  //   console.log("this is the search: ", otcdata);
  // };

  return usersdata.loading ? (
    <CircularLoading />
  ) : (
    ((
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    ),
    (
      <div>
        <>
          {/* <Dialog
            open={openFPModal}
            onClose={handleCloseEvent}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
            size="lg"
            centered={true}
          >
            <DialogTitle id="form-dialog-title">
              {"Over The Counter"}
            </DialogTitle>
            <DialogContent>
              <UsersCombo
                placeholder={"Select a OTC"}
                users={otcdata}
                // value={otc}
                onChange={() => onChangeHandle()}
              />
            </DialogContent>

            <DialogActions>
              <Button color="primary">Save</Button>
              <Button onClick={handleCloseEvent} color="primary">
                Close
              </Button>
              <Button color="primary" onClick={() => window.print(this)}>
                PRINT
              </Button>
            </DialogActions>
          </Dialog> */}
          <MaterialTable
            title={"Prescriptions"}
            columns={columns}
            data={data}
            options={{
              exportButton: true,
              sorting: true,
              actionsColumnIndex: -1,
            }}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  resolve();
                  setTimeout(() => {
                    let tempArr = data;
                    tempArr.push(newData);
                    setData([...tempArr]);
                    dispatch(
                      updateBooking({ ...details, prescription: tempArr })
                    );
                  }, 800);
                }),

              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    let tempArr = data;
                    const indexOf = data.indexOf(oldData);

                    console.log("newData", newData);

                    if (indexOf > -1) {
                      tempArr[indexOf] = newData;
                      setData([...tempArr]);
                      dispatch(
                        updateBooking({ ...details, prescription: tempArr })
                      );
                      resolve();
                    } else {
                      setCommonAlert({
                        open: true,
                        msg: "No note found cant update",
                      });
                      resolve();
                    }

                    dispatch(editUser(oldData.id, newData));
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    let tempArr = data;
                    const indexOf = data.indexOf(oldData);
                    if (indexOf > -1) {
                      tempArr.splice(indexOf, 1);
                      setData([...tempArr]);
                      dispatch(
                        updateBooking({ ...details, prescription: tempArr })
                      );
                      resolve();
                    } else {
                      setCommonAlert({
                        open: true,
                        msg: "No note found cant delete",
                      });
                      resolve();
                    }
                  }, 600);
                }),
            }}
            // actions={[
            //   () => ({
            //     icon: "Add",
            //     tooltip: "Add Medication",
            //     onClick: () => {
            //       setOpenFPModal(true);
            //     },
            //   }),
            // ]}
          />
        </>
      </div>
    ))
  );
}
