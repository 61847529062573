import languageJson from "./language.json";
import clinicalDiseaseJson from "./clinicaldiseases.json";
import icd10codeJson from "./icd10codes.json";
import treamentJson from "./Treatments.json";
import otcJson from "./otc.json";
import conditionJson from "./condition.json";
import prescriptionJson from "./prescriptionDrug.json";

// Goto https://www.w3schools.com/JSREF/jsref_tolocalestring.asp to find the date style for your country.
export const dateStyle = "en-US";

export const language = languageJson;
export const clinicaldisease = clinicalDiseaseJson;
export const icd10codes = icd10codeJson;
export const treatment = treamentJson;
export const otc = otcJson;
export const prescription = prescriptionJson;
export const condition = conditionJson;
