import { 
    FETCH_PROCEDURES,
    FETCH_PROCEDURES_SUCCESS,
    FETCH_PROCEDURES_FAILED,
    ADD_PROCEDURES,
    ADD_PROCEDURES_SUCCESS,
    ADD_PROCEDURES_FAILED,
    EDIT_PROCEDURES,
    EDIT_PROCEDURES_SUCCESS,
    EDIT_PROCEDURES_FAILED,
    DELETE_PROCEDURES,
    DELETE_PROCEDURES_SUCCESS,
    DELETE_PROCEDURES_FAILED,
      } from "../store/types";
      
      export const INITIAL_STATE = {
        device:null,
        loading: false,
        error:{
          flag:false,
          msg: null
        }
      }
      
      export const procedurereducer = (state = INITIAL_STATE, action) => {
        switch (action.type) {
          case FETCH_PROCEDURES:
            return {
              ...state,
              loading: true,
            };
          case FETCH_PROCEDURES_SUCCESS:
            return {
              ...state,
              device: action.payload,
              loading: false,
            };
          case FETCH_PROCEDURES_FAILED:
            return {
              ...state,
              device: null,
              loading: false,
              error: {
                flag: true,
                msg: action.payload,
              },
            };
            case ADD_PROCEDURES:
            return {
              ...state,
              loading: true,
            };
          case ADD_PROCEDURES_SUCCESS:
            return {
              ...state,
              device: action.payload,
              loading: false,
            };
          case ADD_PROCEDURES_FAILED:
            return {
              ...state,
              device: null,
              loading: false,
              error: {
                flag: true,
                msg: action.payload,
              },
            };
          case EDIT_PROCEDURES:
            return {
              ...state,
              loading: true,
            };
          case EDIT_PROCEDURES_SUCCESS:
            return {
              ...state,
              device: action.payload,
              loading: false,
            };
          case EDIT_PROCEDURES_FAILED:
            return {
              ...state,
              device: null,
              loading: false,
              error: {
                flag: true,
                msg: action.payload,
              },
            };
          case DELETE_PROCEDURES:
            return {
              ...state,
              loading: true,
            };
          case DELETE_PROCEDURES_SUCCESS:
            return {
              ...state,
              device: action.payload,
              loading: false,
            };
          case DELETE_PROCEDURES_FAILED:
            return {
              ...state,
              device: null,
              loading: false,
              error: {
                flag: true,
                msg: action.payload,
              },
            };
          default:
            return state;
        }
      };