/* eslint-disable default-case */
import {
  CREATE_MEETINGROOM,
  CREATE_MEETINGROOM_SUCCESS,
  CREATE_MEETINGROOM_FAIL,
  DELETE_MEETINGROOM,
  DELETE_MEETINGROOM_SUCCESS,
  DELETE_MEETINGROOM_FAIL,
} from "../store/types";
const INITIAL_STATE = {
  meetinglink: null,
  loading: false,
  error: {
    flag: false,
    msg: null,
  },
};

export const meetingroomreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_MEETINGROOM:
      return {
        ...state,
        loading: true,
      };
    case CREATE_MEETINGROOM_SUCCESS:
      return {
        ...state,
        meetinglink: action.payload,
        loading: false,
      };
    case CREATE_MEETINGROOM_FAIL: {
      return INITIAL_STATE;
    }
    case DELETE_MEETINGROOM:
      return {
        ...state,
        loading: true,
      };
    case DELETE_MEETINGROOM_SUCCESS:
      return {
        ...state,
        meetinglink: action.payload,
        loading: false,
      };
    case DELETE_MEETINGROOM_FAIL: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};
