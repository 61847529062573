import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";

import { authreducer as auth } from "../reducers/authreducer";
import { bookingoffersreducer as bookingoffersdata } from "../reducers/bookingoffersreducer";
import { practicetypesreducer as practicetypes } from "../reducers/practicetypesreducer";
import { specialitytypesreducer as specialitytypes } from "../reducers/specialitytypesreducer";
import { doctortypesreducer as doctortypes } from "../reducers/doctortypesreducer";
import { bookingslistreducer as bookinglistdata } from "../reducers/bookingslistreducer";
import { bookingreducer as bookingdata } from "../reducers/bookingreducer";
import { estimatereducer as estimatedata } from "../reducers/estimatereducer";
import { cancelreasonreducer as cancelreasondata } from "../reducers/cancelreasonreducer";
import { promoreducer as promodata } from "../reducers/promoreducer";
import { usersreducer as usersdata } from "../reducers/usersreducer";
import { notificationreducer as notificationdata } from "../reducers/notificationreducer";
import { doctorearningreducer as doctorearningdata } from "../reducers/doctorearningreducer";
import { earningreportsreducer as earningreportsdata } from "../reducers/earningreportsreducer";
import { settingsreducer as settingsdata } from "../reducers/settingsreducer";
import { paymentreducer as paymentmethods } from "../reducers/paymentreducer";
import { eventreducer as eventdata } from "../reducers/eventreducer";
import { tasklistreducer as taskdata } from "../reducers/tasklistreducer";
import { locationreducer as locationdata } from "../reducers/locationreducer";
import { chatreducer as chatdata } from "../reducers/chatreducer";
import { doctornotesreducer as doctornotesdata } from "../reducers/doctornotesreducer";
import { withdrawreducer as withdrawdata } from "../reducers/withdrawreducer";
import { gpsreducer as gpsdata } from "../reducers/gpsreducer";
import { treatmenttypesreducer as treatmenttypes } from "../reducers/TreatmentTypeReducer";
import { medicalHistoryReducer as medicalRecords } from "../reducers/medicalhistoryreducer";
import { meetingroomreducer as meetingroomdata } from "../reducers/meetingroomreducer";
import { openfireuserreducer as openfireuserdata } from "../reducers/openfireuserreducer";
import { referralsreducer as referrals } from "../reducers/referralsreducer";
import { referraldoctorsreducer as referraldoctors } from "../reducers/referraldoctorsreducer";
import { Doctorsreducer as doctors } from "../reducers/Doctorsreducer";
import { icdcodesreducer as icdcodes } from "../reducers/icdcodesreducer";
import { drugexposurereducer as drugdata } from "../reducers/drugexposurereducer";
import { deviceexposurereducer as devicedata } from "../reducers/deviceexposurereducer";
import { mednotesreducer as mednotesdata } from "../reducers/mednotesreducer";
import { observationreducer as observedata} from "../reducers/observationsreducer";
import { prescriptionreducer as prescriptdata} from "../reducers/prescriptionreducer";
import { procedurereducer as proceduredata } from "../reducers/proceduresreducer";

const reducers = combineReducers({
  auth,
  practicetypes,
  treatmenttypes,
  bookinglistdata,
  estimatedata,
  bookingdata,
  cancelreasondata,
  promodata,
  usersdata,
  bookingoffersdata,
  meetingroomdata,
  notificationdata,
  doctorearningdata,
  earningreportsdata,
  settingsdata,
  doctors,
  paymentmethods,
  eventdata,
  taskdata,
  locationdata,
  chatdata,
  doctornotesdata,
  withdrawdata,
  medicalRecords,
  gpsdata,
  specialitytypes,
  doctortypes,
  referrals,
  referraldoctors,
  openfireuserdata,
  icdcodes,
  drugdata,
  devicedata,
  mednotesdata,
  observedata,
  prescriptdata,
  proceduredata,
});

let middleware = [thunk];

export const store = createStore(
  reducers,
  {},
  composeWithDevTools(applyMiddleware(...middleware))
);
