export const MainConfig = {
  AppDetails: {
    app_name: "Okdoc-App",
    app_description: "Medical Health Ecosystem",
    app_identifier: "za.co.okdoc.okdoc",
    ios_app_version: "1.0.0",
    android_app_version: 1,
  },
  FirebaseConfig: {
    apiKey: "AIzaSyBen6UKqSASC0pklgdXzNQS1xZlq-RjwnI",
    authDomain: "okdoc-ac3c0.firebaseapp.com",
    databaseURL:
      "https://okdoc-ac3c0-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "okdoc-ac3c0",
    storageBucket: "okdoc-ac3c0.appspot.com",
    messagingSenderId: "430295662772",
    appId: "1:430295662772:web:7a94ca2987e0909f4717e1",
    measurementId: "G-WTTTMS616M",
  },
  Google_Map_Key: "AIzaSyBLBjN180jIg5hjpedODtdgCuqHQhdbFew",

  Google_Calendar_Key: "AIzaSyBLBjN180jIg5hjpedODtdgCuqHQhdbFew",
  Calendar_Client_Id:
    "430295662772-sqbdmak3i8cgtdep1sealud32bqm717j.apps.googleusercontent.com",
  Calendar_Discovery_Docs:
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  Calender_Scope: "https://www.googleapis.com/auth/calendar.events",

  facebookAppId: "2844848755765608",
  PurchaseDetails: {
    CodeCanyon_Purchase_Code: "72a20f0d-c950-4ef9-9e1e-478174037339",
    Buyer_Email_Address: "lucien@plum.systems",
  },
  // {
  //     "clientId": "1:430295662772:web:7a94ca2987e0909f4717e1",
  //     Google_Calendar_Api: "AIzaSyDrU0ml2eiquhBKAbDxvWW2AvTXdn4N6PM",
  //     "scope": "https://www.googleapis.com/auth/calendar",
  //     "discoveryDocs": [
  //       "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  //     ]
  //   }
};
