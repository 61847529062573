/*eslint-disable*/
import React, { useContext, useState } from "react";
// react component used to create a calendar with events on it
import { Calendar as BigCalendar, dateFnsLocalizer } from "react-big-calendar";
// dependency plugin for react-big-calendar

// import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import Email from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import ChatIcon from "@material-ui/icons/ChatBubbleOutlineRounded";
import { VideoCall } from "@material-ui/icons";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import DatePicker from "react-datetime";
import "react-datepicker/dist/react-datepicker.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CustomInput from "components/CustomInput/CustomInput.js";
import { FirebaseContext } from "common";
import { useSelector, useDispatch } from "react-redux";
import { FormControl, Select, MenuItem, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import Symptoms from "./Symptoms";
// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  CardTitle,
  CardHeader,
  Button,
  CardFooter,
  Label,
} from "reactstrap";
import { language } from "config";
import { useNavigate } from "react-router-dom";
const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const events = [
  {
    title: "Conference",
    start: new Date(2022, 2, 20),
    end: new Date(2022, 2, 23),
    serviceType: "",
    practiceType: "",
    description: "",
  },
];

export default function Calendar(props) {
  const { api } = useContext(FirebaseContext);
  const {
    getEstimate,
    clearEstimate,
    addBooking,
    clearBooking,
    MinutesPassed,
    GetDateString,
    fetchUser,
    updateBooking,
    cancelBooking,
  } = api;
  const dispatch = useDispatch();
  const practicetypes = useSelector((state) => state.practicetypes.practices);
  const estimatedata = useSelector((state) => state.estimatedata);
  const bookingdata = useSelector((state) => state.bookingdata);
  const userdata = useSelector((state) => state.usersdata);
  const settings = useSelector((state) => state.settingsdata.settings);
  const [practiceType, setPracticeType] = useState(language.select_practice);
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropAddress, setDropAddress] = useState(null);
  const [estimateModalStatus, setEstimateModalStatus] = useState(false);
  const [selectedPracticeDetails, setSelectedPracticeDetails] = useState(null);
  const [users, setUsers] = useState(null);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [userCombo, setUserCombo] = useState(null);
  const [estimateRequested, setEstimateRequested] = useState(false);
  const [bookingType, setBookingType] = useState(" ");
  const [selectedDate, setSelectedDate] = useState(GetDateString());
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [bookingTypes, setBookingTypes] = useState("");
  const [practicesType, setPracticesType] = useState("");
  const [serviceTypes, setServiceTypes] = useState("");
  const [description, setDescription] = useState("");

  const [newEvent, setNewEvent] = useState({
    title: bookingTypes,
    start: new Date(),
    end: new Date(),
    service: serviceTypes,
    practice: practicesType,
    description: description,
  });
  let bookingData = newEvent;

  // console.log(bookingData);
  const [allEvents, setAllEvents] = useState(events);
  const [openFPModal, setOpenFPModal] = useState(false);
  const handleAddEvent = () => {
    setAllEvents([...allEvents, newEvent]);
    //todo dispatch create booking use newEvent as payload
    let bookingData = {
      //pickup: pickupAddress,
      //drop: dropAddress,
      title: newEvent.title,
      startDate: newEvent.start,
      endDate: newEvent.end,
      service: newEvent.service,
      practiceDetails: selectedPracticeDetails,
      //estimate: estimatedata.estimate,
      eventdate: new Date(selectedDate).toString(),
      bookLater: bookingType === "Book Later" ? true : false,
      settings: settings,
      booking_type_web: true,
      markedDot: {
        date: `${moment(selectedDate).format("YYYY")}-${moment(
          selectedDate
        ).format("MM")}-${moment(selectedDate).format("DD")}`,
        dots: [
          {
            key: uuidv4(),
            color: "#ff0000",
            selectedDotColor: "#ff0000",
          },
        ],
      },
    };
    // console.log(bookingData);
    dispatch(addBooking(bookingData));
  };

  const confirmBooking = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    let bookingObject = {
      pickup: pickupAddress,
      drop: dropAddress,
      practiceDetails: selectedPracticeDetails,
      userDetails: {
        uid: userCombo.uid,
        profile: {
          firstName: userCombo.firstName,
          lastName: userCombo.lastName,
          mobile: userCombo.mobile,
          pushToken: userCombo.pushToken,
        },
      },
      estimate: estimatedata.estimate,
      eventdate: new Date(selectedDate).toString(),
      bookLater: bookingType === "Book Later" ? true : false,
      settings: settings,
      booking_type_web: true,
    };
    dispatch(addBooking(bookingObject));
  };

  const handlePracSelect = (event) => {
    setPracticeType(event.target.value);
    let practiceDetails = null;
    for (let i = 0; i < practicetypes.length; i++) {
      if (practicetypes[i].name === event.target.value) {
        practiceDetails = practicetypes[i];
      }
    }
    setSelectedPracticeDetails(practiceDetails);
  };
  const onVideoSelect = (event) => {
    // console.log("Video Conference");
    navigate("/videoconference");
  };
  const onPhoneSelect = (event) => {
    console.log("Phone Call");
  };
  const onChatSelect = () => {
    navigate("/chats");
  };
  const handleSlot = (slotInfo) => {
    //window.alert(event.title, event.treatment, event.practice);
    
    console.log(slotInfo);
    //setOpenFPModal(true);
    
  };
  const selectedEvent = (event) => {
    //window.alert(event.title, event.treatment, event.practice);
    console.log(
      "Title: " + event.title,
      "Type of treatment: " + event.service,
      "Practice: " + event.practice,
      "Doctor: "
    );
    console.log(event);
    //setOpenFPModal(true);
    if (openFPModal === true) {
    }
  };
  const handleCloseEvent = (e) => {
    e.preventDefault();
    setOpenFPModal(false);
  };

  const eventColors = (event, start, end, isSelected) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  };
  // useEffect(()=>{
  //   if(practicetypes.practices){
  //       let obj =  {};
  //       practicetypes.practices.map((practice)=> obj[practice.name]=practice.name)
  //       setPractice(obj);
  //   }
  // },[practicetypes.practices]);
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">My Health Calendar</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#" method="#">
                  <Row>
                    <Col md="3">
                      {/* <Label>Urgency</Label> */}
                      <FormGroup>
                        <FormControl style={{ width: "100%" }}>
                          <CustomInput
                            labelText={"Booking Name"}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            type="text"
                            placeholder="Add Booking Name"
                            onChange={(e) =>
                              setNewEvent({
                                ...newEvent,
                                title: e.target.value,
                              })
                            }
                            value={newEvent.title}
                          />
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <CustomInput
                        labelText={"Description"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        type="text"
                        placeholder="Add Description"
                        onChange={(e) =>
                          setNewEvent({
                            ...newEvent,
                            description: e.target.value,
                          })
                        }
                        value={newEvent.description}
                      />
                    </Col>
                    <Col md="3">
                      <Label>Start Date</Label>
                      <DatePicker
                        value={newEvent.start}
                        timeConstraints={{
                          hours: { min: 9, max: 18, step: 0.25 },
                        }}
                        // selected={startDate}
                        // onChange={(date) => setStartDate(date)}
                        onChange={(start) =>
                          setNewEvent({ ...newEvent, start })
                        }
                        // showTimeSelect
                        // filterTime={filterPassedTime}
                        // dateFormat="MMMM d, yyyy h:mm aa"
                      />
                    </Col>
                    <Col md="3">
                      <Label>End Date</Label>
                      <DatePicker
                        placeholderText="End Date"
                        timeConstraints={{
                          hours: { min: 9, max: 18, step: 0.25 },
                        }}
                        value={newEvent.end}
                        showTimeSelect
                        onChange={(end) => setNewEvent({ ...newEvent, end })}
                        // dateFormat="MMMM d, yyyy h:mm aa"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <Label>Type of Service</Label>
                      <FormGroup>
                        <FormControl style={{ width: "100%" }}>
                          <Select
                            // className="react-select primary"
                            // classNamePrefix="react-select"
                            placeholder="Select Service Type..."
                            id="practice-type-native"
                            name="singleSelect"
                            value={newEvent.service}
                            onChange={(e) =>
                              setNewEvent({
                                ...newEvent,
                                service: e.target.value,
                              })
                            }
                          >
                            <option value="videoconference">
                              Video Conference
                            </option>
                            <option value="home">Home Consultation</option>
                            <option value="surgery">
                              Surgery Consultation
                            </option>
                            <option value="medication">
                              Medication Delivery
                            </option>
                            <option value="prenatel">
                              {" "}
                              Antenatal Care Assessment A
                            </option>
                            <option value="prenatel">
                              {" "}
                              Antenatal Care Assessment B
                            </option>
                            <option value="prenatel">
                              {" "}
                              Antenatal Care Assessment c
                            </option>
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Col>

                    <Col md="4">
                      <Label>Type of Practice </Label>
                      <FormGroup>
                        <FormControl style={{ width: "100%" }}>
                          <Select
                            id="practice-type-native"
                            value={newEvent.practice}
                            onChange={(e) =>
                              setNewEvent({
                                ...newEvent,
                                practice: e.target.value,
                              })
                            }
                            // className={practiceType === language.select_car ? classes.inputdimmed : classes.input}
                          >
                            {/* <MenuItem value={language.select_car} key={language.select_car}>
                               {language.select_car}
                            </MenuItem> */}
                            {practicetypes.map((practice) => (
                              <MenuItem
                                key={practice.name}
                                value={practice.name}
                              >
                                {practice.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Col>

                    {/* <Col md="4">
                      <Label>List of Doctors</Label>

                      <FormGroup>
                        <FormControl style={{ width: "100%" }}>
                          <Select
                            // className="react-select primary"
                            // classNamePrefix="react-select"
                            // placeholder="Select Treatment Type..."
                            name="singleSelect"
                            value={newEvent.userType}
                            // options={doctorOptions}
                            onChange={(e) =>
                              setNewEvent({
                                ...newEvent,
                                userType: e.target.value,
                              })
                            }
                          >
                            <option value="naidoo">Dr Naidoo</option>
                            <option value="arinze">Dr Arinze</option>
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Col> */}
                  </Row>
                  <Row>
                    <Dialog
                      open={openFPModal}
                      onClose={handleCloseEvent}
                      aria-labelledby="form-dialog-title"
                      maxWidth="md"
                    >
                      <DialogTitle id="form-dialog-title">
                        {"Event Details"}
                      </DialogTitle>
                      <DialogContent>
                        {/* <DialogContentText>
                          <Label>{newEvent.title}</Label>
                        </DialogContentText>
                        <DialogContentText>
                          <p>{newEvent.description}</p>
                          <hr />
                          <p>{newEvent.start}</p>
                          <hr />
                          <p>{newEvent.end} </p>
                          <hr />
                          <p>{newEvent.practice}</p>
                          <hr />
                          <p>{newEvent.service}</p>
                        </DialogContentText>
                        <DialogContentText>
                           <Symptoms/> 
                        </DialogContentText>*/}
                        <DialogContentText>
                          <Button
                            className="btn-icon btn-round"
                            color="neutral"
                            href="#pablo"
                            onClick={onPhoneSelect}
                            size="lg"
                          >
                            <PhoneIcon />
                          </Button>
                          <Button
                            className="btn-icon btn-round"
                            color="neutral"
                            href="#pablo"
                            onClick={onChatSelect}
                            size="lg"
                          >
                            <ChatIcon />
                          </Button>
                          <Button
                            className="btn-icon btn-round"
                            color="neutral"
                            href="#pablo"
                            onClick={onVideoSelect}
                            size="lg"
                          >
                            <VideoCall />
                          </Button>
                        </DialogContentText>
                        <DialogContentText></DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button color="primary">{"Make Booking"}</Button>
                        <Button onClick={handleCloseEvent} color="primary">
                          {language.cancel}
                        </Button>
                        {/* <Button onClick={handleResetPassword} color="primary">
                      {language.reset_password}
                    </Button> */}
                      </DialogActions>
                    </Dialog>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-fill"
                  color="primary"
                  type="submit"
                  onClick={handleAddEvent}
                >
                  ADD
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <BigCalendar
                  selectable
                  localizer={localizer}
                  events={allEvents}
                  step={20}
                  timeslots={3}
                  startAccessor="start"
                  endAccessor="end"
                  // style={{ height: 500, margin: "50px" }}
                  defaultView={"day"}
                  scrollToTime={new Date(2020, 1, 1, 6)}
                  defaultDate={new Date()}
                  onSelectEvent={(event) => selectedEvent(event)}
                  onSelectSlot={(slotInfo) => handleSlot(slotInfo)}
                  eventPropGetter={eventColors}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

// export default Calendar;
