/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";

import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../../components/CircularLoading";

import "react-datepicker/dist/react-datepicker.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import { features, dateStyle, language } from "config";
import { FirebaseContext } from "common";
import NoPhonePatientsDependants from "./NoPhonePatientDependants";
import DocumentScanner from "../../components/DocumentScanner";
import FileUploader from "components/FileUploader";
export default function NoPhonePatients() {
  const { api } = useContext(FirebaseContext);
  const {
    addUser,
    editUser,
    deleteUser,
    checkUserExists,
    updateUserConsentform,
  } = api;
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [SelectedUserData, SetSelectedUserData] = useState({});
  const [camStop, setCamStop] = React.useState(false);
  const [dataUri, setDataUri] = useState("");
  const [data, setData] = useState([]);
  const [practices, setPractices] = useState({});
  const usersdata = useSelector((state) => state.usersdata);
  const practicetypes = useSelector((state) => state.practicetypes);
  const auth = useSelector((state) => state.auth);
  const [openFPModal, setOpenFPModal] = React.useState(false);
  const [openFPModal1, setOpenFPModal1] = React.useState(false);
  const dispatch = useDispatch();
  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };

  const handleCloseEvent = () => {
    setOpenFPModal(false);
  };
  const handleCloseAndUploadEvent = () => {
    if (dataUri) {
      console.log("i am the selected userdata", SelectedUserData, dataUri);
      dispatch(updateUserConsentform(SelectedUserData.id, dataUri));
    }

    setOpenFPModal1(false);
  };

  useEffect(() => {
    console.log("i am the auth", auth);
    console.log("i am the users", usersdata.users);
    console.log("first", auth.info.profile.practiceNumber);
    if (usersdata.users) {
      setData(
        usersdata.users.filter(
          (user) =>
            (user.userType === "nophonepatient" || user.userType === "patient") &&
            user.practiceNumber === auth.info.profile.practiceNumber
        )
      );
    } else {
      setData(usersdata.users);
    }
  }, [usersdata.users, auth.info.profile.userType, auth.info.uid]);

  useEffect(() => {
    if (practicetypes.practices) {
      let obj = {};
      practicetypes.practices.map(
        (practice) => (obj[practice.name] = practice.name)
      );
      setPractices(obj);
    }
  }, [practicetypes.practices]);

  const columns = [
    { title: language.first_name, field: "firstName", initialEditValue: "" },
    { title: language.last_name, field: "lastName", initialEditValue: "" },
    {
      title: language.email,
      field: "email",
      editable: "onAdd",
      render: (rowData) =>
        features.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo",
    },
    {
      title: language.mobile,
      field: "mobile",
      editable: "onAdd",
      render: (rowData) =>
        features.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo",
    },
    {
      title: "Identification Number",
      field: "identityNumber",
      editable: "onAdd",
      render: (rowData) =>
        features.AllowCriticalEditsAdmin
          ? rowData.identityNumber
          : "Hidden for Demo",
    },
    { title: "Gender", field: "gender", initialEditValue: "" },
    { title: "Age", field: "age", initialEditValue: "" },
    {
      title: "Relationship",
      field: "relationship",
      editable: "onAdd",
      initialEditValue: "",
    },
    // {
    //   title: language.referralId,
    //   field: "referralId",
    //   editable: "never",
    //   initialEditValue: "",
    // },

    {
      title: language.account_approve,
      field: "approved",
      type: "boolean",
      initialEditValue: true,
    },
    {
      title: language.signup_via_referral,
      field: "signupViaReferral",
      editable: "never",
    },

    {
      title: "Consent Form",
      field: "consentForm",
      editable: "onAdd",
      initialEditValue: "",
      render: (rowData) => (
        <FileUploader
          setIsSelected={setIsSelected}
          isSelected={isSelected}
          setIsFilePicked={setIsSelected}
          isFilePicked={isFilePicked}
          setSelectedFile={setSelectedFile}
          selectedFile={selectedFile}
        />
      ),
    },
  ];
  return usersdata.loading ? (
    <CircularLoading />
  ) : (
    <div>
      <Dialog
        open={openFPModal}
        onClose={handleCloseEvent}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        size="lg"
        centered={true}
      >
        <DialogTitle id="form-dialog-title">{"Add Dependants"}</DialogTitle>
        <DialogContent>
          <NoPhonePatientsDependants ParentData={SelectedUserData} />
        </DialogContent>

        <DialogActions>
          {/* <Button color="primary">Save</Button> */}
          <Button onClick={handleCloseEvent} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openFPModal1}
        onClose={handleCloseAndUploadEvent}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        size="lg"
        centered={true}
      >
        <DialogTitle id="form-dialog-title">{"upload image"}</DialogTitle>
        <DialogContent>
          <DocumentScanner
            setDataUri={setDataUri}
            camStop={camStop}
            setCamStop={setCamStop}
            dataUri={dataUri}
            setOpenFPModal={setOpenFPModal1}
            openFPModal={openFPModal1}
          />
        </DialogContent>

        <DialogActions>
          {/* <Button color="primary">Save</Button> */}
          <Button onClick={handleCloseAndUploadEvent} color="primary">
            Save Form
          </Button>
        </DialogActions>
      </Dialog>

      <MaterialTable
        title={"Patients"}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          sorting: true,
          actionsColumnIndex: -1,
        }}
        actions={[
          (rowData) => ({
            icon: "library_add",
            tooltip: "Add/View dependants",
            onClick: (event, rowData) => {
              setOpenFPModal(true);
              SetSelectedUserData(rowData);
            },
          }),
          (rowData) => ({
            icon: "save",
            tooltip: "Upload Consent From",
            onClick: (event) => {
              setOpenFPModal1(true);
              SetSelectedUserData(rowData);
            },
          }),
        ]}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                checkUserExists(newData).then((res) => {
                  if (res.users && res.users.length > 0) {
                    alert(language.user_exists);
                    reject();
                  } else if (res.error) {
                    alert(language.email_or_mobile_issue);
                    reject();
                  } else {
                    newData["createdByAdmin"] = true;
                    newData["userType"] = "nophonepatient";
                    newData["createdAt"] = new Date().toISOString();
                    newData["referralId"] =
                      newData.firstName.toLowerCase() +
                      Math.floor(1000 + Math.random() * 9000).toString();
                    let role = auth.info.profile.userType;
                    if (role === "practiceadmin") {
                      newData["practiceadmin"] = auth.info.uid;
                      newData["practiceNumber"] =
                        auth.info.profile.practiceNumber;
                    } else if (role === "nurse") {
                      newData["nurse"] = auth.info.uid;
                      newData["practiceNumber"] =
                        auth.info.profile.practiceNumber;
                    } else if (role === "doctor") {
                      newData["doctor"] = auth.info.uid;
                      newData["practiceNumber"] =
                        auth.info.profile.practiceNumber;
                    }
                    dispatch(addUser(newData));
                    resolve();
                  }
                });
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                dispatch(editUser(oldData.id, newData));
              }, 600);
            }),

          onRowDelete: (oldData) =>
            features.AllowCriticalEditsAdmin
              ? new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    dispatch(deleteUser(oldData.id));
                  }, 600);
                })
              : new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    alert(language.demo_mode);
                  }, 600);
                }),
        }}
      />
    </div>
  );
}
