/*eslint-disable*/
import CircularLoading from "../../../components/CircularLoading";
import { Select } from "@material-ui/core";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  FormText,
  Row,
  Col,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "common";
import CustomInput from "components/CustomInput/CustomInput";
import AlertDialog from "../../../components/AlertDialog";
import MenuItem from "@material-ui/core/MenuItem";
function PatientBasic(props) {
  const { api } = useContext(FirebaseContext);
  const { editMedicalHistory } = api;
  const dispatch = useDispatch();
  const medical = useSelector(
    (state) => state.medicalRecords.medicalHistoryRecords
  );
  const auth = useSelector((state) => state.auth);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [medicalData, setMedicalData] = useState(medical);

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setMedicalData(medical);
    }
  }, [medical.Basic_info]);
  const OnSave = () => {
    let payload = {
      Medical_Information: {
        ...medical,
        Basic_info: medicalData?.Basic_info ? medicalData?.Basic_info : null,
      },
    };
    console.log("this is basic payload:", payload);
    dispatch(editMedicalHistory(payload, "Add medical history"));
    setCommonAlert({
      open: true,
      msg: "Your Basic Information has Been Saved",
    });
  };
  return auth.loading ? (
    <CircularLoading />
  ) : (
    <>
      <div className="content">
        <div className="medicalhistory-form">
          <Row>
            <AlertDialog
              open={commonAlert.open}
              onClose={handleCommonAlertClose}
            >
              {commonAlert.msg}
            </AlertDialog>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Basic Information</CardTitle>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <FormText color="default" tag="span">
                      Please Fill in you basic Information:
                    </FormText>
                  </FormGroup>
                  <Row mb="12">
                    {/* //sexdropdown */}
                    <Col md="4">
                      <FormGroup fullWidth>
                        <FormText color="default" tag="span">
                          Select Gender
                        </FormText>
                      </FormGroup>
                      <Select
                        // styles={{ fullWidth: "30px" }}
                        fullWidth
                        onChange={(event) => {
                          setMedicalData({
                            ...medicalData,
                            Basic_info: {
                              ...medicalData.Basic_info,
                              Sex: event.target.value,
                            },
                          });
                        }}
                        value={medicalData?.Basic_info?.Sex}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                      </Select>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <FormText color="default" tag="span">
                          Date of Birth
                        </FormText>
                      </FormGroup>
                      <Input
                        type="date"
                        placeholder="Date of Birth"
                        onChange={(event) => {
                          setMedicalData({
                            ...medicalData,
                            Basic_info: {
                              ...medicalData.Basic_info,
                              DOB: event.target.value,
                            },
                          });
                        }}
                        value={medicalData?.Basic_info?.DOB}
                      />
                    </Col>
                    {/* /////////////////////////////////////////////////////////////////////// */}
                  </Row>
                  <Row>
                    <Col mb="4">
                      <FormGroup>
                        <FormText color="default" tag="span">
                          Weight
                        </FormText>
                      </FormGroup>
                      <Input
                        type="number"
                        onChange={(event) => {
                          setMedicalData({
                            ...medicalData,
                            Basic_info: {
                              ...medicalData.Basic_info,
                              Weight: event.target.value,
                            },
                          });
                        }}
                        value={medicalData?.Basic_info?.Weight}
                      />
                    </Col>
                    <Col mb="4">
                      <FormGroup>
                        <FormText color="default" tag="span">
                          height(in cm)
                        </FormText>
                      </FormGroup>
                      <Input
                        type="number"
                        onChange={(event) => {
                          setMedicalData({
                            ...medicalData,
                            Basic_info: {
                              ...medicalData.Basic_info,
                              Height: event.target.value,
                            },
                          });
                        }}
                        value={medicalData?.Basic_info?.Height}
                      />
                    </Col>
                    <Col md="4">
                      <FormGroup fullWidth>
                        <FormText color="default" tag="span">
                          Select Blood Type:
                        </FormText>
                      </FormGroup>
                      <Select
                        // styles={{ fullWidth: "30px" }}
                        fullWidth
                        onChange={(event) => {
                          setMedicalData({
                            ...medicalData,
                            Basic_info: {
                              ...medicalData.Basic_info,
                              BloodType: event.target.value,
                            },
                          });
                        }}
                        value={medicalData?.Basic_info?.BloodType}
                      >
                        <MenuItem value="A+">A+</MenuItem>
                        <MenuItem value="A-">A-</MenuItem>
                        <MenuItem value="B+">B+</MenuItem>
                        <MenuItem value="B-">B-</MenuItem>
                        <MenuItem value="AB+">AB+</MenuItem>
                        <MenuItem value="AB-">AB-</MenuItem>
                        <MenuItem value="O+">O+</MenuItem>
                        <MenuItem value="O-">O-</MenuItem>
                      </Select>
                    </Col>
                  </Row>
                  <Col>
                    <Button variant="gradient" color="primary" onClick={OnSave}>
                      SAVE
                    </Button>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default PatientBasic;
