/*eslint-disable*/
import React from "react";
import { Input, Button, InputGroup } from "reactstrap";
import { Send } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  footer: {
    paddingTop: "550px",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "50px",
  },
}));

const NurseTreamentNotes = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.footer}>
        <InputGroup>
          <Input type="textarea" placeholder="Enter Treatment notes" />
          <Button simple color="primary">
            <Send style={{ color: "#ffffff" }} />
          </Button>
        </InputGroup>
      </div>
    </>
  );
};
export default NurseTreamentNotes;
