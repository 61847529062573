/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../../components/CircularLoading";
import { features, dateStyle, language } from "config";
import { FirebaseContext } from "common";
import AlertDialog from "../../components/AlertDialog";
import { useNavigate, useLocation } from "react-router-dom";

export default function DeviceExposure({ Booking }) {
  const { api } = useContext(FirebaseContext);
  const {
    addUser,
    editUser,
    deleteUser,
    checkUserExists,
    emailSignUp,
    clearLoginError,
    updateProfile,
    updateBooking,
  } = api;
  const [state, setState] = useState({
    device_exposure_id: " ",
    person_id: "",
    device_concept_id: "",
    device_exposure_start_date: "",
    device_exposure_start_datetime: "",
    device_exposure_end_date: "",
    device_exposure_end_datetime: "",
    device_type_concept_id: "",
    unique_device_id: "",
    production_id: "",
    quantity: "",
    provider_id: "",
    visit_occurrence_id: "",
    visit_detail_id: "",
    device_source_value: "",
    device_source_concept_id: "",
    unit_concept_id: "",
    unit_source_value: "",
    unit_source_concept_id: "",
  });

  const specialities = useSelector(
    (state) => state.specialitytypes.specialities
  );
  const location = useLocation();
  const [updatebooking, setupdatebooking] = useState(Booking);
  const [details, setdetails] = useState(location.state.Booking);
  const [specialityTypes, setSpecialityTypes] = useState({});
  const auth = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const usersdata = useSelector((state) => state.usersdata);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });

    useEffect(() => {
      // const arr = [];
      // console.log("auth.info.profile", auth.info.profile);
      if (updatebooking) {
        setData([updatebooking.deviceExposure]);
      } else {
        setData([]);
      }

      if (details) {
        setData([details.deviceExposure]);
      } else {
        setData([]);
      }
      console.log("bookings: ", data);
    }, [updatebooking]);
  const columns = [
    {
      title: language.device_id,
      field: "device_exposure_id",
      initialEditValue: "",
    },
    { title: language.personid, field: "person_id", initialEditValue: "" },
    {
      title: language.deviceconceptid,
      field: "device_concept_id",
      initialEditValue: "",
    },
    {
      title: language.startdate,
      field: "device_exposure_start_date",
      initialEditValue: "",
    },
    {
      title: language.start_datetime,
      field: "device_exposure_start_datetime",
      initialEditValue: "",
    },
    {
      title: language.enddate,
      field: "device_exposure_end_date",
      initialEditValue: "",
    },
    {
      title: language.end_datetime,
      field: "device_exposure_end_datetime",
      initialEditValue: "",
    },
    {
      title: language.devicetype_concept_id,
      field: "device_type_concept_id",
      initialEditValue: "",
    },
    {
      title: language.unique_device_id,
      field: "unique_device_id",
      initialEditValue: "",
    },
    {
      title: language.production_id,
      field: "production_id",
      initialEditValue: "",
    },
    { title: language.quantity, field: "quantity", initialEditValue: "" },
    { title: language.provider_id, field: "provider_id", initialEditValue: "" },
    {
      title: language.visit_occurrence_id,
      field: "visit_occurrence_id",
      initialEditValue: "",
    },
    {
      title: language.visit_detail_id,
      field: "visit_detail_id",
      initialEditValue: "",
    },
    {
      title: language.device_source_value,
      field: "device_source_value",
      initialEditValue: "",
    },
    {
      title: language.device_source_concept_id,
      field: "device_source_concept_id",
      initialEditValue: "",
    },
    {
      title: language.unit_concept_id,
      field: "unit_concept_id",
      initialEditValue: "",
    },
    {
      title: language.unit_source_value,
      field: "unit_source_value",
      initialEditValue: "",
    },
    {
      title: language.unit_source_concept_id,
      field: "unit_source_concept_id",
      initialEditValue: "",
    },
  ];
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
    if (auth.error.flag) {
      dispatch(clearLoginError());
    }
  };

  return usersdata.loading ? (
    <CircularLoading />
  ) : (
    ((
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    ),
    (
      <MaterialTable
        title={"Device Exposure"}
        columns={columns}
        data={[data]}
        options={{
          exportButton: true,
          sorting: true,
          actionsColumnIndex: -1,
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              resolve();
              setTimeout(() => {
                dispatch(
                  updateBooking({ ...updatebooking, deviceExposure: newData })
                );
              }, 600);
            }),

          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                dispatch(editUser(oldData.id, newData));
              }, 600);
            }),
          onRowDelete: (oldData) =>
            features.AllowCriticalEditsAdmin
              ? new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    dispatch(deleteUser(oldData.id));
                  }, 600);
                })
              : new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    alert(language.demo_mode);
                  }, 600);
                }),
        }}
      />
    ))
  );
}
