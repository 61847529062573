/*eslint-disable*/
/* eslint-disable quotes */
import React, { useState } from "react";

function FileUploader() {
  const [selectedFile, setSelectedFile] = useState();

  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);

    setIsSelected(true);
  };

  const handleSubmission = () => {};

  return (
    <div>
      <input type="file" name="file" onChange={changeHandler} />

      {isSelected ? <div></div> : <p>Select file you want to upload</p>}

      <div>
        <button onClick={handleSubmission}>Submit</button>
      </div>
    </div>
  );
}
export default FileUploader;
