import { 
    FETCH_OBSERVATIONS,
    FETCH_OBSERVATIONS_SUCCESS,
    FETCH_OBSERVATIONS_FAILED,
    ADD_OBSERVATIONS,
    ADD_OBSERVATIONS_SUCCESS,
    ADD_OBSERVATIONS_FAILED,
    EDIT_OBSERVATIONS,
    EDIT_OBSERVATIONS_SUCCESS,
    EDIT_OBSERVATIONS_FAILED,
    DELETE_OBSERVATIONS,
    DELETE_OBSERVATIONS_SUCCESS,
    DELETE_OBSERVATIONS_FAILED,
      } from "../store/types";
      
      export const INITIAL_STATE = {
        device:null,
        loading: false,
        error:{
          flag:false,
          msg: null
        }
      }
      
      export const observationreducer = (state = INITIAL_STATE, action) => {
        switch (action.type) {
          case FETCH_OBSERVATIONS:
            return {
              ...state,
              loading: true,
            };
          case FETCH_OBSERVATIONS_SUCCESS:
            return {
              ...state,
              device: action.payload,
              loading: false,
            };
          case FETCH_OBSERVATIONS_FAILED:
            return {
              ...state,
              device: null,
              loading: false,
              error: {
                flag: true,
                msg: action.payload,
              },
            };
            case ADD_OBSERVATIONS:
            return {
              ...state,
              loading: true,
            };
          case ADD_OBSERVATIONS_SUCCESS:
            return {
              ...state,
              device: action.payload,
              loading: false,
            };
          case ADD_OBSERVATIONS_FAILED:
            return {
              ...state,
              device: null,
              loading: false,
              error: {
                flag: true,
                msg: action.payload,
              },
            };
          case EDIT_OBSERVATIONS:
            return {
              ...state,
              loading: true,
            };
          case EDIT_OBSERVATIONS_SUCCESS:
            return {
              ...state,
              device: action.payload,
              loading: false,
            };
          case EDIT_OBSERVATIONS_FAILED:
            return {
              ...state,
              device: null,
              loading: false,
              error: {
                flag: true,
                msg: action.payload,
              },
            };
          case DELETE_OBSERVATIONS:
            return {
              ...state,
              loading: true,
            };
          case DELETE_OBSERVATIONS_SUCCESS:
            return {
              ...state,
              device: action.payload,
              loading: false,
            };
          case DELETE_OBSERVATIONS_FAILED:
            return {
              ...state,
              device: null,
              loading: false,
              error: {
                flag: true,
                msg: action.payload,
              },
            };
          default:
            return state;
        }
      };