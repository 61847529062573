import { 
    FETCH_PRESCRIPTIONS,
    FETCH_PRESCRIPTIONS_SUCCESS,
    FETCH_PRESCRIPTIONS_FAILED,
    ADD_PRESCRIPTIONS,
    ADD_PRESCRIPTIONS_SUCCESS,
    ADD_PRESCRIPTIONS_FAILED,
    EDIT_PRESCRIPTIONS,
    EDIT_PRESCRIPTIONS_SUCCESS,
    EDIT_PRESCRIPTIONS_FAILED,
    DELETE_PRESCRIPTIONS,
    DELETE_PRESCRIPTIONS_SUCCESS,
    DELETE_PRESCRIPTIONS_FAILED,
      } from "../store/types";
      
      export const INITIAL_STATE = {
        device:null,
        loading: false,
        error:{
          flag:false,
          msg: null
        }
      }
      
      export const prescriptionreducer = (state = INITIAL_STATE, action) => {
        switch (action.type) {
          case FETCH_PRESCRIPTIONS:
            return {
              ...state,
              loading: true,
            };
          case FETCH_PRESCRIPTIONS_SUCCESS:
            return {
              ...state,
              device: action.payload,
              loading: false,
            };
          case FETCH_PRESCRIPTIONS_FAILED:
            return {
              ...state,
              device: null,
              loading: false,
              error: {
                flag: true,
                msg: action.payload,
              },
            };
            case ADD_PRESCRIPTIONS:
            return {
              ...state,
              loading: true,
            };
          case ADD_PRESCRIPTIONS_SUCCESS:
            return {
              ...state,
              device: action.payload,
              loading: false,
            };
          case ADD_PRESCRIPTIONS_FAILED:
            return {
              ...state,
              device: null,
              loading: false,
              error: {
                flag: true,
                msg: action.payload,
              },
            };
          case EDIT_PRESCRIPTIONS:
            return {
              ...state,
              loading: true,
            };
          case EDIT_PRESCRIPTIONS_SUCCESS:
            return {
              ...state,
              device: action.payload,
              loading: false,
            };
          case EDIT_PRESCRIPTIONS_FAILED:
            return {
              ...state,
              device: null,
              loading: false,
              error: {
                flag: true,
                msg: action.payload,
              },
            };
          case DELETE_PRESCRIPTIONS:
            return {
              ...state,
              loading: true,
            };
          case DELETE_PRESCRIPTIONS_SUCCESS:
            return {
              ...state,
              device: action.payload,
              loading: false,
            };
          case DELETE_PRESCRIPTIONS_FAILED:
            return {
              ...state,
              device: null,
              loading: false,
              error: {
                flag: true,
                msg: action.payload,
              },
            };
          default:
            return state;
        }
      };