/*eslint-disable*/
import React from "react";

function VideoConference(props) {
  const Booking = (props.location && props.location.state.Booking) || {};

  return (
    <>
      <div className="content">
        <div className="videoconference">
          <iframe
            allow="camera; microphone; fullscreen; display-capture; autoplay"
            src={Booking.videoconferencelink}
            height="760px"
            width="100%"
            border="0px"
          />
          {/* <iframe
            allow="camera; microphone; fullscreen; display-capture; autoplay"
            src="https://dev.okdoc.co.za:7443/okdoc/Walters-NMjVKbcOMoT7-PqSKxF"
            style="height: 100%; width: 100%; border: 0px;"
          ></iframe> */}
        </div>
      </div>
    </>
  );
}
export default VideoConference;
