import {
  FETCH_BOOKINGS,
  FETCH_BOOKINGS_SUCCESS,
  FETCH_BOOKINGS_FAILED,
  UPDATE_BOOKING,
  CANCEL_BOOKING,
  FETCH_ALL_BOOKINGS,
  FETCH_ALL_BOOKINGS_SUCCESS,
  FETCH_ALL_BOOKINGS_FAILED,
  UPDATE_ALL_BOOKING,
  CANCEL_ALL_BOOKING
} from "../store/types";
import { store } from '../store/store';
import { fetchBookingLocations } from '../actions/locationactions';
import { RequestPushMsg } from '../other/NotificationFunctions';
import { FareCalculator } from '../other/FareCalculator';
import { GetDistance,GetEventDistance } from '../other/GeoFunctions';
import { fetchAddressfromCoords } from '../other/GoogleAPIFunctions';
import { language, dateStyle } from 'config';
import { useSelector } from "react-redux";
import moment from 'moment';

export const fetchBookings = (uid, role) => (dispatch) => (firebase) => {

  const {
    bookingListRef,
  } = firebase;

  dispatch({
    type: FETCH_BOOKINGS,
    payload: null,
  });
  bookingListRef(uid, role).on("value", (snapshot) => {
    if (snapshot.val()) {
      const data = snapshot.val();
      const active = [];
      let tracked = null;
      const bookings = Object.keys(data)
        .map((i) => {
          data[i].id = i;
          let dt = new Date(data[i].eventdate);
          data[i].bookingDate = dt.toLocaleString(dateStyle);
          //data[i].pickupAddress = data[i].pickup.add;
          //data[i].dropAddress = data[i].drop.add;
          data[i].discount = data[i].discount_amount
            ? data[i].discount_amount
            : 0;
          data[i].cashPaymentAmount = data[i].cashPaymentAmount
            ? data[i].cashPaymentAmount
            : 0;
          data[i].cardPaymentAmount = data[i].cardPaymentAmount
            ? data[i].cardPaymentAmount
            : 0;
          return data[i];
        });
      for (let i = 0; i < bookings.length; i++) {
        if (['NEW', 'ACCEPTED', 'ARRIVED', 'STARTED', 'REACHED', 'PENDING', 'PAID'].indexOf(bookings[i].status) !== -1) {
          active.push(bookings[i]);
        }
        if ((['NEW','ACCEPTED', 'ARRIVED', 'STARTED'].indexOf(bookings[i].status) !== -1) && (role === 'doctor'||role==='nurse')) {
          tracked = bookings[i];
          fetchBookingLocations(tracked.id)(dispatch)(firebase);
        }
      }
      dispatch({
        type: FETCH_BOOKINGS_SUCCESS,
        payload: {
          bookings: bookings.reverse(),
          active: active,
          tracked: tracked
        },
      });
      if (tracked) {
        dispatch({
          type: FETCH_BOOKINGS_SUCCESS,
          payload: null
        });
      }
    } else {
      dispatch({
        type: FETCH_BOOKINGS_FAILED,
        payload: language.no_bookings,
      });
    }
  });
};

export const fetchNewBookings = (uid, role) => (dispatch) => (firebase) => {

  const {
    bookingListRef,
  } = firebase;

  dispatch({
    type: FETCH_ALL_BOOKINGS,
    payload: null,
  });
  bookingListRef(uid, role).on("value", (snapshot) => {
    if (snapshot.val()) {
      const data = snapshot.val();
      const active = [];
      let tracked = null;
      const bookings = Object.keys(data)
        .map((i) => {
          data[i].id = i;
          let dt = new Date(data[i].eventdate);
          data[i].bookingDate = dt.toLocaleString(dateStyle);
          data[i].adjustedtimeStart = moment(data[i].adjustedtimeStart).toLocaleTimeString(); 
          data[i].adjustedtimeEnd = moment(data[i].adjustedtimeEnd).toLocaleTimeString(); 
          if (data[i].serviceType==="Home Consultation" || data[i].serviceType==="Medication Delivery"){
          data[i].pickupAddress = data[i].pickup.add;
          data[i].dropAddress = data[i].drop.add;
          }

          data[i].discount = data[i].discount_amount
            ? data[i].discount_amount
            : 0;
          data[i].cashPaymentAmount = data[i].cashPaymentAmount
            ? data[i].cashPaymentAmount
            : 0;
          data[i].cardPaymentAmount = data[i].cardPaymentAmount
            ? data[i].cardPaymentAmount
            : 0;
          return data[i];
        });
      for (let i = 0; i < bookings.length; i++) {
        if (['NEW', 'ACCEPTED','ARRIVED' , 'OFFERED', 'STARTED','REACHED', 'PENDING', 'PAID'].indexOf(bookings[i].status) !== -1) {
          active.push(bookings[i]);
        }
        if ((['NEW','ACCEPTED', 'ARRIVED', 'STARTED'].indexOf(bookings[i].status) !== -1) && (role === 'doctor'||role==='nurse')) {
          tracked = bookings[i];
          fetchBookingLocations(tracked.id)(dispatch)(firebase);
        }
      }
      dispatch({
        type: FETCH_ALL_BOOKINGS_SUCCESS,
        payload: {
          bookings: bookings.reverse(),
          active: active,
          tracked: tracked
        },
      });
      if (tracked) {
        dispatch({
          type: FETCH_ALL_BOOKINGS_SUCCESS,
          payload: null
        });
      }
    } else {
      dispatch({
        type: FETCH_ALL_BOOKINGS_FAILED,
        payload: language.no_bookings,
      });
    }
  });
};
export const updateBooking = (booking) => (dispatch) => (firebase) => {

  const {
    auth,
    trackingRef,
    singleBookingRef,
    singleUserRef,
    walletBalRef,
    walletHistoryRef
  } = firebase;
 console.log("im the booking", booking);
  dispatch({
    type: UPDATE_BOOKING,
    payload: booking,
  });
  if (booking.status === "ACCEPTED") {
    let dt = new Date();
    booking.doctor_accepted_time = dt.getTime().toString();
    singleBookingRef(booking.id).update(booking);
    //Todo fix the messages
    RequestPushMsg(
      booking.patientToken,
      language.notification_title,
      language.doctor_near
    );
  }
  if (booking.status === 'OFFERED') {
    let dt = new Date();
    
    booking.status = 'OFFERED';
    booking.updated = dt.getTime().toString();
    
    singleBookingRef(booking.id).update(booking);
    RequestPushMsg(booking.patientToken, language.notification_title, language.booking_offered);

    

  }
  if (booking.status === 'STARTED') {
    let dt = new Date();
    let localString = dt.toLocaleTimeString(dateStyle);
    let timeString = dt.getTime();
    booking.event_start_time = localString;
    booking.startTime = timeString;
    singleBookingRef(booking.id).update(booking);

    singleUserRef(booking.doctor.uid).once("value", (snapshot) => {
      let profile = snapshot.val();
      trackingRef(booking.id).push({
        at: new Date().getTime(),
        status: "STARTED",
        lat: profile.location.lat,
        lng: profile.location.lng,
      });
    });

    RequestPushMsg(booking.patientToken, language.notification_title, language.doctor_journey_msg + booking.id);
  }
  if (booking.status === 'REACHED') {
    let lastLocation = store.getState().locationdata.coords;
    let settings = store.getState().settingsdata.settings;

    trackingRef(booking.id).push({
      at: new Date().getTime(),
      status: 'REACHED',
      lat: lastLocation.lat,
      lng: lastLocation.lng
    });

    trackingRef(booking.id).orderByKey().once('value', async (snapshot)=>{  
      const data = snapshot.val();
      let res = await GetEventDistance(data);
      let distance = settings.convert_to_mile? (res.distance / 1.609344) : res.distance;

      let latlng = lastLocation.lat + ',' + lastLocation.lng;
      fetchAddressfromCoords(latlng).then((address) => {
        let drop = {};
        drop['lat'] = lastLocation.lat;
        drop['lng'] = lastLocation.lng;
        drop['add'] = address;
        singleUserRef(booking.patientuid)
          .child("savedAddresses")
          .once("value", (savedAdd) => {
            if (savedAdd.val()) {
              let addresses = savedAdd.val();
              let didNotMatch = true;
              for (let key in addresses) {
                let entry = addresses[key];
                if (
                  GetDistance(entry.lat, entry.lng, drop.lat, drop.lng) < 0.1
                ) {
                  didNotMatch = false;
                  break;
                }
              }
              if (didNotMatch) {
                singleUserRef(booking.patientuid).child("savedAddresses").push({
                  description: drop.add,
                  lat: drop.lat,
                  lng: drop.lng,
                });
              }
            } else {
              singleUserRef(booking.patientuid).child("savedAddresses").push({
                description: drop.add,
                lat: drop.lat,
                lng: drop.lng,
              });
            }
          });

        let practices = store.getState().practicetypes.practices;
        let rates = {};
        for (var i = 0; i < practices.length; i++) {
          if (practices[i].name === booking.practiceType) {
            rates = practices[i];
          }
        }
        let end_time = new Date();
        let diff = (end_time.getTime() - parseFloat(booking.startTime)) / 1000;
        let totalTimeTaken = Math.abs(Math.round(diff));
        let fare = FareCalculator(distance, totalTimeTaken, rates);

        booking.drop = drop;
        booking.dropAddress = address;
        booking.event_cost = fare.grandTotal;
        booking.event_end_time = end_time.toLocaleTimeString(dateStyle);
        booking.distance = parseFloat(distance).toFixed(2);
        booking.convenience_fees = fare.convenience_fees;
        booking.doctor_share = fare.grandTotal - fare.convenience_fees;
        booking.endTime = end_time.getTime();
        booking.total_event_time = totalTimeTaken;
        booking.coords = res.coords;
        singleBookingRef(booking.id).update(booking);
        RequestPushMsg(booking.patient_token, language.notification_title, language.doctor_completed_ride);
      });
    });
  }
  if (booking.status === 'PENDING') {
    singleBookingRef(booking.id).update(booking);
    singleUserRef(booking.doctor.uid).update({ queue: false });
  }
   if (booking.status === "NEW") {
     singleBookingRef(booking.id).update(booking);
   }
  if (booking.status === 'PAID') {
    singleBookingRef(booking.id).update(booking);
    if(booking.doctor === auth.currentUser.uid && (booking.payment_mode === 'cash' || booking.payment_mode === 'wallet')){
      singleUserRef(booking.doctor.uid).update({ queue: false });
    }

    singleUserRef(booking.doctor.uid).once('value', snapshot => {
      let walletBalance = snapshot.val().walletBalance;
      walletBalance = walletBalance + parseFloat(booking.doctor_share);
      if(parseFloat(booking.cashPaymentAmount)>0){
        walletBalance = walletBalance - parseFloat(booking.cashPaymentAmount);
      }
      walletBalRef(booking.doctor).set(walletBalance);

      let details = {
        type: 'Credit',
        amount: booking.doctor_share,
        date: new Date().toString(),
        txRef: booking.id
      }
      walletHistoryRef(booking.doctor).push(details);
      
      if(parseFloat(booking.cashPaymentAmount)>0){
        let details = {
          type: 'Debit',
          amount: booking.cashPaymentAmount,
          date: new Date().toString(),
          txRef: booking.id
        }
        walletHistoryRef(booking.doctor).push(details);
      }  
    });

    RequestPushMsg(booking.patientToken, language.notification_title, language.success_payment);
    RequestPushMsg(booking.doctor_token, language.notification_title, language.success_payment);
  }
  if (booking.status === 'COMPLETE') {
    singleBookingRef(booking.id).update(booking);
    if (booking.rating) {
      RequestPushMsg(booking.doctor_token, language.notification_title, language.received_rating.toString().replace("X", booking.rating.toString()));
      singleUserRef(booking.doctor.uid).once('value', snapshot => {
        let profile = snapshot.val();
        let ratings = {};
        if (profile && profile.ratings) {
          ratings = profile.ratings
          let details = ratings.details;
          let sum = 0;
          for (let i = 0; i < details.length; i++) {
            sum = sum + parseFloat(details[i].rate);
          }
          sum = sum + booking.rating;
          ratings.userrating = parseFloat(sum / (details.length + 1)).toFixed(1);
          ratings.details.push({
            user: booking.customer,
            rate: booking.rating
          });
        } else {
          ratings.userrating = booking.rating;
          ratings.details = [];
          ratings.details.push({
            user: booking.patient,
            rate: booking.rating
          });
        }
        singleUserRef(booking.doctor.uid).update({ ratings: ratings });
      });
    }
  }
};

export const cancelBooking = (data) => (dispatch) => (firebase) => { 
  const {
    singleBookingRef,
    singleUserRef,
    requestedDoctorsRef,
  } = firebase;

  dispatch({
    type: CANCEL_BOOKING,
    payload: data,
  });

  singleBookingRef(data.id).update({
    status: 'CANCELLED',
   // reason: data.reason
  }).then(() => {
    if (data.doctor && (data.status === 'ACCEPTED' || data.status === 'ARRIVED')) {
      singleUserRef(data.doctor.id).update({ queue: false });
      RequestPushMsg(data.doctor_token, language.notification_title, language.booking_cancelled + data.id);
      RequestPushMsg(data.patientToken, language.notification_title, language.booking_cancelled + data.id);
    }
    if (data.booking.status === 'NEW') {
      requestedDoctorsRef(data.id).remove();
    }
  });
};

