/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { language } from "config";
import { makeStyles } from "@material-ui/core/styles";
import UsersCombo from "../../components/UsersCombo";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import AlertDialog from "../../components/AlertDialog";
import FlatList from "flatlist-react";
import CircularLoading from "../../components/CircularLoading";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {
  Button,
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
  UncontrolledCollapse,
  Container,
} from "reactstrap";
import { TextField } from "@material-ui/core";
import { FirebaseContext } from "common";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    alignContent: "center",
  },
  title: {
    color: "#000",
  },
  gridcontainer: {
    alignContent: "center",
  },
  items: {
    margin: 0,
    width: "100%",
  },
  input: {
    fontSize: 18,
    color: "#000",
  },
  inputdimmed: {
    fontSize: 18,
    color: "#737373",
  },
  practicephoto: {
    height: "18px",
    marginRight: "10px",
  },
  buttonStyle: {
    margin: 0,
    width: "100%",
    height: "100%",
  },
  rootTwo: {
    minWidth: 275,
    borderRight: "2px solid",
    borderRightColor: "#ff0000",
    borderBlockStartColor: "#ff0000",
    borderBlockStart: "2px solid",
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  },
}));

const BookingOffers = (props) => {
  ////////////////////REduX////////////////////
  const { api } = useContext(FirebaseContext);
  const {
    GetDateString,
    updateBooking,
    fetchOffers,
    fetchChatMessages,
    createMeetingRoom,
    clearLoginError,
    addOffers,
    updateOffers,
  } = api;
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  ////////////////////constant////////////////////
  const Booking = (props.location && props.location.state.Booking) || {};
  const [updatebooking, setupdatebooking] = useState(Booking);
const navigate = useNavigate();
  const [offers, setOffers] = useState(
    Object.values(Booking.BookingOffers || {})
  );

  const [offerData, setOfferData] = useState();

  console.log("First fetch", Object.keys(offers));

  const classes = useStyles();

  const [rejectbuttonclicked, setrejectbuttonclicked] = useState(false);
  const [acceptbuttonclicked, setacceptbuttonclicked] = useState(false);
  const [commonAlert, setCommonAlert] = useState({
    open: false,
    msg: "",
    title: "",
  });

  useEffect(() => {
    if (offers) {
      let keys = Object.keys(Booking.BookingOffers || {});
      let i = 0;
      let arr = [];
      offers.map((offer) => {
        arr.push({
          ...offer,
          id: keys[i],
        });
        i = i + 1;
      });
      setOfferData(arr);
    }

    //console.log("checking offer info", offerData);
  }, []);

  useEffect(() => {
    if (rejectbuttonclicked === true) {
      navigate("/patientbookingsoffers");
    } else {
      if (acceptbuttonclicked === true) {
        if (updatebooking.status === "ACCEPTED") {
          setCommonAlert({
            open: true,
            msg: "You have accepted your booking, please be ready 10 mintues before your booking",
            title: "Thank you",
          });
        } else {
          navigate("/confirmedBookings");
        }

        //  dispatch(updateOffers());
      }
    }
  }, [rejectbuttonclicked, acceptbuttonclicked]);

  const HandleReject = () => {
    console.log("offerData", offerData);
  };

  const HandleAccept = (Offeredbooking) => {
    console.log("first", Offeredbooking);
    let booking = updatebooking;
    if (booking.status === "NEW") {
      if (auth.info.profile.userType === "patient") {
        booking.practiceNumber = Offeredbooking.practiceNumber
          ? Offeredbooking.practiceNumber
          : null;
        booking.doctor = Offeredbooking.doctor ? Offeredbooking.doctor : null;
        booking.nurse = Offeredbooking.nurse ? Offeredbooking.nurse : null;
        booking.accepted_consultation_fee =
          Offeredbooking.offered_consultation_fee
            ? Offeredbooking.offered_consultation_fee
            : null;
        booking.accepted_treatment_fee = Offeredbooking.offered_treatment_fee
          ? Offeredbooking.offered_treatment_fee
          : null;
        booking.accepted_total = Offeredbooking.offered_total
          ? Offeredbooking.offered_total
          : null;
        booking.adjustedtimeStart = Offeredbooking.time_start_offered
          ? Offeredbooking.time_start_offered
          : null;
        booking.adjustedtimeEnd = Offeredbooking.time_end_offered
          ? Offeredbooking.time_end_offered
          : null;
        booking.markedDot = Offeredbooking.markedDot
          ? Offeredbooking.markedDot
          : " ";
        booking.videoconferencelink = `https://okdoc-prod.okdoc.co.za/${booking.id}`;
        booking.status = "ACCEPTED";

        Offeredbooking.offerstatus = "ACCEPTED";
        dispatch(updateBooking(booking));
        dispatch(updateOffers(booking.id, Offeredbooking));
        navigate("/confirmedBookings");
      }
    } else {
      booking.status = "PENDING";
    }
  };

  const renderBookingData = (item, index) => {
    console.log(offerData);
    console.log("get id", index);
    item.id = index;
    return (
      <>
        <div className="content">
          <Row>
            <Col md="6">
              {item.doctor ? (
                <Card className={classes.root}>
                  <CardHeader as="h5">
                    <CardTitle tag="h4">
                      <div className="picture">
                        <img
                          alt="..."
                          className="picture-src"
                          src={item?.doctor?.profile_image}
                        />
                      </div>{" "}
                      <span>
                        {" "}
                        <p className="description">
                          {" "}
                          DR.{" "}
                          {item?.doctor?.firstName +
                            " " +
                            item?.doctor?.lastName}
                        </p>{" "}
                      </span>
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row mb="12">
                      <Col md="12">
                        <FormGroup>
                          <span>Type of Booking: </span>
                          <p className="description">{item?.offerstatus}</p>
                        </FormGroup>
                        <FormGroup>
                          <span>Offered Amount: </span>
                          <p className="description">{item?.offered_total}</p>
                        </FormGroup>
                        <FormGroup>
                          <span>Offered Date and Time: </span>
                          <p className="description">
                            {item?.time_start_offered}
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" onClick={() => HandleReject()}>
                      Back
                    </Button>
                  </CardFooter>
                  <CardFooter>
                    <Button
                      color="primary"
                      onClick={(offersData) => {
                        HandleAccept(item);
                      }}
                    >
                      Accept Offer
                    </Button>
                  </CardFooter>
                </Card>
              ) : null}
              {!item.doctor && item.nurse ? (
                <Card className={classes.root}>
                  <CardHeader as="h5">
                    <CardTitle tag="h4">
                      <div className="picture">
                        <img
                          alt="..."
                          className="picture-src"
                          src={item?.nurse?.profile_image}
                        />
                      </div>{" "}
                      <span>
                        {" "}
                        <p className="description">
                          {" "}
                          SR.{" "}
                          {item?.nurse?.firstName + " " + item?.nurse?.lastName}
                        </p>{" "}
                      </span>
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row mb="12">
                      <Col md="12">
                        <FormGroup>
                          <span>Type of Booking: </span>
                          <p className="description">{item?.offerstatus}</p>
                        </FormGroup>
                        <FormGroup>
                          <span>Offered Amount: </span>
                          <p className="description">{item?.offered_total}</p>
                        </FormGroup>
                        <FormGroup>
                          <span>Offered Date and Time: </span>
                          <p className="description">
                            {item?.time_start_offered}
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" onClick={() => HandleReject()}>
                      Back
                    </Button>
                  </CardFooter>
                  <CardFooter>
                    <Button
                      color="primary"
                      onClick={(offersData) => {
                        HandleAccept(item);
                      }}
                    >
                      Accept Offer
                    </Button>
                  </CardFooter>
                </Card>
              ) : null}
            </Col>
          </Row>
        </div>
      </>
    );
  };

  return offerData?.loading ? (
    <CircularLoading />
  ) : (
    <Row>
      <Col md="12">
        <Card style={{ backgroundColor: "#4c4b4b" }}>
          <CardHeader>
            <CardTitle tag="h4">Offers</CardTitle>
          </CardHeader>
          <CardBody>
            <ul>
              <Row>
                <FlatList
                  keyExtractor={(item, index) => index}
                  list={offerData}
                  renderItem={renderBookingData}
                  renderWhenEmpty={() => (
                    <div>There currently is no bookings offers!</div>
                  )}
                />
              </Row>
            </ul>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default BookingOffers;
