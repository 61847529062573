/* eslint-disable no-unused-vars */
import {
  FETCH_NOTES,
  FETCH_NOTES_SUCCESS,
  FETCH_NOTES_FAILED,
  SEND_NOTES,
  STOP_FETCH_NOTES,
} from "../store/types";
import { language } from "config";
import { RequestPushMsg } from "../other/NotificationFunctions";
import { authreducer } from "../reducers/authreducer";

export const fetchDoctorNotes = (bookingId) => (dispatch) => (firebase) => {
  const { doctornotesRef } = firebase;

  dispatch({
    type: FETCH_NOTES,
    payload: bookingId,
  });
  doctornotesRef(bookingId).on("value", (snapshot) => {
    if (snapshot.val()) {
      let rootEntry = snapshot.val();
      let allNotes = [];
      for (let key in rootEntry) {
        let entryKey = rootEntry[key];
        for (let ntsKey in entryKey) {
          entryKey[ntsKey].smsId = ntsKey;
          allNotes.push(entryKey[ntsKey]);
        }
      }
      dispatch({
        type: FETCH_NOTES_SUCCESS,
        payload: allNotes,
      });
    } else {
      dispatch({
        type: FETCH_NOTES_FAILED,
        payload: language.notes_not_found,
      });
    }
  });
};


export const addDoctorsNotes = (data) => (dispatch) => (firebase) => {
  const { doctornotesRef, auth } = firebase;
  const doctornotesId = data.booking.customer + "," + data.booking.doctor;
  var today = new Date();
  var time = today.getHours() + ":" + today.getMinutes();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = mm + ":" + dd + ":" + yyyy;
console.log('i am the data ')
  const docNts = {
    doctornotes: data.message,
    from: auth.currentUser.uid,
    type: "doctornotes",
    docNtsDate: today,
    docNtsTime: time,
    source: data.role,
  };
  doctornotesRef(data.booking.id).child(doctornotesId).push(docNts);


  if (data.role === "doctor") {
    RequestPushMsg(
      data.booking.doctor_token,
      language.notification_title + language.doctor_notes,
      data.doctornotes
    );
  } else {
    RequestPushMsg(
      data.booking.customer_token,
      language.notification_title + language.doctor_notes,
      data.doctornotes
    );
  }

  dispatch({
    type: SEND_NOTES,
    payload: docNts,
  });
};

export const stopFetchDoctorNotes = (bookingId) => (dispatch) => (firebase) => {
  const { doctornotesRef } = firebase;

  dispatch({
    type: STOP_FETCH_NOTES,
    payload: bookingId,
  });
  doctornotesRef(bookingId).off();
};
