/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import CircularLoading from "../../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import { language } from "config";
import { FirebaseContext } from "common";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";

import image from "../../assets/img/bg21.jpg";
import profile from "../../assets/img/emilyz.jpg";

const NurseProfile = () => {
  const { api } = useContext(FirebaseContext);
  const { updateProfile, updateWebProfileImage } = api;
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    loginType: null,
    userType: "",
    profile_image: null,
    practiceNumber: "",
    practiceName: "",
    practiceLicenseNumber: "",
    practiceType: false,
  });

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setData({
        firstName:
          !auth.info.profile.firstName || auth.info.profile.firstName === " "
            ? ""
            : auth.info.profile.firstName,
        lastName:
          !auth.info.profile.lastName || auth.info.profile.lastName === " "
            ? ""
            : auth.info.profile.lastName,
        email:
          !auth.info.profile.email || auth.info.profile.email === " "
            ? ""
            : auth.info.profile.email,
        mobile:
          !auth.info.profile.mobile || auth.info.profile.mobile === " "
            ? ""
            : auth.info.profile.mobile,
        loginType: auth.info.profile.loginType ? "social" : "email",
        userType: auth.info.profile.userType,
        practiceNumber: auth.info.profile.practiceNumber,
        practiceName: auth.info.profile.practiceName,
        practiceLicenseNumber: auth.info.profile.practiceLicenseNumber,
        practiceType: auth.info.profile.practiceType,
        uid: auth.info.uid,
        profile_image:
          !auth.info.profile.profile_image ||
          auth.info.profile.profile_image === " "
            ? ""
            : auth.info.profile.profile_image,
      });
    }
  }, [auth.info]);

  /////////////////////////////////////////////////__Picutre Upload__///////////////////////////////////////////
  const [fileState, setFileState] = React.useState(null);

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFileState(file);
      setData({ ...data, profile_image: reader.result });
    };
    dispatch(updateWebProfileImage(auth.info, file));
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return auth.loading ? (
    <CircularLoading />
  ) : (
    <>
      <Row>
        <Col md="12">
          <Card className="card-user">
            {/* <div className="image">
                <img alt="..." src={image} />
              </div> */}
            <CardHeader></CardHeader>
            <CardBody>
              <div className="author">
                <div className="picture">
                  <img
                    src={data.profile_image}
                    className="picture-src"
                    alt="..."
                  />
                  <input type="file" onChange={(e) => handleImageChange(e)} />
                </div>
                <h5 className="title">
                  {data.firstName} {data.lastName}
                </h5>
                {/* <h6>{data.practiceName}{data.practiceType}</h6> */}
                <p className="description">{data.practiceName}</p>
                <p className="description">{data.practiceNumber}</p>
                <p className="description">{data.practiceType}</p>
                <p className="description">{data.userType}</p>
                <p className="description">{data.email}</p>
                <p className="description">{data.mobile}</p>
              </div>
            </CardBody>
            <hr />
            <div className="button-container">
              <Button
                className="btn-icon btn-round"
                color="neutral"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                size="lg"
              >
                <i className="fab fa-facebook-square" />
              </Button>
              <Button
                className="btn-icon btn-round"
                color="neutral"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                size="lg"
              >
                <i className="fab fa-twitter" />
              </Button>
              <Button
                className="btn-icon btn-round"
                color="neutral"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                size="lg"
              >
                <i className="fab fa-google-plus-square" />
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">Notes</h5>
              <CardTitle tag="h2">34,252</CardTitle>
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-round btn-icon"
                  color="default"
                  outline
                >
                  <i className="now-ui-icons loader_gear" />
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem>Action</DropdownItem>
                  <DropdownItem>Another Action</DropdownItem>
                  <DropdownItem>Something else here</DropdownItem>
                  <DropdownItem className="text-danger">
                    Remove data
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </CardHeader>
            <CardBody></CardBody>
            <CardFooter>
              <div className="stats">
                <i className="now-ui-icons arrows-1_refresh-69" />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </Col>

        <Col xs={12} md={4}>
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">Reports</h5>
              <CardTitle tag="h2">34,252</CardTitle>
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-round btn-icon"
                  color="default"
                  outline
                >
                  <i className="now-ui-icons loader_gear" />
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem>Action</DropdownItem>
                  <DropdownItem>Another Action</DropdownItem>
                  <DropdownItem>Something else here</DropdownItem>
                  <DropdownItem className="text-danger">
                    Remove data
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </CardHeader>
            <CardBody></CardBody>
            <CardFooter>
              <div className="stats">
                <i className="now-ui-icons arrows-1_refresh-69" />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </Col>

        <Col xs={12} md={4}>
          <Card
            className="card-chart"
            // backgroundImage: `url(https://images.unsplash.com/photo-1497366811353-6870744d04b2?auto=format&fit=crop&w=750&q=80&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D)`
          >
            <CardHeader>
              <h5 className="card-category">Qualifications</h5>
              <CardTitle tag="h2">34,252</CardTitle>
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-round btn-icon"
                  color="default"
                  outline
                >
                  <i className="now-ui-icons loader_gear" />
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem>Action</DropdownItem>
                  <DropdownItem>Another Action</DropdownItem>
                  <DropdownItem>Something else here</DropdownItem>
                  <DropdownItem className="text-danger">
                    Remove data
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </CardHeader>
            <CardBody background></CardBody>
            <CardFooter>
              <div className="stats">
                <i className="now-ui-icons arrows-1_refresh-69" />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default NurseProfile;
