/*eslint-disable*/
import CircularLoading from "../../../components/CircularLoading";
import { Select } from "@material-ui/core";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  FormText,
  Row,
  Col,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useContext } from "react";
import AlertDialog from "../../../components/AlertDialog";
import { FirebaseContext } from "common";
import CustomInput from "components/CustomInput/CustomInput";
import MenuItem from "@material-ui/core/MenuItem";
function PatientMedication({ props, route }) {
  /////////////////////////////////////////////////////////
  //REdux
  const { api } = useContext(FirebaseContext);
  const { editMedicalHistory } = api;
  const dispatch = useDispatch();
  const medical = useSelector(
    (state) => state.medicalRecords.medicalHistoryRecords
  );
  const auth = useSelector((state) => state.auth);
  const [medicalData, setMedicalData] = useState(medical);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  /////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////
  //useEffect 1
  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setMedicalData(medical);
    }
  }, [medical]);
  const OnSave = () => {
    let payload = {
      Medical_Information: {
        ...medical,
        Medication: medicalData?.Medication ? medicalData?.Medication : null,
      },
    };
    console.log("this is basic payload:", payload);
    dispatch(editMedicalHistory(payload, "Add medical history"));
    setCommonAlert({
      open: true,
      msg: "Your Medication History Has Been Saved",
    });
  };
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  return auth.loading ? (
    <CircularLoading />
  ) : (
    <>
      <div className="content">
        <div className="medicalhistory-form">
          <Row>
            <Col md="12">
              <AlertDialog
                open={commonAlert.open}
                onClose={handleCommonAlertClose}
              >
                {commonAlert.msg}
              </AlertDialog>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4"> MEDICATION</CardTitle>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <FormText color="default" tag="span">
                      Please Fill in the Medication you are Currently using:
                    </FormText>
                  </FormGroup>
                  <Row>
                    <Col>
                      <FormGroup inline>
                        <Label>
                          Drug
                          <Input
                            value={medicalData?.Medication?.Medication1?.Drug}
                            type="text"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Medication: {
                                  ...medicalData.Medication,
                                  Medication1: {
                                    ...medicalData.Medication.Medication1,
                                    Drug: event.target.value,
                                  },
                                },
                              });
                            }}
                          />
                        </Label>
                        <Label>
                          Dose/Frequency
                          <Input
                            value={medicalData?.Medication?.Medication1?.Dose}
                            type="text"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Medication: {
                                  ...medicalData.Medication,
                                  Medication1: {
                                    ...medicalData.Medication.Medication1,
                                    Dose: event.target.value,
                                  },
                                },
                              });
                            }}
                          />
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup inline>
                        <Label>
                          Drug
                          <Input
                            value={medicalData?.Medication?.Medication2?.Drug}
                            type="text"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Medication: {
                                  ...medicalData.Medication,
                                  Medication2: {
                                    ...medicalData.Medication.Medication2,
                                    Drug: event.target.value,
                                  },
                                },
                              });
                            }}
                          />
                        </Label>
                        <Label>
                          Dose/Frequency
                          <Input
                            value={medicalData?.Medication?.Medication2?.Dose}
                            type="text"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Medication: {
                                  ...medicalData.Medication,
                                  Medication2: {
                                    ...medicalData.Medication.Medication2,
                                    Dose: event.target.value,
                                  },
                                },
                              });
                            }}
                          />
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup inline>
                        <Label>
                          Drug
                          <Input
                            value={medicalData?.Medication?.Medication3?.Drug}
                            type="text"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Medication: {
                                  ...medicalData.Medication,
                                  Medication3: {
                                    ...medicalData.Medication.Medication3,
                                    Drug: event.target.value,
                                  },
                                },
                              });
                            }}
                          />
                        </Label>
                        <Label>
                          Dose/Frequency
                          <Input
                            value={medicalData?.Medication?.Medication3?.Dose}
                            type="text"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Medication: {
                                  ...medicalData.Medication,
                                  Medication3: {
                                    ...medicalData.Medication.Medication3,
                                    Dose: event.target.value,
                                  },
                                },
                              });
                            }}
                          />
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup inline>
                        <Label>
                          Drug
                          <Input
                            value={medicalData?.Medication?.Medication4.Drug}
                            type="text"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Medication: {
                                  ...medicalData.Medication,
                                  Medication4: {
                                    ...medicalData.Medication.Medication4,
                                    Drug: event.target.value,
                                  },
                                },
                              });
                            }}
                          />
                        </Label>
                        <Label>
                          Dose/Frequency
                          <Input
                            value={medicalData?.Medication?.Medication4.Dose}
                            type="text"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Medication: {
                                  ...medicalData.Medication,
                                  Medication4: {
                                    ...medicalData.Medication.Medication4,
                                    Dose: event.target.value,
                                  },
                                },
                              });
                            }}
                          />
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup inline>
                        <Label>
                          Drug
                          <Input
                            value={medicalData?.Medication?.Medication5.Drug}
                            type="text"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Medication: {
                                  ...medicalData.Medication,
                                  Medication5: {
                                    ...medicalData.Medication.Medication5,
                                    Drug: event.target.value,
                                  },
                                },
                              });
                            }}
                          />
                        </Label>
                        <Label>
                          Dose/Frequency
                          <Input
                            value={medicalData?.Medication?.Medication5.Dose}
                            type="text"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Medication: {
                                  ...medicalData.Medication,
                                  Medication5: {
                                    ...medicalData.Medication.Medication5,
                                    Dose: event.target.value,
                                  },
                                },
                              });
                            }}
                          />
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup inline>
                        <Label>
                          Drug
                          <Input
                            value={medicalData?.Medication?.Medication6.Drug}
                            type="text"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Medication: {
                                  ...medicalData.Medication,
                                  Medication6: {
                                    ...medicalData.Medication.Medication6,
                                    Drug: event.target.value,
                                  },
                                },
                              });
                            }}
                          />
                        </Label>
                        <Label>
                          Dose/Frequency
                          <Input
                            value={medicalData?.Medication?.Medication6.Dose}
                            type="text"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Medication: {
                                  ...medicalData.Medication,
                                  Medication6: {
                                    ...medicalData.Medication.Medication6,
                                    Dose: event.target.value,
                                  },
                                },
                              });
                            }}
                          />
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Col>
                    <Button variant="gradient" color="primary" onClick={OnSave}>
                      SAVE
                    </Button>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default PatientMedication;
