/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import CircularLoading from "../../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import Button from "components/CustomButtons/Button.js";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { language } from "config";
import AlertDialog from "../../components/AlertDialog";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  CardFooter,
} from "reactstrap";
import { FirebaseContext } from "common";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: 192,
    height: 192,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const NurseEditProfile = (props) => {
  const { api } = useContext(FirebaseContext);
  const { updateProfile } = api;
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
const navigate = useNavigate();
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    loginType: null,
    userType: "",
    practiceNumber: "",
    practiceName: "",
    practiceLicenseNumber: "",
    practiceType: false,
  });

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setData({
        firstName:
          !auth.info.profile.firstName || auth.info.profile.firstName === " "
            ? ""
            : auth.info.profile.firstName,
        lastName:
          !auth.info.profile.lastName || auth.info.profile.lastName === " "
            ? ""
            : auth.info.profile.lastName,
        email:
          !auth.info.profile.email || auth.info.profile.email === " "
            ? ""
            : auth.info.profile.email,
        mobile:
          !auth.info.profile.mobile || auth.info.profile.mobile === " "
            ? ""
            : auth.info.profile.mobile,
        loginType: auth.info.profile.loginType ? "social" : "email",
        userType: auth.info.profile.userType,
        practiceNumber: auth.info.profile.practiceNumber,
        practiceName: auth.info.profile.practiceName,
        practiceLicenseNumber: auth.info.profile.practiceLicenseNumber,
        practiceType: auth.info.profile.practiceType,
        uid: auth.info.uid,
      });
    }
  }, [auth.info]);

  const updateData = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(data.email)) {
      dispatch(
        updateProfile(auth.info, {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          mobile: data.mobile,
        })
      );
      setCommonAlert({ open: true, msg: language.profile_updated });
    } else {
      setCommonAlert({ open: true, msg: language.proper_email });
    }
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
    navigate({
      pathname: "/nurseprofile",
    });
  };

  return (
    <>
      <div className={"content"}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Edit Profile</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pl-1" md="4">
                        <FormGroup>
                          <label>First Name</label>
                          <Input
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="firstName"
                            label={language.firstname}
                            name="firstName"
                            autoComplete="firstName"
                            onChange={updateData}
                            value={data.firstName}
                            autoFocus
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="4">
                        <FormGroup>
                          <label>Last Name</label>
                          <Input
                            color="primary"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="lastName"
                            label={language.lastname}
                            name="lastName"
                            autoComplete="lastName"
                            onChange={updateData}
                            value={data.lastName}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="4">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">
                            Email address
                          </label>
                          <Input
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={language.email}
                            name="email"
                            autoComplete="email"
                            onChange={updateData}
                            value={data.email}
                            disabled={data.loginType === "email" ? true : false}
                          />
                        </FormGroup>
                      </Col>
                      {/* </Row>
                <Row> */}
                    </Row>
                    {/* <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>Address</label>
                      <Input
                        defaultValue=" "
                        placeholder="Office Address"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row> */}

                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Mobile Number</label>
                          <Input
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="mobile"
                            label={language.phone}
                            name="mobile"
                            autoComplete="mobile"
                            onChange={updateData}
                            value={data.mobile}
                            disabled={data.loginType === "email" ? true : false}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-1" md="6">
                        <FormGroup>
                          <label>User Type</label>
                          <Input
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="userType"
                            label={language.userType}
                            name="userType"
                            value={data.userType}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pl-1" md="3">
                        <FormGroup>
                          <label>Practice Name</label>
                          <Input
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="practiceName"
                            label="Practice Name"
                            name="practiceName"
                            value={data.practiceName}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="3">
                        <FormGroup>
                          <label>Practice Number</label>
                          <Input
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="practiceNumber"
                            label="Practice Number"
                            name="practiceNumber"
                            value={data.practiceNumber}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="3">
                        <FormGroup>
                          <label>Practice Type</label>
                          <Input
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="practiceType"
                            label="Practice Type"
                            name="practiceType"
                            value={data.practiceType}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="3">
                        <FormGroup>
                          <label>Practice License Number</label>
                          <Input
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="practiceLicenseNumber"
                            label="Practice license Number"
                            name="practiceLicenseNumber"
                            value={data.practiceLicenseNumber}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>About Me</label>
                      <Input
                        cols="80"
                        defaultValue=" "
                        placeholder="Here can be your description"
                        rows="4"
                        type="textarea"
                      />
                    </FormGroup>
                  </Col>
                </Row> */}
                  </Form>
                </CardBody>
                <Row>
                  <Col>
                    <CardFooter>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="danger"
                        className={classes.submit}
                      >
                        {language.submit}
                      </Button>
                    </CardFooter>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </>
  );
};

export default NurseEditProfile;
