/* eslint-disable  */
import React from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import ProgressReport from "./Doctor/Forms/ProgressReport";
import ProofRecord from "./Doctor/Forms/ProofRecord";
import Returns from "./Doctor/Forms/Returns";
import WalkInForm from "./Doctor/Forms/WalkInForm";
import FamilyPlanning from "./Doctor/Forms/FamilyPlanning";
function TehillahForms() {
  const [pageSubcategories, setpageSubcategories] = React.useState(["ps1"]);
  return (
    <>
      <Nav pills className="nav-pills-primary nav-pills-icons">
        <Row x="4">
          <Col>
            <NavItem>
              <NavLink
                className={pageSubcategories === "ps1" ? "active" : ""}
                onClick={() => setpageSubcategories("ps1")}
              >
                {/* // <i className="now-ui-icons files_paper"></i> */}
                Refferal Form
              </NavLink>
            </NavItem>
          </Col>
          <Col>
            <NavItem>
              <NavLink
                className={pageSubcategories === "ps2" ? "active" : ""}
                onClick={() => setpageSubcategories("ps2")}
              >
                {/* // <i className="now-ui-icons files_paper"></i> */}
                MediMobile Proof
              </NavLink>
            </NavItem>
          </Col>
          <Col>
            <NavItem>
              <NavLink
                className={pageSubcategories === "ps3" ? "active" : ""}
                onClick={() => setpageSubcategories("ps3")}
              >
                {/* // <i className="now-ui-icons files_paper"></i> */}
                MediMobile Returns
              </NavLink>
            </NavItem>
          </Col>
          <Col>
            <NavItem>
              <NavLink
                className={pageSubcategories === "ps4" ? "active" : ""}
                onClick={() => setpageSubcategories("ps4")}
              >
                {/* // <i className="now-ui-icons files_paper"></i> */}
                Tehillah Progress Report
              </NavLink>
            </NavItem>
          </Col>
          <Col>
            <NavItem>
              <NavLink
                className={pageSubcategories === "ps5" ? "active" : ""}
                onClick={() => setpageSubcategories("ps5")}
              >
                {/* // <i className="now-ui-icons files_paper"></i> */}
                Walk-Ins
              </NavLink>
            </NavItem>
          </Col>
          <Col>
            <NavItem>
              <NavLink
                className={pageSubcategories === "ps6" ? "active" : ""}
                onClick={() => setpageSubcategories("ps6")}
              >
                {/* // <i className="now-ui-icons files_paper"></i> */}
                Family Planning
              </NavLink>
            </NavItem>
          </Col>
        </Row>
      </Nav>
      <TabContent className="tab-space" activeTab={pageSubcategories}>
        <TabPane tabId="ps1">
          <div className="content">
            <div className="form">
              <iframe
                // allow="camera; microphone; fullscreen; display-capture; autoplay"
                src="https://rd4a.com/index.php/doctors/tehillah/refferal-form"
                height="760px"
                width="100%"
                border="0px"
              />
            </div>
          </div>
        </TabPane>
        <TabPane tabId="ps2">
          <div className="content">
            <div className="form">
              <ProofRecord />
            </div>
          </div>
        </TabPane>
        <TabPane tabId="ps3">
          <div className="content">
            <div className="form">
              <Returns />
            </div>
          </div>
        </TabPane>
        <TabPane tabId="ps4">
          <div className="content">
            <div className="form">
              <ProgressReport />
            </div>
          </div>
        </TabPane>
        <TabPane tabId="ps5">
          <div className="content">
            <div className="form">
              <WalkInForm />
            </div>
          </div>
        </TabPane>
        <TabPane tabId="ps6">
          <div className="content">
            <div className="form">
              <FamilyPlanning />
            </div>
          </div>
        </TabPane>
      </TabContent>
    </>
  );
}
export default TehillahForms;
