import { 
  FETCH_ICDCODES,
  FETCH_ICDCODES_SUCCESS,
  FETCH_ICDCODES_FAILED,
  } from "../store/types";
  
  export const INITIAL_STATE = {
    codes:null,
    loading: false,
    error:{
      flag:false,
      msg: null
    }
  }
  
  export const icdcodesreducer =  (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_ICDCODES:
        return {
          ...state,
          loading:true
        };
      case FETCH_ICDCODES_SUCCESS:
        return {
          ...state,
          codes:action.payload,
          loading:false
        };
      case FETCH_ICDCODES_FAILED:
        return {
          ...state,
          codes:null,
          loading:false,
          error:{
            flag:true,
            msg:action.payload
          }
        };
      default:
        return state;
    }
  };