/*eslint-disable*/
import React, { useEffect, useContext } from "react";
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import { language } from "config";
import { FirebaseContext } from "common";
function AuthLoading(props) {
  const { api } = useContext(FirebaseContext);
  const {
    fetchUser,
    fetchReferralDrType,
    fetchPracticeTypes,
    fetchSpecialityTypes,
    fetchDoctorTypes,
    fetchSettings,
    fetchBookings,
    fetchCancelReasons,
    fetchPromos,
    fetchDoctorEarnings,
    fetchUsers,
    fetchNotifications,
    fetchEarningsReport,
    signOut,
    fetchWithdraws,
    fetchMedicalHistory,
    fetchChatMessages,
    fetchDoctorNotes,
    fetchCodes,
    // fetchICD,
    // fetchOtc,
    // fetchSpl,
  } = api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(fetchReferralDrType());
    dispatch(fetchDoctorTypes());
    dispatch(fetchSettings());
    dispatch(fetchSpecialityTypes());
    dispatch(fetchCodes());
  }, [
    dispatch,
    fetchUser,
    fetchReferralDrType,
    fetchDoctorTypes,
    fetchSpecialityTypes,
    fetchPracticeTypes,
    fetchSettings,
    fetchMedicalHistory,
    fetchChatMessages,
    fetchDoctorNotes,
    fetchCodes,
    // fetchICD,
    // fetchOtc,
    // fetchSpl,
  ]);
  useEffect(() => {
    // console.log("i truly hope you work", auth.info);

    if (auth.info) {
      if (auth.info.profile) {
        let role = auth.info.profile.userType;
        if (role === "patient") {
          dispatch(api.fetchMedicalHistory(auth.info.uid));
          dispatch(api.fetchUsers());
          dispatch(api.fetchDoctors());
          dispatch(api.fetchBookings(auth.info.uid, role));
          dispatch(api.fetchCancelReasons());
          dispatch(api.fetchPaymentMethods());
          dispatch(api.fetchPromos());
          dispatch(api.fetchSpecialityTypes());
          dispatch(api.fetchPracticeTypes());
          dispatch(api.fetchDoctorTypes());
        } else if (role === "doctor") {
          dispatch(api.fetchMedicalHistory());
          dispatch(api.fetchUsers());
          dispatch(api.fetchDoctors());
          dispatch(api.fetchBookings(auth.info.uid, role));
          dispatch(api.fetchCancelReasons());
          dispatch(api.fetchPaymentMethods());
          dispatch(api.fetchPromos());
          dispatch(api.fetchDoctorNotes());
          dispatch(api.fetchPracticeTypes());
          dispatch(api.fetchDoctorTypes());
          dispatch(api.fetchSettings());
          dispatch(api.fetchSpecialityTypes());
          dispatch(api.fetchReferralDrType());
          // dispatch(api.fetchICD());
          // dispatch(api.fetchOtc());
          // dispatch(api.fetchSpl());
        } else if (role === "nurse") {
          dispatch(api.fetchMedicalHistory());
          dispatch(api.fetchUsers());
          dispatch(api.fetchDoctors());
          dispatch(api.fetchBookings(auth.info.uid, role));
          dispatch(api.fetchCancelReasons());
          dispatch(api.fetchPaymentMethods());
          dispatch(api.fetchPromos());
          dispatch(api.fetchPracticeTypes());
          dispatch(api.fetchDoctorTypes());
          dispatch(api.fetchSettings());
          dispatch(api.fetchSpecialityTypes());
        } else if (role === "nophonepatient") {
          dispatch(api.fetchMedicalHistory());
          dispatch(api.fetchUsers());
          dispatch(api.fetchDoctors());
          dispatch(api.fetchBookings(auth.info.uid, role));
          dispatch(api.fetchCancelReasons());
          dispatch(api.fetchPaymentMethods());
          dispatch(api.fetchPromos());
        } else if (role === "admin") {
          dispatch(api.fetchMedicalHistory());
          dispatch(api.fetchUsers());
          dispatch(api.fetchDoctors());
          dispatch(api.fetchBookings(auth.info.uid, role));
          dispatch(api.fetchCancelReasons());
          dispatch(api.fetchPaymentMethods());
          dispatch(api.fetchPromos());
          dispatch(api.fetchPracticeTypes());
          dispatch(api.fetchDoctorTypes());
          dispatch(api.fetchSettings());
          dispatch(api.fetchSpecialityTypes());
          dispatch(api.fetchReferralDrType());
        } else if (role === "practiceadmin") {
          dispatch(api.fetchMedicalHistory());
          dispatch(api.fetchUsers());
          dispatch(api.fetchDoctors());
          dispatch(api.fetchBookings(auth.info.uid, role));
          dispatch(api.fetchCancelReasons());
          dispatch(api.fetchPaymentMethods());
          dispatch(api.fetchPromos());
          dispatch(api.fetchPracticeTypes());
          dispatch(api.fetchDoctorTypes());
          dispatch(api.fetchReferralDrType());
          dispatch(api.fetchSpecialityTypes());
          dispatch(api.fetchSettings());
          dispatch(api.fetchReferralDrType());
        } else {
          alert(language.not_valid_user_type);
          dispatch(signOut());
        }
      } else {
        alert(language.user_issue_contact_admin);
        dispatch(signOut());
      }
    }
  }, [
    auth.info,
    dispatch,
    fetchBookings,
    fetchCancelReasons,
    fetchDoctorEarnings,
    fetchEarningsReport,
    fetchNotifications,
    fetchPromos,
    fetchUsers,
    fetchWithdraws,
    signOut,
    fetchMedicalHistory,
    // fetchICD,
    // fetchOtc,
    // fetchSpl,
  ]);
  return auth.loading ? <CircularLoading /> : props.children;
}
export default AuthLoading;
