/*eslint-disable*/
import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Input, Button, Label } from "reactstrap";
import GoogleMapsAutoComplete from "components/GoogleMapsAutoComplete";

const Returns = () => {
   const [state, setstate] = useState({
     careDetails: "",
     month: "",
     date: "",
     time: "",
     time: false,
     client: "",
     address: "",
     folderNumber: "",
     comment: "",
     careWorker: "",
     signature: "",
     commentSupervisor: "",
     totalWork:"",
   });
   return (
     <div>
       <GridContainer spacing={4} style={{ marginTop: 30 }}>
         <GridItem xs={1} sm={1} md={1} lg={1}>
           <Label>Carer Name and Surname </Label>
         </GridItem>
         <GridItem xs={5} sm={5} md={5} lg={5}>
           <Input
             fullWidth
             type="text"
             value={state.careDetails}
             onChange={(value) => {
               setstate({ ...state, careDetails: value.target.event });
             }}
           />
         </GridItem>
         <GridItem xs={1} sm={1} md={1} lg={1}>
           <Label>Month</Label>
         </GridItem>
         <GridItem xs={5} sm={5} md={5} lg={5}>
           <Input
             type="text"
             value={state.month}
             onChange={(value) => {
               setstate({ ...state, month: value.target.event });
             }}
           />
         </GridItem>
         <GridItem xs={1} sm={1} md={1} lg={1}>
           <Label>Date </Label>
         </GridItem>
         <GridItem xs={5} sm={5} md={5} lg={5}>
           <Input
             type="date"
             value={state.date}
             onChange={(value) => {
               setstate({ ...state, date: value.target.event });
             }}
           />
         </GridItem>
         <GridItem xs={1} sm={1} md={1} lg={1}>
           <Label>Time</Label>
         </GridItem>
         <GridItem xs={5} sm={5} md={5} lg={5}>
           <Input
             type="time"
             value={state.time}
             onChange={(value) => {
               setstate({ ...state, time: value.target.event });
             }}
           />
         </GridItem>

         <GridItem xs={1} sm={1} md={1} lg={1}>
           <Label>Client</Label>
         </GridItem>
         <GridItem xs={5} sm={5} md={5} lg={5}>
           <Input
             type="text"
             value={state.client}
             onChange={(value) => {
               setstate({ ...state, client: value.target.event });
             }}
           />
         </GridItem>
         <GridItem xs={1} sm={1} md={1} lg={1}>
           <Label>Address</Label>
         </GridItem>
         <GridItem xs={5} sm={5} md={5} lg={5}>
           <GoogleMapsAutoComplete
             value={state.address}
             onChange={(value) => {
               setstate({ ...state, address: value.target.event });
             }}
             // className={styles.input}
             placeholder="Address"
           />
         </GridItem>

         <GridItem xs={1} sm={1} md={1} lg={1}>
           <Label>Folder Number </Label>
         </GridItem>
         <GridItem xs={5} sm={5} md={5} lg={5}>
           <Input
             type="text"
             value={state.folderNumber}
             onChange={(value) => {
               setstate({ ...state, folderNumber: value.target.event });
             }}
           />
         </GridItem>
         <GridItem xs={1} sm={1} md={1} lg={1}>
           <Label>Comments </Label>
         </GridItem>
         <GridItem xs={5} sm={5} md={5} lg={5}>
           <Input
             type="textarea"
             value={state.comment}
             onChange={(value) => {
               setstate({ ...state, comment: value.target.event });
             }}
           />
         </GridItem>
         <GridItem xs={1} sm={1} md={1} lg={1}>
           <Label>Care Worker Name and Surname </Label>
         </GridItem>
         <GridItem xs={5} sm={5} md={5} lg={5}>
           <Input
             type="text"
             value={state.careWorker}
             onChange={(value) => {
               setstate({ ...state, careWorker: value.target.event });
             }}
           />
         </GridItem>
         <GridItem xs={1} sm={1} md={1} lg={1}>
           <Label>Total Hour worked (m/p)</Label>
         </GridItem>
         <GridItem xs={5} sm={5} md={5} lg={5}>
           <Input
             type="text"
             value={state.totalWork}
             onChange={(value) => {
               setstate({ ...state, totalWork: value.target.event });
             }}
           />
         </GridItem>
         <GridItem xs={1} sm={1} md={1} lg={1}>
           <Label>Supervisor Signature </Label>
         </GridItem>
         <GridItem xs={11} sm={11} md={11} lg={11}>
           <Input
             type="text"
             placeholder="Enter Initials"
             value={state.signature}
             onChange={(value) => {
               setstate({ ...state, signature: value.target.event });
             }}
           />
         </GridItem>
         <GridItem xs={1} sm={1} md={1} lg={1}>
           <Label>Comments </Label>
         </GridItem>
         <GridItem xs={11} sm={11} md={11} lg={11}>
           <Input
             type="textarea"
             value={state.commentSupervisor}
             onChange={(value) => {
               setstate({ ...state, commentSupervisor: value.target.event });
             }}
           />
         </GridItem>
       </GridContainer>
     </div>
   );
};

export default Returns;
