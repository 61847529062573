/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "common";
import CircularLoading from "../../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  FormText,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Table,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { FormControl, MenuItem, Select, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { language, default_country_code, features } from "config";
import CustomInput from "components/CustomInput/CustomInput";
import { EditOutlined } from "@material-ui/icons";
//const useStyles = makeStyles();
import { useNavigate } from "react-router-dom";
const PatientProfile = (props) => {
  const medical = useSelector(
    (state) => state.medicalRecords.medicalHistoryRecords
  );
  const auth = useSelector((state) => state.auth);
  const { api } = useContext(FirebaseContext);
  const { updateProfile, updateProfileImage, updateWebProfileImage } = api;
  const dispatch = useDispatch();
  const [openFPModal, setOpenFPModal] = React.useState(false);
  //const classes = useStyles();
  const [pageSubcategories, setpageSubcategories] = React.useState(["ps1"]);
const navigate = useNavigate();
  //Basic infomation
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    loginType: null,
    userType: "",
    profile_image: null,
  });
  //Dependent Modal

  const [firstName, setFirstName] = useState(" ");
  const [lastName, setLastName] = useState(" ");
  const [idNumber, setIdNumber] = useState(null);
  const [relationship, setRelationship] = useState(null);
  const [gender, setGender] = useState(null);
  const [dependent, setdependent] = useState([]);
  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setData({
        firstName:
          !auth.info.profile.firstName || auth.info.profile.firstName === " "
            ? ""
            : auth.info.profile.firstName,
        lastName:
          !auth.info.profile.lastName || auth.info.profile.lastName === " "
            ? ""
            : auth.info.profile.lastName,
        email:
          !auth.info.profile.email || auth.info.profile.email === " "
            ? ""
            : auth.info.profile.email,
        mobile:
          !auth.info.profile.mobile || auth.info.profile.mobile === " "
            ? ""
            : auth.info.profile.mobile,
        loginType: auth.info.profile.loginType ? "social" : "email",
        userType: auth.info.profile.userType,
        uid: auth.info.uid,
        profile_image:
          !auth.info.profile.profile_image ||
          auth.info.profile.profile_image === " "
            ? ""
            : auth.info.profile.profile_image,
      });
    }
    console.log("i am the medical infomation from the reducer", medical);
  }, [auth.info]);

  const onEditSelect = (event) => {
    navigate("/patienteditprofile");
  };
  const onEditSelect1 = (event) => {
    navigate("/patientbasic");
  };
  const onEditSelect2 = (event) => {
    navigate("/patientchildhood");
  };
  const onEditSelect3 = (event) => {
    navigate("/patientsocial");
  };
  const onEditSelect4 = (event) => {
    navigate("/patientmedhistory");
  };
  const onEditSelect5 = (event) => {
    navigate("/patientimmunization");
  };
  const onEditSelect6 = (event) => {
    navigate("/patientsurgery");
  };
  const onEditSelect7 = (event) => {
    navigate("/patientfamilyhistory");
  };
  const onEditSelect8 = (event) => {
    navigate("/patientmedication");
  };
  const handleOpenEvent = (e) => {
    e.preventDefault();
    setOpenFPModal(true);
  };
  const handleCloseEvent = (e) => {
    e.preventDefault();
    setOpenFPModal(false);
  };
  const onSave = (e) => {
    e.preventDefault();
    setFirstName(" ");
    setLastName(" ");
    setGender(null);
    setIdNumber(" ");
    setRelationship(null);
    console.log(
      "dependent added:",
      firstName,
      lastName,
      gender,
      relationship,
      idNumber
    );
  };
  //  const constructor =(props) => {
  //   super();
  //   props = {
  //       pageSubcategories: 'ps1'
  //   };
  // };
  /////////////////////////////////////////////////__Picutre Upload__///////////////////////////////////////////
  const [fileState, setFileState] = React.useState(null);

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFileState(file);
      setData({ ...data, profile_image: reader.result });
    };
    dispatch(updateWebProfileImage(auth.info, file));
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return auth.loading ? (
    <CircularLoading />
  ) : (
    <>
      <Row>
        <Col md="12">
          <Card className="card-user">
            <CardHeader>
              {/* <div className="image">
              <img alt="..." src={image} />
            </div> */}
            </CardHeader>

            <CardBody>
              <div className="author">
                <div className="picture">
                  <img
                    src={data.profile_image}
                    className="picture-src"
                    alt="..."
                  />
                  <input type="file" onChange={(e) => handleImageChange(e)} />
                </div>
                <h5 className="title">
                  {data.firstName} {data.lastName}
                </h5>
                <p className="description">{data.userType}</p>

                <p className="description">{data.email}</p>

                <p className="description">{data.mobile}</p>
              </div>
            </CardBody>
            <hr />
            <div className="button-container">
              <Button
                className="btn-icon btn-round"
                color="neutral"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                size="lg"
              >
                <i className="fab fa-facebook-square" />
              </Button>
              <Button
                className="btn-icon btn-round"
                color="neutral"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                size="lg"
              >
                <i className="fab fa-twitter" />
              </Button>
              <Button
                className="btn-icon btn-round"
                color="neutral"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                size="lg"
              >
                <i className="fab fa-google-plus-square" />
              </Button>
            </div>
            <CardFooter>
              <EditOutlined onClick={onEditSelect} />
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <Card className="card-chart">
            <CardHeader>
              {/* <h5 className="card-category">Basic Information</h5> */}
              <CardTitle tag="h3">Basic Information</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup>
                    <label>Date of Birth:</label>
                    {medical?.Basic_info?.DOB && (
                      <p className="description">{medical?.Basic_info?.DOB}</p>
                    )}
                    {!medical?.Basic_info?.DOB && (
                      <p className="description">{"N/A"}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label>Sex:</label>
                    {medical?.Basic_info?.Sex && (
                      <p className="description">{medical?.Basic_info?.Sex}</p>
                    )}
                    {!medical?.Basic_info?.Sex && (
                      <p className="description">{"N/A"}</p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label>Blood Type:</label>
                    {medical?.Basic_info?.BloodType && (
                      <p className="description">
                        {medical?.Basic_info?.BloodType}
                      </p>
                    )}
                    {!medical?.Basic_info?.BloodType && (
                      <p className="description">{"N/A"}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label>Height:</label>
                    {medical?.Basic_info?.Height && (
                      <p className="description">{medical?.Basic_info?.Height}</p>
                    )}
                    {!medical?.Basic_info?.Height && (
                      <p className="description">{"N/A"}</p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label>Weight:</label>
                    {medical?.Basic_info?.Weight && (
                      <p className="description">{medical?.Basic_info?.Weight}</p>
                    )}
                    {!medical?.Basic_info?.Weight && (
                      <p className="description">{"N/A"}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label>Body Mass Index(BMI):</label>
                    {medical?.Basic_info?.BMI && (
                      <p className="description">{medical?.Basic_info?.BMI}</p>
                    )}
                    {!medical?.Basic_info?.BMI && (
                      <p className="description">{"N/A"}</p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <EditOutlined onClick={onEditSelect1} />
            </CardFooter>
          </Card>
        </Col>
      </Row>

      <Nav pills className="nav-pills-primary nav-pills-icons">
        <Row x="8">
          <Col>
            <NavItem>
              <NavLink
                className={pageSubcategories === "ps1" ? "active" : ""}
                onClick={() => setpageSubcategories("ps1")}
              >
                <i className="now-ui-icons objects_umbrella-13"></i>
                Dependents
              </NavLink>
            </NavItem>
          </Col>
          <Col>
            <NavItem>
              <NavLink
                className={pageSubcategories === "ps2" ? "active" : ""}
                onClick={() => setpageSubcategories("ps2")}
              >
                <i className="now-ui-icons objects_umbrella-13"></i>
                Childhood illness
              </NavLink>
            </NavItem>
          </Col>
          <Col>
            <NavItem>
              <NavLink
                className={pageSubcategories === "ps3" ? "active" : ""}
                onClick={() => setpageSubcategories("ps3")}
              >
                <i className="now-ui-icons objects_umbrella-13"></i>
                Immunization
              </NavLink>
            </NavItem>
          </Col>
          <Col>
            <NavItem>
              <NavLink
                className={pageSubcategories === "ps4" ? "active" : ""}
                onClick={() => setpageSubcategories("ps4")}
              >
                <i className="now-ui-icons objects_umbrella-13"></i>
                Surgeries
              </NavLink>
            </NavItem>
          </Col>
          <Col>
            <NavItem>
              <NavLink
                className={pageSubcategories === "ps5" ? "active" : ""}
                onClick={() => setpageSubcategories("ps5")}
              >
                <i className="now-ui-icons objects_umbrella-13"></i>
                Social
              </NavLink>
            </NavItem>
          </Col>
          <Col>
            <NavItem>
              <NavLink
                className={pageSubcategories === "ps6" ? "active" : ""}
                onClick={() => setpageSubcategories("ps6")}
              >
                <i className="now-ui-icons objects_umbrella-13"></i>
                Medical
              </NavLink>
            </NavItem>
          </Col>
          <Col>
            <NavItem>
              <NavLink
                className={pageSubcategories === "ps7" ? "active" : ""}
                onClick={() => setpageSubcategories("ps7")}
              >
                <i className="now-ui-icons objects_umbrella-13"></i>
                Medication
              </NavLink>
            </NavItem>
          </Col>
          <Col>
            <NavItem>
              <NavLink
                className={pageSubcategories === "ps8" ? "active" : ""}
                onClick={() => setpageSubcategories("ps8")}
              >
                <i className="now-ui-icons objects_umbrella-13"></i>
                Family
              </NavLink>
            </NavItem>
          </Col>
        </Row>
      </Nav>

      <TabContent className="tab-space" activeTab={pageSubcategories}>
        <TabPane tabId="ps1">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h3">My Dependents</CardTitle>
                  <Button onClick={handleOpenEvent}>ADD</Button>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">First Name</th>
                        <th className="text-center">Last Name</th>
                        <th className="text-center">ID Number</th>
                        <th className="text-center">Relationship</th>
                        <th className="text-center">Gender</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">#</td>
                        <td className="text-center">First Name</td>
                        <td className="text-center">Last Name</td>
                        <td className="text-center">ID Number</td>
                        <td className="text-center">Relationship</td>
                        <td className="text-center">Gender</td>
                        <td className="text-center">Gender</td>
                      </tr>
                    </tbody>
                  </Table>
                  <Dialog
                    open={openFPModal}
                    onClose={handleCloseEvent}
                    aria-labelledby="form-dialog-title"
                    maxWidth="lg"
                    size="lg"
                    centered={true}
                    // size="lg"
                  >
                    <DialogTitle id="form-dialog-title">
                      {"Add Dependent"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <CustomInput
                          labelText={language.firstname}
                          id="firstName"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            required: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                {/* <Person className={classes.inputIconsColor} /> */}
                              </InputAdornment>
                            ),
                          }}
                          onChange={(event) => {
                            setFirstName(event.target.value);
                          }}
                          value={firstName}
                        />
                        <CustomInput
                          labelText={language.lastname}
                          id="lastName"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            required: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                {/* <Person className={classes.inputIconsColor} /> */}
                              </InputAdornment>
                            ),
                          }}
                          onChange={(event) => {
                            setLastName(event.target.value);
                          }}
                          value={lastName}
                        />
                        <CustomInput
                          labelText={language.identity_number_placeholder}
                          id="idNumber"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "number",
                            required: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                {/* <Person className={classes.inputIconsColor} /> */}
                              </InputAdornment>
                            ),
                          }}
                          onChange={(event) => {
                            setIdNumber(event.target.value);
                          }}
                          value={idNumber}
                        />
                        <Row xs="2">
                          <Col>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-standard-label">
                                Relationship
                              </InputLabel>
                              <Select
                                // styles={{ fullWidth: "100%" }}
                                id="service-type-native"
                                value={relationship}
                                onChange={(event) => {
                                  setRelationship(event.target.value);
                                }}
                              >
                                <MenuItem value="child">Child</MenuItem>
                                <MenuItem value="mother">Mother</MenuItem>
                                <MenuItem value="father">Father</MenuItem>
                                <MenuItem value="husband">Husband</MenuItem>
                              </Select>
                            </FormControl>
                          </Col>
                          <Col>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-standard-label">
                                Gender
                              </InputLabel>
                              <Select
                                // styles={{ fullWidth: "60px" }}
                                id="service-type-native"
                                value={gender}
                                onChange={(event) => {
                                  setGender(event.target.value);
                                }}
                              >
                                <MenuItem value="male">Male</MenuItem>
                                <MenuItem value="female">Female</MenuItem>
                              </Select>
                            </FormControl>
                          </Col>
                        </Row>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={onSave} color="primary">
                        Save
                      </Button>
                      <Button onClick={handleCloseEvent} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="ps2">
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h3">Childhood illnesses</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    {medical?.Childhood_illness?.Measles && (
                      <Col md="4">
                        <FormGroup>
                          <p className="description">{"Measles"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Childhood_illness?.Chickenpox && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Chickenpox"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Childhood_illness?.Mumps && (
                      <Col md="4">
                        <FormGroup>
                          <p className="description">{"Mumps"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Childhood_illness?.Polio && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Polio"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Childhood_illness?.Rheumatic && (
                      <Col md="4">
                        <FormGroup>
                          <p className="description">{"Rheumatic"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Childhood_illness?.Rubellac && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Rubellac"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                </CardBody>
                <CardFooter>
                  <EditOutlined onClick={onEditSelect2} />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="ps3">
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h3">Immunization and dates</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <h5 className="card-category">Name</h5>
                      <FormGroup>
                        {medical?.Immunizations_an_Dates?.Tetanus.checked && (
                          <p className="description">{"Tetanus"}</p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <h5 className="card-category">Date</h5>
                      <FormGroup>
                        {medical?.Immunizations_an_Dates?.Tetanus.Date && (
                          <p className="description">
                            {medical?.Immunizations_an_Dates?.Tetanus.Date}
                          </p>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    {medical?.Immunizations_an_Dates?.Hepatitis_A.checked && (
                      <Col md="6">
                        <FormGroup>
                          <p className="description">{"Hepatitis A"}</p>
                        </FormGroup>
                      </Col>
                    )}

                    {medical?.Immunizations_an_Dates?.Hepatitis_A.Date && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {medical?.Immunizations_an_Dates?.Hepatitis_A.Date}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Immunizations_an_Dates?.Chickenpox2.checked && (
                      <Col md="6">
                        <FormGroup>
                          <p className="description">{"Chickenpox"}</p>
                        </FormGroup>
                      </Col>
                    )}

                    {medical?.Immunizations_an_Dates?.Chickenpox2.Date && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {medical?.Immunizations_an_Dates?.Chickenpox2.Date}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Immunizations_an_Dates?.Hepatitis_B.checked && (
                      <Col md="6">
                        <FormGroup>
                          <p className="description">{"Hepatitis B"}</p>
                        </FormGroup>
                      </Col>
                    )}

                    {medical?.Immunizations_an_Dates?.Hepatitis_B.Date && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {medical?.Immunizations_an_Dates?.Hepatitis_B.Date}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Immunizations_an_Dates?.MMR.checked && (
                      <Col md="6">
                        <FormGroup>
                          <p className="description">{"MMR"}</p>
                        </FormGroup>
                      </Col>
                    )}

                    {medical?.Immunizations_an_Dates?.MMR.Date && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {medical?.Immunizations_an_Dates?.MMR.Date}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Immunizations_an_Dates?.Meningococcal.checked && (
                      <Col md="6">
                        <FormGroup>
                          <p className="description">{"Meningococcal"}</p>
                        </FormGroup>
                      </Col>
                    )}

                    {medical?.Immunizations_an_Dates?.Meningococcal.Date && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {medical?.Immunizations_an_Dates?.Meningococcal.Date}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Immunizations_an_Dates?.Pneumonia.checked && (
                      <Col md="6">
                        <FormGroup>
                          <p className="description">{"Pneumonia"}</p>
                        </FormGroup>
                      </Col>
                    )}

                    {medical?.Immunizations_an_Dates?.Pneumonia.Date && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {medical?.Immunizations_an_Dates?.Pneumonia.Date}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Immunizations_an_Dates?.influenza.checked && (
                      <Col md="6">
                        <FormGroup>
                          <p className="description">{"influenza"}</p>
                        </FormGroup>
                      </Col>
                    )}

                    {medical?.Immunizations_an_Dates?.influenza.Date && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {medical?.Immunizations_an_Dates?.influenza.Date}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                </CardBody>
                <CardFooter>
                  <EditOutlined onClick={onEditSelect5} />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="ps4">
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h3">Surgeries</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <h5 className="card-category">Reason</h5>
                      <FormGroup>
                        {medical?.Surgries?.Surgry1st?.ReasonOfSurgry && (
                          <p className="description">
                            {medical?.Surgries?.Surgry1st?.ReasonOfSurgry}
                          </p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <h5 className="card-category">Date</h5>
                      <FormGroup>
                        {medical?.Surgries?.Surgry1st?.YearOfSurgry && (
                          <p className="description">
                            {medical?.Surgries?.Surgry1st?.YearOfSurgry}
                          </p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <h5 className="card-category">Hospital</h5>
                      <FormGroup>
                        {medical?.Surgries?.Surgry1st?.HospitalOfSurgry && (
                          <p className="description">
                            {medical?.Surgries?.Surgry1st?.HospitalOfSurgry}
                          </p>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        {medical?.Surgries?.Surgry2nd?.ReasonOfSurgry && (
                          <p className="description">
                            {medical?.Surgries?.Surgry2nd?.ReasonOfSurgry}
                          </p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        {medical?.Surgries?.Surgry2nd?.YearOfSurgry && (
                          <p className="description">
                            {medical?.Surgries?.Surgry2nd?.YearOfSurgry}
                          </p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        {medical?.Surgries?.Surgry2nd?.HospitalOfSurgry && (
                          <p className="description">
                            {medical?.Surgries?.Surgry2nd?.HospitalOfSurgry}
                          </p>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        {medical?.Surgries?.Surgry3rd?.ReasonOfSurgry && (
                          <p className="description">
                            {medical?.Surgries?.Surgry3rd?.ReasonOfSurgry}
                          </p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        {medical?.Surgries?.Surgry3rd?.YearOfSurgry && (
                          <p className="description">
                            {medical?.Surgries?.Surgry3rd?.YearOfSurgry}
                          </p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        {medical?.Surgries?.Surgry3rd?.HospitalOfSurgry && (
                          <p className="description">
                            {medical?.Surgries?.Surgry3rd?.HospitalOfSurgry}
                          </p>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <EditOutlined onClick={onEditSelect6} />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="ps5">
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h3">Social Medical History</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Row>
                      <Col md="3">
                        <label>Occupation :</label>
                        {medical?.LifeStyle_History?.SocialHistory?.occupation && (
                          <FormGroup>
                            <p className="description">
                              {
                                medical?.LifeStyle_History?.SocialHistory?.occupation
                              }
                            </p>
                          </FormGroup>
                        )}
                      </Col>
                      <Col md="3">
                        <label>Maritial Status :</label>
                        {medical?.LifeStyle_History?.SocialHistory?.maritalstatus && (
                          <FormGroup>
                            <p className="description">
                              {
                                medical?.LifeStyle_History?.SocialHistory?.maritalstatus
                              }
                            </p>
                          </FormGroup>
                        )}
                      </Col>
                      <Col md="3">
                        <label>Exercise :</label>
                        {medical?.LifeStyle_History?.SocialHistory?.exercise && (
                          <FormGroup>
                            <p className="description">
                              {medical?.LifeStyle_History?.SocialHistory?.exercise}
                            </p>
                          </FormGroup>
                        )}
                      </Col>
                      <Col md="3">
                        <label>Sexual Orientation :</label>
                        {medical?.LifeStyle_History?.SocialHistory?.sexualorientation && (
                          <FormGroup>
                            <p className="description">
                              {
                                medical?.LifeStyle_History?.SocialHistory?.sexualorientation
                              }
                            </p>
                          </FormGroup>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <label>Stress Level :</label>
                        {medical?.LifeStyle_History?.SocialHistory?.stressfactor && (
                          <FormGroup>
                            <p className="description">
                              {
                                medical?.LifeStyle_History?.SocialHistory?.stressfactor
                              }
                            </p>
                          </FormGroup>
                        )}
                      </Col>
                      <Col md="3">
                        <label>Home Language :</label>
                        {medical?.LifeStyle_History?.SocialHistory?.language && (
                          <FormGroup>
                            <p className="description">
                              {medical?.LifeStyle_History?.SocialHistory?.language}
                            </p>
                          </FormGroup>
                        )}
                      </Col>
                      <Col md="3">
                        <label>Dependents :</label>
                        {medical?.LifeStyle_History?.SocialHistory?.children && (
                          <FormGroup>
                            <p className="description">
                              {medical?.LifeStyle_History?.SocialHistory?.children}
                            </p>
                          </FormGroup>
                        )}
                      </Col>
                      <Col md="3">
                        <label>Diet :</label>
                        {medical?.LifeStyle_History?.SocialHistory?.diet && (
                          <FormGroup>
                            <p className="description">
                              {medical?.LifeStyle_History?.SocialHistory?.diet}
                            </p>
                          </FormGroup>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <label>Alcohol Consumption :</label>
                        {medical?.LifeStyle_History?.SocialHistory?.alcoholconsumption && (
                          <FormGroup>
                            <p className="description">
                              {
                                medical?.LifeStyle_History?.SocialHistory?.alcoholconsumption
                              }
                            </p>
                          </FormGroup>
                        )}
                      </Col>
                      <Col md="3">
                        <label>Smoking :</label>
                        {medical?.LifeStyle_History?.SocialHistory?.smoking && (
                          <FormGroup>
                            <p className="description">
                              {medical?.LifeStyle_History?.SocialHistory?.smoking}
                            </p>
                          </FormGroup>
                        )}
                      </Col>
                      <Col md="3">
                        <label>Substance Abuse :</label>
                        {medical?.LifeStyle_History?.SocialHistory?.substanceabuse && (
                          <FormGroup>
                            <p className="description">
                              {
                                medical?.LifeStyle_History?.SocialHistory?.substanceabuse
                              }
                            </p>
                          </FormGroup>
                        )}
                      </Col>
                    </Row>
                  </Row>
                </CardBody>
                <CardFooter>
                  <EditOutlined onClick={onEditSelect3} />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="ps6">
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h3">Medical History</CardTitle>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <FormText color="default" tag="span">
                      Your Current Medical History
                    </FormText>
                  </FormGroup>
                  <Row>
                    {medical?.Medical_History?.medicalhistory?.Anemia && (
                      <Col md="4">
                        <FormGroup>
                          <p className="description">{"Anemia"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Blood_Colts && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Blood Colts"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Alcohol_Abuse && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Alcohol Abuse"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Medical_History?.medicalhistory?.Heart_Disease && (
                      <Col md="4">
                        <FormGroup>
                          <p className="description">{"Heart Disease"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Anesthetic_Complication && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {"Anesthetic Complication"}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Anxiety_Disorder && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Anxiety Disorder"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Medical_History?.medicalhistory?.Arthritis && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Arthritis"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Asthma && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Asthma"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    {medical?.Medical_History?.medicalhistory?.Birth_Defects && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Birth Defects"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Bladder_Problems && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Bladder Problems"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Bleeding_Disease && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Bleeding Disease"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Medical_History?.medicalhistory?.Blood_Clots && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Blood Clots"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Blood_Transfusions && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Blood Transfusions"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Bowel_Disease && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Bowel_Disease"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Medical_History?.medicalhistory?.Breast_Cancer && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Breast Cancer"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Cervical_Cancer && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Cervical Cancer"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Colon_Cancer && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Colon Cancer"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Medical_History?.medicalhistory?.Depression && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Depression"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Diabetes && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Diabetes"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.GrowthDevelopment_Disorder && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {"GrowthDevelopment Disorder"}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Medical_History?.medicalhistory?.HIV && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"HIV"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Hearing_Impairment && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Hearing Impairment"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Heart_Attack && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Heart Attack"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Medical_History?.medicalhistory?.Heart_Disease && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Heart Disease"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Heart_Pain_Angina && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Heart Pain Angina"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Hepatitis_A && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Hepatitis A"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Medical_History?.medicalhistory?.Hepatitis_B && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Hepatitis B"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Hepatitis_C && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Hepatitis C"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.High_Blood_Pressure && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"High Blood Pressure"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    {medical?.Medical_History?.medicalhistory?.High_Cholesterol && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"High Cholesterol"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Hives && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Hives"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Kidney_Disease && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Kidney Disease"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Medical_History?.medicalhistory?.Liver_Cancer && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Liver Cancer"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Liver_Disease && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Liver Disease"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Lung_Cancer && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Lung Cancer"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Medical_History?.medicalhistory?.Liver_Disease && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Liver Disease"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Lung_Cancer && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Lung Cancer"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Lung_Respiratory_Disease && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {"Lung Respiratory Disease"}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    {medical?.Medical_History?.medicalhistory?.Mental_Illness && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Mental Illness"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Migraines && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Migraines"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Osteoporosis && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Osteoporosis"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    {medical?.Medical_History?.medicalhistory?.Other_Disease_Cancer_or_Significant_Medical_Illness && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {"Other Disease Cancers"}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Other_Disease_Cancer_or_Significant_Medical_Illness && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {"Other Signficant Illness"}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Prostate_Cancer && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Prostate Cancer"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    {medical?.Medical_History?.medicalhistory?.Rectal_Cancer && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Rectal Cancer"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Reflux_GERD && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Reflux Gerd"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Severe_Allergy && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Severe Allergy"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Medical_History?.medicalhistory?.Sexually_Transmitted_Disease && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"STD"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Skin_Cancer && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Skin Cancer"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Stroke_CVA_of_the_Brain && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Stroke of the Brain"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Medical_History?.medicalhistory?.Suicide_Attempt && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Suicide Attempt"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Thyroid_Problems && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Thyroid Problems"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Medical_History?.medicalhistory?.Ulcer && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Ulcer"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Medical_History?.medicalhistory?.Visual_Impairment && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Visual Impairment"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                </CardBody>
                <CardFooter>
                  <EditOutlined onClick={onEditSelect4} />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="ps7">
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h3">Medication</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    {medical?.Medication?.Medication1.Drug && (
                      <Col md="6">
                        <label>Medication Name</label>
                        <FormGroup>
                          <p className="description">
                            {medical?.Medication?.Medication1.Drug}
                          </p>
                        </FormGroup>
                      </Col>
                    )}

                    {medical?.Medication?.Medication1.Dose && (
                      <Col>
                        <label>Dose</label>
                        <FormGroup>
                          <p className="description">
                            {medical?.Medication?.Medication1.Dose}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Medication?.Medication2.Drug && (
                      <Col md="6">
                        <FormGroup>
                          <p className="description">
                            {medical?.Medication?.Medication2.Drug}
                          </p>
                        </FormGroup>
                      </Col>
                    )}

                    {medical?.Medication?.Medication2.Dose && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {medical?.Medication?.Medication2.Dose}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Medication?.Medication3.Drug && (
                      <Col md="6">
                        <FormGroup>
                          <p className="description">
                            {medical?.Medication?.Medication3.Drug}
                          </p>
                        </FormGroup>
                      </Col>
                    )}

                    {medical?.Medication?.Medication3.Dose && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {medical?.Medication?.Medication3.Dose}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Medication?.Medication4.Drug && (
                      <Col md="6">
                        <FormGroup>
                          <p className="description">
                            {medical?.Medication?.Medication4.Drug}
                          </p>
                        </FormGroup>
                      </Col>
                    )}

                    {medical?.Medication?.Medication4.Dose && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {medical?.Medication?.Medication4.Dose}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Medication?.Medication5.Drug && (
                      <Col md="6">
                        <FormGroup>
                          <p className="description">
                            {medical?.Medication?.Medication5.Drug}
                          </p>
                        </FormGroup>
                      </Col>
                    )}

                    {medical?.Medication?.Medication5.Dose && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {medical?.Medication?.Medication5.Dose}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Medication?.Medication6.Drug && (
                      <Col md="6">
                        <FormGroup>
                          <p className="description">
                            {medical?.Medication?.Medication6.Drug}
                          </p>
                        </FormGroup>
                      </Col>
                    )}

                    {medical?.Medication?.Medication6.Dose && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {medical?.Medication?.Medication6.Dose}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                </CardBody>
                <CardFooter>
                  <EditOutlined onClick={onEditSelect8} />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="ps8">
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h3"> Family Medical History</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.Adopted && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Adopted"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Alcohol_Abuse && (
                      <Col>
                        <FormGroup>
                          <p className="description">{" Alcohol Abuse"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Anemia && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Anemia"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Anesthetic_Complication && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {"Anesthetic Complication"}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Anxiety_Disorder && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Anxiety Disorder"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Arthritis && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Arthritis"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Asthma && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Asthma"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Autoimmune_Problems && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Autoimmune Problems"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Birth_Defects && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Birth Defects"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Bladder_Problems && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Bladder Problems"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Bleeding_Disease && (
                      <Col>
                        <FormGroup>
                          <p className="description">{" Bleeding Disease"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Blood_Clots && (
                      <Col>
                        <FormGroup>
                          <p className="description">{" Blood Clots"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Blood_Transfusions && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Blood Transfusions"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Bowel_Disease && (
                      <Col>
                        <FormGroup>
                          <p className="description">{" Bowel Disease"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Breast_Cancer && (
                      <Col>
                        <FormGroup>
                          <p className="description">{" Breast Cancer"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Cervical_Cancer && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Cervical Cancer"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Colon_Cancer && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Colon Cancer "}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Depression && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Depression"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Diabetes && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Diabetes"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_GrowthDevelopment_Disorder && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {"Growth Development Disorder"}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_HIV && (
                      <Col>
                        <FormGroup>
                          <label>Height</label>
                          <p className="description">{" HIV"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Hearing_Impairment && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Hearing Impairment"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Heart_Attack && (
                      <Col>
                        <FormGroup>
                          <p className="description">{" Heart Attack"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Heart_Disease && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Heart Disease"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Heart_Pain_Angina && (
                      <Col>
                        <FormGroup>
                          <p className="description">{" Heart Pain Angina "}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Hepatitis_A && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Hepatitis A"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Hepatitis_B && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Hepatitis B"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Hepatitis_C && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Hepatitis C"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_High_Blood_Pressure && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"High Blood Pressure"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_High_Cholesterol && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"High Cholesterol"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Hives && (
                      <Col>
                        <FormGroup>
                          <p className="description">{" Hives"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Kidney_Disease && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Kidney Disease "}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Liver_Cancer && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Liver Cancer "}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Liver_Disease && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Liver Disease "}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Lung_Cancer && (
                      <Col>
                        <FormGroup>
                          <p className="description">{" Lung Cancer"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Lung_Respiratory_Disease && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {"Lung Respiratory Disease"}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Mental_Illness && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Mental Illness"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Migraines && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Migraines"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_NONE_of_the_Above && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"NONE"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Osteoporosis && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Osteoporosis"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Other_Disease_Cancer_or_Significant_Medical_Illness && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {
                              "Other Disease Cancer or Significant Medical Illness"
                            }
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Prostate_Cancer && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Prostate Cancer"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Rectal_Cancer && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Rectal Cancer"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Reflux_GERD && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Reflux GERD"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Seizures_Convulsions && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {"Seizures Convulsions"}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Severe_Allergy && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Severe Allergy"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Sexually_Transmitted_Disease && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {"Sexually Transmitted Disease"}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Skin_Cancer && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Skin Cancer"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Stroke_CVA_of_the_Brain && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {"Stroke CVA of the Brain"}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Suicide_Attempt && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Suicide Attempt"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Thyroid_Problems && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Thyroid Problems"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Ulcer && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Ulcer"}</p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.F_Visual_Impairment && (
                      <Col>
                        <FormGroup>
                          <p className="description">{"Visual Impairment"}</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.Family_History_unknow && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {"Family History unknown "}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                    {medical?.Family_Medical_History?.Fmedicalhistory?.Female_Family && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {
                              "Mother, Grandmother, or Sister developed heart disease before the age of 65"
                            }
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {medical?.Family_Medical_History?.Fmedicalhistory?.Male_Family && (
                      <Col>
                        <FormGroup>
                          <p className="description">
                            {
                              "Father, Grandfather, or Brother developed heart disease before the age of 65"
                            }
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                </CardBody>
                <CardFooter>
                  <EditOutlined onClick={onEditSelect7} />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </>
  );
};

export default PatientProfile;
