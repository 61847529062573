/*eslint-disable*/
import React from "react";
import DocumentScanner from "../../components/DocumentScanner";

// eslint-disable-next-line no-unused-expressions

export default function DocScan() {
  // eslint-disable-next-line no-unused-expressions
  return <DocumentScanner />;
}
