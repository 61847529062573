import {
  FETCH_DRUGEXPO,
  FETCH_DRUGEXPO_SUCCESS,
  FETCH_DRUGEXPO_FAILED,
  EDIT_DRUGEXPO,
  EDIT_DRUGEXPO_SUCCESS,
  EDIT_DRUGEXPO_FAILED,
  ADD_DRUGEXPO,
  ADD_DRUGEXPO_SUCCESS,
  ADD_DRUGEXPO_FAILED,
  DELETE_DRUGEXPO,
  DELETE_DRUGEXPO_SUCCESS,
  DELETE_DRUGEXPO_FAILED,
  FETCH_OTC,
  FETCH_OTC_SUCCESS,
  FETCH_OTC_FAILED,
  FETCH_SPL,
  FETCH_SPL_SUCCESS,
  FETCH_SPL_FAILED,
  FETCH_ICD10CM,
  FETCH_ICD10CM_SUCCESS,
  FETCH_ICD10CM_FAILED,
} from "../store/types";

export const INITIAL_STATE = {
  drugs: null,
  loading: false,
  error: {
    flag: false,
    msg: null,
  },
};

export const drugexposurereducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DRUGEXPO:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DRUGEXPO_SUCCESS:
      return {
        ...state,
        drugs: action.payload,
        loading: false,
      };
    case FETCH_DRUGEXPO_FAILED:
      return {
        ...state,
        drugs: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
      };
    case ADD_DRUGEXPO:
      return {
        ...state,
        loading: true,
      };
    case ADD_DRUGEXPO_SUCCESS:
      return {
        ...state,
        drugs: action.payload,
        loading: false,
      };
    case ADD_DRUGEXPO_FAILED:
      return {
        ...state,
        drugs: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
      };
    case EDIT_DRUGEXPO:
      return {
        ...state,
        loading: true,
      };
    case EDIT_DRUGEXPO_SUCCESS:
      return {
        ...state,
        drugs: action.payload,
        loading: false,
      };
    case EDIT_DRUGEXPO_FAILED:
      return {
        ...state,
        drugs: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
      };
    case DELETE_DRUGEXPO:
      return {
        ...state,
        loading: true,
      };
    case DELETE_DRUGEXPO_SUCCESS:
      return {
        ...state,
        drugs: action.payload,
        loading: false,
      };
    case DELETE_DRUGEXPO_FAILED:
      return {
        ...state,
        drugs: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
      };

    case FETCH_OTC:
      return {
        ...state,
        loading: true,
      };
    case FETCH_OTC_SUCCESS:
      return {
        ...state,
        otc: action.payload,
        loading: false,
      };
    case FETCH_OTC_FAILED:
      return {
        ...state,
        otc: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
      };

    case FETCH_SPL:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SPL_SUCCESS:
      return {
        ...state,
        spl: action.payload,
        loading: false,
      };
    case FETCH_SPL_FAILED:
      return {
        ...state,
        spl: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
      };
    case FETCH_ICD10CM:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ICD10CM_SUCCESS:
      return {
        ...state,
        icd: action.payload,
        loading: false,
      };
    case FETCH_ICD10CM_FAILED:
      return {
        ...state,
        icd: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
      };

    default:
      return state;
  }
};
