import {
  FETCH_DRUGEXPO,
  FETCH_DRUGEXPO_SUCCESS,
  FETCH_DRUGEXPO_FAILED,
  EDIT_DRUGEXPO,
  EDIT_DRUGEXPO_SUCCESS,
  EDIT_DRUGEXPO_FAILED,
  ADD_DRUGEXPO,
  ADD_DRUGEXPO_SUCCESS,
  ADD_DRUGEXPO_FAILED,
  DELETE_DRUGEXPO,
  DELETE_DRUGEXPO_SUCCESS,
  DELETE_DRUGEXPO_FAILED,
  FETCH_OTC,
  FETCH_OTC_SUCCESS,
  FETCH_OTC_FAILED,
  FETCH_SPL,
  FETCH_SPL_SUCCESS,
  FETCH_SPL_FAILED,
  FETCH_ICD10CM,
  FETCH_ICD10CM_SUCCESS,
  FETCH_ICD10CM_FAILED,
} from "../store/types";

export const fetchDrugExposure = () => (dispatch) => {
  dispatch({
    type: FETCH_DRUGEXPO,
    payload: null,
  });

  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // change url to deployed api url

    fetch("localhost:32784/api/drug_exposure", requestOptions)
      .then((response) => {
        console.log("i am the response", response);
        dispatch({
          type: FETCH_DRUGEXPO_SUCCESS,
          payload: response,
        });
      })
      .then((result) => console.log("this is created user results: ", result))
      .catch((error) => console.log("error", error));
  } catch (err) {
    dispatch({
      type: FETCH_DRUGEXPO_FAILED,

      payload: err,
    });
    console.log("fail", err);
  }
};

export const addDrugExposure = (drugData) => (dispatch) => {
  dispatch({
    type: ADD_DRUGEXPO,
    payload: null,
  });

  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "text/plain");

    const raw = drugData;

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // change url to deployed api url

    fetch("localhost:32784/api/drug_exposure", requestOptions)
      .then((response) => {
        console.log("i am the response", response);
        dispatch({
          type: ADD_DRUGEXPO_SUCCESS,
          payload: response,
        });
      })
      .then((result) => console.log("this is created user results: ", result))
      .catch((error) => console.log("error", error));
  } catch (err) {
    dispatch({
      type: ADD_DRUGEXPO_FAILED,

      payload: err,
    });
    console.log("fail", err);
  }
};
export const editDrugExposure = (id, drugData) => (dispatch) => {
  dispatch({
    type: EDIT_DRUGEXPO,
    payload: null,
  });

  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "text/plain");

    const raw = drugData;

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // change url to deployed api url

    fetch(`localhost:32784/api/drug_exposure/${id}`, requestOptions)
      .then((response) => {
        console.log("i am the response", response);
        dispatch({
          type: EDIT_DRUGEXPO_SUCCESS,
          payload: response,
        });
      })
      .then((result) => console.log("this is created user results: ", result))
      .catch((error) => console.log("error", error));
  } catch (err) {
    dispatch({
      type: EDIT_DRUGEXPO_FAILED,

      payload: err,
    });
    console.log("fail", err);
  }
};

export const deleteDrugExposure = (id) => (dispatch) => {
  dispatch({
    type: DELETE_DRUGEXPO,
    payload: null,
  });

  try {
    const requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };
    fetch(`localhost:32784/api/drug_exposure/${id}`, requestOptions)
      .then((response) => {
        console.log("i am the response", response);
        dispatch({
          type: DELETE_DRUGEXPO_SUCCESS,
          payload: response,
        });
      })
      .then((result) => console.log("this is created user results: ", result))
      .catch((error) => console.log("error", error));
  } catch (err) {
    dispatch({
      type: DELETE_DRUGEXPO_FAILED,

      payload: err,
    });
    console.log("fail", err);
  }
};

// CONDITION
export const fetchICD = () => (dispatch) => {
  dispatch({
    type: FETCH_ICD10CM,
    payload: null,
  });

  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      vocabulary_id: "ICD10CM",
      concept_class_id: "%6-char%",
      domain_id: "Condition",
      searchtext: "%gout%",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("http://34.88.203.177/api/concepts/getcondition/", requestOptions)
      .then((response) => {
         response.json();
        console.log("icd10 :e ", response);
        dispatch({
          type: FETCH_ICD10CM_SUCCESS,
          payload: response,
        });
      })
      .then((result) => console.log("icd10 :  ", result))
      .catch((error) => console.log("error", error));
  } catch (err) {
    dispatch({
      type: FETCH_ICD10CM_FAILED,

      payload: err,
    });
    console.log("fail", err);
  }
};

// OTC
export const fetchOtc = (otcdata) => (dispatch) => {
  dispatch({
    type: FETCH_OTC,
    payload: null,
  });

  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      vocabulary_id: "ICD10CM",
      concept_class_id: "%OTC%",
      domain_id: "Drug",
      searchtext: `%${otcdata}%`,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("http://34.88.203.177/api/concepts/getotcdrugs/", requestOptions)
      .then((response) => {
        response.json();
        dispatch({
          type: FETCH_OTC_SUCCESS,
          payload: response,
        });
      })
      .then((result) => console.log("otc : : ", result))
      .catch((error) => console.log("error", error));
  } catch (err) {
    dispatch({
      type: FETCH_OTC_FAILED,

      payload: err,
    });
    console.log("fail", err);
  }
};

// SPL
export const fetchSpl = () => (dispatch) => {
  dispatch({
    type: FETCH_SPL,
    payload: null,
  });

  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      vocabulary_id: "SPL",
      concept_class_id: "%OTC%",
      domain_id: "Drug",
      searchtext: "%bromine%",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "http://34.88.203.177/api/concepts/getprescriptiondrugs/",
      requestOptions
    )
      .then((response) => {
        console.log("spl : ", response);
        response.json()
        dispatch({
          type: FETCH_SPL_SUCCESS,
          payload: response,
        });
      })
      .then((result) => console.log("spl : : ", result))
      .catch((error) => console.log("error", error));
  } catch (err) {
    dispatch({
      type: FETCH_SPL_FAILED,

      payload: err,
    });
    console.log("fail", err);
  }
};
