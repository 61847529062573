/*eslint-disable*/
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
// import { Card, CardBody, CardTitle, CardHeader } from "reactstrap";
// @asseinfo/react-kanban components
import Board from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";

function Kanban() {
  const auth = useSelector((state) => state.auth);
  const usersdata = useSelector((state) => state.usersdata.users);
  const [userData, setuserData] = useState([]);
  const [data, setdata] = useState([]);
  useEffect(() => {
    let arr = [];
    usersdata.map((user) => {
      if (user.userType === "doctor") {
        arr.push({
          firstName: user.firstName,
          lastName: user.lastName,
          mobile: user.mobile,
          email: user.email,
          uid: user.id,
          profile_image: user?.profile_image,
          desc:
            user.firstName +
            " " +
            user.lastName +
            " (" +
            user.mobile +
            ") " +
            user.email,
          pushToken: user.pushToken,
          userType: user.userType,
        });
      }
    });
    setdata(arr);
    setuserData(auth.info.profile);
    // console.log("'data'", data);
  }, [auth, usersdata, data]);

  const board = {
    columns: [
      {
        id: 1,
        title: data[1].firstName,
        cards: [
          {
            id: 1,
            title: userData.firstName,
            description: "Card content",
          },
        ],
      },
    ],
  };
  // const board = {
  //   columns: [
  //     {
  //       id: 1,
  //       title: data.firstName,
  //       cards: [
  //         {
  //           id: 2,
  //           title: "Test",
  //           template: (
  //             <Card>
  //               <CardHeader as="h5">
  //                 <CardTitle tag="h4">
  //                   <span>
  //                     {" "}
  //                     <p className="description"> {userData?.firstName}</p>{" "}
  //                   </span>
  //                 </CardTitle>
  //               </CardHeader>
  //               <CardBody>{userData?.firstName}</CardBody>
  //             </Card>
  //           ),
  //         },
  //       ],
  //     },
  //   ],
  // };
  // const renderBookingData = (item, index) => {

  // }
  return (
    <>
      <Board
      // allowAddCard={{ on: "top" }}
      // allowAddColumn
      // onCardNew={() => null}
      // renderCard={({ content }, { removeCard, dragging }) => (
      //   <Card dragging={dragging}>
      //     <CardHeader as="h5">
      //       <CardTitle tag="h4">
      //         <span>
      //           {" "}
      //           <p className="description">
      //             {" "}
      //             DR. {auth.info.profile.firstName}
      //           </p>{" "}
      //         </span>
      //       </CardTitle>
      //     </CardHeader>
      //     <CardBody>{auth.info.profile.firstName}</CardBody>
      //   </Card>
      // )}
      >
        {board}
      </Board>
    </>
  );
}
export default Kanban;
