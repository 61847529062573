import {
  CREATE_MEETINGROOM,
  CREATE_MEETINGROOM_SUCCESS,
  CREATE_MEETINGROOM_FAIL,
  DELETE_MEETINGROOM,
  DELETE_MEETINGROOM_SUCCESS,
  DELETE_MEETINGROOM_FAIL,
} from "../store/types";

export const createMeetingRoom =
  (firstName, lastName, meetingroom) => (dispatch) => (firebase) => {
    const { meetingroomRef } = firebase;
    dispatch({
      type: CREATE_MEETINGROOM,
      payload: meetingroom,
    });
    try {
      console.log("meetingroom registrations", lastName);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Basic YWRtaW46czd0MTFkYg==");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        "/JSESSIONID=[^;]+/"
      );

      var raw = JSON.stringify({
        roomName: lastName,
        naturalName: firstName,
        description: lastName,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://dev.okdoc.co.za:9091/plugins/restapi/v1/chatrooms",
        requestOptions
      )
        .then((response) => {
          console.log("i am the response", response);
          dispatch({
            type: CREATE_MEETINGROOM_SUCCESS,
            payload: response,
          });
        })
        .then((result) => console.log("i am the result", result))
        .catch((error) => console.log("error", error));
    } catch (err) {
      dispatch({
        type: CREATE_MEETINGROOM_FAIL,

        payload: err,
      });
      console.log("fail", err);
    }
  };
export const deleteMeetingRoom =
  (meetingroom, lastName) => (dispatch) => (firebase) => {
    dispatch({
      type: DELETE_MEETINGROOM,
      payload: meetingroom,
    });
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Basic YWRtaW46czd0MTFkYg==");

      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `https://dev.okdoc.co.za:9091/plugins/restapi/v1/chatrooms/${lastName}`,
        requestOptions
      )
      .then((response) => {
        console.log("DELETE", response);
        dispatch({
          type: DELETE_MEETINGROOM_SUCCESS,
          payload: response,
        });
      })
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    } catch (err) {
      dispatch({
        type: DELETE_MEETINGROOM_FAIL,

        payload: err,
      });
      console.log("fail", err);
    }
  };
