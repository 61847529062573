/*eslint-disable*/
import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { language } from "config";

export default function DoctorEarning() {
  const columns = [
    { title: language.year, field: "year" },
    { title: language.months, field: "monthsName" },
    { title: language.doctor_name, field: "doctorName" },
    { title: language.vehicle_reg_no, field: "doctorVehicleNo" },
    { title: language.earning_amount, field: "doctorShare" },
  ];

  const [data, setData] = useState([]);
  const doctorearningdata = useSelector((state) => state.doctorearningdata);

  useEffect(() => {
    if (doctorearningdata.doctorearnings) {
      setData(doctorearningdata.doctorearnings);
    }
  }, [doctorearningdata.doctorearnings]);

  return doctorearningdata.loading ? (
    <CircularLoading />
  ) : (
    <MaterialTable
      title={language.doctor_earning}
      columns={columns}
      data={data}
      options={{
        exportButton: true,
        grouping: true,
      }}
    />
  );
}
