/*eslint-disable*/
import React from "react";

const AudiologyForm = () => {
  return (
    <div className="content">
      <div className="videoconference">
        <iframe
          // allow="camera; microphone; fullscreen; display-capture; autoplay"
          src="https://rd4a.com/index.php/doctors/sioc-audiology-assessment/mensas-forms"
          height="650px"
          width="100%"
          border="0px"
        ></iframe>
      </div>
    </div>
  );
};

export default AudiologyForm;
