/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { language } from "config";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Collapse,
  Col,
  UncontrolledCollapse,
  Container,
} from "reactstrap";
import { TextField } from "@material-ui/core";
import { FirebaseContext } from "common";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    alignContent: "center",
  },
  title: {
    color: "#000",
  },
  gridcontainer: {
    alignContent: "center",
  },
  items: {
    margin: 0,
    width: "100%",
  },
  input: {
    fontSize: 18,
    color: "#000",
  },
  inputdimmed: {
    fontSize: 18,
    color: "#737373",
  },
  practicephoto: {
    height: "18px",
    marginRight: "10px",
  },
  buttonStyle: {
    margin: 0,
    width: "100%",
    height: "100%",
  },
  rootTwo: {
    minWidth: 275,
    borderRight: "2px solid",
    borderRightColor: "#ff0000",
    borderBlockStartColor: "#ff0000",
    borderBlockStart: "2px solid",
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  },
}));

const PatientBookingDetails = (props) => {
  ////////////////////REduX////////////////////
  const { api } = useContext(FirebaseContext);
  const { GetDateString, updateBooking, fetchChatMessages, clearLoginError } =
    api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  ////////////////////constant////////////////////
  const location = useLocation();
  const Booking = location.state.Booking;
  const [updatebooking, setupdatebooking] = useState(Booking);
  const usersdata = useSelector((state) => state.doctors);
  const [Doctors, setDoctors] = useState([]);
  // const [offers, setOffers] = useState(Object.values(Booking.BookingOffers)) || {};
  const [Nurses, setNurses] = useState([]);
  const [offerData, setOfferData] = useState();
  const [userCombo, setUserCombo] = useState();
  const classes = useStyles();
  const [selectedStartDate, setSelectedStartDate] = useState(GetDateString());
  const [selectedEndDate, setSelectedEndDate] = useState(GetDateString());
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [redodate, setredodate] = useState(false);
  const [ButtonText, setButtonText] = useState("Accept Booking");
  const [rejectbuttonclicked, setrejectbuttonclicked] = useState(false);
  const [acceptbuttonclicked, setacceptbuttonclicked] = useState(false);
  const [commonAlert, setCommonAlert] = useState({
    open: false,
    msg: "",
    title: "",
  });
  // useEffect(() => {
  //   if (offers) {
  //     let keys =Object.keys(Booking.BookingOffers);
  //     let i =0;
  //     let arr=[];
  //     offers.map((offer) =>{
  //     if (offer.offerstatus ==='ACCEPTED'){
  //       arr.push({
  //       ...offer,
  //       id:keys[i]
  //     });}
  //     i=i+1
  //     });
  //     console.log("checking offer info", arr[0]);
  //     setOfferData(arr[0]);
  //   }

  // }, []);

  useEffect(() => {
    let arr = [];
    usersdata.users.map((user) => {
      if (
        user.practiceNumber === auth.info.profile.practiceNumber &&
        user.userType === "doctor"
      ) {
        arr.push({
          firstName: user.firstName,
          lastName: user.lastName,
          mobile: user.mobile,
          email: user.email,
          uid: user.id,
          profile_image: user.profile_image,
          desc:
            user.firstName +
            " " +
            user.lastName +
            " (" +
            user.mobile +
            ") " +
            user.email,
          pushToken: user.pushToken,
        });
      }
    });
    setDoctors(arr);
  }, []);

  useEffect(() => {
    console.log("this is the booking", Booking);
    let arr = [];
    usersdata.users.map((user) => {
      if (
        user.practiceNumber === auth.info.profile.practiceNumber &&
        user.userType === "nurse"
      ) {
        arr.push({
          firstName: user.firstName,
          lastName: user.lastName,
          mobile: user.mobile,
          email: user.email,
          uid: user.id,
          profile_image: user.profile_image,
          desc:
            user.firstName +
            " " +
            user.lastName +
            " (" +
            user.mobile +
            ") " +
            user.email,
          pushToken: user.pushToken,
        });
      }
    });
    setNurses(arr);
  }, []);

  const [openedCollapses, setOpenCollapses] = React.useState(["collapseOne"]);

  const collapsesToggle = (collapse) => {
    if (openedCollapses.includes(collapse)) {
      setOpenCollapses([]);
    } else {
      setOpenCollapses([collapse]);
    }
  };

  const renderBookingData = () => {
    return (
      <Container>
        <Row>
          <Col sm="6">
            <Card md="6" className={classes.rootTwo}>
              <CardBody>
                <FormGroup>
                  <span>Booking Name: </span>
                  <p className="description">{Booking.bookingName}</p>
                </FormGroup>

                <FormGroup>
                  <span>Type of Service: </span>
                  <p className="description">{Booking.serviceType}</p>
                </FormGroup>

                <FormGroup>
                  <span>Booking ID: </span>
                  <p className="description">{Booking.id}</p>
                </FormGroup>

                <FormGroup>
                  <span>Estimate Amount: </span>
                  <p className="description">
                    R{Booking?.accepted_total || Booking?.estimate_bigGrand}
                  </p>
                </FormGroup>

                <FormGroup>
                  <span>Booking Status: </span>
                  <p className="description">{Booking.status}</p>
                </FormGroup>

                <FormGroup>
                  <span>Type of Doctor/Practice They Looking for: </span>
                  <p className="description">{Booking.specialityType}</p>
                </FormGroup>
                {Booking.adjustedtimeStart ? (
                  <Col>
                    <FormGroup>
                      <span>New Booking Date and Time of Booking: </span>
                      <p className="description">{Booking.adjustedtimeStart}</p>
                    </FormGroup>
                  </Col>
                ) : null}
                {!Booking.adjustedtimeStart ? (
                  <Col>
                    <FormGroup>
                      <span>Date and Time of Booking: </span>
                      <p className="description">{Booking.eventDate}</p>
                    </FormGroup>
                  </Col>
                ) : null}
              </CardBody>
              <Button
                color="primary"
                onClick={() => {
                  navigate({
                    pathname: "/patientbookingoffers",
                    state: { Booking },
                  });

                  setOpen(true);
                }}
              >
                Back
              </Button>
            </Card>
          </Col>

          {/* ////////////////Nurse Details */}

          <Col sm="6">
            {Booking.status !== "NEW" && Booking.nurse ? (
              <Card className={classes.rootTwo} md="6">
                <CardHeader role="tab" id="toggler">
                  <a href="#pablo">
                    <CardTitle>
                      Nurse Practioner Details
                      <i className="now-ui-icons arrows-1_minimal-down" />
                    </CardTitle>
                  </a>
                </CardHeader>

                <UncontrolledCollapse toggler="#toggler">
                  <div className="picture">
                    <img
                      alt="..."
                      className="picture-src"
                      src={Booking?.nurse?.profile_image}
                    />{" "}
                  </div>

                  <CardBody>
                    <Col>
                      <FormGroup>
                        <span>Name of Nurse </span>
                        <p className="description">
                          {Booking?.nurse?.firstName +
                            " " +
                            Booking?.nurse?.lastName}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <span>Nurse Contact Number: </span>
                        <p className="description">{Booking?.nurse?.mobile}</p>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <span>Email of Nurse : </span>
                        <p className="description">{Booking?.nurse?.email}</p>
                      </FormGroup>
                    </Col>
                  </CardBody>
                </UncontrolledCollapse>
              </Card>
            ) : null}
            {Booking.status !== "NEW" && !Booking.nurse ? (
              <Card className={classes.rootTwo} md="6">
                <CardHeader role="tab" id="togglerTwo">
                  <a href="#pablo">
                    <CardTitle>
                      Nurse Practioner Details
                      <i className="now-ui-icons arrows-1_minimal-down" />
                    </CardTitle>
                  </a>
                </CardHeader>
                <UncontrolledCollapse toggler="#togglerTwo">
                  <CardBody>
                    <FormGroup>
                      <span>No Nurse Practioner Assigned </span>
                    </FormGroup>
                  </CardBody>
                </UncontrolledCollapse>
              </Card>
            ) : null}
            {Booking.status !== "NEW" && Booking.doctor ? (
              // <Row>
              //   <Col sm="6"></Col>
              // <Col sm="6">
              <Card className={classes.rootTwo} md="6">
                <CardHeader role="tab" id="togglerTwo">
                  <a href="#pablo">
                    <CardTitle>
                      Doctor Practioner Details
                      <i className="now-ui-icons arrows-1_minimal-down" />
                    </CardTitle>
                  </a>
                </CardHeader>
                <UncontrolledCollapse toggler="#togglerTwo">
                  <div className="picture">
                    <img
                      alt="..."
                      className="picture-src"
                      src={Booking?.doctor?.profile_image}
                    />{" "}
                  </div>
                  <CardBody>
                    <FormGroup>
                      <span>Name of Doctor </span>
                      <p className="description">
                        {Booking?.doctor?.firstName +
                          " " +
                          Booking?.doctor?.lastName}
                      </p>
                    </FormGroup>
                    <FormGroup>
                      <span>Doctor Contact Number: </span>
                      <p className="description">{Booking?.doctor?.mobile}</p>
                    </FormGroup>
                    <FormGroup>
                      <span>Email of Doctor: </span>
                      <p className="description">{Booking?.doctor?.email}</p>
                    </FormGroup>
                  </CardBody>
                </UncontrolledCollapse>
              </Card>
            ) : null}
            {Booking.status !== "NEW" && !Booking.doctor ? (
              <Card className={classes.rootTwo} md="6">
                <CardHeader role="tab" id="togglerTwo">
                  <a href="#pablo">
                    <CardTitle>
                      Doctor Practioner Details
                      <i className="now-ui-icons arrows-1_minimal-down" />
                    </CardTitle>
                  </a>
                </CardHeader>
                <UncontrolledCollapse toggler="#togglerTwo">
                  <CardBody>
                    <FormGroup>
                      <span>No Doctor Practioner Assigned </span>
                    </FormGroup>
                  </CardBody>
                </UncontrolledCollapse>
              </Card>
            ) : null}
            {Booking.status !== "NEW" ? (
              <Card className={classes.rootTwo} md="6">
                <CardHeader role="tab" id="togglerThree">
                  <a href="#pablo">
                    <CardTitle>
                      INVOICE
                      <i className="now-ui-icons arrows-1_minimal-down" />
                    </CardTitle>
                  </a>
                </CardHeader>
                <UncontrolledCollapse toggler="#togglerThree">
                  <CardBody>
                    <FormGroup>
                      <Button
                        color="primary"
                        onClick={() => {
                          // dispatch(fetchChatMessages(Booking.id));
                          navigate({
                            pathname: "/patientinvoice",
                            state: { Booking },
                          });

                          setOpen(true);
                        }}
                      >
                        Invoice
                      </Button>
                    </FormGroup>
                  </CardBody>
                </UncontrolledCollapse>
              </Card>
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  };
  const renderButtons = () => {
    return (
      <Col md="12">
        <Row>
          {Booking.status === "ACCEPTED" ? (
            <Col md="2">
              <Button
                onClick={() => {
                  dispatch(fetchChatMessages(Booking.id));
                  navigate({
                    pathname: "/chats",
                    state: { Booking },
                  });

                  setOpen(true);
                }}
                color="primary"
              >
                Chat
              </Button>
            </Col>
          ) : null}
          {Booking.status === "ACCEPTED" &&
          Booking.serviceType === "Video Conference" ? (
            <Col md="2">
              <Button
                onClick={() => {
                  navigate({
                    pathname: "/videoconference",
                    state: { Booking },
                  });
                }}
                color="primary"
              >
                Video Conference
              </Button>
            </Col>
          ) : null}
        </Row>
      </Col>
    );
  };
  return (
    <Card>
      <CardBody>
        <CardHeader>
          <CardTitle tag="h4">Booking Details</CardTitle>
        </CardHeader>
        {/* {renderAlertDialog()} */}
        {renderBookingData()}
      </CardBody>
      <CardFooter>{renderButtons()}</CardFooter>
    </Card>
  );
};

export default PatientBookingDetails;
