/*eslint-disable*/
import React, { useState, useEffect, Component, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import VideoConference from "../VideoConference";
import { FirebaseContext } from "common";
import { makeStyles } from "@material-ui/core/styles";
import "react-chat-elements/dist/main.css";
// import "react-sortable-tree/style.css";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { language } from "config";
import {
  Select,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
// import { MessageList, MessageBox, ChatList, Avatar } from "react-chat-elements";
import CardFooter from "components/Card/CardFooter";
import AudiologyForm from "./Forms/AudiologyForm";
import DentalForm from "./Forms/DentalForm";
import OPTForm from "./Forms/OPTForm";
import PrimaryHealthCare from "./Forms/PrimaryHealthCare";
import AncAssesment from "./Forms/AncAssesment";
import AncAssesmentB from "./Forms/AncAssesmentB";
import AncAssesmentC from "./Forms/AncAssesmentC";
import ProgressReport from "./Forms/ProgressReport";
import ProofRecord from "./Forms/ProofRecord";
import Returns from "./Forms/Returns";
import medicForm from "../../assets/medic-icons/icon-form-general@2x.png";
import refIcon from "../../assets/medic-icons/icon-places-hospital@2x.png";
import medIcon from "../../assets/medic-icons/icon-healthcare-generic@2x.png";
import { useNavigate, useLocation } from "react-router-dom";
// import Chat from "../Chat";
import UsersCombo from "components/UsersCombo";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CustomInput from "components/CustomInput/CustomInput.js";
import MaterialTable from "material-table";
import Notes from "views/Consultations/Notes";
import DeviceExposure from "views/Consultations/DeviceExposure";
import DrugExposure from "views/Consultations/DrugExposure";
import Observations from "views/Consultations/Observations";
import Procedures from "views/Consultations/Procedures";
const AppointmentScreen = (props) => {
  const medical = useSelector(
    (state) => state.medicalRecords.medicalHistoryRecords
  );
  // const docNotesRef = useRef();
  const auth = useSelector((state) => state.auth);
  const { api } = useContext(FirebaseContext);
  const {
    GetDateString,
    fetchMedicalHistory,
    addDoctorsNotes,
    updateBooking,
    fetchChatMessages,
    deleteMeetingRoom,
    addBooking,
    addRefBooking,
  } = api;
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const Booking = location.state.Booking;
  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const settings = useSelector((state) => state.settingsdata.settings);
  const userdata = useSelector((state) => state.usersdata);
  const [users, setUsers] = useState(null);
  const [openFPModal, setOpenFPModal] = React.useState(false);
  const [openMedModal, setOpenMedModal] = React.useState(false);
  const [openPpModal, setOpenPpModal] = React.useState(false);
  const [openPModal, setOpenPModal] = React.useState(false);
  const [openCModal, setOpenCModal] = React.useState(false);
  const usersdata = useSelector((state) => state.doctors);
  // const bookingdata = useSelector((state) => state.bookingdata);
  const referraldoctors = useSelector(
    (state) => state.referraldoctors.referraldrs
  );
  const [updatebooking, setupdatebooking] = useState(Booking);
  const [DoctorsRef, setDoctorsRef] = useState([]);
  const [userCombo1, setUserCombo1] = useState(null);
  const [refSpec, setrefSpec] = useState(false);
  const navigate = useNavigate();
  const [acceptbutton, setAcceptbutton] = useState(false);
  const [commonAlert, setCommonAlert] = useState({
    open: false,
    msg: "",
    title: "",
  });
  /////////////////////////////////
  const [referralreason, setreferralreason] = useState();
  const [certificate, setCertificate] = useState({});
  const [perscriptions, setPerscriptions] = useState({});
  // const getHtml = (value) =>
  // draftToHtml(convertToRaw(value.getCurrentContent()));
  const specialities = useSelector(
    (state) => state.specialitytypes.specialities
  );
  const [specialityTypes, setSpecialityTypes] = useState([]);

  useEffect(() => {
    if (specialities) {
      let arr = [];
      for (let i = 0; i < Object.values(specialities).length; i++) {
        arr.push({
          value: Object.values(specialities)[i],
          label: Object.values(specialities)[i],
        });
      }
      setSpecialityTypes(arr);
      console.log("here", specialityTypes);
    }
  }, []);
  const enterChange = (e) => {
    let arr = [];
    for (let i = 0; i < e.blocks.length; i++) {
      arr = e.blocks;
    }
    setupdatebooking({
      ...updatebooking,
      certificate: certificate,
    });
    setCertificate(arr);
  };
  const onSave = () => {
    dispatch(updateBooking(updatebooking));
  };
  const enterChangeP = (e) => {
    let arr = [];
    for (let i = 0; i < e.blocks.length; i++) {
      arr = e.blocks;
    }
    setupdatebooking({
      ...updatebooking,
      persciption: perscriptions,
    });
    setPerscriptions(arr);
  };
  const onSaveP = () => {
    dispatch(updateBooking(updatebooking));
  };
  useEffect(() => {
    console.log("i am the referral doctors: ", referraldoctors);

    let arr = [];
    for (let i = 0; i < referraldoctors.length; i++) {
      let user = referraldoctors[i];
      if (
        auth.info.profile.userType === "doctor" &&
        auth.info.profile.practiceNumber === user.practiceNumber
      ) {
        arr.push({
          firstName: user.firstName,
          lastName: user.lastName,
          mobile: user.mobile,
          email: user.email,
          uid: user.id,
          practiceNumber: user.practiceNumber,
          practiceName: user.practiceName,
          referralId: user.referralId,
          refferalPracticeNumber: user.refferalPracticeNumber,
          specialityType: user.specialityTypes,
          desc:
            user.firstName +
            " " +
            user.lastName +
            " (" +
            user.specialityTypes +
            ") ",
          // pushToken: user.pushToken,
        });
        setDoctorsRef(arr);
      } else {
        if (referraldoctors !== null) {
          setDoctorsRef(referraldoctors);
        }
      }
    }
  }, [referraldoctors]);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  useEffect(() => {
    dispatch(fetchChatMessages(Booking.id));
    if (userdata.users) {
      let arr = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        arr.push({
          firstName: user.firstName,
          lastName: user.lastName,
          mobile: user.mobile,
          email: user.email,
          uid: user.id,

          desc:
            user.firstName +
            " " +
            user.lastName +
            " (" +
            user.mobile +
            ") " +
            user.email,
          pushToken: user.pushToken,
        });
      }

      setUsers(arr);
      console.log("this is the users: ", arr);
    }
  }, [userdata.users]);

  useEffect(() => {
    if (userdata.users) {
      let arr = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        arr.push({});
      }

      setUsers(arr);
    }
  }, [userdata.users]);

  const handleCloseEvent = () => {
    setOpenFPModal(false);
  };
  const handleOpenEvent = () => {
    setOpenFPModal(true);
  };
  const handleCloseMed = () => {
    setOpenMedModal(false);
  };
  const handleMedOpen = () => {
    setOpenMedModal(true);
  };
  const handlePpClose = () => {
    setOpenPpModal(false);
  };
  const handlePpOpen = () => {
    setOpenPpModal(true);
  };
  const handlePClose = () => {
    setOpenPModal(false);
  };
  const handlePOpen = () => {
    // navigate({
    //   pathname: "/wysiwig",
    //   state: { Booking },
    // });

    setOpenPModal(true);
  };
  const handleCClose = () => {
    setOpenCModal(false);
  };
  const handleCOpen = () => {
    setOpenCModal(true);
  };
  const Accept = () => {
    if (auth.info.profile.userType === "practiceadmin")
      if (updatebooking.status === "ACCEPTED") {
        setCommonAlert({
          open: true,
          msg: "Referral send",
          title: "Thank you",
        });
      } else {
        navigate("/bookingrequest");
      }

    dispatch(updateBooking(updatebooking));
    handleCloseEvent();
  };
  const endConsultation = () => {
    if (Booking.status === "ACCEPTED") {
      setupdatebooking({ ...updatebooking, status: "COMPLETE" });

      console.log("this is the update", updatebooking);
      navigate("/bookings", { state: { Booking } });
      dispatch(updateBooking(updatebooking));
    } else {
      console.log("this did not work");
    }
  };
  const [state, setstate] = useState({
    refferalDoctor: false,
    refferalType: false,
    // this is the format required for the api
    refferalDate: new Date().toISOString().substring(0, 19),
    refferalNote: "",
  });
  const makeRefferal = () => {
    let refferal = {
      ...updatebooking,
      refferalStatus: "NEW",
      refferal: {
        refferalDoctor: state.refferalDoctor,
        refferalType: state.refferalType,
        refferalDate: state.refferalDate,
        refferalNote: state.refferalNote,
      },
    };
    let newBookingRef = {
      ...updatebooking,
      status: "NEW",
      refferal: {
        refferalDoctor: state.refferalDoctor,
        refferalType: state.refferalType,
        refferalDate: state.refferalDate,
        refferalNote: state.refferalNote,
      },
      settings: settings,
    };
    dispatch(updateBooking(refferal));
    if (newBookingRef) {
      dispatch(addRefBooking(newBookingRef));
    }
    // dispatch(addBooking(refferal));
    setOpenFPModal(false);
    // console.log(refferal);
  };

  return (
    <>
      <Row>
        <Col md="10">
          <Row>
            <Col md="12">
              <Card border="danger" style={{ marginLeft: 15 }}>
                <CardBody>
                  <FormGroup>
                    <span>Name </span>
                    <p className="description">{Booking.patientName}</p>
                  </FormGroup>

                  <FormGroup>
                    <span>Booking ID: </span>
                    <p className="description">{Booking.id}</p>
                  </FormGroup>

                  <FormGroup>
                    <span>Booking Name: </span>
                    <p className="description">{Booking.bookingName}</p>
                  </FormGroup>

                  <FormGroup>
                    <span>Booking DateTime : </span>
                    <p className="description">{Booking.eventDate}</p>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Row>
                <Col md="3">
                  <Card border="danger" style={{ height: 60 }}>
                    <CardHeader >
                      <CardTitle >Name</CardTitle>
                    </CardHeader>
                  </Card>
                </Col>
                <Col md="3">
                  <Card border="danger" style={{ height: 60 }}>
                    <CardHeader >
                      <CardTitle >Booking Id</CardTitle>
                    </CardHeader>
                  </Card>
                </Col>
                <Col md="3">
                  <Card border="danger" style={{ height: 60 }}>
                    <CardHeader >
                      <CardTitle >DateTime</CardTitle>
                    </CardHeader>
                  </Card>
                </Col>
                <Col md="3">
                  <Card border="danger" style={{ height: 60 }}>
                    <CardHeader >
                      <CardTitle >Booking Name</CardTitle>
                    </CardHeader>
                  </Card>
                </Col>
              </Row> */}
          {/* <Row>
                <Col md="12">
                  <Card border="danger" style={{ height: 60 }}>
                    <CardHeader >
                      <CardTitle >Booking Description</CardTitle>
                    </CardHeader>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Card border="danger" style={{ height: 60 }}>
                    <CardHeader >
                      <CardTitle >Clinic</CardTitle>
                    </CardHeader>
                  </Card>
                </Col>
                <Col md="6">
                  <Card border="danger" style={{ height: 60 }}>
                    <CardHeader >
                      <CardTitle >Treatment Type</CardTitle>
                    </CardHeader>
                  </Card>
                </Col>
              </Row> */}
          <Row>
            <Col md="12">
              <Card border="danger" style={{ marginLeft: 15 }}>
                {/* <CardHeader>
                  <CardTitle>Observation Result</CardTitle>
                </CardHeader> */}

                <Observations Booking={Booking} />
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col md="12">
              <Card border="danger" style={{ marginLeft: 15 }}>
                {/* <CardHeader>
                  <CardTitle>Measurements</CardTitle>
                </CardHeader> 

                <DeviceExposure Booking={Booking} />
              </Card>
            </Col>
          </Row> */}
          {/* <Row>
            <Col md="12">
              <Card border="danger" >
                <CardHeader>
                  <CardTitle>Scans & Test</CardTitle>
                </CardHeader>
                <CardBody></CardBody>
              </Card>
            </Col>
          </Row> */}
          <Row>
            <Col md="12">
              <Card border="danger" style={{ marginLeft: 15 }}>
                <DrugExposure Booking={Booking} />
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col md="12">
              <Card border="danger" >
                <CardHeader >
                  <CardTitle >Prescriptions</CardTitle>
                </CardHeader>
                <CardBody></CardBody>
              </Card>
            </Col>
          </Row> */}
          {/* <Row>
            <Col md="12">
              <Card border="danger" style={{ marginLeft: 15 }}>
                {/* <CardHeader>
                  <CardTitle>Procedures</CardTitle>
                </CardHeader> 

                <Procedures Booking={Booking} />
              </Card>
            </Col>
          </Row> */}
          <Row>
            <Col md="12">
              <Card border="danger" style={{ marginLeft: 15 }}>
                <Notes Booking={Booking} />
              </Card>
            </Col>
          </Row>
        </Col>
        {/* <Col xs="10">
          {auth.info.profile.specialityType === "AUDIOLOGY" ? (
            <AudiologyForm />
          ) : null}
          {Booking.serviceType === "Antenatal Care Assessment A" ? (
            <AncAssesment />
          ) : null}
          {Booking.serviceType === "Antenatal Care Assessment B" ? (
            <AncAssesmentB />
          ) : null}
          {Booking.serviceType === "Antenatal Care Assessment C" ? (
            <AncAssesmentC />
          ) : null}

          {auth.info.profile.specialityType === "DENTIST" ? (
            <DentalForm />
          ) : null}
          {auth.info.profile.specialityType === "OPTOMETRIST" ? (
            <OPTForm />
          ) : null}
          {auth.info.profile.specialityType === "PUBLIC_HEALTH_MEDICINE" ? (
            <PrimaryHealthCare />
          ) : null}
          {auth.info.profile.specialityType !== "AUDIOLOGY" &&
          auth.info.profile.specialityType !== "DENTIST" &&
          Booking.serviceType !== "Antenatal Care Assessment A" &&
          Booking.serviceType !== "Antenatal Care Assessment B" &&
          Booking.serviceType !== "Antenatal Care Assessment C" &&
          auth.info.profile.specialityType !== "OPTOMETRIST" &&
          auth.info.profile.specialityType !== "PUBLIC_HEALTH_MEDICINE" ? (
            <iframe
              allow="camera; microphone; fullscreen; display-capture; autoplay"
              src={Booking.videoconferencelink}
              height="760px"
              width="100%"
              border="0px"
            ></iframe>
          ) : null}
        </Col> */}
        <Col xs="2" style={{ padding: 15 }}>
          <Row>
            <Col>
              <Button
                block="true"
                size="sm"
                color="primary"
                onClick={handlePpOpen}
              >
                {"Patient Details"}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                block="true"
                size="sm"
                color="primary"
                onClick={handleMedOpen}
              >
                {"Medical History"}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                block="true"
                size="sm"
                color="primary"
                onClick={handleOpenEvent}
              >
                {"Refferal"}
              </Button>
            </Col>
          </Row>

          {/* <Row>
            <Col>
              <Button
                block="true"
                size="sm"
                color="primary"
                onClick={handlePOpen}
              >
                {"Prescription"}
              </Button>
            </Col>
          </Row> */}
          <Row>
            <Col>
              <Button
                block="true"
                size="sm"
                color="primary"
                onClick={handleCOpen}
              >
                {"Certificate"}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                block="true"
                size="sm"
                color="primary"
                onClick={() => {
                  openInNewTab(Booking?.videoconferencelink);
                }}
              >
                {"Video Consultation"}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                block="true"
                size="sm"
                color="info"
                onClick={endConsultation}
              >
                {"End Consultation"}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Dialog
        open={openCModal}
        onClose={handleCClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {"Write a Certificate"}
        </DialogTitle>
        <DialogContent>
          <Editor theme="snow" value={certificate} onChange={enterChange} />
          <button onClick={onSave}>SAVE</button>
        </DialogContent>
        <DialogActions>
          <Button fullWidth color="primary" onClick={handleCClose}>
            {"Close"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openPpModal}
        onClose={handlePpClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{"Patient Details"}</DialogTitle>
        <DialogContent>
          <FormGroup>
            <span>Name of Patient </span>
            <p className="description">{Booking.patientName}</p>
          </FormGroup>

          <FormGroup>
            <span>Patient Contant Number: </span>
            <p className="description">{Booking.patientContact}</p>
          </FormGroup>

          <FormGroup>
            <span>Email of Patient : </span>
            <p className="description">{Booking.patientEmail}</p>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button fullWidth color="primary" onClick={handlePpClose}>
            {"Close"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openPModal}
        onClose={handlePClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{"Perscription"}</DialogTitle>
        <DialogContent>
          <Editor theme="snow" value={perscriptions} onChange={enterChangeP} />
          <button onClick={onSaveP}>SAVE</button>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => window.print(this)}>
            Print
          </Button>
          <Button fullWidth color="primary" onClick={handlePClose}>
            {"Close"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openMedModal}
        onClose={handleCloseMed}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {"Patient Medical History"}
        </DialogTitle>
        <DialogContent>
          <Row>
            <Col>
              <FormGroup>
                <span>Basic Information: </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label>Date of Birth:</label>
                {Booking?.Medical_Information?.Basic_info?.DOB && (
                  <p className="description">
                    {Booking?.Medical_Information?.Basic_info?.DOB}
                  </p>
                )}
                {!Booking?.Medical_Information?.Basic_info?.DOB && (
                  <p className="description">{"N/A"}</p>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label>Sex:</label>
                {Booking?.Medical_Information?.Basic_info?.Sex && (
                  <p className="description">
                    {Booking?.Medical_Information?.Basic_info?.Sex}
                  </p>
                )}
                {!Booking?.Medical_Information?.Basic_info?.Sex && (
                  <p className="description">{"N/A"}</p>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label>Blood Type:</label>
                {Booking?.Medical_Information?.Basic_info?.BloodType && (
                  <p className="description">
                    {Booking?.Medical_Information?.Basic_info?.BloodType}
                  </p>
                )}
                {!Booking?.Medical_Information?.Basic_info?.BloodType && (
                  <p className="description">{"N/A"}</p>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label>Height:</label>
                {Booking?.Medical_Information?.Basic_info?.Height && (
                  <p className="description">
                    {Booking?.Medical_Information?.Basic_info?.Height}
                  </p>
                )}
                {!Booking?.Medical_Information?.Basic_info?.Height && (
                  <p className="description">{"N/A"}</p>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label>Weight:</label>
                {Booking?.Medical_Information?.Basic_info?.Weight && (
                  <p className="description">
                    {Booking?.Medical_Information?.Basic_info?.Weight}
                  </p>
                )}
                {!Booking?.Medical_Information?.Basic_info?.Weight && (
                  <p className="description">{"N/A"}</p>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label>Body Mass Index(BMI):</label>
                {Booking?.Medical_Information?.Basic_info?.BMI && (
                  <p className="description">
                    {Booking?.Medical_Information?.Basic_info?.BMI}
                  </p>
                )}
                {!Booking?.Medical_Information?.Basic_info?.BMI && (
                  <p className="description">{"N/A"}</p>
                )}
              </FormGroup>
            </Col>
          </Row>
          {/* ////CHILDHOOD ILLNESSES */}
          <Row>
            <FormGroup>
              <span>Childhood Illness: </span>
            </FormGroup>
            {Booking?.Medical_Information?.Childhood_illness?.Measles && (
              <Col md="4">
                <FormGroup>
                  <p className="description">{"Measles"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Childhood_illness?.Chickenpox && (
              <Col>
                <FormGroup>
                  <p className="description">{"Chickenpox"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Childhood_illness?.Mumps && (
              <Col md="4">
                <FormGroup>
                  <p className="description">{"Mumps"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Childhood_illness?.Polio && (
              <Col>
                <FormGroup>
                  <p className="description">{"Polio"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Childhood_illness?.Rheumatic && (
              <Col md="4">
                <FormGroup>
                  <p className="description">{"Rheumatic"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Childhood_illness?.Rubellac && (
              <Col>
                <FormGroup>
                  <p className="description">{"Rubellac"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          {/* IMMUNAZATION */}
          <Row>
            <Col>
              <FormGroup>
                <span>Immunization: </span>
              </FormGroup>
              <Row>
                <Col md="6">
                  <h5 className="card-category">Name</h5>
                  <FormGroup>
                    {Booking?.Medical_Information?.Immunizations_an_Dates
                      ?.Tetanus?.checked && (
                      <p className="description">{"Tetanus"}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <h5 className="card-category">Date</h5>
                  <FormGroup>
                    {Booking?.Medical_Information?.Immunizations_an_Dates
                      ?.Tetanus?.Date && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Immunizations_an_Dates
                            ?.Tetanus?.Date
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                {Booking?.Medical_Information?.Immunizations_an_Dates
                  ?.Hepatitis_A?.checked && (
                  <Col md="6">
                    <FormGroup>
                      <p className="description">{"Hepatitis A"}</p>
                    </FormGroup>
                  </Col>
                )}

                {Booking?.Medical_Information?.Immunizations_an_Dates
                  ?.Hepatitis_A?.Date && (
                  <Col>
                    <FormGroup>
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Immunizations_an_Dates
                            ?.Hepatitis_A?.Date
                        }
                      </p>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row>
                {Booking?.Medical_Information?.Immunizations_an_Dates
                  ?.Chickenpox2?.checked && (
                  <Col md="6">
                    <FormGroup>
                      <p className="description">{"Chickenpox"}</p>
                    </FormGroup>
                  </Col>
                )}

                {Booking?.Medical_Information?.Immunizations_an_Dates
                  ?.Chickenpox2?.Date && (
                  <Col>
                    <FormGroup>
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Immunizations_an_Dates
                            ?.Chickenpox2?.Date
                        }
                      </p>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row>
                {Booking?.Medical_Information?.Immunizations_an_Dates
                  ?.Hepatitis_B?.checked && (
                  <Col md="6">
                    <FormGroup>
                      <p className="description">{"Hepatitis B"}</p>
                    </FormGroup>
                  </Col>
                )}

                {Booking?.Medical_Information?.Immunizations_an_Dates
                  ?.Hepatitis_B?.Date && (
                  <Col>
                    <FormGroup>
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Immunizations_an_Dates
                            ?.Hepatitis_B?.Date
                        }
                      </p>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row>
                {Booking?.Medical_Information?.Immunizations_an_Dates?.MMR
                  ?.checked && (
                  <Col md="6">
                    <FormGroup>
                      <p className="description">{"MMR"}</p>
                    </FormGroup>
                  </Col>
                )}

                {Booking?.Medical_Information?.Immunizations_an_Dates?.MMR
                  ?.Date && (
                  <Col>
                    <FormGroup>
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Immunizations_an_Dates
                            ?.MMR?.Date
                        }
                      </p>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row>
                {Booking?.Medical_Information?.Immunizations_an_Dates
                  ?.Meningococcal?.checked && (
                  <Col md="6">
                    <FormGroup>
                      <p className="description">{"Meningococcal"}</p>
                    </FormGroup>
                  </Col>
                )}

                {Booking?.Medical_Information?.Immunizations_an_Dates
                  ?.Meningococcal?.Date && (
                  <Col>
                    <FormGroup>
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Immunizations_an_Dates
                            ?.Meningococcal?.Date
                        }
                      </p>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row>
                {Booking?.Medical_Information?.Immunizations_an_Dates?.Pneumonia
                  ?.checked && (
                  <Col md="6">
                    <FormGroup>
                      <p className="description">{"Pneumonia"}</p>
                    </FormGroup>
                  </Col>
                )}

                {Booking?.Medical_Information?.Immunizations_an_Dates?.Pneumonia
                  ?.Date && (
                  <Col>
                    <FormGroup>
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Immunizations_an_Dates
                            ?.Pneumonia?.Date
                        }
                      </p>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row>
                {Booking?.Medical_Information?.Immunizations_an_Dates?.influenza
                  ?.checked && (
                  <Col md="6">
                    <FormGroup>
                      <p className="description">{"influenza"}</p>
                    </FormGroup>
                  </Col>
                )}

                {Booking?.Medical_Information?.Immunizations_an_Dates?.influenza
                  ?.Date && (
                  <Col>
                    <FormGroup>
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Immunizations_an_Dates
                            ?.influenza?.Date
                        }
                      </p>
                    </FormGroup>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          {/* SURGERIES */}
          <Row>
            <Col>
              <FormGroup>
                <span>Surgeries: </span>
              </FormGroup>
              <Row>
                <Col md="6">
                  <h5 className="card-category">Reason</h5>
                  <FormGroup>
                    {Booking?.Medical_Information?.Surgries?.Surgry1st
                      ?.ReasonOfSurgry && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Surgries?.Surgry1st
                            ?.ReasonOfSurgry
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <h5 className="card-category">Date</h5>
                  <FormGroup>
                    {Booking?.Medical_Information?.Surgries?.Surgry1st
                      ?.YearOfSurgry && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Surgries?.Surgry1st
                            ?.YearOfSurgry
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <h5 className="card-category">Hospital</h5>
                  <FormGroup>
                    {Booking?.Medical_Information?.Surgries?.Surgry1st
                      ?.HospitalOfSurgry && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Surgries?.Surgry1st
                            ?.HospitalOfSurgry
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    {Booking?.Medical_Information?.Surgries?.Surgry2nd
                      ?.ReasonOfSurgry && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Surgries?.Surgry2nd
                            ?.ReasonOfSurgry
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    {Booking?.Medical_Information?.Surgries?.Surgry2nd
                      ?.YearOfSurgry && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Surgries?.Surgry2nd
                            ?.YearOfSurgry
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    {Booking?.Medical_Information?.Surgries?.Surgry2nd
                      ?.HospitalOfSurgry && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Surgries?.Surgry2nd
                            ?.HospitalOfSurgry
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    {Booking?.Medical_Information?.Surgries?.Surgry3rd
                      ?.ReasonOfSurgry && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Surgries?.Surgry3rd
                            ?.ReasonOfSurgry
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    {Booking?.Medical_Information?.Surgries?.Surgry3rd
                      ?.YearOfSurgry && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Surgries?.Surgry3rd
                            ?.YearOfSurgry
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    {Booking?.Medical_Information?.Surgries?.Surgry3rd
                      ?.HospitalOfSurgry && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Surgries?.Surgry3rd
                            ?.HospitalOfSurgry
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <FormGroup>
                  <span>Social History: </span>
                </FormGroup>
                <Row>
                  <Col md="3">
                    <label>Occupation :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.occupation && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.occupation
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                  <Col md="3">
                    <label>Maritial Status :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.maritalstatus && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.maritalstatus
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                  <Col md="3">
                    <label>Exercise :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.exercise && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.exercise
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                  <Col md="3">
                    <label>Sexual Orientation :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.sexualorientation && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.sexualorientation
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <label>Stress Level :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.stressfactor && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.stressfactor
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                  <Col md="3">
                    <label>Home Language :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.language && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.language
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                  <Col md="3">
                    <label>Dependents :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.children && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.children
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                  <Col md="3">
                    <label>Diet :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.diet && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.diet
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <label>Alcohol Consumption :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.alcoholconsumption && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              .SocialHistory?.alcoholconsumption
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                  <Col md="3">
                    <label>Smoking :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.smoking && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.smoking
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                  <Col md="3">
                    <label>Substance Abuse :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.substanceabuse && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.substanceabuse
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
          <FormGroup>
            <FormGroup color="default" tag="span">
              Your Current Medical History
            </FormGroup>
          </FormGroup>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Anemia && (
              <Col md="4">
                <FormGroup>
                  <p className="description">{"Anemia"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Blood_Colts && (
              <Col>
                <FormGroup>
                  <p className="description">{"Blood Colts"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Alcohol_Abuse && (
              <Col>
                <FormGroup>
                  <p className="description">{"Alcohol Abuse"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Heart_Disease && (
              <Col md="4">
                <FormGroup>
                  <p className="description">{"Heart Disease"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Anesthetic_Complication && (
              <Col>
                <FormGroup>
                  <p className="description">{"Anesthetic Complication"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Anxiety_Disorder && (
              <Col>
                <FormGroup>
                  <p className="description">{"Anxiety Disorder"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Arthritis && (
              <Col>
                <FormGroup>
                  <p className="description">{"Arthritis"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Asthma && (
              <Col>
                <FormGroup>
                  <p className="description">{"Asthma"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>

          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Birth_Defects && (
              <Col>
                <FormGroup>
                  <p className="description">{"Birth Defects"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Bladder_Problems && (
              <Col>
                <FormGroup>
                  <p className="description">{"Bladder Problems"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Bleeding_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Bleeding Disease"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Blood_Clots && (
              <Col>
                <FormGroup>
                  <p className="description">{"Blood Clots"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Blood_Transfusions && (
              <Col>
                <FormGroup>
                  <p className="description">{"Blood Transfusions"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Bowel_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Bowel_Disease"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Breast_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Breast Cancer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Cervical_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Cervical Cancer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Colon_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Colon Cancer"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Depression && (
              <Col>
                <FormGroup>
                  <p className="description">{"Depression"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Diabetes && (
              <Col>
                <FormGroup>
                  <p className="description">{"Diabetes"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.GrowthDevelopment_Disorder && (
              <Col>
                <FormGroup>
                  <p className="description">{"GrowthDevelopment Disorder"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.HIV && (
              <Col>
                <FormGroup>
                  <p className="description">{"HIV"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Hearing_Impairment && (
              <Col>
                <FormGroup>
                  <p className="description">{"Hearing Impairment"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Heart_Attack && (
              <Col>
                <FormGroup>
                  <p className="description">{"Heart Attack"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Heart_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Heart Disease"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Heart_Pain_Angina && (
              <Col>
                <FormGroup>
                  <p className="description">{"Heart Pain Angina"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Hepatitis_A && (
              <Col>
                <FormGroup>
                  <p className="description">{"Hepatitis A"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Hepatitis_B && (
              <Col>
                <FormGroup>
                  <p className="description">{"Hepatitis B"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Hepatitis_C && (
              <Col>
                <FormGroup>
                  <p className="description">{"Hepatitis C"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.High_Blood_Pressure && (
              <Col>
                <FormGroup>
                  <p className="description">{"High Blood Pressure"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>

          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.High_Cholesterol && (
              <Col>
                <FormGroup>
                  <p className="description">{"High Cholesterol"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Hives && (
              <Col>
                <FormGroup>
                  <p className="description">{"Hives"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Kidney_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Kidney Disease"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Liver_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Liver Cancer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Liver_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Liver Disease"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Lung_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Lung Cancer"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Liver_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Liver Disease"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Lung_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Lung Cancer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Lung_Respiratory_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Lung Respiratory Disease"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>

          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Mental_Illness && (
              <Col>
                <FormGroup>
                  <p className="description">{"Mental Illness"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Migraines && (
              <Col>
                <FormGroup>
                  <p className="description">{"Migraines"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Osteoporosis && (
              <Col>
                <FormGroup>
                  <p className="description">{"Osteoporosis"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>

          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Other_Disease_Cancer_or_Significant_Medical_Illness && (
              <Col>
                <FormGroup>
                  <p className="description">{"Other Disease Cancers"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Other_Disease_Cancer_or_Significant_Medical_Illness && (
              <Col>
                <FormGroup>
                  <p className="description">{"Other Signficant Illness"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Prostate_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Prostate Cancer"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>

          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Rectal_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Rectal Cancer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Reflux_GERD && (
              <Col>
                <FormGroup>
                  <p className="description">{"Reflux Gerd"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Severe_Allergy && (
              <Col>
                <FormGroup>
                  <p className="description">{"Severe Allergy"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Sexually_Transmitted_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"STD"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Skin_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Skin Cancer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Stroke_CVA_of_the_Brain && (
              <Col>
                <FormGroup>
                  <p className="description">{"Stroke of the Brain"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Suicide_Attempt && (
              <Col>
                <FormGroup>
                  <p className="description">{"Suicide Attempt"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Thyroid_Problems && (
              <Col>
                <FormGroup>
                  <p className="description">{"Thyroid Problems"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Ulcer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Ulcer"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Visual_Impairment && (
              <Col>
                <FormGroup>
                  <p className="description">{"Visual Impairment"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            <FormGroup>
              <span>Medication: </span>
            </FormGroup>
            {Booking?.Medical_Information?.Medication?.Medication1?.Drug && (
              <Col md="6">
                <label>Medication Name</label>
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication1?.Drug
                    }
                  </p>
                </FormGroup>
              </Col>
            )}

            {Booking?.Medical_Information?.Medication?.Medication1?.Dose && (
              <Col>
                <label>Dose</label>
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication1?.Dose
                    }
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medication?.Medication2?.Drug && (
              <Col md="6">
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication2?.Drug
                    }
                  </p>
                </FormGroup>
              </Col>
            )}

            {Booking?.Medical_Information?.Medication?.Medication2?.Dose && (
              <Col>
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication2?.Dose
                    }
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medication?.Medication3?.Drug && (
              <Col md="6">
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication3?.Drug
                    }
                  </p>
                </FormGroup>
              </Col>
            )}

            {Booking?.Medical_Information?.Medication?.Medication3?.Dose && (
              <Col>
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication3?.Dose
                    }
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medication?.Medication4?.Drug && (
              <Col md="6">
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication4?.Drug
                    }
                  </p>
                </FormGroup>
              </Col>
            )}

            {Booking?.Medical_Information?.Medication?.Medication4?.Dose && (
              <Col>
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication4?.Dose
                    }
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medication?.Medication5?.Drug && (
              <Col md="6">
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication5?.Drug
                    }
                  </p>
                </FormGroup>
              </Col>
            )}

            {Booking?.Medical_Information?.Medication?.Medication5?.Dose && (
              <Col>
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication5?.Dose
                    }
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medication?.Medication6?.Drug && (
              <Col md="6">
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication6?.Drug
                    }
                  </p>
                </FormGroup>
              </Col>
            )}

            {Booking?.Medical_Information?.Medication?.Medication6?.Dose && (
              <Col>
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication6?.Dose
                    }
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            <FormGroup>
              <span>Family Medical History: </span>
            </FormGroup>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.medicalhistory?.Family_Medical_History.Fmedicalhistory
              ?.Adopted && (
              <Col>
                <FormGroup>
                  <p className="description">{"Adopted"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Alcohol_Abuse && (
              <Col>
                <FormGroup>
                  <p className="description">{" Alcohol Abuse"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Anemia && (
              <Col>
                <FormGroup>
                  <p className="description">{"Aneamia"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Anesthetic_Complication && (
              <Col>
                <FormGroup>
                  <p className="description">{"Anesthetic Complication"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Anxiety_Disorder && (
              <Col>
                <FormGroup>
                  <p className="description">{"Anxiety Disorder"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Arthritis && (
              <Col>
                <FormGroup>
                  <p className="description">{"Arthritis"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Asthma && (
              <Col>
                <FormGroup>
                  <p className="description">{"Asthma"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Autoimmune_Problems && (
              <Col>
                <FormGroup>
                  <p className="description">{"Autoimmune Problems"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Birth_Defects && (
              <Col>
                <FormGroup>
                  <p className="description">{"Birth Defects"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Bladder_Problems && (
              <Col>
                <FormGroup>
                  <p className="description">{"Bladder Problems"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Bleeding_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{" Bleeding Disease"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Blood_Clots && (
              <Col>
                <FormGroup>
                  <p className="description">{" Blood Clots"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Blood_Transfusions && (
              <Col>
                <FormGroup>
                  <p className="description">{"Blood Transfusions"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Bowel_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{" Bowel Disease"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Breast_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{" Breast Cancer"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Cervical_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Cervical Cancer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Colon_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Colon Cancer "}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Depression && (
              <Col>
                <FormGroup>
                  <p className="description">{"Depression"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Diabetes && (
              <Col>
                <FormGroup>
                  <p className="description">{"Diabetes"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory.F_GrowthDevelopment_Disorder && (
              <Col>
                <FormGroup>
                  <p className="description">{"Growth Development Disorder"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_HIV && (
              <Col>
                <FormGroup>
                  <label>Height</label>
                  <p className="description">{" HIV"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Hearing_Impairment && (
              <Col>
                <FormGroup>
                  <p className="description">{"Hearing Impairment"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Heart_Attack && (
              <Col>
                <FormGroup>
                  <p className="description">{" Heart Attack"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Heart_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Heart Disease"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Heart_Pain_Angina && (
              <Col>
                <FormGroup>
                  <p className="description">{" Heart Pain Angina "}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Hepatitis_A && (
              <Col>
                <FormGroup>
                  <p className="description">{"Hepatitis A"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Hepatitis_B && (
              <Col>
                <FormGroup>
                  <p className="description">{"Hepatitis B"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Hepatitis_C && (
              <Col>
                <FormGroup>
                  <p className="description">{"Hepatitis C"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_High_Blood_Pressure && (
              <Col>
                <FormGroup>
                  <p className="description">{"High Blood Pressure"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>

          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_High_Cholesterol && (
              <Col>
                <FormGroup>
                  <p className="description">{"High Cholesterol"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Hives && (
              <Col>
                <FormGroup>
                  <p className="description">{" Hives"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Kidney_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Kidney Disease "}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Liver_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Liver Cancer "}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Liver_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Liver Disease "}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Lung_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{" Lung Cancer"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory.F_Lung_Respiratory_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Lung Respiratory Disease"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Mental_Illness && (
              <Col>
                <FormGroup>
                  <p className="description">{"Mental Illness"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Migraines && (
              <Col>
                <FormGroup>
                  <p className="description">{"Migraines"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_NONE_of_the_Above && (
              <Col>
                <FormGroup>
                  <p className="description">{"NONE"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Osteoporosis && (
              <Col>
                <FormGroup>
                  <p className="description">{"Osteoporosis"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory
              ?.F_Other_Disease_Cancer_or_Significant_Medical_Illness && (
              <Col>
                <FormGroup>
                  <p className="description">
                    {"Other Disease Cancer or Significant Medical Illness"}
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Prostate_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Prostate Cancer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Rectal_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Rectal Cancer"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Reflux_GERD && (
              <Col>
                <FormGroup>
                  <p className="description">{"Reflux GERD"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Seizures_Convulsions && (
              <Col>
                <FormGroup>
                  <p className="description">{"Seizures Convulsions"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Severe_Allergy && (
              <Col>
                <FormGroup>
                  <p className="description">{"Severe Allergy"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Sexually_Transmitted_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">
                    {"Sexually Transmitted Disease"}
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Skin_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Skin Cancer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Stroke_CVA_of_the_Brain && (
              <Col>
                <FormGroup>
                  <p className="description">{"Stroke CVA of the Brain"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Suicide_Attempt && (
              <Col>
                <FormGroup>
                  <p className="description">{"Suicide Attempt"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Thyroid_Problems && (
              <Col>
                <FormGroup>
                  <p className="description">{"Thyroid Problems"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Ulcer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Ulcer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Visual_Impairment && (
              <Col>
                <FormGroup>
                  <p className="description">{"Visual Impairment"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.Family_History_unknow && (
              <Col>
                <FormGroup>
                  <p className="description">{"Family History unknown "}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.Female_Family && (
              <Col>
                <FormGroup>
                  <p className="description">
                    {
                      "Mother, Grandmother, or Sister developed heart disease before the age of 65"
                    }
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.Male_Family && (
              <Col>
                <FormGroup>
                  <p className="description">
                    {
                      "Father, Grandfather, or Brother developed heart disease before the age of 65"
                    }
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => window.print(this)}>
            {"Print"}
          </Button>
          <Button color="primary" onClick={handleCloseMed}>
            {"Close"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openFPModal}
        onClose={handleCloseEvent}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{"Make A Refferal"}</DialogTitle>

        <DialogContent>
          <Label>Patient's Details </Label>
        </DialogContent>

        <DialogContent>
          <Row>
            <Col>
              <Label>Patient: </Label>
            </Col>
            <Col>{Booking.patientName}</Col>
          </Row>
          <Row>
            <Col>
              <Label>Contact Number: </Label>
            </Col>
            <Col>{Booking.patientContact}</Col>
          </Row>
          <Row>
            <Col>
              <Label>Email: </Label>
            </Col>
            <Col>{Booking.patientEmail}</Col>
          </Row>
        </DialogContent>

        <hr />
        <DialogContent>
          <Label>Doctor's Details </Label>
        </DialogContent>
        <DialogContent>
          <Row>
            <Col>
              <Label>Doctor : </Label>
            </Col>
            <Col>
              {" "}
              {Booking.doctor.firstName} {Booking.doctor.lastName}
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>Contact Number: </Label>
            </Col>
            <Col>{Booking.doctor.mobile}</Col>
          </Row>
          <Row>
            <Col>
              <Label>Email: </Label>
            </Col>
            <Col>{Booking.doctor.email}</Col>
          </Row>
        </DialogContent>
        <hr />
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="service-type-native">Refferal Type</InputLabel>
            <Select
              id="practice-type-native"
              value={state.refferalType}
              onChange={(event, newValue) => {
                setstate({
                  ...state,
                  refferalType: event.target.value,
                });
                // setrefSpec(event);
                console.log("ref", event.target.value);
              }}
              variant="outlined"
              fullWidth
              className={
                specialityTypes === language.select_practice
                  ? classes.inputdimmed
                  : classes.input
              }
            >
              {specialityTypes.map((special) => (
                <MenuItem value={special.value}>{special.label}</MenuItem>
              ))}
            </Select>
            {/* <Select
              variant="outlined"
              fullWidth
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem key={"emergencycare"} value={"Emergency Care"}>
                Emergency Care (Ambulance Needed)
              </MenuItem>
              <MenuItem
                key={"emergencycarevc"}
                value={"Emergency Care Video Conference"}
              >
                Emergency Care (Video Conference)
              </MenuItem>
              <MenuItem key={"SRHservices"} value={"SRH services"}>
                SRH services
              </MenuItem>
              <MenuItem
                key={"facilitymanagement&treatment"}
                value={"facility management & treatment"}
              >
                Facility management & treatment
              </MenuItem>
            </Select> */}
          </FormControl>
        </DialogContent>

        <DialogContent>
          {/* <UsersCombo
            className={classes.items}
            placeholder={"Select a Doctor"}
            users={DoctorsRef}
            value={state.refferalDoctor}
            onChange={(event, newValue) => {
              setstate({
                ...state,
                refferalDoctor: newValue,
              });
              // console.log("ref", updatebooking);
              // setUserCombo1(newValue);
            }}
          /> */}

          <CustomInput
            labelText={"Reason for Referral"}
            formControlProps={{
              fullWidth: true,
            }}
            type="textarea"
            placeholder="Reason for Referral"
            onChange={(e) => {
              setstate({
                ...state,
                refferalNote: e.target.value,
              });
              // setreferralreason(e.target.value);
            }}
            value={state.refferalNote}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => makeRefferal()}>
            {"Make Refferal"}
          </Button>
          <Button color="primary" onClick={handleCloseEvent}>
            {"Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "220px",
    borderRight: "2px solid",
    borderRightColor: "#ff0000",
    borderBlockStartColor: "#ff0000",
    borderBlockStart: "2px solid",
    width: "600px",
  },
  rootThree: {
    // paddingTop: "220px",
    // position: "fixed",
    height: "400px",
    borderRight: "2px solid",
    borderRightColor: "#ff0000",
    borderBlockStartColor: "#ff0000",
    borderBlockStart: "2px solid",
    width: "600px",
    display: "flex",
  },
  video: {
    borderRight: "2px solid",
    borderRightColor: "#ff0000",
    borderBlockStartColor: "#ff0000",
    borderBlockStart: "2px solid",
    // height: "600px",
  },
  rootTwo: {
    borderRight: "2px solid",
    borderRightColor: "#ff0000",
    borderBlockStartColor: "#ff0000",
    borderBlockStart: "2px solid",
    height: "670px",
  },
  container: {
    display: "flex",
    // flex: row,
    height: "100%",

    overflow: "hidden",
    position: "right",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  chatlist: {
    width: "240px",
    overflow: "auto",
  },
  header: {
    maxHeight: 250,
    minWidth: 275,
    border: "2px solid black",
  },
  footer: {
    paddingTop: "400px",
    paddingLeft: "500px",
    // border: "2px solid black",
  },
}));
export default AppointmentScreen;
