/*eslint-disable*/
import React from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import "assets/scss/material-kit-react.scss?v=1.9.0";
import "assets/scss/now-ui-dashboard.scss";
import { Provider } from "react-redux";
import Dashboard from "./views/Dashboard";
import AddBookings from "./views/AddBookings";
import Promos from "./views/Promos";
import Patients from "./views/Patients";
import PracticeAdmin from "./views/PracticeAdmins";

import Notifications from "./views/Notifications";
import Earningreports from "./views/Earningreports";
import Settings from "./views/Settings";
import Withdraws from "./views/Withdraws";
import CancellationReasons from "./views/CancellationReasons";
import RegisterPage from "./views/RegisterPage";
import AddMoney from "./views/AddMoney";
import NurseCreateBooking from "./views/Nurse/NurseCreateBooking";
//General
import LandingPage from "./views/LandingPage.js";
import LoginPage from "./views/LoginPage.js";
import PrivacyPolicy from "./views/PrivacyPolicy.js";
import AboutUs from "./views/AboutUs";
import AuthLoading from "./views/AuthLoading";
import MyProfile from "./views/MyProfile";
import Calendar from "./views/Calendar";
import Chats from "./views/Chat";
import PracticeDetails from "./views/PracticeDetails";
// import DoctorDetails from "./views/doctordetails";
import ResponsiveDrawer from "./components/ResponsiveDrawer";
import ResponsiveQueueDrawer from "./components/ResponsiveQueueDrawer";
import ResponsiveConsultation from "./components/ResponsiveConsultation";
import { Wysiwig } from "components/Wysiwig";
//Admin
import AddAdminBookings from "./views/PracticeAdmin/AddAdminBookings";
import BookingRequest from "./views/PracticeAdmin/BookingRequest";
import PABookingDetails from "./views/PracticeAdmin/PABookingDetails";
import Doctors from "./views/PracticeAdmin/Doctors";
import Nurses from "./views/PracticeAdmin/Nurses";
import NoPhonePatients from "./views/PracticeAdmin/NoPhonePatients";
import ReferralDoctors from "./views/PracticeAdmin/ReferralDoctors";
import CreateBooking from "./views/PracticeAdmin/CreateBooking";
import PracticeReports from "views/PracticeAdmin/PracticeReports";
//Patient
import Appointments from "./views/Patient/Appointments ";
import PatientQuote from "views/Patient/PatientQuote";

//PatientProfile
import PatientEditProfile from "./views/Patient/PatientEditProfile";
import PatientProfile from "./views/Patient/PatientProfile";
//Patient Medical History
import PatientMedicalHistory from "views/Patient/PatientMedicalHistory";
//import PatientFamilyMedicalHistory from "./views/Patient/PatientMedicalHistory";

import MedicalWizard from "./views/Patient/MedicalHistoryWizard/MedicalWizard";
//import PatientMedicalHistory from "./views/Patient/MedicalHistoryWizard/PatientMedicalHistory";
import PatientBasic from "./views/Patient/MedicalHistoryWizard/PatientBasic";
import PatientSocial from "./views/Patient/MedicalHistoryWizard/PatientSocial";
import PatientChildhood from "./views/Patient/MedicalHistoryWizard/PatientChildhood";
import PatientMedicalTwoHistory from "./views/Patient/MedicalHistoryWizard/PatientMedicalHistory";
import PatientImmunization from "views/Patient/MedicalHistoryWizard/PatientImmunization";
import PatientSurgery from "views/Patient/MedicalHistoryWizard/PatientSurgery";
import PatientFamilyMedicalHistory from "views/Patient/MedicalHistoryWizard/PatientFamilyMedicalHistory";
import VideoConference from "./views/VideoConference";
import PatientMedication from "views/Patient/MedicalHistoryWizard/PatientMedication";
import PatientTimeLine from "./views/Patient/PatientTimeline";
import ConfirmedBooking from "./views/Patient/confirmedBookings";
import BookingOffers from "./views/Patient/BookingOffers";
import PatientBookingOffers from "./views/Patient/PatientBookingOffers";
import PatientBookingDetails from "./views/Patient/PatientBookingDetails";
import PatientInvoice from "views/Patient/Wallet/PatientInvoice";
import PatientWallet from "views/Patient/Wallet/PatientWallet";

//Doctor
import DoctorProfile from "./views/Doctor/DoctorProfile";
import DoctorTreatmentNotes from "./views/Doctor/DoctorTreatmentNotes";
import DoctorEarning from "./views/DoctorEarning";
import BookingInformation from "./views/Doctor/BookingInformation";
import AppointmentScreen from "./views/Doctor/AppointmentScreen";
import BookingHistory from "./views/Doctor/BookingHistory";
import FinancialReport from "views/Doctor/FinancialReport";
import BookingsReport from "views/Doctor/BookingsReport";
import DoctorPrescriptionForm from "./views/Doctor/DoctorPrescriptionForm";
import DocScan from "views/Doctor/DocScan";
import UploadFile from "./views/Doctor/UploadFile";
import Forms from "views/Doctor/Forms";
import DoctorTimeLine from "views/Doctor/DoctorTimeLine";
import DoctorEditProfile from "views/Doctor/DoctorEditProfile";
import DoctorCalendar from "views/Doctor/DoctorCalendar";
import Kanban from "views/Kanban";
//Nurse
import NurseEditProfile from "./views/Nurse/NurseEditProfile.js";
import NurseProfile from "./views/Nurse/NurseProfile";
import NurseBookingsReport from "./views/Nurse/NurseBookingsReport";
import NurseFinancialReport from "./views/Nurse/NurseFinancialReport";
import NurseBookingInformation from "./views/Nurse/NurseBookingInformation";
import NurseAppointmentScreen from "./views/Nurse/NurseAppointmentsScreen";
import NurseBookingHistory from "./views/Nurse/NurseBookingHistory";
import NursePrescriptionForm from "views/Nurse/NursePrescriptionForm";
import NurseTreamentNotes from "views/Nurse/NurseTreamentNotes";
import NurseTimeLine from "views/Nurse/NurseTimeLine";
import NurseCalendar from "views/Nurse/NurseCalendar";
import { store, FirebaseProvider } from "common";
import { features } from "config";

import Payfast from "views/Payfast";

import Reports from "./views/PracticeAdmin/Reports";
import PaInvoice from "./views/PracticeAdmin/PaInvoice";
// eslint-disable-next-line no-unused-vars
import Widgets from "views/Widgets";
import AudiologyForm from "views/Doctor/Forms/AudiologyForm";
import DentalForm from "views/Doctor/Forms/DentalForm";
import OPTForm from "views/Doctor/Forms/OPTForm";
import PrimaryHealthCare from "views/Doctor/Forms/PrimaryHealthCare";
import AddPracticeAdmin from "views/Doctor/AddPracticeAdmin";
import AdminDoctors from "views/AdminDoctors";
import TehillahForms from "views/TehillahForms";
import ClinicBookings from "views/Doctor/ClinicBookings";
import PaClinicBookings from "views/PracticeAdmin/PaClinicBookings";
import NurseClinicBookings from "views/Nurse/NurseClinicBookings";
// Consultation
import DeviceExposure from "views/Consultations/DeviceExposure";
import DrugExposure from "views/Consultations/DrugExposure";
import Observations from "views/Consultations/Observations";
import Prescriptions from "views/Consultations/Prescriptions";
import Notes from "views/Consultations/Notes";
import Procedures from "views/Consultations/Procedures";

// refferals
import DoctorRefferalRequest from "views/Doctor/DoctorRefferalRequest";

var hist = createBrowserHistory();

function App() {
  return (
    <Provider store={store}>
      <FirebaseProvider>
        <AuthLoading>
          <BrowserRouter>
            <Routes>
              {/* Practice Admin/////////////////////////////////////////////////////////////// */}
              <Route
                element={
                  <ResponsiveDrawer>
                    <TehillahForms />{" "}
                  </ResponsiveDrawer>
                }
                path="/forms"
                //permit={"admin,practiceadmin,doctor"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <Nurses />{" "}
                  </ResponsiveDrawer>
                }
                path="/nurses"
                //permit={"admin,practiceadmin,doctor"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <NoPhonePatients />{" "}
                  </ResponsiveDrawer>
                }
                path="/nophonepatients"
                //permit={"admin,practiceadmin,doctor,nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <Doctors />{" "}
                  </ResponsiveDrawer>
                }
                path="/doctors"
                //permit={"admin,practiceadmin,doctor"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <NurseCalendar />{" "}
                  </ResponsiveDrawer>
                }
                path="/nursecalendar"
                //permit={"admin,practiceadmin,doctor"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <ReferralDoctors />{" "}
                  </ResponsiveDrawer>
                }
                path="/referraldoctors"
                //permit={"admin,practiceadmin,doctor,nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <DoctorCalendar />{" "}
                  </ResponsiveDrawer>
                }
                path="/doctorcalendar"
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <AdminDoctors />{" "}
                  </ResponsiveDrawer>
                }
                path="/admindoctors"
                //permit={"admin"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <DoctorRefferalRequest />{" "}
                  </ResponsiveDrawer>
                }
                path="/doctorrefreq"
                //permit={"admin"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <BookingRequest />{" "}
                  </ResponsiveDrawer>
                }
                path="/bookingrequest"
                //permit={"admin,doctor,practiceadmin,nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <PABookingDetails />{" "}
                  </ResponsiveDrawer>
                }
                path="/pabookingdetails"
                //permit={"admin,doctor,practiceadmin,nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <AddAdminBookings />{" "}
                  </ResponsiveDrawer>
                }
                path="/addadminbookings"
                //permit={"admin,patient,doctor,nurse,practiceadmin"}
              />

              <Route
                element={
                  <ResponsiveDrawer>
                    <CreateBooking />{" "}
                  </ResponsiveDrawer>
                }
                path="/createbookings"
                //permit={"admin,patient,doctor,nurse,practiceadmin"}
              />

              <Route
                element={
                  <ResponsiveDrawer>
                    <DoctorEarning />{" "}
                  </ResponsiveDrawer>
                }
                path="/doctorearning"
                //permit={"admin,practiceadmin,doctor"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <Kanban />{" "}
                  </ResponsiveDrawer>
                }
                path="/doctorkanban"
                //permit={"admin,practiceadmin,doctor"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <PracticeReports />{" "}
                  </ResponsiveDrawer>
                }
                path="/practicereports"
                //permit={"admin,practiceadmin,doctor"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <Reports />{" "}
                  </ResponsiveDrawer>
                }
                path="/reports"
                //permit={"admin,practiceadmin,doctor"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <FinancialReport />{" "}
                  </ResponsiveDrawer>
                }
                path="/financereports"
                //permit={"admin,practiceadmin,doctor"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <PaInvoice />{" "}
                  </ResponsiveDrawer>
                }
                path="/practiceadmininvoice"
                //permit={"admin,practiceadmin,doctor"}
              />

              <Route
                element={
                  <ResponsiveDrawer>
                    <MyProfile />{" "}
                  </ResponsiveDrawer>
                }
                path="/profile"
                //permit={"patient,admin,practiceadmin"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <VideoConference />{" "}
                  </ResponsiveDrawer>
                }
                path="/videoconference"
                //permit={"patient,admin,doctor,practiceadmin,nurse"}
              />

              <Route
                element={
                  <ResponsiveDrawer>
                    <Chats />{" "}
                  </ResponsiveDrawer>
                }
                path="/chats"
                //permit={"patient,admin,doctor,practiceadmin,nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <BookingHistory />{" "}
                  </ResponsiveDrawer>
                }
                path="/bookings"
                //permit={"patient,admin,doctor,practiceadmin,nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <ClinicBookings />{" "}
                  </ResponsiveDrawer>
                }
                path="/clinicbookings"
                //permit={"patient,admin,doctor,practiceadmin,nurse"}
              />
              <Route
                element={
                  <ResponsiveQueueDrawer>
                    <PaClinicBookings />{" "}
                  </ResponsiveQueueDrawer>
                }
                path="/paclinicbookings"
                //permit={"patient,admin,doctor,practiceadmin,nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <MyProfile />{" "}
                  </ResponsiveDrawer>
                }
                path="/profile"
                //permit={"patient,admin,practiceadmin"}
              />

              <Route
                element={
                  <ResponsiveDrawer>
                    <Dashboard />{" "}
                  </ResponsiveDrawer>
                }
                path="/dashboard"
                //permit={"doctor,patient,admin,practiceadmin,nurse"}
              />
              {/* <Route
                
                element={<ResponsiveDrawer><Widgets/>{" "}
                  </ResponsiveDrawer>}
                path="/widgets"
                //permit={"doctor,patient,admin,practiceadmin"}
              /> */}
              <Route
                element={
                  <ResponsiveDrawer>
                    <CancellationReasons />{" "}
                  </ResponsiveDrawer>
                }
                path="/cancelreasons"
                //permit={"admin"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <AddBookings />{" "}
                  </ResponsiveDrawer>
                }
                path="/addbookings"
                //permit={"admin,patient,doctor,nurse,practiceadmin"}
              />

              <Route
                element={
                  <ResponsiveDrawer>
                    <PracticeDetails />{" "}
                  </ResponsiveDrawer>
                }
                path="/practicedetails"
                //permit={"admin,practiceadmin,doctor"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <Promos />{" "}
                  </ResponsiveDrawer>
                }
                path="/promos"
                //permit={"admin,practiceadmin"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <Patients />{" "}
                  </ResponsiveDrawer>
                }
                path="/patient"
                //permit={"admin"}
              />
              {/* <Route
                
                element={<ResponsiveDrawer><DoctorDetails/>{" "}
                  </ResponsiveDrawer>}
                path="/doctordetails"
                //permit={"admin,patient,doctor,nurse"}
              /> */}

              <Route
                element={
                  <ResponsiveDrawer>
                    <Wysiwig />{" "}
                  </ResponsiveDrawer>
                }
                path="/wysiwig"
                //permit={"doctor"}
              />

              <Route
                element={
                  <ResponsiveDrawer>
                    <Calendar />{" "}
                  </ResponsiveDrawer>
                }
                path="/calendar"
                //permit={"admin,patient,doctor,nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <PracticeAdmin />{" "}
                  </ResponsiveDrawer>
                }
                path="/practiceadmin"
                //permit={"admin,practiceadmin"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <AddPracticeAdmin />{" "}
                  </ResponsiveDrawer>
                }
                path="/addpracticeadmin"
                //permit={"doctor,nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <Earningreports />{" "}
                  </ResponsiveDrawer>
                }
                path="/earningreports"
                //permit={"admin,practiceadmin,doctor"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <AddMoney />{" "}
                  </ResponsiveDrawer>
                }
                path="/addtowallet"
                //permit={"admin,practiceadmin,patient"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <Withdraws />{" "}
                  </ResponsiveDrawer>
                }
                path="/withdraws"
                //permit={"admin"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <Settings />{" "}
                  </ResponsiveDrawer>
                }
                path="/settings"
                //permit={"admin"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <Notifications />{" "}
                  </ResponsiveDrawer>
                }
                path="/notifications"
                //permit={"admin,doctor,patient,nurse,practiceadmin"}
              />

              <Route
                element={
                  <ResponsiveDrawer>
                    <UploadFile />{" "}
                  </ResponsiveDrawer>
                }
                path="/uploadfile"
                //permit={"doctor,nurse,patient"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <Forms />{" "}
                  </ResponsiveDrawer>
                }
                path="/forms"
                //permit={"doctor,nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <AudiologyForm />{" "}
                  </ResponsiveDrawer>
                }
                path="/audioform"
                //permit={"doctor,nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <DentalForm />{" "}
                  </ResponsiveDrawer>
                }
                path="/dentalform"
                //permit={"doctor,nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <OPTForm />{" "}
                  </ResponsiveDrawer>
                }
                path="/optform"
                //permit={"doctor,nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <PrimaryHealthCare />{" "}
                  </ResponsiveDrawer>
                }
                path="/phcform"
                //permit={"doctor,nurse"}
              />
              {/* Patient////////////////////////////////////////////////////////////////////////////////// */}
              <Route
                element={
                  <ResponsiveDrawer>
                    <PatientProfile />{" "}
                  </ResponsiveDrawer>
                }
                path="/patientprofile"
                //permit={"patient"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <PatientEditProfile />{" "}
                  </ResponsiveDrawer>
                }
                path="/patienteditprofile"
                //permit={"patient"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <DoctorEditProfile />{" "}
                  </ResponsiveDrawer>
                }
                path="/editprofile"
                //permit={"doctor"}
              />
              {/* <Route
                
                element={<ResponsiveDrawer><PatientBasic/>{" "}
                  </ResponsiveDrawer>}
                path="/patientbasic"
                //permit={"patient"}
              />
              <Route
                
                element={<ResponsiveDrawer><PatientChildhood/>{" "}
                  </ResponsiveDrawer>}
                path="/patientchildhood"
                //permit={"patient"}
              /> */}
              <Route
                element={
                  <ResponsiveDrawer>
                    <BookingOffers />{" "}
                  </ResponsiveDrawer>
                }
                path="/bookingoffers"
                //permit={"patient"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <PatientBookingOffers />{" "}
                  </ResponsiveDrawer>
                }
                path="/patientbookingoffers"
                //permit={"patient"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <Payfast />{" "}
                  </ResponsiveDrawer>
                }
                path="/payfast"
                //permit={"patient"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <PatientBookingDetails />{" "}
                  </ResponsiveDrawer>
                }
                path="/patientbookingdetails"
                //permit={"patient"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <ConfirmedBooking />{" "}
                  </ResponsiveDrawer>
                }
                path="/confirmedBookings"
                //permit={"patient"}
              />

              <Route
                element={
                  <ResponsiveDrawer>
                    <Appointments />{" "}
                  </ResponsiveDrawer>
                }
                path="/appointments"
                //permit={"patient"}
              />

              <Route
                element={
                  <ResponsiveDrawer>
                    <PatientMedicalHistory />{" "}
                  </ResponsiveDrawer>
                }
                path="/patientmedicalhistory"
                //permit={"patient"}
              />
              {/* <Route
                
                element={<ResponsiveDrawer><PatientFamilyMedicalHistory/>{" "}
                  </ResponsiveDrawer>}
                path="/patientfamilymedicalhistory"
                //permit={"patient"}
              /> */}
              <Route
                element={
                  <ResponsiveDrawer>
                    <VideoConference />{" "}
                  </ResponsiveDrawer>
                }
                path="/videoconference"
                //permit={"patient"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <PatientProfile />{" "}
                  </ResponsiveDrawer>
                }
                path="/patientprofile"
                //permit={"patient"}
              />

              <Route
                element={
                  <ResponsiveDrawer>
                    <PatientQuote />{" "}
                  </ResponsiveDrawer>
                }
                path="/patientquote"
                //permit={"patient"}
              />
              {/* Medical Wizard//////////////////////////////////////// */}
              <Route
                element={
                  <ResponsiveDrawer>
                    <MedicalWizard />{" "}
                  </ResponsiveDrawer>
                }
                path="/medicalwizard"
                //permit={"patient"}
              />

              <Route
                element={
                  <ResponsiveDrawer>
                    <PatientBasic />{" "}
                  </ResponsiveDrawer>
                }
                path="/patientbasic"
                //permit={"patient"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <PatientSocial />{" "}
                  </ResponsiveDrawer>
                }
                path="/patientsocial"
                //permit={"patient"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <PatientChildhood />{" "}
                  </ResponsiveDrawer>
                }
                path="/patientchildhood"
                //permit={"patient"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <PatientImmunization />{" "}
                  </ResponsiveDrawer>
                }
                path="/patientimmunization"
                //permit={"patient"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <PatientSurgery />{" "}
                  </ResponsiveDrawer>
                }
                path="/patientsurgery"
                //permit={"patient"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <PatientFamilyMedicalHistory />{" "}
                  </ResponsiveDrawer>
                }
                path="/patientfamilyhistory"
                //permit={"patient"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <PatientMedication />{" "}
                  </ResponsiveDrawer>
                }
                path="/patientmedication"
                //permit={"patient"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <PatientMedicalHistory />{" "}
                  </ResponsiveDrawer>
                }
                path="/patientmedicalhistory"
                //permit={"patient"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <PatientMedicalTwoHistory />{" "}
                  </ResponsiveDrawer>
                }
                path="/patientmedhistory"
                //permit={"patient"}
              />
              {/* /////////////////////////////////////////////// */}

              <Route
                element={
                  <ResponsiveDrawer>
                    <PatientInvoice />{" "}
                  </ResponsiveDrawer>
                }
                path="/patientinvoice"
                //permit={"patient"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <PatientWallet />{" "}
                  </ResponsiveDrawer>
                }
                path="/patientwallet"
                //permit={"patient"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <PatientTimeLine />{" "}
                  </ResponsiveDrawer>
                }
                path="/mytimeline"
                //permit={"patient"}
              />

              {/* Doctor/////////////////////////////////////////////////////////////////// */}
              <Route
                element={
                  <ResponsiveDrawer>
                    <DoctorProfile />{" "}
                  </ResponsiveDrawer>
                }
                path="/doctorprofile"
                //permit={"doctor"}
              />

              <Route
                element={
                  <ResponsiveDrawer>
                    <DoctorTreatmentNotes />{" "}
                  </ResponsiveDrawer>
                }
                path="/doctortreatmentnotes"
                //permit={"doctor"}
              />

              <Route
                element={
                  <ResponsiveDrawer>
                    <BookingInformation />{" "}
                  </ResponsiveDrawer>
                }
                path="/bookinginformation"
                //permit={"doctor,admin"}
              />

              <Route
                element={
                  <ResponsiveDrawer>
                    <FinancialReport />{" "}
                  </ResponsiveDrawer>
                }
                path="/financialreport"
                //permit={"doctor"}
              />

              <Route
                element={
                  <ResponsiveDrawer>
                    <BookingsReport />{" "}
                  </ResponsiveDrawer>
                }
                path="/bookingsreport"
                //permit={"doctor"}
              />

              <Route
                element={
                  <ResponsiveDrawer>
                    <DoctorPrescriptionForm />{" "}
                  </ResponsiveDrawer>
                }
                path="/doctorprescriptionform"
                //permit={"doctor"}
              />

              <Route
                element={
                  <ResponsiveDrawer>
                    <DocScan />{" "}
                  </ResponsiveDrawer>
                }
                path="/docscan"
                //permit={"doctor"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <DoctorTimeLine />{" "}
                  </ResponsiveDrawer>
                }
                path="/timeline"
                //permit={"doctor"}
              />

              {/* Nurse //////////////////////////////////////////////////////////////////////////////*/}
              <Route
                element={
                  <ResponsiveDrawer>
                    <NurseProfile />{" "}
                  </ResponsiveDrawer>
                }
                path="/nurseprofile"
                //permit={"nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <NurseEditProfile />{" "}
                  </ResponsiveDrawer>
                }
                path="/nurseeditprofile"
                //permit={"nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <NurseTimeLine />{" "}
                  </ResponsiveDrawer>
                }
                path="/nursetimeline"
                //permit={"nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <NurseTreamentNotes />{" "}
                  </ResponsiveDrawer>
                }
                path="/nursetreatmentnotes"
                //permit={"nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <NursePrescriptionForm />{" "}
                  </ResponsiveDrawer>
                }
                path="/nurseprescription"
                //permit={"nurse"}
              />

              <Route
                element={
                  <ResponsiveDrawer>
                    <NurseBookingsReport />{" "}
                  </ResponsiveDrawer>
                }
                path="/nursebookingsreport"
                //permit={"nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <NurseFinancialReport />{" "}
                  </ResponsiveDrawer>
                }
                path="/nursefinancialreport"
                //permit={"nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <NurseCreateBooking />{" "}
                  </ResponsiveDrawer>
                }
                path="/nursecreatebooking"
                //permit={"nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <NurseBookingInformation />{" "}
                  </ResponsiveDrawer>
                }
                path="/nursebookings"
                //permit={"nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <NurseBookingHistory />{" "}
                  </ResponsiveDrawer>
                }
                path="/nursebookingshistory"
                //permit={"nurse"}
              />
              <Route
                element={
                  <ResponsiveDrawer>
                    <NurseClinicBookings />{" "}
                  </ResponsiveDrawer>
                }
                path="/nurseclinicbooking"
                //permit={"nurse"}
              />
              {/* // Consultations */}
              <Route
                path="/appointmentscreen"
                element={
                  // <ResponsiveConsultation>
                  <AppointmentScreen />
                  // </ResponsiveConsultation>
                }
              />
              <Route
                path="/deviceexposure"
                element={
                  <ResponsiveDrawer>
                    <DeviceExposure />
                  </ResponsiveDrawer>
                }
              />
              <Route
                path="/drugexposure"
                element={
                  <ResponsiveDrawer>
                    <DrugExposure />
                    </ResponsiveDrawer>
                }
              />
              <Route
                path="/observations"
                element={
                  <ResponsiveDrawer>
                    <Observations />
                  </ResponsiveDrawer>
                }
              />
              <Route
                path="/prescriptions"
                element={
                  <ResponsiveDrawer>
                    <Prescriptions />
                    </ResponsiveDrawer>
                }
              />
              <Route
                path="/notes"
                element={
                  <ResponsiveDrawer>
                    <Notes />
                  </ResponsiveDrawer>
                }
              />
              <Route
                path="/procedures"
                element={
                  <ResponsiveDrawer>
                    <Procedures />
                  </ResponsiveDrawer>
                }
              />
              {/* General ////////////////////////////////////////////////////////////////////////// */}
              <Route path="/about-us" element={<AboutUs />} />

              <Route
                path="/nurseappointments"
                element={<NurseAppointmentScreen />}
              />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              {features.WebsitePagesEnabled ? (
                <Route path="/register" element={<RegisterPage />} />
              ) : null}
              {features.WebsitePagesEnabled ? (
                <Route path="/login" element={<LoginPage />} />
              ) : null}
              {features.WebsitePagesEnabled ? (
                <Route path="/" element={<LandingPage />} />
              ) : (
                <Route path="/" element={<LoginPage />} />
              )}
            </Routes>
          </BrowserRouter>
        </AuthLoading>
      </FirebaseProvider>
    </Provider>
  );
}

export default App;
