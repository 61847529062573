/*eslint-disable*/
import React, { useState, useEffect } from "react";
// import MaterialTable from 'material-table';
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
// import {
//   features ,
//   dateStyle,
//   language
// } from 'config';
import FlatList from "flatlist-react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
// import { FirebaseContext } from 'common';
import { useNavigate } from "react-router-dom";
export default function Users(props) {
  // const { api } = useContext(FirebaseContext);
  // const { addUser, editUser, deleteUser, checkUserExists } = api;
  const [data, setData] = useState([]);
  const usersdata = useSelector((state) => state.usersdata);
  // const dispatch = useDispatch();
const navigate = useNavigate();
  useEffect(() => {
    if (usersdata.users) {
      setData(
        usersdata.users.filter(
          (user) =>
            user.userType === "patient" && user.userType === "nophonepatient"
        )
      );
    } else {
      setData([]);
    }
  }, [usersdata.users]);
  const renderPatient = (patient, index) => {
    return (
      <Row key={index}>
        <Col md="8">
          <Card border="danger">
            <CardHeader as="h5">
              <CardTitle tag="h4">
                <img
                  alt="..."
                  className="avatar border-gray"
                  src={patient.profile_image}
                />{" "}
                {patient.firstName + " " + patient.lastName}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row mb="12">
                <Col md="12">
                  <FormGroup>
                    <span>Patient Mobile Number: </span>
                    <p className="description">{patient.mobile}</p>
                  </FormGroup>
                </Col>
              </Row>
              <Row mb="12">
                <Col md="12">
                  <FormGroup>
                    <span>Patient email: </span>
                    <p className="description">{patient.email}</p>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Button
                onClick={() => {
                  navigate("/landingpage");
                }}
              >
                View Patient
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    );
  };

  return usersdata.loading ? (
    <CircularLoading />
  ) : (
    <Row>
      <Col md="12">
        <Card>
          <CardHeader>
            <CardTitle tag="h4">ALL CURRENT PATIENTS</CardTitle>
          </CardHeader>
          <CardBody>
            <ul>
              <FlatList
                keyExtractor={(item, index) => {
                  return index.toString();
                }}
                list={data}
                renderItem={renderPatient}
                renderWhenEmpty={() => <div>List is empty!</div>}
                sortBy={["firstName", { key: "lastName", descending: true }]}
                //groupBy={(person) => (person.info.age > 18 ? "Over 18" : "Under 18")}
              />
            </ul>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
