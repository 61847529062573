/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../../components/CircularLoading";
import { features, dateStyle, language } from "config";
import { FirebaseContext } from "common";
import AlertDialog from "../../components/AlertDialog";
import { useLocation, useNavigate } from "react-router-dom";

export default function Observations({ Booking }) {
  const { api } = useContext(FirebaseContext);
  const { editUser, clearLoginError, updateBooking } = api;

  const location = useLocation();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const usersdata = useSelector((state) => state.usersdata);
  
  const booking = location.state.Booking
  const [data, setData] = useState([]);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });

  useEffect(() => {
    console.log("booking", booking);
    if (booking) {
      setData(Object.values(booking.observations));
    } else {
      setData([]);
    }
  }, [booking]);

  const columns = [
    {
      title: language.person_id,
      field: "person_id",
      initialEditValue: booking?.patientName,
    },
    {
      title: "Date",
      field: "observation_date",
      initialEditValue: new Date(),
      type: "date",
      editable: false,
    },
    {
      title: language.observation_datetime,
      field: "observation_datetime",
      initialEditValue: new Date(),
      type: "time",
      editable: false,
    },
    {
      title: language.observation_type_concept_id,
      field: "observation_type_concept_id",
      initialEditValue: "",
    },
    {
      title: language.observation_source_concept_id,
      field: "observation_source_concept_id",
      initialEditValue: "",
    },
    {
      title: language.provider_id,
      field: "provider_id",
      initialEditValue:
        booking?.doctor?.firstName + " " + booking?.doctor?.lastName,
      editable: false,
    },
    {
      title: language.value_as_number,
      field: "value_as_number",
      initialEditValue: "",
    },
    {
      title: language.value_as_string,
      field: "value_as_string",
      initialEditValue: "",
    },
  ];
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
    if (auth.error.flag) {
      dispatch(clearLoginError());
    }
  };

  return usersdata.loading ? (
    <CircularLoading />
  ) : (
    ((
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    ),
    (
      <MaterialTable
        title={"Observations"}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          sorting: true,
          actionsColumnIndex: -1,
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              resolve();
              setTimeout(() => {
                let tempArr = data;
                tempArr.push(newData);
                setData([...tempArr]);
                dispatch(updateBooking({ ...booking, observations: tempArr }));
              }, 800);
            }),

          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                let tempArr = data;
                const indexOf = data.indexOf(oldData);

                console.log("newData", newData);

                if (indexOf > -1) {
                  tempArr[indexOf] = newData;
                  setNotes([...tempArr]);
                  dispatch(
                    updateBooking({ ...booking, observations: tempArr })
                  );
                  resolve();
                } else {
                  setCommonAlert({
                    open: true,
                    msg: "No note found cant update",
                  });
                  resolve();
                }

                dispatch(editUser(oldData.id, newData));
              }, 600);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                let tempArr = data;
                const indexOf = data.indexOf(oldData);
                if (indexOf > -1) {
                  tempArr.splice(indexOf, 1);
                  setData([...tempArr]);
                  dispatch(
                    updateBooking({ ...booking, observations: tempArr })
                  );
                  resolve();
                } else {
                  setCommonAlert({
                    open: true,
                    msg: "No note found cant delete",
                  });
                  resolve();
                }
              }, 600);
            }),
        }}
      />
    ))
  );
}
