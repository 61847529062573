/*eslint-disable*/
import React, { useState, useEffect } from "react";

import CircularLoading from "components/CircularLoading";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import FlatList from "flatlist-react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Row,
  Col,
} from "reactstrap";

const ConfirmedBooking = (props) => {
  const auth = useSelector((state) => state.auth);
  const [role, setRole] = useState(null);
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const bookinglistdata = useSelector((state) => state.bookinglistdata);

  useEffect(() => {
    if (auth.info.profile.userType === "patient") {
      if (bookinglistdata.bookings) {
        setData(
          bookinglistdata.bookings.filter(
            (bookings) => bookings.status === "ACCEPTED"
          )
        );
      } else {
        setData([]);
      }
    } else {
      if (bookinglistdata.bookings) {
        setData(
          bookinglistdata.bookings.filter(
            (bookings) => bookings.status === "NEW"
          )
        );
      } else {
        setData([]);
      }
    }
  }, [bookinglistdata.bookings]);

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.userType);
    }
  }, [auth.info]);
  const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 275,
      borderRight: "2px solid",
      borderRightColor: "#ff0000",
      borderBlockStartColor: "#ff0000",
      borderBlockStart: "2px solid",
      // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    },
  }));
  const classes = useStyles();

  const renderBooking = (Booking, index) => {
    return (
      // <Row key={index}>
      <Col md="4">
        {Booking.doctor ? (
          <Card className={classes.root}>
            <CardHeader as="h5">
              <CardTitle tag="h4">
                <div className="picture">
                  <img
                    alt="..."
                    className="picture-src"
                    src={Booking?.doctor?.profile_image}
                  />{" "}
                </div>
                {Booking?.doctor?.desc}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row mb="12">
                <Col md="12">
                  <FormGroup>
                    <span>Type of Booking Service: </span>
                    <p className="description">{Booking.serviceType}</p>
                  </FormGroup>
                </Col>
                {Booking.adjustedtimeStart ? (
                  <Col>
                    <FormGroup>
                      <span>New Booking Date and Time of Booking: </span>
                      <p className="description">{Booking.adjustedtimeStart}</p>
                    </FormGroup>
                  </Col>
                ) : null}
                {!Booking.adjustedtimeStart ? (
                  <Col>
                    <FormGroup>
                      <span>Date and Time of Booking: </span>
                      <p className="description">{Booking.eventDate}</p>
                    </FormGroup>
                  </Col>
                ) : null}
              </Row>
              <Row mb="12">
                <Col md="12">
                  <FormGroup>
                    <span>Booking Status: </span>
                    <p className="description">{Booking.status}</p>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Button
                color="primary"
                onClick={() => {
                  navigate("/patientbookingdetails", { state: { Booking } });
                }}
              >
                View Booking
              </Button>
            </CardFooter>
          </Card>
        ) : null}
        {Booking.nurse ? (
          <Card className={classes.root}>
            <CardHeader as="h5">
              <CardTitle tag="h4">
                <div className="picture">
                  <img
                    alt="..."
                    className="picture-src"
                    src={Booking?.nurse?.profile_image}
                  />{" "}
                </div>
                {Booking?.nurse?.desc}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row mb="12">
                <Col md="12">
                  <FormGroup>
                    <span>Type of Booking Service: </span>
                    <p className="description">{Booking.serviceType}</p>
                  </FormGroup>
                </Col>
                {Booking.adjustedtimeStart ? (
                  <Col>
                    <FormGroup>
                      <span>New Booking Date and Time of Booking: </span>
                      <p className="description">{Booking.adjustedtimeStart}</p>
                    </FormGroup>
                  </Col>
                ) : null}
                {!Booking.adjustedtimeStart ? (
                  <Col>
                    <FormGroup>
                      <span>Date and Time of Booking: </span>
                      <p className="description">{Booking.eventDate}</p>
                    </FormGroup>
                  </Col>
                ) : null}
              </Row>
              <Row mb="12">
                <Col md="12">
                  <FormGroup>
                    <span>Booking Status: </span>
                    <p className="description">{Booking.status}</p>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Button
                color="primary"
                onClick={() => {
                  navigate("/patientbookingdetails", { state: { Booking } });
                }}
                // patientbookingdetails
              >
                View Booking
              </Button>
            </CardFooter>
          </Card>
        ) : null}
      </Col>
      // </Row>
    );
  };

  return data.loading ? (
    <CircularLoading />
  ) : (
    <Row>
      <Col md="12">
        <Card>
          <CardHeader>
            <CardTitle tag="h4">CONFIRMED BOOKINGS</CardTitle>
          </CardHeader>
          <CardBody>
            <ul>
              <Row>
                <FlatList
                  keyExtractor={(item, index) => {
                    return index.toString();
                  }}
                  list={data}
                  renderItem={renderBooking}
                  renderWhenEmpty={() => (
                    <div>There currently is no confirmed bookings!</div>
                  )}

                  //groupBy={(person) => (person.info.age > 18 ? "Over 18" : "Under 18")}
                />
              </Row>
            </ul>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ConfirmedBooking;
