/*eslint-disable*/
import CircularLoading from "../../../components/CircularLoading";
import { Select } from "@material-ui/core";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  FormText,
  Row,
  Col,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useContext } from "react";
import AlertDialog from "../../../components/AlertDialog";
import { FirebaseContext } from "common";
// import Input from "components/Input/Input";
import MenuItem from "@material-ui/core/MenuItem";
function PatientSocial({ props, route }) {
  /////////////////////////////////////////////////////////
  //REdux
  const { api } = useContext(FirebaseContext);
  const { editMedicalHistory } = api;
  const dispatch = useDispatch();
  const medical = useSelector(
    (state) => state.medicalRecords.medicalHistoryRecords
  );
  const auth = useSelector((state) => state.auth);
  const [medicalData, setMedicalData] = useState(medical);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  /////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////
  //useEffect 1
  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setMedicalData(medical);
    }
  }, [medical]);
  const OnSave = () => {
    let payload = {
      Medical_Information: {
        ...medical,
        LifeStyle_History: {
          ...medicalData.LifeStyle_History,
          SocialHistory: medicalData?.LifeStyle_History?.SocialHistory
            ? medicalData?.LifeStyle_History?.SocialHistory
            : null,
        },
      },
    };
    console.log("this is social payload:", payload);
    dispatch(editMedicalHistory(payload, "Add medical history"));
    setCommonAlert({ open: true, msg: "Your Social History Has Been Saved" });
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };
  return auth.loading ? (
    <CircularLoading />
  ) : (
    <>
      <div className="content">
        <div className="medicalhistory-form">
          <Row>
            <Col md="12">
              <AlertDialog
                open={commonAlert.open}
                onClose={handleCommonAlertClose}
              >
                {commonAlert.msg}
              </AlertDialog>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4"> SOCIAL MEDICAL HISTORY</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <FormText color="default" tag="span">
                        Occupation
                      </FormText>
                    </Col>
                    <Col md="4">
                      <FormText color="default" tag="span">
                        How many children do you have:
                      </FormText>
                    </Col>
                    <Col md="4">
                      <FormText color="default" tag="span">
                        Select Your Marital Status:
                      </FormText>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <Input
                        //returnKeyType={'next'}
                        fullWidth
                        label={"Your Occupation"}
                        value={
                          medicalData?.LifeStyle_History?.SocialHistory
                            ?.occupation
                        }
                        onChange={(event) => {
                          setMedicalData({
                            ...medicalData,
                            LifeStyle_History: {
                              ...medicalData.LifeStyle_History,
                              SocialHistory: {
                                ...medicalData.LifeStyle_History.SocialHistory,
                                occupation: event.target.value,
                              },
                            },
                          });
                        }}
                        customLabelStyles={{
                          colorFocused: "red",
                          colorBlurred: "black",
                          fontSizeFocused: 12,
                        }}
                      />
                    </Col>

                    <Col md="4">
                      <Input
                        label={"Number of Dependents"}
                        value={
                          medicalData?.LifeStyle_History?.SocialHistory
                            ?.children
                        }
                        onChange={(event) => {
                          setMedicalData({
                            ...medicalData,
                            LifeStyle_History: {
                              ...medicalData.LifeStyle_History,
                              SocialHistory: {
                                ...medicalData.LifeStyle_History.SocialHistory,
                                children: event.target.value,
                              },
                            },
                          });
                        }}
                        customLabelStyles={{
                          colorFocused: "red",
                          colorBlurred: "black",
                          fontSizeFocused: 12,
                        }}
                      />
                    </Col>
                    <Col md="4">
                      <Select
                        fullWidth
                        styles={{ fullWidth: "30px" }}
                        onChange={(event) => {
                          setMedicalData({
                            ...medicalData,
                            LifeStyle_History: {
                              ...medicalData.LifeStyle_History,
                              SocialHistory: {
                                ...medicalData.LifeStyle_History.SocialHistory,
                                maritalstatus: event.target.value,
                              },
                            },
                          });
                        }}
                        value={
                          medicalData?.LifeStyle_History?.SocialHistory
                            ?.maritalstatus
                        }
                      >
                        <MenuItem value="Married">Married</MenuItem>
                        <MenuItem value="Single">Single</MenuItem>
                        <MenuItem value="Divorced">Divorced</MenuItem>
                        <MenuItem value="Widowed">Widowed</MenuItem>
                      </Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4">
                      <FormText color="default" tag="span">
                        Your General Diet:
                      </FormText>
                    </Col>
                    <Col md="4">
                      <FormText color="default" tag="span">
                        Select Your Sexual Orientation
                      </FormText>
                    </Col>
                    <Col md="4">
                      <FormText color="default" tag="span">
                        How often/Hard you Exercise ?
                      </FormText>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <Input
                        //returnKeyType={'next'}

                        label={"Your Childern"}
                        value={
                          medicalData?.LifeStyle_History?.SocialHistory?.diet
                        }
                        onChange={(event) => {
                          setMedicalData({
                            ...medicalData,
                            LifeStyle_History: {
                              ...medicalData.LifeStyle_History,
                              SocialHistory: {
                                ...medicalData.LifeStyle_History.SocialHistory,
                                diet: event.target.value,
                              },
                            },
                          });
                        }}
                        customLabelStyles={{
                          colorFocused: "red",
                          colorBlurred: "black",
                          fontSizeFocused: 12,
                        }}
                      />
                    </Col>
                    <Col md="4">
                      <Select
                        fullWidth
                        styles={{ fullWidth: "30px" }}
                        onChange={(event) => {
                          setMedicalData({
                            ...medicalData,
                            LifeStyle_History: {
                              ...medicalData.LifeStyle_History,
                              SocialHistory: {
                                ...medicalData.LifeStyle_History.SocialHistory,
                                sexualorientation: event.target.value,
                              },
                            },
                          });
                        }}
                        value={
                          medicalData?.LifeStyle_History?.SocialHistory
                            ?.sexualorientation
                        }
                      >
                        <MenuItem value="Heterosexual">Heterosexual</MenuItem>
                        <MenuItem value="Homosexual">Homosexual</MenuItem>
                        <MenuItem value="Bisexual">Bisexual</MenuItem>
                      </Select>
                    </Col>
                    <Col md="4">
                      <Select
                        fullWidth
                        styles={{ fullWidth: "30px" }}
                        onChange={(event) => {
                          setMedicalData({
                            ...medicalData,
                            LifeStyle_History: {
                              ...medicalData.LifeStyle_History,
                              SocialHistory: {
                                ...medicalData.LifeStyle_History.SocialHistory,
                                exercise: event.target.value,
                              },
                            },
                          });
                        }}
                        value={
                          medicalData?.LifeStyle_History?.SocialHistory
                            ?.exercise
                        }
                      >
                        <MenuItem value="Sedentary">Sedentary</MenuItem>
                        <MenuItem value="Moderately">Moderately</MenuItem>
                        <MenuItem value="Vigorous">Vigorous</MenuItem>
                        <MenuItem value="Not Specified">Not Specified</MenuItem>
                      </Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <FormText color="default" tag="span">
                          Do you drink Alcohol?
                        </FormText>

                        <Input
                          //returnKeyType={'next'}
                          type="checkbox"
                          checked={
                            medicalData?.LifeStyle_History?.SocialHistory
                              ?.alcoholconsumption
                          }
                          onChange={(event) => {
                            setMedicalData({
                              ...medicalData,
                              LifeStyle_History: {
                                ...medicalData.LifeStyle_History,
                                SocialHistory: {
                                  ...medicalData.LifeStyle_History
                                    .SocialHistory,
                                  alcoholconsumption: event.target.value,
                                },
                              },
                            });
                          }}
                          customLabelStyles={{
                            colorFocused: "red",
                            colorBlurred: "black",
                            fontSizeFocused: 12,
                          }}
                        />
                        <Label> Yes</Label>

                        <Input
                          //returnKeyType={'next'}
                          type="checkbox"
                          checked={
                            !medicalData?.LifeStyle_History?.SocialHistory
                              ?.alcoholconsumption
                          }
                          onChange={(event) => {
                            setMedicalData({
                              ...medicalData,
                              LifeStyle_History: {
                                ...medicalData.LifeStyle_History,
                                SocialHistory: {
                                  ...medicalData.LifeStyle_History
                                    .SocialHistory,
                                  alcoholconsumption: false,
                                },
                              },
                            });
                          }}
                          customLabelStyles={{
                            colorFocused: "red",
                            colorBlurred: "black",
                            fontSizeFocused: 12,
                          }}
                        />
                        <Label>No</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <FormText color="default" tag="span">
                          Do you Smoke ?
                        </FormText>

                        <Label> Yes</Label>
                        <Input
                          //returnKeyType={'next'}
                          type="checkbox"
                          checked={
                            medicalData?.LifeStyle_History?.SocialHistory
                              ?.smoking
                          }
                          onChange={(event) => {
                            setMedicalData({
                              ...medicalData,
                              LifeStyle_History: {
                                ...medicalData.LifeStyle_History,
                                SocialHistory: {
                                  ...medicalData.LifeStyle_History
                                    .SocialHistory,
                                  smoking: true,
                                },
                              },
                            });
                          }}
                          customLabelStyles={{
                            colorFocused: "red",
                            colorBlurred: "black",
                            fontSizeFocused: 12,
                          }}
                        />
                        <Label>No</Label>
                        <Input
                          //returnKeyType={'next'}
                          type="checkbox"
                          checked={
                            !medicalData?.LifeStyle_History?.SocialHistory
                              ?.smoking
                          }
                          onChange={(event) => {
                            setMedicalData({
                              ...medicalData,
                              LifeStyle_History: {
                                ...medicalData.LifeStyle_History,
                                SocialHistory: {
                                  ...medicalData.LifeStyle_History
                                    .SocialHistory,
                                  smoking: false,
                                },
                              },
                            });
                          }}
                          customLabelStyles={{
                            colorFocused: "red",
                            colorBlurred: "black",
                            fontSizeFocused: 12,
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormText color="default" tag="span">
                        Select Your Stress Factor
                      </FormText>

                      <Select
                        // styles={{ fullWidth: "30px" }}
                        fullWidth
                        onChange={(event) => {
                          setMedicalData({
                            ...medicalData,
                            LifeStyle_History: {
                              ...medicalData.LifeStyle_History,
                              SocialHistory: {
                                ...medicalData.LifeStyle_History.SocialHistory,
                                stressfactor: event.target.value,
                              },
                            },
                          });
                        }}
                        value={
                          medicalData?.LifeStyle_History?.SocialHistory
                            ?.stressfactor
                        }
                      >
                        <MenuItem value="Relaxed">Relaxed</MenuItem>
                        <MenuItem value="Moderately Stressed">
                          Moderately Stressed
                        </MenuItem>
                        <MenuItem value="Vigorous">Vigorous</MenuItem>
                        <MenuItem value="Highly Stressed">
                          Highly Stressed
                        </MenuItem>
                      </Select>
                    </Col>
                  </Row>
                  <Row>
                    <Button variant="gradient" color="primary" onClick={OnSave}>
                      UPDATE
                    </Button>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default PatientSocial;
