import {
  FETCH_PRACTICE_TYPES,
  FETCH_PRACTICE_TYPES_SUCCESS,
  FETCH_PRACTICE_TYPES_FAILED,
  EDIT_PRACTICE_TYPE
} from "../store/types";
import { language } from 'config';

export const fetchPracticeTypes = () => (dispatch) => (firebase) => {

  const {
    practiceTypesRef
  } = firebase;

  dispatch({
    type: FETCH_PRACTICE_TYPES,
    payload: null
  });
  practiceTypesRef.on("value", snapshot => {
    if (snapshot.val()) {
      let data = snapshot.val();
      const arr = Object.keys(data).map(i => {
        data[i].id = i;
        return data[i]
      });
      dispatch({
        type: FETCH_PRACTICE_TYPES_SUCCESS,
        payload: arr
      });
    } else {
      dispatch({
        type: FETCH_PRACTICE_TYPES_FAILED,
        payload: language.no_PRACTICEs
      });
    }
  });
};

export const editPracticeType = (practicetypes, method) => (dispatch) => (firebase) => {
  const {
    practiceTypesRef, 
    practiceTypesEditRef
  } = firebase;
  dispatch({
    type: EDIT_PRACTICE_TYPE,
    payload: { method, practicetypes }
  });
  if (method === 'Add') {
    practiceTypesRef.push(practicetypes);
  } else if (method === 'Delete') {
    practiceTypesEditRef(practicetypes.id).remove();
  } else {
    practiceTypesEditRef(practicetypes.id).set(practicetypes);
  }
}
