/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import { Button, Typography } from "@material-ui/core";
import {
  Label,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Container,
  Button as Buttons,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from "../../components/AlertDialog";
import { language } from "config";
import { makeStyles } from "@material-ui/core/styles";
import { FirebaseContext } from "common";
import Grid from "@mui/material/Grid";
import GridItem from "components/Grid/GridItem";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import stripe from "../../assets/img/payment-icons/stripe-logo.png";
import payfast from "../../assets/img/payment-icons/payfast-logo.png";
import StripeCheckout from "react-stripe-checkout";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    zIndex: "12",
    color: "#000",
    alignContent: "center",
  },
  title: {
    color: "#000",
  },
  gridcontainer: {
    alignContent: "center",
  },
  items: {
    margin: 0,
    width: "100%",
  },
  input: {
    fontSize: 18,
    color: "#000",
  },
  inputdimmed: {
    fontSize: 18,
    color: "#737373",
  },
  carphoto: {
    height: "18px",
    marginRight: "10px",
  },
  buttonStyle: {
    margin: 0,
    width: "100%",
    height: "100%",
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function PaInvoice(props) {
  const { api } = useContext(FirebaseContext);
  const { addToWallet } = api;
  const dispatch = useDispatch();
  const classes = useStyles();
  const userdata = useSelector((state) => state.usersdata);
  const [users, setUsers] = useState(null);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [userCombo, setUserCombo] = useState(null);
  const [amount, setAmount] = useState(0);
  // const Booking = (props.location && props.location.state.Booking) || {};
const navigate = useNavigate();
  useEffect(() => {
    if (userdata.users) {
      let arr = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        arr.push({
          firstName: user.firstName,
          lastName: user.lastName,
          mobile: user.mobile,
          email: user.email,
          uid: user.id,
          desc:
            user.firstName +
            " " +
            user.lastName +
            " (" +
            user.mobile +
            ") " +
            user.email,
          pushToken: user.pushToken,
        });
      }
      setUsers(arr);
    }
    console.log("checking user info", userdata);
  }, [userdata.users]);

  const handleAddBalance = () => {
    if (userCombo && userCombo.uid && amount > 0) {
      dispatch(addToWallet(userCombo.uid, amount));
      setCommonAlert({ open: true, msg: language.success });
    } else {
      setCommonAlert({ open: true, msg: language.no_details_error });
    }
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  return (
    <div className={classes.container}>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>

      <Card>
        <CardBody>
          <Grid container justifyContent="space-between">
            <GridItem xs={6} sm={6} md={6} lg={6}>
              <Card>
                <CardBody>
                  <Typography display="block" variant="body2" color={"#000"}>
                    {/* {Booking.doctor.firstName} {Booking.doctor.lastName} -{" "}
                    {Booking.practiceType.name} */}
                  </Typography>

                  <Row>
                    <Col>
                      <Label>Practice Number</Label>
                    </Col>
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Address</Label>
                    </Col>
                    <Col></Col>
                  </Row>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={6} md={6} lg={6}>
              <Card width="100%" textAlign={{ xs: "left", md: "right" }} mt={6}>
                <CardBody>
                  <Typography display="block" variant="body2" color={"#000"}>
                    <Label>Mobile Number : </Label>
                    {/* {Booking.doctor.mobile} */}
                  </Typography>
                  <Typography display="block" variant="body2" color={"#000"}>
                    <Label>Email : </Label>
                    {/* {Booking.doctor.email} */}
                  </Typography>
                  <Typography
                    display="block"
                    variant="body2"
                    color={"secondary"}
                  >
                    <Label>VAT Reg no. : </Label>{" "}
                  </Typography>
                </CardBody>
              </Card>
            </GridItem>
          </Grid>

          <Grid container justifyContent="space-between">
            <GridItem xs={6} sm={6} md={6} lg={6}>
              <Card>
                <CardBody>
                  <Typography variant="h6" fontWeight="medium" color={"#000"}>
                    Billed to:
                    {/* {Booking.patientName} */}
                  </Typography>
                  <Typography display="block" variant="body2" color={"#000"}>
                    <Label>Mobile Number : </Label>
                    {/* {Booking.patientContact} */}
                  </Typography>
                  <Typography display="block" variant="body2" color={"#000"}>
                    <Label>Email : </Label>
                    {/* {Booking.patientEmail} */}
                  </Typography>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={6} md={6} lg={6}>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Label>Invoice Number: </Label>
                    </Col>
                    <Col>
                      <Label>{/* {Booking.id} */}</Label>
                    </Col>
                  </Row>
                  {/* <Row>
                  <Col>
                    <Label>Account Number: </Label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label>File Number: </Label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label>Date Printed: </Label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label>Medical Scheme: </Label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label>Option/Plan: </Label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label>Membership Number: </Label>
                  </Col>
                </Row> */}
                  <Row>
                    <Col>
                      <Label>Patient ID Number: </Label>
                    </Col>
                  </Row>
                  {/* <Row>
                  <Col>
                    <Label>Patient VAT Number: </Label>
                  </Col>
                </Row> */}
                  <Row>
                    <Col>
                      <Label>Home/ Work Number: </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Cell Number/Email:</Label>
                    </Col>
                    <Col>
                      <Label>
                        {" "}
                        {/* {Booking.patientContact} {Booking.patientEmail} */}
                      </Label>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </GridItem>
          </Grid>
        </CardBody>
      </Card>

      {/* Invoice table */}

      <Card>
        <CardHeader></CardHeader>
        <CardBody>
          <Container className="bg-light border">
            <Label>Doctor's Banking Details:</Label>
            <Row xs="5">
              <Col className="bg-light border">Banking Institution</Col>
              <Col className="bg-light border">Branch</Col>
              <Col className="bg-light border">Branch Code</Col>
              <Col className="bg-light border">Account Number</Col>
              <Col className="bg-light border">Account Name</Col>
            </Row>

            <Row xs="5">
              <Col className="bg-light border">{"XXXXXXX"}</Col>
              <Col className="bg-light border">{"XXXXXXX"}</Col>
              <Col className="bg-light border">{"XXXXXXX"}</Col>
              <Col className="bg-light border">{"XXXXXXX"}</Col>
              <Col className="bg-light border">{"XXXXXXX"}</Col>
            </Row>
            {/* ///////////////////////////////////////////////////////////////////// */}
            <Label>Account Detail Statement</Label>
            <Row xs="6">
              <Col className="bg-light border">Date</Col>
              <Col className="bg-light border">Patient</Col>
              <Col className="bg-light border">Ref</Col>
              <Col className="bg-light border">Description</Col>
              <Col className="bg-light border">Account Amount</Col>
              <Col className="bg-light border">Amount Founder</Col>
            </Row>
            <Row xs="6">
              <Col className="bg-light border">{"XXXXXXX"}</Col>
              <Col className="bg-light border">{"XXXXXXX"}</Col>
              <Col className="bg-light border">{"XXXXXXX"}</Col>
              <Col className="bg-light border">{"XXXXXXX"}</Col>
              <Col className="bg-light border">{"XXXXXXX"}</Col>
              <Col className="bg-light border">{"XXXXXXX"}r</Col>
            </Row>
            {/* ///////////////////////////////////////////////////// */}
            <Label>Statement Note</Label>
            <Row xs="1">
              <Col className="bg-light border">statement...</Col>
              {/* ///////////////////////////////////////////////////// */}
            </Row>
            <Label>Original Tax Invoice Details</Label>
            <Row xs="9">
              <Col className="bg-light border" xs="2">
                Invoice No.
              </Col>
              <Col className="bg-light border">Treament Date</Col>
              <Col className="bg-light border">Patient Name</Col>
              <Col className="bg-light border">ID No.</Col>
              <Col className="bg-light border">Dependent Type</Col>
              <Col className="bg-light border">Date of Birth</Col>
              <Col className="bg-light border">Gender</Col>
              <Col className="bg-light border">Claim Ref</Col>
            </Row>
            <Row xs="9">
              <Col className="bg-light border" xs="2">
                {"XXXXXXX"}
              </Col>
              <Col className="bg-light border">{"XXXXXXX"}</Col>
              <Col className="bg-light border">{"XXXXXXX"}</Col>
              <Col className="bg-light border">{"XXXXXXX"}.</Col>
              <Col className="bg-light border">{"XXXXXXX"}</Col>
              <Col className="bg-light border">{"XXXXXXX"}</Col>
              <Col className="bg-light border">{"XXXXXXX"}</Col>
              <Col className="bg-light border">{"XXXXXXX"}</Col>
            </Row>
            {/* ///////////////////////////////////////////////////// */}
            <Row xs="1">
              <Col className="bg-light border">
                Treating/Attending Doctor
                <Col className="bg-light border">
                  {/* {Booking.doctor.firstName} {Booking.doctor.lastName} -{" "}
                  {Booking.practiceType.name} */}
                </Col>
                <Col className="bg-light border">
                  <Row xs="2">
                    <Col className="bg-light border">PR Number</Col>
                    <Col className="bg-light border">{"XXXXXXX"}</Col>
                  </Row>
                </Col>
                <Col className="bg-light border">
                  <Row xs="2">
                    <Col className="bg-light border">HPCSA Number</Col>
                    <Col className="bg-light border">{"XXXXXXX"}</Col>
                  </Row>
                </Col>
              </Col>
            </Row>
            {/* ///////////////////////////////////////////////////// */}
            <Label>Age Analysis</Label>
            <Row xs="6">
              <Col className="bg-light border">Current</Col>
              <Col className="bg-light border">30 Days</Col>
              <Col className="bg-light border">60 Days</Col>
              <Col className="bg-light border">90 Days</Col>
              <Col className="bg-light border">120+ Days</Col>
              <Col className="bg-light border">Total</Col>
            </Row>
            <Row xs="6">
              <Col className="bg-light border">{"XXXXXXX"}</Col>
              <Col className="bg-light border">{"XXXXXXX"}</Col>
              <Col className="bg-light border">{"XXXXXXX"}</Col>
              <Col className="bg-light border">{"XXXXXXX"}</Col>
              <Col className="bg-light border">{"XXXXXXX"}</Col>
              <Col className="bg-light border">{"XXXXXXX"}</Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
      <Card p={3} mt={7}>
        <CardBody>
          <Row>
            <Label>Payment Type:</Label>
          </Row>

          <Row xs="3">
            <Col>
              {/* <Button
              variant="gradient"
              onClick={() => {
                navigate({
                  pathname: "/payfast",
                  // state: { Booking },
                });
              }}
            > */}
              <img src={payfast} />
              {/*          
            </Button> */}
            </Col>
            <Col>
              <img src={stripe} />
            </Col>
            <Col>
              {/* <Buttons
              variant="gradient"
              color="primary"
              size="lg"
              onClick={() => window.print(this)}
                >
              PRINT
            </Buttons> */}

              {/* </Button> */}
            </Col>
          </Row>

          <hr />
          <Typography variant="h5" fontWeight="medium" color={"#000"}>
            Thank you!
          </Typography>

          <Typography variant="button" color={"#000"}>
            If you encounter any issues related to the invoice you can contact
            us at:
          </Typography>

          <Typography variant="button" color={"#000"}>
            support@plum.systems
          </Typography>
        </CardBody>
      </Card>
    </div>
  );
}
