import { language } from "config";

import {
  FETCH_REFERRALDR_TYPES,
  FETCH_REFERRALDR_TYPES_SUCCESS,
  FETCH_REFERRALDR_TYPES_FAILED,
  EDIT_REFERRALDR_TYPE,
  UPDATE_REFERRALS_TYPE,
  UPDATE_REFERRALS_TYPE_SUCCESS,
  UPDATE_REFERRALS_TYPE_FAILED,
  DELETE_REFERRALS_TYPE,
  DELETE_REFERRALS_TYPE_SUCCESS,
  DELETE_REFERRALS_TYPE_FAILED,
} from "../store/types";

export const fetchReferralDrType = () => (dispatch) => (firebase) => {

  const {
    ReferralDoctorRef
  } = firebase;

  dispatch({
    type: FETCH_REFERRALDR_TYPES,
    payload: null
  });
  ReferralDoctorRef.on("value", snapshot => {
    if (snapshot.val()) {
      console.log("hi there i am the snapsot",snapshot.val())
      let data = snapshot.val();
      const arr = Object.keys(data)
      
      .map(i => {
        data[i].id = i;
        return data[i]
      });
      dispatch({
        type: FETCH_REFERRALDR_TYPES_SUCCESS,
        payload: arr
      });
    } else {
      dispatch({
        type: FETCH_REFERRALDR_TYPES_FAILED,
        payload: language.no_PRACTICEs
      });
    }
  });
};

export const editReferralDrType =
  (referraldoctors, method) => (dispatch) => (firebase) => {
    const { 
       ReferralDoctorRef,
       ReferralDoctorEditRef
       } = firebase;
    dispatch({
      type: EDIT_REFERRALDR_TYPE,
      payload: { method, referraldoctors },
    });
    if (method === "Add") {
      ReferralDoctorRef.push(referraldoctors);
    } else if (method === "Delete") {
      ReferralDoctorEditRef(referraldoctors.uid).remove();
    } else {
      ReferralDoctorEditRef(referraldoctors.uid,referraldoctors).set(referraldoctors);
    }
  };
  export const updateRefDoctor = (id, user) => (dispatch) => (firebase) => {
    const { ReferralDoctorEditRef } = firebase;

    dispatch({
      type: UPDATE_REFERRALS_TYPE,
      payload: user,
    });
    let editedUser = user;
    delete editedUser.id;
    ReferralDoctorEditRef(id)
      .set(editedUser)
      .then(() => {
        dispatch({
          type: UPDATE_REFERRALS_TYPE_SUCCESS,
          payload: null,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_REFERRALS_TYPE_FAILED,
          payload: error,
        });
      });
  };

export const deleteRefDoctor = (id) => (dispatch) => (firebase) => {
  const { ReferralDoctorEditRef } = firebase;

  dispatch({
    type: DELETE_REFERRALS_TYPE,
    payload: id,
  });

  ReferralDoctorEditRef(id)
    .remove()
    .then(() => {
      dispatch({
        type: DELETE_REFERRALS_TYPE_SUCCESS,
        payload: null,
      });
    })
    .catch((error) => {
      dispatch({
        type: DELETE_REFERRALS_TYPE_FAILED,
        payload: error,
      });
    });
};