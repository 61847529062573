import {
  FETCH_SPECIALITY_TYPES,
  FETCH_SPECIALITY_TYPES_SUCCESS,
  FETCH_SPECIALITY_TYPES_FAILED,
  EDIT_SPECIALITY_TYPE
} from "../store/types";

const INITIAL_STATE = {
  specialities:  {
    ADDICTION_PSYCHIATRY: "ADDICTION PSYCHIATRY",
    ANAESTHESIOLOGY: "ANAESTHESIOLOGY",
    CARDIOLOGY: "CARDIOLOGY",
    CARDIOLOGY_CLOSED: "CARDIOLOGY (CLOSED)",
    CARDIOTHORACIC_SURGERY: "CARDIOTHORACIC SURGERY",
    CLINICAL_PHARMACOLOGY: "CLINICAL PHARMACOLOGY",
    COMMUNITY_DENTISTRY: "COMMUNITY DENTISTRY",
    COMMUNITY_HEALTH: "COMMUNITY HEALTH",
    CONSULTATION_LIAISON_PSYCHIATRY: "CONSULTATION LIAISON PSYCHIATRY",
    DERMATOLOGY: "DERMATOLOGY",
    DIAGNOSTIC_RADIOLOGY: "DIAGNOSTIC RADIOLOGY",
    "EDUCATION_PATHOLOGY_MICROBIOLOGICAL)":
      "EDUCATION PATHOLOGY (MICROBIOLOGICAL)",
    EDUCATION_SURGERY: "EDUCATION SURGERY",
    EMERGENCY_MEDICINE: "EMERGENCY MEDICINE",
    FAMILY_MEDICINE: "FAMILY MEDICINE",
    FAST_TRACK_PAEDIATRICS: "FAST TRACK - PAEDIATRICS",
    GERIATRIC_PSYCHIATRY: "GERIATRIC PSYCHIATRY",
    HAEMATOLOGICAL_PATHOLOGY: "HAEMATOLOGICAL PATHOLOGY",
    MAXILLO_AND_ORAL_PATHOLOGY: "MAXILLO AND ORAL PATHOLOGY",
    MAXILL_FACIAL_AND_ORAL_SURGERY: "MAXILLO FACIAL AND ORAL SURGERY",
    "MAXILLO_FACIAL_AND_ORAL_SURGERY_DENTAL_CLOSED)":
      "MAXILLO FACIAL AND ORAL SURGERY (DENTAL) (CLOSED)",
    MEDICAL_GENETICS: "MEDICAL GENETICS",
    MEDICINE: "MEDICINE",
    NEUROLOGY: "NEUROLOGY",
    NEUROPSYCHIATRY: "NEUROPSYCHIATRY",
    NEUROSURGERY: "NEUROSURGERY",
    NON_EXAM: "NON EXAM",
    NUCLEAR_MEDICINE: "NUCLEAR MEDICINE",
    OBSTETRICS_AND_GYNAECOLOGY: "OBSTETRICS AND GYNAECOLOGY",
    OCCUPATIONAL_MEDICINE: "OCCUPATIONAL MEDICINE",
    OPHTHALMOLOGY: "OPHTHALMOLOGY",
    ORAL_MEDICINE_AND_PERIODONTICS: "ORAL MEDICINE AND PERIODONTICS",
    ORAL_PATHOLOGY: "ORAL PATHOLOGY",
    ORTHODONTICS: "ORTHODONTICS",
    ORTHOPAEDICS: "ORTHOPAEDICS",
    OTORHINOLARYNGOLOGY: "OTORHINOLARYNGOLOGY",
    PAEDIATRIC: "PAEDIATRIC",
    PAEDIATRIC_SURGERY: "PAEDIATRIC SURGERY",
    PAEDIATRICS: "PAEDIATRICS",
    PATHOLOGY: "PATHOLOGY",
    PATHOLOGY_ANATOMICAL: "PATHOLOGY (ANATOMICAL)",
    PATHOLOGY_CHEMICAL: "PATHOLOGY (CHEMICAL)",
    PATHOLOGY_CLINICAL: "PATHOLOGY (CLINICAL)",
    PATHOLOGY_FORENSIC: "PATHOLOGY (FORENSIC)",
    PATHOLOGY_HAEMATOLOGICAL: "PATHOLOGY (HAEMATOLOGICAL)",
    "PATHOLOGY_MEDICAL MICROBIOLOGY": "PATHOLOGY (MEDICAL MICROBIOLOGY)",
    PATHOLOGY_MICROBIOLOGICAL: "PATHOLOGY (MICROBIOLOGICAL)",
    PATHOLOGY_MICROBIOLOGY: "PATHOLOGY (MICROBIOLOGY)",
    PATHOLOGY_VIROLOGICAL: "PATHOLOGY (VIROLOGICAL)",
    PERIODONTICS: "PERIODONTICS",
    PERIODONTICS_CLOSED: "PERIODONTICS (CLOSED)",
    PHYSICAL_MEDICINE_CLOSED: "PHYSICAL MEDICINE (CLOSED)",
    PLASTIC_AND_MAXILLO_FACIAL_SURGERY: "PLASTIC AND MAXILLO-FACIAL SURGERY",
    PLASTIC_AND_RECONSTRUCTIVE_SURGERY: "PLASTIC AND RECONSTRUCTIVE SURGERY",
    PLASTIC_SURGERY: "PLASTIC SURGERY",
    PREVENTIVE_MEDICINE_CLOSED: "PREVENTIVE MEDICINE (CLOSED)",
    PROSTHODONTICS: "PROSTHODONTICS",
    PSYCHIATRY: "PSYCHIATRY",
    PUBLIC_HEALTH_MEDICINE: "PUBLIC HEALTH MEDICINE",
    RADIATION_ONCOLOGY: "RADIATION ONCOLOGY",
    RADIOLOGY: "RADIOLOGY",
    "RADIOLOGY_AND_ELECTRO-THERAPEUTICS":
      "RADIOLOGY AND ELECTRO-THERAPEUTICS",
    "RESTRICTED_TO_CARDIOTHORACIC SURGERY":
      "RESTRICTED_TO_CARDIOTHORACIC SURGERY",
    "RESTRICTED_TO_DIAGNOSTIC RADIOLOGY":
      "RESTRICTED_TO_DIAGNOSTIC RADIOLOGY",
    RESTRICTED_TO_NEUROLOGY: "RESTRICTED_TO_NEUROLOGY",
    RESTRICTED_TO_NEUROSURGERY: "RESTRICTED_TO_NEUROSURGERY",
    "RESTRICTED_TO_NUCLEAR MEDICINE": "RESTRICTED_TO_NUCLEAR MEDICINE",
    "RESTRICTED_TO_OBSTETRICS AND GYNAECOLOGY":
      "RESTRICTED_TO_OBSTETRICS AND GYNAECOLOGY",
    RESTRICTED_TO_OTORHINOLARYNGOLOGY: "RESTRICTED_TO_OTORHINOLARYNGOLOGY",
    RESTRICTED_TO_PATHOLOGY_ANATOMICAL:
      "RESTRICTED_TO_PATHOLOGY (ANATOMICAL)",
    RESTRICTED_TO_PATHOLOGY_CHEMICAL: "RESTRICTED_TO_PATHOLOGY (CHEMICAL)",
    RESTRICTED_TO_PATHOLOGY_CLINICAL: "RESTRICTED_TO_PATHOLOGY (CLINICAL)",
    RESTRICTED_TO_PATHOLOGY_FORENSIC: "RESTRICTED_TO_PATHOLOGY (FORENSIC)",
    RESTRICTED_TO_PATHOLOGY_HAEMATOLOGICAL:
      "RESTRICTED_TO_PATHOLOGY (HAEMATOLOGICAL)",
    RESTRICTED_TO_PATHOLOGY_MICROBIOLOGICAL:
      "RESTRICTED_TO_PATHOLOGY (MICROBIOLOGICAL)",
    RESTRICTED_TO_PLASTIC_AND_RECONSTRUCTIVE_SURGERY:
      "RESTRICTED_TO_PLASTIC AND RECONSTRUCTIVE SURGERY",
    RESTRICTED_TO_RADIATION_ONCOLOGY: "RESTRICTED_TO_RADIATION ONCOLOGY",
    RESTRICTED_TO_UROLOGY: "RESTRICTED_TO_UROLOGY",
    SPECIAL_MERIT: "SPECIAL MERIT",
    SPECIALIST_NEUROSURGERY: "SPECIALIST NEUROSURGERY",
    SPECIALIST_SURGERY: "SPECIALIST SURGERY",
    SPORTS_AND_EXERCISE_MEDICINE: "SPORTS AND EXERCISE MEDICINE",
    SURGERY: "SURGERY",
    THERAPEUTIC_RADIOLOGY_CLOSED: "THERAPEUTIC RADIOLOGY (CLOSED)",
    UROLOGY: "UROLOG",
    VENEROLOGY_CLOSED: "VENEROLOGY (CLOSED)",
  },
  loading: false,
  error: {
    flag: false,
    msg: null
  }
}

export const specialitytypesreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SPECIALITY_TYPES:
      return {
        ...state,
        loading: true
      };
    case FETCH_SPECIALITY_TYPES_SUCCESS:
      return {
        ...state,
        specialities: action.payload,
        loading: false
      };
    case FETCH_SPECIALITY_TYPES_FAILED:
      return {
        ...state,
        specialities: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload
        }
      };
    case EDIT_SPECIALITY_TYPE:
      return state;
    default:
      return state;
  }
};