/*eslint-disable*/
import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Input, Button, Label } from "reactstrap";
import GoogleMapsAutoComplete from "components/GoogleMapsAutoComplete";
const ProgressReport = () => {
  const [state, setstate] = useState({
    descName: "",
    address: "",
    carerDescName: "",
    signIn: false,
    signOut: false,
    progress: "",
    signature: "",
  });
  return (
    <div>
      <GridContainer spacing={4} style={{marginTop:30}}>
        <GridItem xs={1} sm={1} md={1} lg={1}>
          <Label>Patient Name and Surname </Label>
        </GridItem>
        <GridItem xs={5} sm={5} md={5} lg={5}>
          <Input
            fullWidth
            type="text"
            value={state.descName}
            onChange={(value) => {
              setstate({ ...state, descName: value });
            }}
          />
        </GridItem>
        <GridItem xs={1} sm={1} md={1} lg={1}>
          <Label>Patient Address </Label>
        </GridItem>
        <GridItem xs={5} sm={5} md={5} lg={5}>
          <GoogleMapsAutoComplete
            value={state.address}
            onChange={(value) => {
              setstate({ ...state, address: value });
            }}
            // className={styles.input}
            placeholder="Address"
          />

          {/* <Input type="text" value={state.address} /> */}
        </GridItem>
        <GridItem xs={1} sm={1} md={1} lg={1}>
          <Label>Carer Name and Surname </Label>
        </GridItem>
        <GridItem xs={11} sm={11} md={11} lg={11}>
          <Input
            type="text"
            value={state.carerDescName}
            onChange={(value) => {
              setstate({ ...state, carerDescName: value });
            }}
          />
        </GridItem>
        <GridItem xs={1} sm={1} md={1} lg={1}>
          <Label>Carer Date and time Sign In</Label>
        </GridItem>
        <GridItem xs={5} sm={5} md={5} lg={5}>
          <Input
            type="time"
            value={state.signIn}
            onChange={(value) => {
              setstate({ ...state, signIn: value });
            }}
          />
        </GridItem>
        <GridItem xs={1} sm={1} md={1} lg={1}>
          <Label>Carer Date and time Sign out</Label>
        </GridItem>
        <GridItem xs={5} sm={5} md={5} lg={5}>
          <Input
            type="time"
            value={state.signOut}
            onChange={(value) => {
              setstate({ ...state, signOut: value });
            }}
          />
        </GridItem>
        <GridItem xs={1} sm={1} md={1} lg={1}>
          <Label>Progress</Label>
        </GridItem>
        <GridItem xs={5} sm={5} md={5} lg={5}>
          <Input
            type="text"
            value={state.progress}
            onChange={(value) => {
              setstate({ ...state, progress: value });
            }}
          />
        </GridItem>
        <GridItem xs={1} sm={1} md={1} lg={1}>
          <Label>Signature</Label>
        </GridItem>
        <GridItem xs={5} sm={5} md={5} lg={5}>
          <Input
            type="text"
            value={state.signature}
            onChange={(value) => {
              setstate({ ...state, signature: value });
            }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ProgressReport;
