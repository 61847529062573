/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../../components/CircularLoading";
import { features, dateStyle, language } from "config";
import { FirebaseContext } from "common";
import AlertDialog from "../../components/AlertDialog";
import { useNavigate, useLocation } from "react-router-dom";

export default function Notes({ Booking }) {
  //Actions Declare
  const { api } = useContext(FirebaseContext);
  const {
    editUser,
    deleteUser,
    clearLoginError,
    updateBooking,
  } = api;
  ///





  //setting hookes
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  ///


  //Reducers
  const auth = useSelector((state) => state.auth);
  const usersdata = useSelector((state) => state.usersdata);
  ///


  //States
  const booking = location.state.Booking
  const [notes, setNotes] = useState([]);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  //

  //Table Fields
  const columns = [
    { title: language.personid, field: "person_id", initialEditValue: booking.patient },
    {
      title: language.note_text,
      field: "note_text",
      initialEditValue: "",
    },
    {
      title: language.note_date,
      field: "note_date",
      initialEditValue: new Date(),
      type: "date"
    },
    {
      title: language.note_datetime,
      field: "note_datetime",
      initialEditValue: new Date(),
      type: "time"
    }
  ];
  ///

  //UseEffects
  useEffect(() => {
    if (booking) {
      setNotes(Object.values(booking.medicalNote));
    } else {
      setNotes([]);
    }
  }, [booking]);

  useEffect(() => {
    console.log('notes', notes)
  }, [notes]);

  ///




  //Functions
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
    if (auth.error.flag) {
      dispatch(clearLoginError());
    }
  }
  ///


  //Render View
  return usersdata.loading ? (
    <CircularLoading />
  ) : (
    ((
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    ),
      (
        <MaterialTable
          title={"Medical Notes"}
          columns={columns}
          data={notes}
          options={{
            exportButton: true,
            sorting: true,
            actionsColumnIndex: -1,
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                resolve();
                setTimeout(() => {
                  let tempArr = notes
                  tempArr.push(newData)
                  setNotes([...tempArr])
                  dispatch(
                    updateBooking({ ...booking, medicalNote: tempArr })
                  );
                }, 800);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  let tempArr = notes
                  const indexOf = notes.indexOf(oldData)

                  console.log('newData', newData)

                  if (indexOf > -1) {
                    tempArr[indexOf] = newData
                    setNotes([...tempArr])
                    dispatch(
                      updateBooking({ ...booking, medicalNote: tempArr })
                    );
                    resolve()
                  }
                  else {
                    setCommonAlert({
                      open: true,
                      msg: "No note found cant update"
                    })
                    resolve()
                  }

                  dispatch(editUser(oldData.id, newData));
                }, 600);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  let tempArr = notes
                  const indexOf = notes.indexOf(oldData)
                  if (indexOf > -1) {
                    tempArr.splice(indexOf, 1)
                    setNotes([...tempArr])
                    dispatch(
                      updateBooking({ ...booking, medicalNote: tempArr })
                    );
                    resolve()
                  }
                  else {
                    setCommonAlert({
                      open: true,
                      msg: "No note found cant delete"
                    })
                    resolve()
                  }
                }, 600);
              })

          }}
        />

      ))
  );
}
