import "firebase/auth";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/database";
import "firebase/storage";
import React, { createContext } from "react";
import app from "firebase/compat/app";
import { FirebaseConfig } from "config";
import { updateUserConsentform } from "./actions/consentformuploadaction";
import { addDeviceExposure, deleteDeviceExposure, editDeviceExposure, fetchDeviceExposure } from "./actions/deviceexposureactions.js";
import { fetchDoctorEarnings } from "./actions/doctorearningaction";
import { editDoctorType, fetchDoctorTypes } from "./actions/doctortypesaction";
import { addDrugExposure, deleteDrugExposure, editDrugExposure, fetchDrugExposure, fetchICD, fetchOtc, fetchSpl } from "./actions/drugexposureactions.js";
import { fetchEarningsReport } from "./actions/earningreportsaction";
import { clearEstimate, getEstimate } from "./actions/estimateactions";
import { fetchCodes } from "./actions/icdcodesactions.js";
import { addMedNotes, deleteMedNotes, editMedNotes, fetchMedNotes } from "./actions/mednotesactions.js";
import { AddPaymentMethod } from "./actions/paymentactions";
import { editPromo, fetchPromos } from "./actions/promoactions";
import { acceptTask, cancelTask, fetchTasks } from "./actions/taskactions";
import { completeWithdraw, fetchWithdraws } from "./actions/withdrawactions";
import { GetDateString, MinutesPassed } from "./other/DateFunctions";
import { GetDistance, GetEventDistance } from "./other/GeoFunctions";
import { store } from "./store/store";

import {
  fetchUser,
  emailSignUp,
  mobileSignIn,
  signIn,
  facebookSignIn,
  appleSignIn,
  signOut,
  updateProfile,
  clearLoginError,
  sendResetMail,
  updatePushToken,
  updateProfileImage,
  updateWebProfileImage,
  requestPhoneOtpDevice,
  deleteUser,
  validateReferer,
  checkUserExists,
  monitorProfileChanges,
} from "./actions/authactions";
import {
  editMedicalHistory,
  fetchMedicalHistory,
} from "./actions/medicalhistoryactions";



import {
  addBooking,
  clearBooking,
  addRefBooking,
} from "./actions/bookingactions";
import {
  fetchBookings,
  updateBooking,
  cancelBooking,
} from "./actions/bookinglistactions";
import {
  fetchOffers,
  addOffers,
  updateOffers,
} from "./actions/bookingoffersactions";
import {
  fetchCancelReasons,
  editCancellationReason,
} from "./actions/cancelreasonactions";
import {
  fetchPracticeTypes,
  editPracticeType,
} from "./actions/practicetypeactions";
import {
  fetchReferralDrType,
  editReferralDrType,
  updateRefDoctor,
  deleteRefDoctor,
} from "./actions/referraldoctorsactions";
// import {
//   fetchReferralsTypes,
//   editReferralsTypes,
// } from "./actions/referralactions";
import {
  fetchSpecialityTypes,
  editSpecialityType,
} from "./actions/specialitytypesaction";
// import { CreateMeetingLink, DeleteMeetingLink } from "./actions/meetingaction";

import {
  fetchNotifications,
  editNotifications,
  sendNotification,
} from "./actions/notificationactions";
import {
  addUser,
  fetchUsers,
  fetchDoctors,
  editUser,
} from "./actions/usersactions";
import {
  fetchSettings,
  editSettings,
  clearSettingsViewError,
} from "./actions/settingsactions";
import {
  createMeetingRoom,
  deleteMeetingRoom,
} from "./actions/meetingroomactions";
import {
  createOpenfireUser,
  // createDoctorRoom,
} from "./actions/openfireuseractions";
import {
  fetchPaymentMethods,
  addToWallet,
  updateWalletBalance,
  clearMessage,
} from "./actions/paymentactions";
import {
  updateEventPickup,
  updateEventDrop,
  updateEventPractice,
  updatSelPointType,
  clearEventPoints,
} from "./actions/eventactions";
import {
  fetchBookingLocations,
  stopLocationFetch,
} from "./actions/locationactions";
import {
  fetchChatMessages,
  sendMessage,
  stopFetchMessages,
  // SavePictureMessage,
} from "./actions/chatactions";

import {
  fetchDoctorNotes,
  addDoctorsNotes,
  stopFetchDoctorNotes,
} from "./actions/doctornotesactions";

import {
  fetchCoordsfromPlace,
  fetchAddressfromCoords,
  getDriveTime,
  getRouteDetails,
} from "./other/GoogleAPIFunctions";

const FirebaseContext = createContext(null);

const FirebaseProvider = ({ children }) => {
  let firebase = {
    app: null,
    database: null,
    auth: null,
    storage: null,
  };

  if (!app.apps.length) {
    app.initializeApp(FirebaseConfig);
    console.log("hallo checking for app being initialized", app);
    firebase = {
      app: app,
      database: app.database(),
      auth: app.auth(),
      storage: app.storage(),
      authRef: app.auth(),
      facebookProvider: new app.auth.FacebookAuthProvider(),
      googleProvider: new app.auth.GoogleAuthProvider(),
      appleProvider: new app.auth.OAuthProvider("apple.com"),
      phoneProvider: new app.auth.PhoneAuthProvider(),
      RecaptchaVerifier: app.auth.RecaptchaVerifier,
      captchaGenerator: (element) => new app.auth.RecaptchaVerifier(element),
      facebookCredential: (token) =>
        app.auth.FacebookAuthProvider.credential(token),
      googleCredential: (idToken) =>
        app.auth.GoogleAuthProvider.credential(idToken),
      mobileAuthCredential: (verificationId, code) =>
        app.auth.PhoneAuthProvider.credential(verificationId, code),
      usersRef: app.database().ref("users"),
      codesRef: app.database().ref("icd10codes"),
      bookingRef: app.database().ref("bookings"),
      bookingListRef: (uid, role) =>
        role === "patient"
          ? app.database().ref("bookings").orderByChild("patient").equalTo(uid)
          : role === "doctor"
          ? app.database().ref("bookings")
          : role === "nurse"
          ? app.database().ref("bookings")
          : role === "practiceadmin"
          ? app.database().ref("bookings")
          : app.database().ref("bookings"),

      FeesStructureRef: (PracticeNumbers) =>
        app.database().ref(`
      practicetypes/${PracticeNumbers}`),
      DrReferralRef: (referralId) =>
        app.database().ref(`
      DrReferral/${referralId}`),
      DrSpecialityDetailsRef: (bookinguid) =>
        app.database().ref(`
      Booking/${bookinguid}/DrSpecialityDetails`),

      ReferralDoctorRef: app.database().ref(`drsreferrals`),

      ReferralDoctorEditRef: (Doctoruid) =>
        app.database().ref(`drsreferrals/${Doctoruid}`),

      DrSpecialityDetailsRef: (bookinguid) =>
        app.database().ref(`
      Booking/${bookinguid}/DrSpecialityDetails`),

      cancelreasonRef: app.database().ref("cancel_reason"),

      settingsRef: app.database().ref("settings"),

      practiceTypesRef: app.database().ref("practicetypes"),
      practiceTypesEditRef: (id) => app.database().ref("practicetypes/" + id),

      specialityTypesRef: app.database().ref("specialitytypes"),
      specialityTypesEditRef: (id) =>
        app.database().ref("specialitytypes/" + id),
      doctorTypesRef: app.database().ref("doctortypes"),
      doctorTypesEditRef: (id) => app.database().ref("doctortypes/" + id),
      consultationTypeRef: (id) => app.database.ref(`consultationType`),
      consultationTypeEditRef: (id) =>
        app.database.ref(`consultationType/${id}`),
      ////////////////////////////////////////////////////////////////
      offersRef: (bookingid) =>
        app.database().ref(`bookings/${bookingid}/BookingOffers/`),
      offersEditRef: (bookingid, id) =>
        app.database().ref(`bookings/${bookingid}/BookingOffers/${id}`),
      ////////////////////////////////////////////////////////////////////////////////////////
      medicalHistoryRef: app.database().ref("medicalrecords"),
      medicalHistoryEditRef: (id) => app.database().ref("medicalrecords/" + id),
      ////////////////////////////////////////////////////////////////////////////////////////
      clinicalDiseaseRef: (uid) =>
        app.storage().ref(`users/${uid}/medicalrecords/clinicalDisablities`),
      ////////////////////////////////////////////////////////////////////////////////////////
      ChatImageRef: (Chatuid) =>
        app.storage().ref(`Images/ChatImage/${Chatuid}`),
      promoRef: app.database().ref("promos"),
      promoEditRef: (id) => app.database().ref("promos/" + id),
      notifyRef: app.database().ref("notifications/"),
      notifyEditRef: (id) => app.database().ref("notifications/" + id),
      singleUserRef: (uid) => app.database().ref("users/" + uid),
      PaymentMethodRef: (uid) =>
        app.database().ref("users/" + uid + "/paymentmethod"),
      profileImageRef: (uid) => app.storage().ref(`users/${uid}/profileImage`),
      groupDocsRef: (timestamp) =>
        app.storage().ref(`users/groupDocuments/${timestamp}/`),
      doctorDocsRef: (timestamp) =>
        app.storage().ref(`users/doctorsDocuments/${timestamp}/`),
      patientDocsRef: (uid, timestamp) =>
        app.storage().ref(`users/${uid}/patientDocuments/${timestamp}/`),
      nurseDocsRef: (uid, timestamp) =>
        app.storage().ref(`users/${uid}/nurseDocuments/${timestamp}/`),

      ConsentFormRef: (uid) => app.storage().ref(`users/${uid}/ConsentForm`),

      singleBookingRef: (bookingKey) =>
        app.database().ref("bookings/" + bookingKey),

      requestedDoctorsRef: (bookingKey) =>
        app.database().ref("bookings/" + bookingKey + "/requestedDoctors"),
      walletBalRef: (uid) =>
        app.database().ref("users/" + uid + "/walletBalance"),
      walletHistoryRef: (uid) =>
        app.database().ref("users/" + uid + "/walletHistory"),
      referralIdRef: (referralId) =>
        app
          .database()
          .ref("users")
          .orderByChild("referralId")
          .equalTo(referralId),
      trackingRef: (bookingId) => app.database().ref("tracking/" + bookingId),
      tasksRef: () =>
        app.database().ref("bookings").orderByChild("status").equalTo("NEW"),
      singleTaskRef: (uid, bookingId) =>
        app
          .database()
          .ref("bookings/" + bookingId + "/requestedDoctors/" + uid),

      openfireuserRef: (uid, openfireuser) =>
        app.database().ref(uid + "/openfire/" + openfireuser),
      meetingroomRef: (uid, meetingroom) =>
        app.database().ref(uid + "/openfire/" + meetingroom),
      chatRef: (bookingId) =>
        app.database().ref("chats/" + bookingId + "/messages"),

      doctornotesRef: (bookingId) =>
        app.database().ref("doctornotes/" + bookingId + "/doctorsnotes"),

      withdrawRef: app.database().ref("withdraws/"),

      api: {
        MinutesPassed: MinutesPassed,
        GetDateString: GetDateString,

        fetchCoordsfromPlace: fetchCoordsfromPlace,
        fetchAddressfromCoords: fetchAddressfromCoords,
        getDriveTime: getDriveTime,
        getRouteDetails: getRouteDetails,

        GetDistance: GetDistance,
        GetEventDistance: GetEventDistance,
        saveUserLocation: (uid, location) =>
          app
            .database()
            .ref("users/" + uid + "/location")
            .set(location),
        saveTracking: (bookingId, location) =>
          app
            .database()
            .ref("tracking/" + bookingId)
            .push(location),

        validateReferer: validateReferer,
        checkUserExists: checkUserExists,
        fetchUser: () => (dispatch) => fetchUser()(dispatch)(firebase),
        fetchCodes: () => (dispatch) => fetchCodes()(dispatch)(firebase),
        emailSignUp: (data) => emailSignUp(data)(firebase),
        mobileSignIn: (verficationId, code) => (dispatch) =>
          mobileSignIn(verficationId, code)(dispatch)(firebase),
        AddPaymentMethod: (PaymentMethodDetails) => (dispatch) =>
          AddPaymentMethod(PaymentMethodDetails)(dispatch)(firebase),
        signIn: (email, password) => (dispatch) =>
          signIn(email, password)(dispatch)(firebase),
        facebookSignIn: (token) => (dispatch) =>
          facebookSignIn(token)(dispatch)(firebase),
        appleSignIn: (credentialData) => (dispatch) =>
          appleSignIn(credentialData)(dispatch)(firebase),
        signOut: () => (dispatch) => signOut()(dispatch)(firebase),
        updateProfile: (userAuthData, updateData) => (dispatch) =>
          updateProfile(userAuthData, updateData)(dispatch)(firebase),
        monitorProfileChanges: () => (dispatch) =>
          monitorProfileChanges()(dispatch)(firebase),
        clearLoginError: () => (dispatch) =>
          clearLoginError()(dispatch)(firebase),

        addBooking: (bookingData) => (dispatch) =>
          addBooking(bookingData)(dispatch)(firebase),
        addRefBooking: (bookingData) => (dispatch) =>
          addRefBooking(bookingData)(dispatch)(firebase),

        clearBooking: () => (dispatch) => clearBooking()(dispatch)(firebase),
        fetchBookings: (uid, role) => (dispatch) =>
          fetchBookings(uid, role)(dispatch)(firebase),
        updateBooking: (booking) => (dispatch) =>
          updateBooking(booking)(dispatch)(firebase),
        cancelBooking: (data) => (dispatch) =>
          cancelBooking(data)(dispatch)(firebase),
        // booking offers
        addOffers: (bookingoffersdata, method) => (dispatch) =>
          addOffers(bookingoffersdata, method)(dispatch)(firebase),
        updateOffers: (bookingoffersdata, method) => (dispatch) =>
          updateOffers(bookingoffersdata, method)(dispatch)(firebase),
        fetchOffers: () => (dispatch) => fetchOffers()(dispatch)(firebase),
        //
        fetchCancelReasons: () => (dispatch) =>
          fetchCancelReasons()(dispatch)(firebase),
        editCancellationReason: (reasons, method) => (dispatch) =>
          editCancellationReason(reasons, method)(dispatch)(firebase),

        editMedicalHistory: (data, method, blob, metadata) => (dispatch) =>
          editMedicalHistory(data, method, blob, metadata)(dispatch)(firebase),
        fetchMedicalHistory: () => (dispatch) =>
          fetchMedicalHistory()(dispatch)(firebase),

        fetchReferralDrType: () => (dispatch) =>
          fetchReferralDrType()(dispatch)(firebase),
        editReferralDrType: (data, method) => (dispatch) =>
          editReferralDrType(data, method)(dispatch)(firebase),
        updateRefDoctor: (data, method) => (dispatch) =>
          updateRefDoctor(data, method)(dispatch)(firebase),
        deleteRefDoctor: (id) => (dispatch) =>
          deleteRefDoctor(id)(dispatch)(firebase),
        // updateRefDoctor
        fetchPracticeTypes: () => (dispatch) =>
          fetchPracticeTypes()(dispatch)(firebase),
        editPracticeType: (data, method) => (dispatch) =>
          editPracticeType(data, method)(dispatch)(firebase),
        fetchSpecialityTypes: () => (dispatch) =>
          fetchSpecialityTypes()(dispatch)(firebase),
        editSpecialityType: (data, method) => (dispatch) =>
          editSpecialityType(data, method)(dispatch)(firebase),
        fetchDoctorTypes: () => (dispatch) =>
          fetchDoctorTypes()(dispatch)(firebase),
        editDoctorType: (data, method) => (dispatch) =>
          editDoctorType(data, method)(dispatch)(firebase),
        // CreateMeetingLink: (roomName, naturalName, description) => (dispatch) =>
        //   CreateMeetingLink(roomName, naturalName, description)(dispatch)(
        //     firebase
        //   ),
        // DeleteMeetingLink: (roomName, naturalName, uid) => (dispatch) =>
        //   DeleteMeetingLink(roomName, naturalName, uid)(dispatch)(firebase),
        getEstimate: (bookingData) => (dispatch) =>
          getEstimate(bookingData)(dispatch)(firebase),
        clearEstimate: () => (dispatch) => clearEstimate()(dispatch)(firebase),
        fetchSettings: () => (dispatch) => fetchSettings()(dispatch)(firebase),
        editSettings: (settings) => (dispatch) =>
          editSettings(settings)(dispatch)(firebase),
        clearSettingsViewError: () => (dispatch) =>
          clearSettingsViewError()(dispatch)(firebase),
        sendResetMail: (email) => (dispatch) =>
          sendResetMail(email)(dispatch)(firebase),
        fetchDoctorEarnings: (uid, role) => (dispatch) =>
          fetchDoctorEarnings(uid, role)(dispatch)(firebase),
        fetchEarningsReport: () => (dispatch) =>
          fetchEarningsReport()(dispatch)(firebase),
        fetchNotifications: () => (dispatch) =>
          fetchNotifications()(dispatch)(firebase),
        editNotifications: (notifications, method) => (dispatch) =>
          editNotifications(notifications, method)(dispatch)(firebase),
        sendNotification: (notification) => (dispatch) =>
          sendNotification(notification)(dispatch)(firebase),
        fetchPromos: () => (dispatch) => fetchPromos()(dispatch)(firebase),
        editPromo: (data, method) => (dispatch) =>
          editPromo(data, method)(dispatch)(firebase),
        fetchUsers: () => (dispatch) => fetchUsers()(dispatch)(firebase),
        fetchDoctors: () => (dispatch) => fetchDoctors()(dispatch)(firebase),
        updateUserConsentform: (userid, file) => (dispatch) =>
          updateUserConsentform(userid, file)(dispatch)(firebase),

        addUser: (userdata) => (dispatch) =>
          addUser(userdata)(dispatch)(firebase),
        editUser: (id, user) => (dispatch) =>
          editUser(id, user)(dispatch)(firebase),
        updatePushToken: (userAuthData, token, platform) => (dispatch) =>
          updatePushToken(userAuthData, token, platform)(dispatch)(firebase),
        updateProfileImage: (userAuthData, imageBlob) => (dispatch) =>
          updateProfileImage(userAuthData, imageBlob)(dispatch)(firebase),
        updateWebProfileImage: (userAuthData, imageFile) => (dispatch) =>
          updateWebProfileImage(userAuthData, imageFile)(dispatch)(firebase),
        requestPhoneOtpDevice: (phoneNumber, appVerifier) => (dispatch) =>
          requestPhoneOtpDevice(phoneNumber, appVerifier)(dispatch)(firebase),
        deleteUser: (uid) => (dispatch) => deleteUser(uid)(dispatch)(firebase),
        fetchPaymentMethods: () => (dispatch) =>
          fetchPaymentMethods()(dispatch)(firebase),
        addToWallet: (uid, amount) => (dispatch) =>
          addToWallet(uid, amount)(dispatch)(firebase),
        updateWalletBalance: (balance, details) => (dispatch) =>
          updateWalletBalance(balance, details)(dispatch)(firebase),
        clearMessage: () => (dispatch) => clearMessage()(dispatch)(firebase),
        updateEventPickup: (pickupAddress) => (dispatch) =>
          updateEventPickup(pickupAddress)(dispatch)(firebase),
        updateEventDrop: (dropAddress) => (dispatch) =>
          updateEventDrop(dropAddress)(dispatch)(firebase),
        updateEventPractice: (selectedPractice) => (dispatch) =>
          updateEventPractice(selectedPractice)(dispatch)(firebase),
        updatSelPointType: (selection) => (dispatch) =>
          updatSelPointType(selection)(dispatch)(firebase),
        clearEventPoints: () => (dispatch) =>
          clearEventPoints()(dispatch)(firebase),
        fetchTasks: () => (dispatch) => fetchTasks()(dispatch)(firebase),
        acceptTask: (userAuthData, task) => (dispatch) =>
          acceptTask(userAuthData, task)(dispatch)(firebase),
        cancelTask: (bookingId) => (dispatch) =>
          cancelTask(bookingId)(dispatch)(firebase),
        fetchBookingLocations: (bookingId) => (dispatch) =>
          fetchBookingLocations(bookingId)(dispatch)(firebase),
        stopLocationFetch: (bookingId) => (dispatch) =>
          stopLocationFetch(bookingId)(dispatch)(firebase),

        fetchChatMessages: (bookingId) => (dispatch) =>
          fetchChatMessages(bookingId)(dispatch)(firebase),
        sendMessage: (data) => (dispatch) =>
          sendMessage(data)(dispatch)(firebase),
        // SavePictureMessage: (userAuthData, imageBlob) => (dispatch) =>
        //   SavePictureMessage(userAuthData, imageBlob)(dispatch)(firebase),
        stopFetchMessages: (bookingId) => (dispatch) =>
          stopFetchMessages(bookingId)(dispatch)(firebase),

        fetchDoctorNotes: (bookingId) => (dispatch) =>
          fetchDoctorNotes(bookingId)(dispatch)(firebase),
        addDoctorsNotes: (data) => (dispatch) =>
          addDoctorsNotes(data)(dispatch)(firebase),
        stopFetchDoctorNotes: (bookingId) => (dispatch) =>
          stopFetchDoctorNotes(bookingId)(dispatch)(firebase),

        createMeetingRoom: (firstName, lastName) => (dispatch) =>
          createMeetingRoom(firstName, lastName)(dispatch)(firebase),

        createOpenfireUser:
          (username, password, firstName, lastName, email) => (dispatch) =>
            createOpenfireUser(
              username,
              password,
              firstName,
              lastName,
              email
            )(dispatch)(firebase),

        deleteMeetingRoom: (firstName) => (dispatch) =>
          deleteMeetingRoom(firstName)(dispatch)(firebase),

        fetchWithdraws: () => (dispatch) =>
          fetchWithdraws()(dispatch)(firebase),
        completeWithdraw: (entry) => (dispatch) =>
          completeWithdraw(entry)(dispatch)(firebase),

        // device exposure
        fetchDeviceExposure: () => (dispatch) =>
          fetchDeviceExposure()(dispatch)(firebase),
        addDeviceExposure: (deviceData) => (dispatch) =>
          addDeviceExposure(deviceData)(dispatch)(firebase),
        editDeviceExposure: (id, deviceData) => (dispatch) =>
          editDeviceExposure(id, deviceData)(dispatch)(firebase),
        deleteDeviceExposure: (id) => (dispatch) =>
          deleteDeviceExposure(id)(dispatch)(firebase),
        // drug exposure
        fetchDrugExposure: () => (dispatch) =>
          fetchDrugExposure()(dispatch)(firebase),
        addDrugExposure: (drugData) => (dispatch) =>
          addDrugExposure(drugData)(dispatch)(firebase),
        editDrugExposure: (id, drugData) => (dispatch) =>
          editDrugExposure(id, drugData)(dispatch)(firebase),
        deleteDrugExposure: (id) => (dispatch) =>
          deleteDrugExposure(id)(dispatch)(firebase),

        fetchICD: () => (dispatch) => fetchICD()(dispatch)(firebase),
        fetchSpl: () => (dispatch) => fetchSpl()(dispatch)(firebase),
        fetchOtc: (otcdata) => (dispatch) =>
          fetchOtc(otcdata)(dispatch)(firebase),
        // medical notes
        fetchMedNotes: () => (dispatch) => fetchMedNotes()(dispatch)(firebase),
        addMedNotes: (medNotes) => (dispatch) =>
          addMedNotes(medNotes)(dispatch)(firebase),
        editMedNotes: (id, medNotes) => (dispatch) =>
          editMedNotes(id, medNotes)(dispatch)(firebase),
        deleteMedNotes: (id) => (dispatch) =>
          deleteMedNotes(id)(dispatch)(firebase),
      },
    };
  }

  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  );
};

export { FirebaseContext, FirebaseProvider, store };
