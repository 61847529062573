/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../../components/CircularLoading";
import { features, dateStyle, language } from "config";
import { FirebaseContext } from "common";
import AlertDialog from "../../components/AlertDialog";
import { useLocation, useNavigate } from "react-router-dom";



export default function Procedures({ Booking }) {

  const { api } = useContext(FirebaseContext);
  const {
    addUser,
    editUser,
    deleteUser,
    checkUserExists,
    emailSignUp,
    clearLoginError,
    updateProfile,
    updateBooking,
  } = api;
 
  const location = useLocation();
    const booking = location.state.Booking
  const [updatebooking, setupdatebooking] = useState(Booking);
  const [specialityTypes, setSpecialityTypes] = useState({});
  const auth = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const usersdata = useSelector((state) => state.usersdata);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });

  useEffect(() => {
    if (booking) {
      setData(booking.procedures);
    } else {
      setData([]);
    }
  }, [booking]);

  const columns = [
    { title: language.first_name, field: "firstName", initialEditValue: "" },
    { title: language.last_name, field: "lastName", initialEditValue: "" },
    // { title: "Private Practice Number", field: "privatePracticeNumber", initialEditValue: "" },
    { title: "Practice Name", field: "practiceName", initialEditValue: "" },
   
    {
      title: language.email,
      field: "email",
      editable: "onAdd",
      render: (rowData) =>
        features.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo",
    },
    { title: "password", field: "password", initialEditValue: "" },
    {
      title: language.mobile,
      field: "mobile",
      editable: "onAdd",
      render: (rowData) =>
        features.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo",
    },
    {
      title: "Private Practice",
      field: "privatePractice",
      type: "boolean",
      initialEditValue: true,
    },
    {
      title: language.account_approve,
      field: "practiceAdminActiveStatus",
      type: "boolean",
      initialEditValue: true,
    },
  ];
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
    if (auth.error.flag) {
      dispatch(clearLoginError());
    }
  };

  return usersdata.loading ? (
    <CircularLoading />
  ) : (
    ((
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    ),
    (
      <MaterialTable
        title={"Procedures"}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          sorting: true,
          actionsColumnIndex: -1,
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              resolve();
              setTimeout(() => {
                let tempArr = data;
                tempArr.push(newData);
                setNotes([...tempArr]);
                dispatch(updateBooking({ ...booking, procedures: tempArr }));
              }, 800);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                let tempArr = data;
                const indexOf = data.indexOf(oldData);

                console.log("newData", newData);

                if (indexOf > -1) {
                  tempArr[indexOf] = newData;
                  setNotes([...tempArr]);
                  dispatch(updateBooking({ ...booking, procedures: tempArr }));
                  resolve();
                } else {
                  setCommonAlert({
                    open: true,
                    msg: "No note found cant update",
                  });
                  resolve();
                }

                dispatch(editUser(oldData.id, newData));
              }, 600);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                let tempArr = data;
                const indexOf = data.indexOf(oldData);
                if (indexOf > -1) {
                  tempArr.splice(indexOf, 1);
                  setNotes([...tempArr]);
                  dispatch(updateBooking({ ...booking, procedures: tempArr }));
                  resolve();
                } else {
                  setCommonAlert({
                    open: true,
                    msg: "No note found cant delete",
                  });
                  resolve();
                }
              }, 600);
            }),
        }}
      />
    ))
  );
}
