/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../../components/CircularLoading";
import { features, dateStyle, language } from "config";
import { FirebaseContext } from "common";
import AlertDialog from "../../components/AlertDialog";
import { useLocation, useNavigate } from "react-router-dom";



export default function Prescriptions({ Booking }) {

  const { api } = useContext(FirebaseContext);
  const {
    addUser,
    editUser,
    deleteUser,
    checkUserExists,
    emailSignUp,
    clearLoginError,
    updateProfile,
    updateBooking,
  } = api;
  const [state, setState] = useState({
    userType: "practiceadmin",
    firstName: "",
    lastName: "",
    referralId: "",
    practiceNumber: "",
    practiceName: "",
    practiceLicenseNumber: "",
    specialityType: "Default",
    bankAccount: "",
    bankCode: "",
    bankName: "",
    bankType: "",
    licenseImage: null,
    doctorImage: null,
    nurseImage: null,
    other_info: "",
    password: "",
    email: "",
  });
  const [isdepdata, setIsdepData] = useState({
    isIndependent: false,
  });

  const specialities = useSelector(
    (state) => state.specialitytypes.specialities
  );
  const location = useLocation();
  const [details, setDetails] = useState(location.state.Booking);
   const [updatebooking, setupdatebooking] = useState(Booking);
  const [specialityTypes, setSpecialityTypes] = useState({});
  const auth = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const usersdata = useSelector((state) => state.usersdata);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  useEffect(() => {
    // const arr = [];
    // console.log("auth.info.profile", auth.info.profile);
    // if (updatebooking) {
    //   setData([updatebooking.prescriptions]);
    // } else {
    //   setData([]);
    // }

    if (details) {
      setData([details.prescriptions]);
    } else {
      setData(details);
    }
    console.log("bookings: ", data);
  }, [updatebooking]);

  // useEffect(() => {
  //   if (auth.info && auth.info.profile) {
  //     setIsdepData({
  //       isIndependent: false,
  //     });
  //   }
  // }, [auth.info]);
  // console.log("this is data", data);
  const columns = [
    { title: language.first_name, field: "firstName", initialEditValue: "" },
    { title: language.last_name, field: "lastName", initialEditValue: "" },
    // { title: "Private Practice Number", field: "privatePracticeNumber", initialEditValue: "" },
    { title: "Practice Name", field: "practiceName", initialEditValue: "" },
    {
      title: "Speciality type",
      field: "specialityType",
      lookup: specialityTypes,
    },
    {
      title: language.email,
      field: "email",
      editable: "onAdd",
      render: (rowData) =>
        features.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo",
    },
    { title: "password", field: "password", initialEditValue: "" },
    {
      title: language.mobile,
      field: "mobile",
      editable: "onAdd",
      render: (rowData) =>
        features.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo",
    },
    {
      title: "Private Practice",
      field: "privatePractice",
      type: "boolean",
      initialEditValue: true,
    },
    {
      title: language.account_approve,
      field: "practiceAdminActiveStatus",
      type: "boolean",
      initialEditValue: true,
    },
  ];
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
    if (auth.error.flag) {
      dispatch(clearLoginError());
    }
  };

  return usersdata.loading ? (
    <CircularLoading />
  ) : (
    ((
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    ),
    (
      <MaterialTable
        title={"Practice Admins"}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          sorting: true,
          actionsColumnIndex: -1,
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                checkUserExists(newData).then((res) => {
                  if (res.users && res.users.length > 0) {
                    alert(language.user_exists);
                    reject();
                  } else if (res.error) {
                    alert(language.email_or_mobile_issue);
                    reject();
                  } else {
                    newData["createdByAdmin"] = true;
                    newData["userType"] = "practiceadmin";
                    newData["createdAt"] = new Date().toISOString();
                    newData["referralId"] =
                      newData.firstName.toLowerCase() +
                      Math.floor(1000 + Math.random() * 9000).toString();
                    setState({
                      ...state,
                      newData,
                    });
                    let role = auth.info.profile.userType;
                    if (role === "practiceadmin") {
                      newData["practiceadmin"] = auth.info.uid;
                      newData["practiceNumber"] =
                        auth.info.profile.practiceNumber;
                    } else if (role === "nurse") {
                      newData["nurse"] = auth.info.uid;
                      newData["practiceNumber"] =
                        auth.info.profile.practiceNumber;
                    } else if (role === "doctor") {
                      newData["doctor"] = auth.info.uid;
                      newData["practiceNumber"] =
                        auth.info.profile.practiceNumber;
                    }

                    emailSignUp(newData).then((res) => {
                      resolve();
                      // console.log("i am the back data", res);
                      if (res.uid) {
                        setTimeout(() => {
                          setCommonAlert({
                            open: true,
                            msg: language.account_create_successfully,
                          });
                          navigate("/login");
                          // console.log(newData);
                        }, 3000);
                      } else {
                        setCommonAlert({
                          open: true,
                          msg: language.reg_error,
                        });
                      }
                    });
                  }
                });
              }, 600);
            }),

          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                dispatch(editUser(oldData.id, newData));
              }, 600);
            }),
          onRowDelete: (oldData) =>
            features.AllowCriticalEditsAdmin
              ? new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    dispatch(deleteUser(oldData.id));
                  }, 600);
                })
              : new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    alert(language.demo_mode);
                  }, 600);
                }),
        }}
      />
    ))
  );
}
