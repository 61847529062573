/*eslint-disable*/
import React, {
  useState,
  useEffect,
  useContext,
  componentDidMount,
} from "react";
import {
  Select,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import {
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  CardTitle,
  CardHeader,
  CardFooter,
  Label,
} from "reactstrap";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import AlertDialog from "../../components/AlertDialog";
import ChatIcon from "@material-ui/icons/ChatBubbleOutlineRounded";
import CustomInput from "components/CustomInput/CustomInput.js";
import DatePicker from "react-datetime";
import Email from "@material-ui/icons/Email";
import GoogleMapsAutoComplete from "../../components/GoogleMapsAutoComplete";
import PhoneIcon from "@material-ui/icons/Phone";
import SweetAlert from "react-bootstrap-sweetalert";
import Symptoms from "../Symptoms";
import UsersCombo from "../../components/UsersCombo";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import moment from "moment";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { makeStyles } from "@material-ui/core/styles";
import { VideoCall } from "@material-ui/icons";
import { FirebaseContext } from "common";
import { language, treatment } from "config";
import { Calendar as BigCalendar, dateFnsLocalizer } from "react-big-calendar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const events = [
  {
    title: "Conference",
    start: new Date(2022, 2, 20),
    end: new Date(2022, 2, 23),
    serviceType: "",
    specialityType: "",
    description: "",
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    alignContent: "center",
  },
  title: {
    color: "#000",
  },
  gridcontainer: {
    alignContent: "center",
  },
  items: {
    margin: 0,
    width: "100%",
  },
  input: {
    fontSize: 18,
    color: "#000",
  },
  inputdimmed: {
    fontSize: 18,
    color: "#737373",
  },
  practicephoto: {
    height: "18px",
    marginRight: "10px",
  },
  buttonStyle: {
    margin: 0,
    width: "100%",
    height: "100%",
  },
}));

export default function AddAdminBookings(props) {
  const { api } = useContext(FirebaseContext);
  const {
    getEstimate,
    clearEstimate,
    addBooking,
    clearBooking,
    MinutesPassed,
    GetDateString,
    fetchDoctors,
    fetchUser,
    fetchUsers,
    updateBooking,
    cancelBooking,
  } = api;
  const dispatch = useDispatch();
  const practicetypes = useSelector((state) => state.practicetypes.practices);
  const doctortypes = useSelector((state) => state.doctortypes.doctortypes);
  const specialitytypes = useSelector(
    (state) => state.specialitytypes.specialities
  );
  const usersdata = useSelector((state) => state.doctors);
  const estimatedata = useSelector((state) => state.estimatedata);
  const bookingdata = useSelector((state) => state.bookingdata);
  const userdata = useSelector((state) => state.usersdata);
  const settings = useSelector((state) => state.settingsdata.settings);
  const medical = useSelector(
    (state) => state.medicalRecords.medicalHistoryRecords
  );
  const [slotSize, setSlotSize] = useState(4);
  const [stepSize, setStepSize] = useState(15);
  const codes = useSelector((state) => state.icdcodes.codes);
  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const navigate = useNavigate();
  const [Doctors, setDoctors] = useState([]);
  const [Nurses, setNurses] = useState([]);
  const [specialityType, setSpecialityType] = useState("");
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropAddress, setDropAddress] = useState(null);
  const [estimateModalStatus, setEstimateModalStatus] = useState(false);
  const [selectedPracticeDetails, setSelectedPracticeDetails] = useState(null);
  const [users, setUsers] = useState(null);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [userComboDoctors, setUserComboDoctors] = useState(null);
  const [userComboNurses, setUserComboNurses] = useState(null);
  const [userCombo, setUserCombo] = useState(null);
  const [estimateRequested, setEstimateRequested] = useState(false);
  const [bookingType, setBookingType] = useState("");
  const [clinicianType, setClinicianType] = useState(false);
  const [clinicType, setClinicType] = useState("");
  const [treatmentType, setTreatmentType] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(GetDateString());
  const [selectedEndDate, setSelectedEndDate] = useState(GetDateString());
  const auth = useSelector((state) => state.auth);
  const [bookingTypes, setBookingTypes] = useState("");
  const [practicesType, setPracticesType] = useState("");
  const [serviceTypes, setServiceTypes] = useState("");
  const [description, setDescription] = useState("");
  const classes = useStyles();
  const [specialityTypes, setSpecialityTypes] = useState(null);
  const [newEvent, setNewEvent] = useState({
    title: bookingTypes,
    start: new Date(),
    end: new Date(),
    service: serviceTypes,
    practice: practicesType,
    description: description,
  });
  const [selectedDoctor, setSelectedDoctor] = useState({});
  const [selectedNurse, setSelectedNurse] = useState({});

  // console.log(bookingData);
  const [allEvents, setAllEvents] = useState(events);
  const [openFPModal, setOpenFPModal] = React.useState(false);

  const handleAddEvent = () => {
    setAllEvents([...allEvents, newEvent]);
    //todo dispatch create booking use newEvent as payload
    let bookingData = newEvent;
    // console.log(bookingData);
    dispatch(addBooking(bookingData));
  };
  useEffect(() => {
    if (bookinglistdata.bookings) {
      const eventlist = bookinglistdata.bookings.map((bookings) => {
        return {
          title: bookings.title,
          start: new Date(bookings.endDate),
          end: new Date(bookings.eventDate),
          eventId: bookings.id,
          description: bookings.description,
          service: bookings.serviceType,
          practice: bookings.practiceType,
          status: bookings.status,
          doctorname: bookings.doctor ? bookings.doctor.firstName : " ",
          doctoremail: bookings.doctor ? bookings.doctor.email : " ",
          doctormobile: bookings.doctor ? bookings.doctor.mobile : " ",
        };
      });
      const dots = bookinglistdata.bookings.map((bookings) => {
        return {
          [bookings.markedDot.date]: {
            selected: true,
            selectedColor: bookings.markedDot.dots[0].selectedDotColor,
          },
        };
      });
      console.log("this is the dots: ", dots);

      setAllEvents(eventlist);
    }
  }, [bookinglistdata]);
  const onVideoSelect = (event) => {
    // console.log("Video Conference");
    navigate("/videoconference");
  };
  const onPhoneSelect = (event) => {
    console.log("Phone Call");
  };
  const onChatSelect = (event) => {
    console.log("Start Chatting");
  };

  const selectedEvent = (event) => {
    // window.alert(event.title, event.treatment, event.practice);
    // console.log("Title: "+event.title,"Type of treatment: "+ event.service, "Practice: "+ event.practice, "Doctor: ");
    // console.log(event)
    setOpenFPModal(true);
    if (openFPModal === true) {
    }
  };
  const handleCloseEvent = (e) => {
    e.preventDefault();
    setOpenFPModal(false);
  };

  const eventColors = (event, start, end, isSelected) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  };
  const handleSpecialitySelect = (event, text) => {
    setSpecialityType(event.target.value);

    let practiceDetails = null;
    for (let i = 0; i < practicetypes.length; i++) {
      if (practicetypes[i].specialityType === event.target.value) {
        practiceDetails = practicetypes[i];
      }
    }
    if (!practiceDetails) {
      practiceDetails = practicetypes.find(
        (x) => x.specialityType === "Default"
      );
    }

    setSelectedPracticeDetails(practiceDetails);
    console.log("this is prac", practiceDetails);
  };

  const handleBookTypeSelect = (event) => {
    setBookingType(event.target.value);
    if (bookingType === "Book Later") {
      setSelectedStartDate(GetDateString());
      setSelectedEndDate(GetDateString());
    }
  };

  const onDateStartChange = (event) => {
    setSelectedStartDate(event.target.value);
  };
  const onDateEndChange = (event) => {
    setSelectedEndDate(event.target.value);
  };
  const [icdcodes, seticdcodes] = useState([]);
  const [service, setservice] = useState([]);
  const [state, setstate] = useState({
    serviceType: false,
    treatmentType: false,
  });
  const [serviceT, setserviceT] = useState([]);
  const [treatments, settreatments] = useState([]);
  useEffect(() => {
    console.log("This the event in spec", state.serviceType);
    if (treatment) {
      let arr = [];
      for (let i = 0; i < Object.keys(treatment).length; i++) {
        arr.push({
          value: Object.values(treatment)[i],
          label: Object.keys(treatment)[i],
        });
      }
      setservice(arr);
    }
  }, [state.serviceType]);
  useEffect(() => {
    if (serviceT) {
      let arr = [];
      for (let i = 0; i < Object.keys(serviceT).length; i++) {
        arr.push({
          value: Object.values(serviceT)[i],
          label: Object.keys(serviceT)[i],
        });
      }
      settreatments(arr);
    }
  }, [serviceT]);

  const handleSpecialitySelectT = async (event, text) => {
    console.log("this is prac", state.serviceType);
    setstate({
      ...state,
      serviceType: text.props.children,
    });
    console.log("this is clinic", text.props.children);
    setClinicType(text.props.children);
    setserviceT(event.target.value);
    setNewEvent({
      ...newEvent,
      clinicType: text.props.children,
    });
    setstate({ ...state, treatmentType: serviceT });
  };
  const handleClinicSelect = async (event, text) => {
    setstate({
      ...state,
      clinicType: text.props.children,
    });
    setClinicType(event.target.value);
    setstate({ ...state, clinicType: clinicType });
  };
  useEffect(() => {
    const fetchPracticeDetails = async () => {
      let practiceDetails = null;
      for (let i = 0; i < practicetypes.length; i++) {
        if (practicetypes[i].specialityType === state.serviceType) {
          practiceDetails = practicetypes[i];
        }
      }

      if (!practiceDetails) {
        practiceDetails = practicetypes.find(
          (x) => x.specialityType === "Default"
        );
      }

      setSelectedPracticeDetails(practiceDetails);
      console.log("this is prac TT overwrite", practiceDetails);
    };

    if (state.serviceType && state.serviceType !== "false") {
      fetchPracticeDetails();
    }
  }, [state.serviceType]);
  useEffect(() => {
    console.log("this the treatment", service);
    if (userdata.users) {
      let arr = [];
      let pracdoc = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        if (user.userType === "nurse") {
          if (
            user.practiceNumber !== null ||
            user.practiceNumber !== undefined
          ) {
            if (user.practiceNumber === auth.info.profile.practiceNumber) {
              arr.push({
                firstName: user.firstName,
                lastName: user.lastName,
                mobile: user.mobile,
                email: user.email,
                uid: user.id,
                profile_image: user.profile_image,
                practiceNumber: user.practiceNumber,
                desc:
                  user.firstName +
                  " " +
                  user.lastName +
                  " (" +
                  user.mobile +
                  ") " +
                  user.email,
                pushToken: user.pushToken,
              });
            } else {
              if (
                auth.info.profile.userType === "practiceadmin" &&
                !auth.info.profile.privatePractice
              ) {
                arr.push({
                  firstName: user.firstName,
                  lastName: user.lastName,
                  mobile: user.mobile,
                  email: user.email,
                  uid: user.id,
                  profile_image: user.profile_image,
                  desc:
                    user.firstName +
                    " " +
                    user.lastName +
                    " (" +
                    user.mobile +
                    ") " +
                    user.email,
                  pushToken: user.pushToken,
                });
              }
            }
          }
        }
      }

      setNurses(arr);

      //console.log("UserData", arr);
    }
  }, []);

  useEffect(() => {
    if (userdata.users.length === 0) {
      dispatch(fetchUsers());
    }
  }, []);
  useEffect(() => {
    if (userdata.users) {
      let arr = [];
      let pracdoc = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        if (user.userType === "doctor") {
          if (
            user.practiceNumber !== null ||
            user.practiceNumber !== undefined
          ) {
            if (user.practiceNumber === auth.info.profile.practiceNumber) {
              arr.push({
                firstName: user.firstName,
                lastName: user.lastName,
                mobile: user.mobile,
                email: user.email,
                uid: user.id,
                profile_image: user.profile_image,
                practiceNumber: user.practiceNumber,
                desc:
                  user.firstName +
                  " " +
                  user.lastName +
                  " (" +
                  user.mobile +
                  ") " +
                  user.email,
                pushToken: user.pushToken,
              });
            } else {
              if (
                auth.info.profile.userType === "practiceadmin" &&
                !auth.info.profile.privatePractice
              ) {
                arr.push({
                  firstName: user.firstName,
                  lastName: user.lastName,
                  mobile: user.mobile,
                  email: user.email,
                  uid: user.id,
                  profile_image: user.profile_image,
                  desc:
                    user.firstName +
                    " " +
                    user.lastName +
                    " (" +
                    user.mobile +
                    ") " +
                    user.email,
                  pushToken: user.pushToken,
                });
              }
            }
          }
        }
      }

      setDoctors(arr);

      //console.log("UserData", arr);
    }
  }, []);

  useEffect(() => {
    if (userdata.users) {
      let arr = [];
      let pracpatient = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        if (user.userType === "patient" || user.userType === "nophonepatient") {
          if (
            user.practiceNumber !== null ||
            user.practiceNumber !== undefined
          ) {
            if (user.practiceNumber === auth.info.profile.practiceNumber) {
              arr.push({
                Medical_Information: user.Medical_Information
                  ? user.Medical_Information
                  : " ",
                firstName: user.firstName,
                lastName: user.lastName,
                mobile: user.mobile,
                email: user.email,
                uid: user.id,
                profile_image: user.profile_image,
                practiceNumber: user.practiceNumber,
                desc:
                  user.firstName +
                  " " +
                  user.lastName +
                  " (" +
                  user.mobile +
                  ") " +
                  user.email,
                pushToken: user.pushToken,
              });
            } else {
              if (
                auth.info.profile.userType === "practiceadmin" &&
                !auth.info.profile.privatePractice
              )
                if (user.practiceNumber !== auth.info.profile.practiceNumber)
                  if (
                    user.practiceNumber === null ||
                    user.practiceNumber === undefined
                  ) {
                    arr.push({
                      Medical_Information: user.Medical_Information
                        ? user.Medical_Information
                        : " ",
                      firstName: user.firstName,
                      lastName: user.lastName,
                      mobile: user.mobile,
                      email: user.email,
                      uid: user.id,
                      profile_image: user.profile_image,
                      desc:
                        user.firstName +
                        " " +
                        user.lastName +
                        " (" +
                        user.mobile +
                        ") " +
                        user.email,
                      pushToken: user.pushToken,
                    });
                  }
            }
          }
        }
      }

      setUsers(arr);

      //console.log("UserData", arr);
    }
  }, []);

  useEffect(() => {
    if (estimatedata.estimate && estimateRequested) {
      setEstimateRequested(false);
      setEstimateModalStatus(true);
    }
  }, [estimatedata.estimate, estimateRequested]);

  const setCalendarSlots = (value) => {
    console.log("first", value);
    setNewEvent({ ...newEvent, service: value });
    if (value === "Surgery Consultation") {
      setSlotSize(3);
      setStepSize(20);
      console.log("first", "set cal slots");
    }
  };
  const handleChange = (event) => {
    setClinicianType(event.target.value);
  };
  const handleGetEstimate = (e) => {
    e.preventDefault();

    setEstimateRequested(true);
    if (userCombo && selectedPracticeDetails && newEvent.service) {
      if (newEvent.title) {
        if (newEvent.description) {
          if (bookingType === "Book Now") {
            dispatch(
              getEstimate({
                pickup: pickupAddress,
                drop: dropAddress,
                practiceDetails: selectedPracticeDetails,
                serviceType: newEvent.service,
              })
            );
          } else {
            if (
              bookingType === "Book Later" &&
              selectedStartDate &&
              newEvent.service
            ) {
              if (MinutesPassed(selectedStartDate) >= 15) {
                console.log({
                  pickup: pickupAddress,
                  drop: dropAddress,
                  practiceDetails: selectedPracticeDetails,
                  serviceType: newEvent.service,
                });
                dispatch(
                  getEstimate({
                    pickup: pickupAddress,
                    drop: dropAddress,
                    practiceDetails: selectedPracticeDetails,
                    serviceType: newEvent.service,
                  })
                );
                console.table("got estimate", estimatedata);
              } else {
                setCommonAlert({
                  open: true,
                  msg: language.past_booking_error,
                });
              }
            } else {
              setCommonAlert({ open: true, msg: language.select_proper });
            }
          }
        } else {
          setCommonAlert({
            open: true,
            msg: "Please give a Booking Description",
          });
        }
      } else {
        setCommonAlert({
          open: true,
          msg: "Please give a Booking Name eg: Heart Pains",
        });
      }
    } else {
      setCommonAlert({ open: true, msg: language.select_proper });
    }
  };

  const confirmBooking = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    let stat = "NEW";
    let doc = null;
    let nur = null;
  
    // auth.info.profile.userType === "practiceadmin"
       if (clinicianType === "nurse" && auth.info.profile.userType === "practiceadmin") {
         stat = "ACCEPTED";
         nur = {
           desc: userComboNurses.firstName
             ? userComboNurses.firstName
             : null + " " + userComboNurses.lastName
             ? userComboNurses.lastName
             : null + " (" + userComboNurses.mobile
             ? userComboNurses.mobile
             : null + ") " + userComboNurses.email
             ? userComboNurses.email
             : null,
           firstName: userComboNurses.firstName
             ? userComboNurses.firstName
             : null,
           lastName: userComboNurses.lastName ? userComboNurses.lastName : null,
           mobile: userComboNurses.mobile ? userComboNurses.mobile : null,
           pushToken: userComboNurses.pushToken
             ? userComboNurses.pushToken
             : null,
           email: userComboNurses.email ? userComboNurses.email : null,
           practiceNumber: auth.info.profile.practiceNumber
             ? auth.info.profile.practiceNumber
             : null,
           profile_image: userComboNurses.profile_image
             ? userComboNurses.profile_image
             : null,
           uid: userComboNurses.uid ? userComboNurses.uid : null,
         };
       }

       if (
         clinicianType === "doctor" &&
         auth.info.profile.userType === "practiceadmin"
       ) {
         stat = "ACCEPTED";
         doc = {
           desc: userComboDoctors.firstName
             ? userComboDoctors.firstName
             : null + " " + userComboDoctors.lastName
             ? userComboDoctors.lastName
             : null + " (" + userComboDoctors.mobile
             ? userComboDoctors.mobile
             : null + ") " + userComboDoctors.email
             ? userComboDoctors.email
             : null,
           firstName: userComboDoctors.firstName
             ? userComboDoctors.firstName
             : null,
           lastName: userComboDoctors.lastName
             ? userComboDoctors.lastName
             : null,
           mobile: userComboDoctors.mobile ? userComboDoctors.mobile : null,
           pushToken: userComboDoctors.pushToken
             ? userComboDoctors.pushToken
             : null,
           email: userComboDoctors.email ? userComboDoctors.email : null,
           practiceNumber: auth.info.profile.practiceNumber
             ? auth.info.profile.practiceNumber
             : null,
           profile_image: userComboDoctors.profile_image
             ? userComboDoctors.profile_image
             : null,
           uid: userComboDoctors.uid ? userComboDoctors.uid : null,
         };
       }
    if (
      auth.info.profile.privatePractice && !auth.info.profile.isIndependent 
    ) {
      stat = "ACCEPTED";
      nur = {
        desc: userComboNurses.firstName
          ? userComboNurses.firstName
          : null + " " + userComboNurses.lastName
          ? userComboNurses.lastName
          : null + " (" + userComboNurses.mobile
          ? userComboNurses.mobile
          : null + ") " + userComboNurses.email
          ? userComboNurses.email
          : null,
        firstName: userComboNurses.firstName ? userComboNurses.firstName : null,
        lastName: userComboNurses.lastName ? userComboNurses.lastName : null,
        mobile: userComboNurses.mobile ? userComboNurses.mobile : null,
        pushToken: userComboNurses.pushToken ? userComboNurses.pushToken : null,
        email: userComboNurses.email ? userComboNurses.email : null,
        practiceNumber: auth.info.profile.practiceNumber
          ? auth.info.profile.practiceNumber
          : null,
        profile_image: userComboNurses.profile_image
          ? userComboNurses.profile_image
          : null,
        uid: userComboNurses.uid ? userComboNurses.uid : null,
      };
      doc = {
        desc: userComboDoctors.firstName
          ? userComboDoctors.firstName
          : null + " " + userComboDoctors.lastName
          ? userComboDoctors.lastName
          : null + " (" + userComboDoctors.mobile
          ? userComboDoctors.mobile
          : null + ") " + userComboDoctors.email
          ? userComboDoctors.email
          : null,
        firstName: userComboDoctors.firstName
          ? userComboDoctors.firstName
          : null,
        lastName: userComboDoctors.lastName ? userComboDoctors.lastName : null,
        mobile: userComboDoctors.mobile ? userComboDoctors.mobile : null,
        pushToken: userComboDoctors.pushToken
          ? userComboDoctors.pushToken
          : null,
        email: userComboDoctors.email ? userComboDoctors.email : null,
        practiceNumber: auth.info.profile.practiceNumber
          ? auth.info.profile.practiceNumber
          : null,
        profile_image: userComboDoctors.profile_image
          ? userComboDoctors.profile_image
          : null,
        uid: userComboDoctors.uid ? userComboDoctors.uid : null,
      };
    }
    if (
      (auth.info.profile.isIndependent &&
        auth.info.profile.userType === "nurse") 
    ) {
      stat = "ACCEPTED";
      nur = {
        desc: auth.info.profile.firstName
          ? auth.info.profile.firstName
          : null + " " + auth.info.profile.lastName
          ? auth.info.profile.lastName
          : null + " (" + auth.info.profile.mobile
          ? auth.info.profile.mobile
          : null + ") " + auth.info.profile.email
          ? auth.info.profile.email
          : null,
        firstName: auth.info.profile.firstName
          ? auth.info.profile.firstName
          : null,
        lastName: auth.info.profile.lastName
          ? auth.info.profile.lastName
          : null,
        mobile: auth.info.profile.mobile ? auth.info.profile.mobile : null,
        pushToken: auth.info.profile.pushToken
          ? auth.info.profile.pushToken
          : null,
        email: auth.info.profile.email ? auth.info.profile.email : null,
        practiceNumber: auth.info.profile.practiceNumber
          ? auth.info.profile.practiceNumber
          : null,
        profile_image: auth.info.profile.profile_image
          ? auth.info.profile.profile_image
          : null,
        uid: auth.info.uid ? auth.info.uid : null,
      };
    }

    if (
      (auth.info.profile.isIndependent &&
        auth.info.profile.userType === "doctor") 
    ) {
      stat = "ACCEPTED";
      doc = {
        desc: userComboDoctors.firstName
          ? userComboDoctors.firstName
          : null + " " + userComboDoctors.lastName
          ? userComboDoctors.lastName
          : null + " (" + userComboDoctors.mobile
          ? userComboDoctors.mobile
          : null + ") " + userComboDoctors.email
          ? userComboDoctors.email
          : null,
        firstName: userComboDoctors.firstName
          ? userComboDoctors.firstName
          : null,
        lastName: userComboDoctors.lastName ? userComboDoctors.lastName : null,
        mobile: userComboDoctors.mobile ? userComboDoctors.mobile : null,
        pushToken: userComboDoctors.pushToken
          ? userComboDoctors.pushToken
          : null,
        email: userComboDoctors.email ? userComboDoctors.email : null,
        practiceNumber: auth.info.profile.practiceNumber
          ? auth.info.profile.practiceNumber
          : null,
        profile_image: userComboDoctors.profile_image
          ? userComboDoctors.profile_image
          : null,
        uid: userComboDoctors.uid ? userComboDoctors.uid : null,
      };
    }
    let bookingObject = {
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      pickup: pickupAddress,
      drop: dropAddress,
      doctor: doc ? doc : "",
      nurse: nur ? nur : "",

      practiceDetails: selectedPracticeDetails,
      Medical_Information: userCombo.Medical_Information,
      status: stat,
      userDetails: {
        uid: userCombo.uid,
        profile: {
          firstName: userCombo.firstName,
          lastName: userCombo.lastName,
          mobile: userCombo.mobile,
          pushToken: userCombo.pushToken,
          email: userCombo.email,
          profile_image: userCombo.profile_image
            ? userCombo.profile_image
            : null,
        },
      },
      estimate: estimatedata.estimate,

      eventdate: new Date(selectedStartDate).toString(),
      bookLater: bookingType === "Book Later" ? true : false,
      settings: settings,
      specialityType: specialityType,
      serviceType: newEvent.service,
      treatmentType: newEvent.treatmentType,
      clinicType: newEvent.clinicType,
      bookingName: newEvent.title,
      description: newEvent.description,
      booking_type_web: true,
      videoconferencelink: `https://okdoc-prod.okdoc.co.za/${newEvent.title}`,
      markedDot: {
        date: `${moment(selectedStartDate).format("YYYY")}-${moment(
          selectedStartDate
        ).format("MM")}-${moment(selectedStartDate).format("DD")}`,
        dots: [
          {
            key: uuidv4(),
            color: "#ff0000",
            selectedDotColor: "#ff0000",
          },
        ],
      },
    };
    console.log(bookingObject);
    dispatch(addBooking(bookingObject));
    if (auth.info.profile.userType === "nurse") {
      navigate("/nursebookingshistory");
    }
    if (auth.info.profile.userType === "doctor") {
      navigate("/bookings");
    }
    if (auth.info.profile.userType === "practiceadmin") {
      navigate("/bookingrequest");
    }
  };
  const handleSlot = (slotInfo) => {
    //window.alert(event.title, event.treatment, event.practice);

    console.log(slotInfo);
    setSelectedStartDate(slotInfo.start);
    setSelectedEndDate(slotInfo.end);
    //setOpenFPModal(true);
  };
  const handleNurseSlot = (slotInfo) => {
    //window.alert(event.title, event.treatment, event.practice);

    console.log(slotInfo);
    //setOpenFPModal(true);
  };
  const handleEstimateModalClose = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    dispatch(clearEstimate());
    setEstimateRequested(false);
  };

  const handleEstimateErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearEstimate());
    setEstimateRequested(false);
  };

  const handleBookingAlertClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    dispatch(clearEstimate());
    clearForm();
  };

  const clearForm = () => {
    setUserCombo(null);
    setPickupAddress(null);
    setNewEvent({ description: null, title: null });
    setDropAddress(null);
    setSelectedPracticeDetails(null);
    setSpecialityType(language.select_practice);
    setBookingType(language.book_now);
    setEstimateRequested(false);
  };

  const handleBookingErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    setEstimateRequested(false);
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  return (
    <div className={classes.container}>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h1" variant="h5" className={classes.title}>
              {language.addbookinglable}
            </Typography>
          </Grid>

          <FormGroup className="form-check form-check-radio form-check-inline">
            <Label check>
              <Input
                value="doctor"
                id="doctor"
                name="clinicianType"
                type="radio"
                onChange={handleChange}
              ></Input>
              Doctor <span className="form-check-sign"></span>
            </Label>
          </FormGroup>
          <FormGroup className="form-check form-check-radio form-check-inline">
            <Label check>
              <Input
                value="nurse"
                id="nurse"
                name="clinicianType"
                type="radio"
                onChange={handleChange}
              ></Input>
              Nurse <span className="form-check-sign"></span>
            </Label>
          </FormGroup>
          <FormGroup className="form-check form-check-radio form-check-inline">
            <Label check>
              <Input
                value="both"
                id="both"
                name="clinicianType"
                type="radio"
                onChange={handleChange}
              ></Input>
              Both <span className="form-check-sign"></span>
            </Label>
          </FormGroup>
          <Grid item xs={12}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="practice-type-native">Practice Type</InputLabel>
                {specialitytypes ? (
                  <Select
                    id="practice-type-native"
                    value={specialityType}
                    onChange={handleSpecialitySelect}
                    variant="outlined"
                    fullWidth
                    className={
                      specialityType === language.select_practice
                        ? classes.inputdimmed
                        : classes.input
                    }
                  >
                    {specialitytypes.map((speciality) => {
                      // console.log(speciality);
                      return (
                        <MenuItem key={speciality} value={speciality}>
                          {speciality}
                        </MenuItem>
                      );
                    })}
                  </Select>
                ) : null}
              </FormControl>
            </Grid>
            {/* {!auth.info.profile.isIndependent && ( */}
              <FormGroup>
                {(clinicianType === "doctor" || clinicianType === "both") && (
                  <UsersCombo
                    className={classes.items}
                    placeholder={"Assign a Doctor to the Booking"}
                    users={Doctors}
                    value={userComboDoctors}
                    onChange={(event, newValue) => {
                      setUserComboDoctors(newValue);
                    }}
                  />
                )}

                {(clinicianType === "nurse" || clinicianType === "both") && (
                  <UsersCombo
                    className={classes.items}
                    placeholder={"Assign a Nurse to the Booking"}
                    users={Nurses}
                    value={userComboNurses}
                    onChange={(event, newValue) => {
                      setUserComboNurses(newValue);
                    }}
                  />
                )}
              </FormGroup>
            {/* )} */}
            {users ? (
              <UsersCombo
                className={classes.items}
                placeholder={language.select_user}
                users={users}
                value={userCombo}
                onChange={(event, newValue) => {
                  console.log("User Details", newValue);
                  setUserCombo(newValue);
                }}
              />
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInput
              labelText={"Booking Name"}
              formControlProps={{
                fullWidth: true,
              }}
              type="text"
              placeholder="Add Booking Name"
              onChange={(e) =>
                setNewEvent({
                  ...newEvent,
                  title: e.target.value,
                })
              }
              value={newEvent.title}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInput
              labelText={"Description"}
              formControlProps={{
                fullWidth: true,
              }}
              type="text"
              placeholder="Add Description"
              onChange={(e) =>
                setNewEvent({
                  ...newEvent,
                  description: e.target.value,
                })
              }
              value={newEvent.description}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {specialityType === "COMMUNITY_HEALTH" ? (
              <FormControl fullWidth>
                <InputLabel id="practice-type-native"> Clinic</InputLabel>

                <Select
                  id="practice-type-native"
                  // value={state.serviceType}
                  onChange={handleSpecialitySelectT}
                  variant="outlined"
                  fullWidth
                  className={
                    specialityType === language.select_practice
                      ? classes.inputdimmed
                      : classes.input
                  }
                >
                  {service.map((special) => (
                    <MenuItem value={special.value}>{special.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            {specialityType === "COMMUNITY_HEALTH" ? (
              <FormControl fullWidth>
                <InputLabel id="practice-type-native">
                  {" "}
                  Treatment Type
                </InputLabel>

                <Select
                  id="practice-type-native"
                  onChange={(event) => {
                    console.log("treatment: ", event.target.value);
                    setTreatmentType(event.target.value);
                    setNewEvent({
                      ...newEvent,
                      treatmentType: event.target.value,
                    });
                  }}
                  variant="outlined"
                  fullWidth
                  className={
                    specialityType === language.select_practice
                      ? classes.inputdimmed
                      : classes.input
                  }
                >
                  {treatments.map((special) => (
                    <MenuItem value={special.value}>{special.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="booking-type-native">Booking Type</InputLabel>
              <Select
                id="booking-type-native"
                value={bookingType}
                onChange={handleBookTypeSelect}
                className={classes.input}
                variant="outlined"
                fullWidth
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem key={"Book Now"} value={"Book Now"}>
                  {language.book_now}
                </MenuItem>
                <MenuItem key={"Book Later"} value={"Book Later"}>
                  {language.book_later}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="service-type-native">Service Type</InputLabel>
              <Select
                id="service-type-native"
                value={newEvent.service}
                onChange={(e) => setCalendarSlots(e.target.value)}
                className={classes.input}
                variant="outlined"
                fullWidth
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem key={"videoconference"} value={"Video Conference"}>
                  {language.Video_Conference}
                </MenuItem>
                <MenuItem key={"homeconsultation"} value={"Home Consultation"}>
                  {language.Home_Consultation}
                </MenuItem>
                <MenuItem
                  key={"medicationdelivery"}
                  value={"Medication Delivery"}
                >
                  {language.Medication_Delivery}
                </MenuItem>
                <MenuItem
                  key={"surgeryconsultation "}
                  value={"Surgery Consultation"}
                >
                  {language.Surgery_Consultation}
                </MenuItem>
                {/* <MenuItem
                  key={"Antenatal Care Assessment A"}
                  value={"Antenatal Care Assessment A"}
                >
                  {language.antenatal_care_assesment_a}
                </MenuItem>
                <MenuItem
                  key={"Antenatal Care Assessment B"}
                  value={"Antenatal Care Assessment B"}
                >
                  {language.antenatal_care_assesment_b}
                </MenuItem>
                <MenuItem
                  key={"Antenatal Care Assessment C"}
                  value={"Antenatal Care Assessment C"}
                >
                  {language.antenatal_care_assesment_c}
                </MenuItem> */}
                <MenuItem key={"Home Care"} value={"Home Care"}>
                  {language.home_care}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {newEvent.service === "Home Consultation" ||
          newEvent.service === "Medication Delivery" ? (
            <>
              <Grid item xs={12}>
                <GoogleMapsAutoComplete
                  variant={"outlined"}
                  placeholder={language.pickup_location}
                  value={pickupAddress}
                  className={classes.items}
                  onChange={(value) => {
                    setPickupAddress(value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <GoogleMapsAutoComplete
                  placeholder={language.drop_location}
                  variant={"outlined"}
                  value={dropAddress}
                  className={classes.items}
                  onChange={(value) => {
                    setDropAddress(value);
                  }}
                />
              </Grid>
            </>
          ) : null}

          {bookingType === "Book Later" ? (
            <>
              {(clinicianType === "doctor" || clinicianType === "both") && (
                <Grid item xs={12} sm={6}>
                  <Card>
                    <CardTitle>Doctor Calendar</CardTitle>
                    <CardBody>
                      <BigCalendar
                        selectable
                        localizer={localizer}
                        events={allEvents}
                        step={stepSize}
                        timeslots={slotSize}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500, margin: "50px" }}
                        defaultView={"day"}
                        scrollToTime={new Date(2020, 1, 1, 6)}
                        defaultDate={new Date()}
                        onSelectEvent={(event) => selectedEvent(event)}
                        onSelectSlot={(slotInfo) => handleSlot(slotInfo)}
                        eventPropGetter={eventColors}
                      />
                    </CardBody>
                  </Card>
                </Grid>
              )}
              {(clinicianType === "nurse" || clinicianType === "both") && (
                <Grid item xs={12} sm={6}>
                  <Card>
                    <CardTitle>Nurse Calendar</CardTitle>
                    <CardBody>
                      <BigCalendar
                        selectable
                        localizer={localizer}
                        events={allEvents}
                        step={stepSize}
                        timeslots={slotSize}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500, margin: "50px" }}
                        defaultView={"day"}
                        scrollToTime={new Date(2020, 1, 1, 6)}
                        defaultDate={new Date()}
                        onSelectEvent={(event) => selectedEvent(event)}
                        onSelectSlot={(slotInfo) => handleNurseSlot(slotInfo)}
                        eventPropGetter={eventColors}
                      />
                    </CardBody>
                  </Card>
                </Grid>
              )}
            </>
          ) : null}
          {/* {bookingType === "Book Later" ? (
            <Grid item xs={12} sm={6}>
              <TextField
                id="datetime-local"
                label={language.booking_end_date_time}
                type="datetime-local"
                variant="outlined"
                fullWidth
                className={classes.commonInputStyle}
                InputProps={{
                  className: classes.input,
                }}
                value={selectedStartDate}
                onChange={onDateStartChange}
              />
            </Grid>
          ) : null} */}

          <Grid item xs={12} sm={6}>
            <Button
              size="large"
              onClick={handleGetEstimate}
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
            >
              <i className="fas fa-practice" />
              {language.book}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={estimateModalStatus}
        onClose={handleEstimateModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{language.estimate}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>Estimate Amount: </span>
            {settings ? settings.symbol : null}{" "}
            {estimatedata.estimate
              ? parseFloat(estimatedata.estimate.estimateFare)
              : null}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            <span>Treatment Amount: </span>
            {settings ? settings.symbol : null}{" "}
            {estimatedata.estimate
              ? parseFloat(estimatedata.estimate.treatment_fee)
              : null}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            <span>Total: </span>
            {settings ? settings.symbol : null}{" "}
            {estimatedata.estimate
              ? parseFloat(estimatedata.estimate.bigGrand)
              : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEstimateModalClose} color="primary">
            {language.cancel}
          </Button>
          <Button onClick={confirmBooking} color="primary" autoFocus>
            {"Accept Estimate"}
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        open={bookingdata.booking ? true : false}
        onClose={handleBookingAlertClose}
      >
        {bookingdata.booking
          ? language.booking_success + bookingdata.booking.booking_id
          : null}
      </AlertDialog>
      <AlertDialog
        open={bookingdata.error.flag}
        onClose={handleBookingErrorClose}
      >
        {bookingdata.error.msg}
      </AlertDialog>
      <AlertDialog
        open={estimatedata.error.flag}
        onClose={handleEstimateErrorClose}
      >
        {estimatedata.error.msg}
      </AlertDialog>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
}
