/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import CircularLoading from "../../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationDialogRaw from "../../components/ConfirmationDialogRaw";
// import { Visibility } from "@material-ui/icons/Visibility";
import { features, dateStyle, language } from "config";
import { FirebaseContext } from "common";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Button } from "reactstrap";
import PaInvoice from "./PaInvoice";
const Reports = () => {
  const { api } = useContext(FirebaseContext);
  const { cancelBooking } = api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [role, setRole] = useState(null);
  const [openFPModal, setOpenFPModal] = React.useState(false);
  const [openFPModaltwo, setOpenFPModalTwo] = React.useState(false);
  const userdata = useSelector((state) => state.usersdata);
  const [users, setUsers] = useState(null);

  const handleOpenEvent = (e) => {
    e.preventDefault();
    setOpenFPModal(true);
  };
  const handleCloseEvent = (e) => {
    e.preventDefault();
    setOpenFPModal(false);
  };
  const handleOpenEvents = (e) => {
    e.preventDefault();
    setOpenFPModalTwo(true);
  };
  const handleCloseEvents = (e) => {
    e.preventDefault();
    setOpenFPModalTwo(false);
  };

  const columns = [
    { title: language.booking_id, field: "id" },
    {
      title: language.booking_date,
      field: "eventdate",
      render: (rowData) =>
        rowData.eventDate
          ? new Date(rowData.eventDate).toLocaleString(dateStyle)
          : null,
    },
    {
      title: "Doctor Name",
      field: "firstName",
      render: (rowData) =>
        rowData.doctor ? rowData.doctor.firstName : "No Doctor Assigned",
    },
    {
      title: "Patient Name",
      field: "patientName",
      render: (rowData) => rowData.patientName,
    },
    {
      title: language.booking_status,
      field: "status",
      render: (rowData) => <span>{language[rowData.status]}</span>,
    },
    { title: language.Customer_paid, field: "customer_paid" },
  ];
  const columnsTwo = [
    { title: language.booking_id, field: "id" },
    {
      title: language.booking_date,
      field: "eventdate",
      render: (rowData) =>
        rowData.eventDate
          ? new Date(rowData.eventDate).toLocaleString(dateStyle)
          : null,
    },
    {
      title: "Doctor Name",
      field: "firstName",
      render: (rowData) =>
        rowData.doctor ? rowData.doctor.firstName : "No Doctor Assigned",
    },
    {
      title: "Patient Name",
      field: "patientName",
      render: (rowData) =>
        rowData.patientName ? rowData.patientName : "No patient name found",
    },
    {
      title: language.booking_status,
      field: "status",
      render: (rowData) => <span>{language[rowData.status]}</span>,
    },
    // { title: language.Customer_paid, field: 'customer_paid'},
  ];
  const [data, setData] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState("");
  const bookinglistdata = useSelector((state) => state.bookinglistdata);

  useEffect(() => {
    const arr = [];
    console.log("auth.info.profile", auth.info.profile);
    if (bookinglistdata.bookings) {
      bookinglistdata.bookings.map((booking) => {
        if (
          booking.doctor &&
          booking.doctor.practiceNumber === auth.info.profile.practiceNumber
        ) {
          arr.push(booking);
        } else if (
          booking.nurse &&
          !booking.doctor &&
          booking.doctor.practiceNumber === auth.info.profile.practiceNumber
        ) {
          arr.push(booking);
        }
      });
      setData(arr);
    } else {
      setData([]);
      // console.log("bookings: ",   bookinglistdata.bookings[3].doctor );
    }
  }, [bookinglistdata.bookings]);

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.userType);
    }
  }, [auth.info]);

  const onConfirmClose = (value) => {
    if (value) {
      dispatch(
        cancelBooking({
          reason: value,
          booking: selectedBooking,
        })
      );
    }
    setOpenConfirm(false);
  };

  return bookinglistdata.loading ? (
    <CircularLoading />
  ) : (
    <div>
      <Dialog
        open={openFPModal}
        onClose={handleCloseEvent}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        size="lg"
        centered={true}
        // size="lg"
      >
        <DialogTitle id="form-dialog-title">{"Invoice"}</DialogTitle>
        <DialogContent>
          <PaInvoice />
        </DialogContent>

        <DialogActions>
          <Button color="primary">Save</Button>
          <Button onClick={handleCloseEvent} color="primary">
            Close
          </Button>
          <Button color="primary" onClick={() => window.print(this)}>
            PRINT
          </Button>
        </DialogActions>
      </Dialog>

      <MaterialTable
        title="Financial Reports"
        columns={columns}
        data={data}
        options={{
          actionsColumnIndex: -1,
          filtering: true,
        }}
        actions={[
          (rowData) => ({
            // icon: <Visibility/>,
            tooltip: "View Invoice",
            disabled:
              rowData.status === "NEW" || rowData.status === "ACCEPTED"
                ? false
                : true,
            onClick: (event, rowData) => {
              if (
                features.AllowCriticalEditsAdmin &&
                (role === "patient" || role === "practiceadmin")
              ) {
                setSelectedBooking(rowData);
                setOpenFPModal(true);
              } else {
                alert(language.demo_mode);
              }
            },
          }),
        ]}
      />

      <MaterialTable
        title="Booking History"
        columns={columnsTwo}
        data={data}
        options={{
          actionsColumnIndex: -1,
        }}
        actions={[
          (rowData) => ({
            // icon: <Visibility />,
            tooltip: "Booking Information",
            disabled:
              rowData.status === "NEW" || rowData.status === "ACCEPTED"
                ? false
                : true,
            onClick: (event, rowData) => {
              if (
                features.AllowCriticalEditsAdmin &&
                (role === "patient" || role === "practiceadmin")
              ) {
                setSelectedBooking(rowData);
                setOpenFPModalTwo(true);
              } else {
                alert(language.demo_mode);
              }
            },
          }),
        ]}
      />
      <ConfirmationDialogRaw
        open={openConfirm}
        onClose={onConfirmClose}
        value={""}
      />
    </div>
  );
};

export default Reports;
