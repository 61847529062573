/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
// import MaterialTable from "material-table";
import CircularLoading from "../../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import FlatList from "flatlist-react";
// import { features, dateStyle, language } from "config";
import { FirebaseContext } from "common";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  FormGroup,
} from "reactstrap";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Navigate, useNavigate } from "react-router-dom";
const NurseTimeLine = () => {
  const { api } = useContext(FirebaseContext);
  const { fetchMedicalHistory } = api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  // const [role, setRole] = useState(null);
  // const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  // const [openConfirm, setOpenConfirm] = useState(false);
  // const [selectedBooking, setSelectedBooking] = useState("");
  const navigate = useNavigate();
  const bookinglistdata = useSelector((state) => state.bookinglistdata);

  useEffect(() => {
    if (auth.info.profile.userType === "nurse") {
      if (bookinglistdata.bookings) {
        setData(
          bookinglistdata.bookings.filter(
            (bookings) =>
              bookings.status === "ACCEPTED" &&
              auth.info.uid === bookings?.nurse.uid
          )
        );
      } else {
        setData([]);
      }
    } else if (auth.info.profile.userType === "nurse") {
      if (bookinglistdata.bookings) {
        setData(
          bookinglistdata.bookings.filter(
            (bookings) =>
              bookings.status === "ACCEPTED" &&
              auth.info.uid === bookings?.nurse.uid
          )
        );
      } else {
        setData([]);
      }
    } else {
      setData([]);
    }
  }, [bookinglistdata.bookings]);

  const renderBooking = (Booking, index) => {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="6">
              <Card className="card-timeline card-plain">
                <CardBody>
                  {/* <li className="timeline-inverted"></li> */}
                  <ul className="timeline timeline-simple">
                    <li className="timeline-inverted">
                      {/* <div className="timeline-badge danger">
                        <i className="now-ui-icons objects_planet" />
                      </div> */}
                      <div className="timeline-panel">
                        <div
                          className="timeline-heading"
                          style={{ marginLeft: "90%" }}
                        >
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-round btn-icon"
                              color="default"
                              outline
                            >
                              <i className="now-ui-icons loader_gear" />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => {
                                  navigate("/Observations", {
                                    state: { Booking },
                                  });
                                }}
                              >
                                Oberservations
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  navigate("/notes", { state: { Booking } });
                                }}
                              >
                                Notes
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  navigate("/drugexposure", {
                                    state: { Booking },
                                  });
                                }}
                              >
                                Prescriptions
                              </DropdownItem>
                              {/* <DropdownItem
                                onClick={() => {
                                  navigate("/procedures", {
                                    state: { Booking },
                                  });
                                }}
                              >
                                Procedures
                              </DropdownItem> */}
                              {/* <DropdownItem
                                onClick={() => {
                                  navigate("/drugexposure");
                                }}
                              >
                                Drug Exposure
                              </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <div className="timeline-body">
                          <Col md="12">
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                              <FormGroup>
                                <p className="h4">{Booking.patientName}</p>
                              </FormGroup>
                            </GridItem>
                          </Col>
                          <Col md="12">
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                              <FormGroup>
                                <span>Estimate Time: </span>
                                <p className="description">
                                  {Booking.eventDate}
                                </p>
                              </FormGroup>
                            </GridItem>
                          </Col>
                        </div>
                      </div>
                    </li>
                  </ul>
                </CardBody>
              </Card>
              {/* <Card className="card-timeline card-plain">
                <CardBody>
                  <ul className="timeline timeline-simple">
                    <li className="timeline-inverted">
                      <div className="timeline-badge danger">
                        <i className="now-ui-icons objects_planet" />
                      </div>
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <Badge color="danger">{Booking.patientName}</Badge>
                        </div>
                        <div className="timeline-body">
                          <p>{Booking.description}</p>
                        </div>
                        <h6>
                          <i className="ti-time" />
                          {Booking.eventDate}
                        </h6>
                      </div>
                    </li>
                  </ul>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
        </div>
      </>
    );
  };

  return data.loading ? (
    <CircularLoading />
  ) : (
    <Row>
      <Col md="12">
        <Card style={{ backgroundColor: "#6b7a83" }}>
          <CardHeader>
            <CardTitle tag="h4">CONSULATIONS</CardTitle>
          </CardHeader>
          <CardBody>
            <ul>
              <Row>
                <FlatList
                  keyExtractor={(item, index) => {
                    return index.toString();
                  }}
                  list={data}
                  renderItem={renderBooking}
                  renderWhenEmpty={() => (
                    <div>There currently is no bookings!</div>
                  )}

                  //groupBy={(person) => (person.info.age > 18 ? "Over 18" : "Under 18")}
                />
              </Row>
            </ul>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
export default NurseTimeLine;
