import {
  FETCH_MEDNOTES,
  FETCH_MEDNOTES_SUCCESS,
  FETCH_MEDNOTES_FAILED,
  ADD_MEDNOTES,
  ADD_MEDNOTES_SUCCESS,
  ADD_MEDNOTES_FAILED,
  EDIT_MEDNOTES,
  EDIT_MEDNOTES_SUCCESS,
  EDIT_MEDNOTES_FAILED,
  DELETE_MEDNOTES,
  DELETE_MEDNOTES_SUCCESS,
  DELETE_MEDNOTES_FAILED,
} from "../store/types";
  
  export const INITIAL_STATE = {
    mednotes:null,
    loading: false,
    error:{
      flag:false,
      msg: null
    }
  }
  
  export const mednotesreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_MEDNOTES:
        return {
          ...state,
          loading: true,
        };
      case FETCH_MEDNOTES_SUCCESS:
        return {
          ...state,
          mednotes: action.payload,
          loading: false,
        };
      case FETCH_MEDNOTES_FAILED:
        return {
          ...state,
          mednotes: null,
          loading: false,
          error: {
            flag: true,
            msg: action.payload,
          },
        };
      case ADD_MEDNOTES:
        return {
          ...state,
          loading: true,
        };
      case ADD_MEDNOTES_SUCCESS:
        return {
          ...state,
          mednotes: action.payload,
          loading: false,
        };
      case ADD_MEDNOTES_FAILED:
        return {
          ...state,
          mednotes: null,
          loading: false,
          error: {
            flag: true,
            msg: action.payload,
          },
        };
      case EDIT_MEDNOTES:
        return {
          ...state,
          loading: true,
        };
      case EDIT_MEDNOTES_SUCCESS:
        return {
          ...state,
          mednotes: action.payload,
          loading: false,
        };
      case EDIT_MEDNOTES_FAILED:
        return {
          ...state,
          mednotes: null,
          loading: false,
          error: {
            flag: true,
            msg: action.payload,
          },
        };
      case DELETE_MEDNOTES:
        return {
          ...state,
          loading: true,
        };
      case DELETE_MEDNOTES_SUCCESS:
        return {
          ...state,
          mednotes: action.payload,
          loading: false,
        };
      case DELETE_MEDNOTES_FAILED:
        return {
          ...state,
          mednotes: null,
          loading: false,
          error: {
            flag: true,
            msg: action.payload,
          },
        };
      default:
        return state;
    }
  };