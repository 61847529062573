import {
    UPDATE_EVENT_PICKUP,
    UPDATE_EVENT_DROP,
    UPDATE_EVENT_PRACTICE,
    UPDATE_SELECTED_POINT_TYPE,
    CLEAR_EVENT_POINTS
} from "../store/types";

const INITIAL_STATE = {
    pickup: null,
    drop: null,
    practiceType: null,
    selected:'pickup'
}

export const eventreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_EVENT_PICKUP:
            return {
                ...state,
                pickup:action.payload
            };
        case UPDATE_EVENT_DROP:
            return {
                ...state,
                drop:action.payload
            };
        case UPDATE_EVENT_PRACTICE:
            return {
                ...state,
                practiceType:action.payload
            };
        case UPDATE_SELECTED_POINT_TYPE:
            return {
                ...state,
                selected:action.payload
            };
        case CLEAR_EVENT_POINTS:
            return {
                ...state,
                drop: {
                    lat:state.pickup.lat,
                    lng:state.pickup.lng,
                    add: ' '
                },
                practiceType: null,
                selected:'pickup'
            };
        default:
            return state;
    }
};