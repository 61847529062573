import { 
FETCH_DEVICEEXPO,
FETCH_DEVICEEXPO_SUCCESS,
FETCH_DEVICEEXPO_FAILED,
ADD_DEVICEEXPO,
ADD_DEVICEEXPO_SUCCESS,
  ADD_DEVICEEXPO_FAILED,
EDIT_DEVICEEXPO,
EDIT_DEVICEEXPO_SUCCESS,
EDIT_DEVICEEXPO_FAILED,
DELETE_DEVICEEXPO,
DELETE_DEVICEEXPO_SUCCESS,
DELETE_DEVICEEXPO_FAILED,
  } from "../store/types";
  
  export const INITIAL_STATE = {
    device:null,
    loading: false,
    error:{
      flag:false,
      msg: null
    }
  }
  
  export const deviceexposurereducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_DEVICEEXPO:
        return {
          ...state,
          loading: true,
        };
      case FETCH_DEVICEEXPO_SUCCESS:
        return {
          ...state,
          device: action.payload,
          loading: false,
        };
      case FETCH_DEVICEEXPO_FAILED:
        return {
          ...state,
          device: null,
          loading: false,
          error: {
            flag: true,
            msg: action.payload,
          },
        };
      case ADD_DEVICEEXPO:
        return {
          ...state,
          loading: true,
        };
      case ADD_DEVICEEXPO_SUCCESS:
        return {
          ...state,
          device: action.payload,
          loading: false,
        };
      case ADD_DEVICEEXPO_FAILED:
        return {
          ...state,
          device: null,
          loading: false,
          error: {
            flag: true,
            msg: action.payload,
          },
        };
      case EDIT_DEVICEEXPO:
        return {
          ...state,
          loading: true,
        };
      case EDIT_DEVICEEXPO_SUCCESS:
        return {
          ...state,
          device: action.payload,
          loading: false,
        };
      case EDIT_DEVICEEXPO_FAILED:
        return {
          ...state,
          device: null,
          loading: false,
          error: {
            flag: true,
            msg: action.payload,
          },
        };
      case DELETE_DEVICEEXPO:
        return {
          ...state,
          loading: true,
        };
      case DELETE_DEVICEEXPO_SUCCESS:
        return {
          ...state,
          device: action.payload,
          loading: false,
        };
      case DELETE_DEVICEEXPO_FAILED:
        return {
          ...state,
          device: null,
          loading: false,
          error: {
            flag: true,
            msg: action.payload,
          },
        };
      default:
        return state;
    }
  };