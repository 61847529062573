/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import MaterialTable, { MTableAction } from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../../components/CircularLoading";
import { Calendar as BigCalendar, dateFnsLocalizer } from "react-big-calendar";
import "react-datepicker/dist/react-datepicker.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ImageUpload from "../../components/CustomUpload/ImageUpload";
import PictureUpload from "../../components/CustomUpload/PictureUpload";
import DocumentScanner from "../../components/DocumentScanner";
import FileUploader from "components/FileUploader";
import { features, dateStyle, language } from "config";
import { FirebaseContext } from "common";
import { Button } from "reactstrap";

export default function NoPhonePatientsDependants(ParentData) {
  const { api } = useContext(FirebaseContext);
  const { addUser, editUser, deleteUser, checkUserExists } = api;
  const [data, setData] = useState([]);
  const [practices, setPractices] = useState({});
  const usersdata = useSelector((state) => state.usersdata);
  const practicetypes = useSelector((state) => state.practicetypes);
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };
  const TitleImg = (rowData) => {
    return (
      <div>
        <input type="file" />
      </div>
    );
  };

  useEffect(() => {
    console.log("i am the auth", auth);
    console.log("i am the ", usersdata.users.referralId);
    console.log(
      "i am the parent data inside the modal",
      ParentData.ParentData.id
    );
    //if statement to to add dependants to to parentnophone patient linked to  parent nophonepatient prent uid

    if (usersdata.users && auth.info.profile.userType === "practiceadmin") {
      setData(
        usersdata.users.filter(
          (user) =>
            ParentData.ParentData.id === user.Parentuid &&
            user.userType === "nophonepatient"
        )
      );
    } else {
      setData(usersdata.users);
    }
  }, [usersdata.users, auth.info.profile.userType, auth.info.uid]);

  useEffect(() => {
    if (practicetypes.practices) {
      let obj = {};
      practicetypes.practices.map(
        (practice) => (obj[practice.name] = practice.name)
      );
      setPractices(obj);
    }
  }, [practicetypes.practices]);

  const columns = [
    { title: language.first_name, field: "firstName", initialEditValue: "" },

    { title: language.last_name, field: "lastName", initialEditValue: "" },
    {
      title: language.email,
      field: "email",
      editable: "onAdd",
      render: (rowData) =>
        features.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo",
    },
    {
      title: language.mobile,
      field: "mobile",
      editable: "onAdd",
      render: (rowData) =>
        features.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo",
    },
    {
      title: "Identification Number",
      field: "identityNumber",
      editable: "onAdd",
      render: (rowData) =>
        features.AllowCriticalEditsAdmin
          ? rowData.identityNumber
          : "Hidden for Demo",
    },
    { title: "Gender", field: "gender", initialEditValue: "" },
    { title: "Age", field: "age", initialEditValue: "" },
    {
      title: "Relationship",
      field: "relationship",
      editable: "onAdd",
      initialEditValue: "",
    },
    {
      title: language.referralId,
      field: "referralId",
      editable: "onAdd",
      initialEditValue: "",
    },

    {
      title: language.account_approve,
      field: "approved",
      type: "boolean",
      initialEditValue: true,
    },
  ];
  return usersdata.loading ? (
    <CircularLoading />
  ) : (
    <MaterialTable
      title={"Dependants"}
      columns={columns}
      data={data}
      options={{
        exportButton: true,
        sorting: true,
        actionsColumnIndex: -1,
      }}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              checkUserExists(newData).then((res) => {
                if (res.users && res.users.length > 0) {
                  alert(language.user_exists);
                  reject();
                } else if (res.error) {
                  alert(language.email_or_mobile_issue);
                  reject();
                } else {
                  newData["createdByAdmin"] = true;
                  newData["userType"] = "nophonepatient";
                  newData["createdAt"] = new Date().toISOString();
                  newData["referralId"] = ParentData.ParentData.referralId;

                  let role = auth.info.profile.userType;
                  if (role === "practiceadmin") {
                    newData["practiceadmin"] = auth.info.uid;
                    newData["practiceNumber"] =
                      auth.info.profile.practiceNumber;
                    newData["Parentuid"] = ParentData.ParentData.id;
                  }

                  dispatch(addUser(newData));
                  resolve();
                }
              });
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              dispatch(editUser(oldData.id, newData));
            }, 600);
          }),

        onRowDelete: (oldData) =>
          features.AllowCriticalEditsAdmin
            ? new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  dispatch(deleteUser(oldData.id));
                }, 600);
              })
            : new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  alert(language.demo_mode);
                }, 600);
              }),
      }}
    />
  );
}
