/* eslint-disable */
import React, { useRef } from "react";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { Print } from "@material-ui/icons";
import { useReactToPrint } from "react-to-print";

//import { ComponentToPrint } from './ComponentToPrint';

// eslint-disable-next-line react/prop-types
function DocumentScanner({ setDataUri, setCamStop, setOpenFPModal }) {
  let componentRef = useRef();
  function handleTakePhoto() {
    console.log("takePhoto");
  }

  function handleTakePhotoAnimationDone(dataUri) {
    // Do stuff with the photo....
    setDataUri(dataUri);
    //window.print(dataUri)

    handlePrint(dataUri);
    setOpenFPModal(true);

    console.log("Photo taken", dataUri);
  }

  function handleCameraError(error) {
    console.log("handleCameraError", error);
  }

  function handleCameraStart(stream) {
    console.log("handleCameraStart");
    if (handleCameraStop) {
      setCamStop(true);
    }
  }

  function handleCameraStop() {
    console.log("handleCameraStop");
  }

  const handleCloseEvent = (e) => {
    e.preventDefault();
    setOpenFPModal(false);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
  const isFullscreen = false;

  // const renderScanner =() => {
  //   // eslint-disable-next-line no-unused-expressions
  //   return (
  //   <div>

  // </div>
  //   )
  // }

  return (
    <div>
      <Print>Scan me</Print>

      <Camera
        onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
        isFullscreen={isFullscreen}
        onTakePhoto={(dataUri) => {
          handleTakePhoto(dataUri);
        }}
        //onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
        onCameraError={(error) => {
          handleCameraError(error);
        }}
        idealFacingMode={FACING_MODES.ENVIRONMENT}
        idealResolution={{ width: 640, height: 480 }}
        imageType={IMAGE_TYPES.JPG}
        imageCompression={0.97}
        isMaxResolution={true}
        isImageMirror={false}
        isSilentMode={false}
        isDisplayStartCameraError={true}
        //isFullscreen = {false}
        sizeFactor={1}
        onCameraStart={(stream) => {
          handleCameraStart(stream);
        }}
        onCameraStop={() => {
          handleCameraStop();
        }}
      />
    </div>
  );
}

export default DocumentScanner;
