import {
    FETCH_NOTES,
    FETCH_NOTES_SUCCESS,
    FETCH_NOTES_FAILED,
    SEND_NOTES,
    STOP_FETCH_NOTES
} from "../store/types";

const INITIAL_STATE = {
    doctorsnotes: [],
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const doctornotesreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_NOTES:
            return {
                ...state,
                loading: true
            };
        case FETCH_NOTES_SUCCESS:
            return {
                ...state,
                doctorsnotes: action.payload,
                loading: false
            };
        case FETCH_NOTES_FAILED:
            return {
                ...state,
                doctorsnotes:null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case SEND_NOTES:
            return state;
        case STOP_FETCH_NOTES:
            return INITIAL_STATE;
        default:
            return state;
    }
};