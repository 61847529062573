/*eslint-disable*/
import React, { useState, useEffect } from "react";
// import MaterialTable from "material-table";
import CircularLoading from "../../components/CircularLoading";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
// import { features, dateStyle, language } from "config";
import { FirebaseContext } from "common";
// import { Book } from "@material-ui/icons";
import FlatList from "flatlist-react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Row,
  Col,
} from "reactstrap";

const PatientBookingOffers = (props) => {
  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      borderRight: "2px solid",
      borderRightColor: "#ff0000",
      borderBlockStartColor: "#ff0000",
      borderBlockStart: "2px solid",
      // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    },
  });
  const auth = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const classes = useStyles();
  const offer = useSelector((state) => state.bookingoffersdata);
  const navigate = useNavigate();
  useEffect(() => {
    if (auth.info.profile.userType === "patient") {
      if (bookinglistdata.bookings) {
        setData(
          bookinglistdata.bookings.filter(
            (bookings) => bookings.status === "NEW"
          )
        );
      } else {
        setData([]);
      }
    } else if (auth.info.profile.userType === "doctor") {
      if (bookinglistdata.bookings) {
        setData(
          bookinglistdata.bookings.filter(
            (bookings) => bookings.status === "ACCEPTED"
          )
        );
      } else {
        setData([]);
      }
    } else {
      if (bookinglistdata.bookings) {
        setData(
          bookinglistdata.bookings.filter(
            (bookings) => bookings.status === "NEW"
          )
        );
      } else {
        setData([]);
      }
    }
  }, [bookinglistdata.bookings]);

  const renderBooking = (Booking, index) => {
    return (
      <Col md="4">
        <Card className={classes.root}>
          <CardHeader as="h5">
            {/* <CardTitle tag="h4">
              <div className="picture">
                   <img
                          alt="..."
                          className="picture-src"
                          src={Booking?.doctor?.profile_image}
                        />
                     </div> 
                        {" "}
    
                        <span> <p className="description"> DR. {Booking?.BookingOffers?.doctor?.firstName +
                                  " " +
                                  Booking?.doctor?.lastName}
                              </p> </span>
               
              </CardTitle> */}
          </CardHeader>
          <CardBody>
            <Row mb="12">
              <Col md="12">
                <FormGroup>
                  <span>Type of Booking: </span>
                  <p className="description">{Booking.serviceType}</p>
                </FormGroup>
              </Col>
              {Booking.adjustedtimeStart ? (
                <Col md="12">
                  <FormGroup>
                    <span>New Booking Date and Time of Booking: </span>
                    <p className="description">{Booking.adjustedtimeStart}</p>
                  </FormGroup>
                </Col>
              ) : null}
              {!Booking.adjustedtimeStart ? (
                <Col md="12">
                  <FormGroup>
                    <span>Date and Time of Booking: </span>
                    <p className="description">{Booking.eventDate}</p>
                  </FormGroup>
                </Col>
              ) : null}
            </Row>
            <Row mb="12">
              <Col md="12">
                <FormGroup>
                  <span>Booking Status: </span>
                  <p className="description">{Booking.status}</p>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button
              style={{ marginLeft: 10, marginRight: 10 }}
              color="primary"
              onClick={() => {
                navigate("/patientbookingdetails", { state: { Booking } });
              }}
            >
              Booking Details
            </Button>
            <Button
              style={{ marginLeft: 10, marginRight: 10 }}
              color="primary"
              onClick={() => {
                navigate({
                  pathname: "/bookingoffers",
                  state: { Booking },
                });
              }}
            >
              Offers
            </Button>
          </CardFooter>
        </Card>
      </Col>
    );
  };

  return data.loading ? (
    <CircularLoading />
  ) : (
    <Row>
      <Col md="12">
        <Card>
          <CardHeader>
            <CardTitle tag="h4">BOOKINGS</CardTitle>
          </CardHeader>
          <CardBody>
            <ul>
              <Row>
                <FlatList
                  keyExtractor={(item, index) => {
                    return index.toString();
                  }}
                  list={data}
                  renderItem={renderBooking}
                  renderWhenEmpty={() => (
                    <div>There currently is no booking offers!</div>
                  )}

                  //groupBy={(person) => (person.info.age > 18 ? "Over 18" : "Under 18")}
                />
              </Row>
            </ul>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default PatientBookingOffers;
