/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { language } from "config";
import { makeStyles } from "@material-ui/core/styles";
import UsersCombo from "../../components/UsersCombo";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import AlertDialog from "../../components/AlertDialog";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import { TextField } from "@material-ui/core";
import { FirebaseContext } from "common";
import { useNavigate, useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    alignContent: "center",
  },
  title: {
    color: "#000",
  },
  gridcontainer: {
    alignContent: "center",
  },
  items: {
    margin: 0,
    width: "100%",
  },
  input: {
    fontSize: 18,
    color: "#000",
  },
  inputdimmed: {
    fontSize: 18,
    color: "#737373",
  },
  practicephoto: {
    height: "18px",
    marginRight: "10px",
  },
  buttonStyle: {
    margin: 0,
    width: "100%",
    height: "100%",
  },
  rootTwo: {
    minWidth: 275,
    border: "1px solid",
    borderColor: "#ff0000",
    //borderBlockStartColor: "#ff0000",
    //borderBlockStart: "1px solid",
    background: "linear-gradient(45deg, #FFFFFF 60%, skyblue 90%)",
  },
}));

const NurseBookingInformation = (props) => {
  ////////////////////REduX////////////////////
  const { api } = useContext(FirebaseContext);
  const { GetDateString, updateBooking, fetchChatMessages, clearLoginError } =
    api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  // const Booking?.Medical_Information? = useSelector(
  //   (state) => state.medicalRecords.medicalHistoryRecords
  // );
  ////////////////////constant////////////////////
  const location = useLocation();
  const Booking = location.state.Booking;

  const [updatebooking, setupdatebooking] = useState(Booking);
  const usersdata = useSelector((state) => state.doctors);
  const [Doctors, setDoctors] = useState([]);
  const navigate = useNavigate();

  const [Nurses, setNurses] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [rejectbuttonclicked, setrejectbuttonclicked] = useState(false);
  const [acceptbuttonclicked, setacceptbuttonclicked] = useState(false);
  const [commonAlert, setCommonAlert] = useState({
    open: false,
    msg: "",
    title: "",
  });

  useEffect(() => {
    // console.log(Booking)
    let arr = [];
    usersdata.users.map((user) => {
      if (
        user.practiceNumber === auth.info.profile.practiceNumber &&
        user.userType === "doctor"
      ) {
        arr.push({
          firstName: user.firstName,
          lastName: user.lastName,
          mobile: user.mobile,
          email: user.email,
          uid: user.id,
          profile_image: user.profile_image,
          desc:
            user.firstName +
            " " +
            user.lastName +
            " (" +
            user.mobile +
            ") " +
            user.email,
          pushToken: user.pushToken,
        });
      }
    });
    setDoctors(arr);
  }, []);

  useEffect(() => {
    let arr = [];
    usersdata.users.map((user) => {
      if (
        user.practiceNumber === auth.info.profile.practiceNumber &&
        user.userType === "nurse"
      ) {
        arr.push({
          firstName: user.firstName,
          lastName: user.lastName,
          mobile: user.mobile,
          email: user.email,
          uid: user.id,
          profile_image: user.profile_image,
          desc:
            user.firstName +
            " " +
            user.lastName +
            " (" +
            user.mobile +
            ") " +
            user.email,
          pushToken: user.pushToken,
        });
      }
    });
    setNurses(arr);
  }, []);

  /////////////useeffect used for the reject button & accept button//////////////
  useEffect(() => {
    if (rejectbuttonclicked === true) {
      dispatch(updateBooking(updatebooking));
      navigate("/bookingrequest");
    } else {
      if (acceptbuttonclicked === true) {
        if (updatebooking.status === "ACCEPTED") {
          setCommonAlert({
            open: true,
            msg: "You have accepted your booking, please be ready 10 mintues before your booking",
            title: "Thank you",
          });
        } else {
          navigate("/bookingrequest");
        }

        dispatch(updateBooking(updatebooking));
      }
    }
  }, [rejectbuttonclicked, acceptbuttonclicked]);

  //////////////functions needed for code to run//////////////
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    if (updatebooking.status === "ACCEPTED") {
      navigate("/bookingrequest");
    }
    setCommonAlert({ open: false, msg: "" });
    if (auth.error.flag) {
      dispatch(clearLoginError());
    }
  };

  const HandleReject = () => {
    if (auth.info.profile.userType === "patient") {
      setupdatebooking({ ...updatebooking, status: "NEW", doctor: null });
      setrejectbuttonclicked(true);
    }
  };
  const HandleAccept = () => {
    if (Booking.status === "PENDING") {
      setupdatebooking({ ...updatebooking, status: "ACCEPTED" });
      setacceptbuttonclicked(true);
    } else {
      setupdatebooking({ ...updatebooking, status: "PENDING" });
      setOpen(false);
      setacceptbuttonclicked(true);
    }
  };
  const closeMed = () => {
    setOpen(false);
  };
  //////////////////////Render the alert Dialog/////////////////
  const renderAlertDialog = () => {
    return (
      <AlertDialog
        open={commonAlert.open}
        onClose={handleCommonAlertClose}
        title={commonAlert.title}
      >
        {commonAlert.msg}
      </AlertDialog>
    );
  };

  const renderModal = () => {
    return (
      <Modal isOpen={open} toggle={() => setOpen(false)} centered={true}>
        <ModalHeader>Medical Information</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <span>Basic Information: </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label>Date of Birth:</label>
                {Booking?.Medical_Information?.Basic_info?.DOB && (
                  <p className="description">
                    {Booking?.Medical_Information?.Basic_info?.DOB}
                  </p>
                )}
                {!Booking?.Medical_Information?.Basic_info?.DOB && (
                  <p className="description">{"N/A"}</p>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label>Sex:</label>
                {Booking?.Medical_Information?.Basic_info?.Sex && (
                  <p className="description">
                    {Booking?.Medical_Information?.Basic_info?.Sex}
                  </p>
                )}
                {!Booking?.Medical_Information?.Basic_info?.Sex && (
                  <p className="description">{"N/A"}</p>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label>Blood Type:</label>
                {Booking?.Medical_Information?.Basic_info?.BloodType && (
                  <p className="description">
                    {Booking?.Medical_Information?.Basic_info?.BloodType}
                  </p>
                )}
                {!Booking?.Medical_Information?.Basic_info?.BloodType && (
                  <p className="description">{"N/A"}</p>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label>Height:</label>
                {Booking?.Medical_Information?.Basic_info?.Height && (
                  <p className="description">
                    {Booking?.Medical_Information?.Basic_info?.Height}
                  </p>
                )}
                {!Booking?.Medical_Information?.Basic_info?.Height && (
                  <p className="description">{"N/A"}</p>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label>Weight:</label>
                {Booking?.Medical_Information?.Basic_info?.Weight && (
                  <p className="description">
                    {Booking?.Medical_Information?.Basic_info?.Weight}
                  </p>
                )}
                {!Booking?.Medical_Information?.Basic_info?.Weight && (
                  <p className="description">{"N/A"}</p>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label>Body Mass Index(BMI):</label>
                {Booking?.Medical_Information?.Basic_info?.BMI && (
                  <p className="description">
                    {Booking?.Medical_Information?.Basic_info?.BMI}
                  </p>
                )}
                {!Booking?.Medical_Information?.Basic_info?.BMI && (
                  <p className="description">{"N/A"}</p>
                )}
              </FormGroup>
            </Col>
          </Row>
          {/* ////CHILDHOOD ILLNESSES */}
          <Row>
            <FormGroup>
              <span>Childhood Illness: </span>
            </FormGroup>
            {Booking?.Medical_Information?.Childhood_illness?.Measles && (
              <Col md="4">
                <FormGroup>
                  <p className="description">{"Measles"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Childhood_illness?.Chickenpox && (
              <Col>
                <FormGroup>
                  <p className="description">{"Chickenpox"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Childhood_illness?.Mumps && (
              <Col md="4">
                <FormGroup>
                  <p className="description">{"Mumps"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Childhood_illness?.Polio && (
              <Col>
                <FormGroup>
                  <p className="description">{"Polio"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Childhood_illness?.Rheumatic && (
              <Col md="4">
                <FormGroup>
                  <p className="description">{"Rheumatic"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Childhood_illness?.Rubellac && (
              <Col>
                <FormGroup>
                  <p className="description">{"Rubellac"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          {/* IMMUNAZATION */}
          <Row>
            <Col>
              <FormGroup>
                <span>Immunization: </span>
              </FormGroup>
              <Row>
                <Col md="6">
                  <h5 className="card-category">Name</h5>
                  <FormGroup>
                    {Booking?.Medical_Information?.Immunizations_an_Dates
                      ?.Tetanus?.checked && (
                      <p className="description">{"Tetanus"}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <h5 className="card-category">Date</h5>
                  <FormGroup>
                    {Booking?.Medical_Information?.Immunizations_an_Dates
                      ?.Tetanus?.Date && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Immunizations_an_Dates
                            ?.Tetanus?.Date
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                {Booking?.Medical_Information?.Immunizations_an_Dates
                  ?.Hepatitis_A?.checked && (
                  <Col md="6">
                    <FormGroup>
                      <p className="description">{"Hepatitis A"}</p>
                    </FormGroup>
                  </Col>
                )}

                {Booking?.Medical_Information?.Immunizations_an_Dates
                  ?.Hepatitis_A?.Date && (
                  <Col>
                    <FormGroup>
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Immunizations_an_Dates
                            ?.Hepatitis_A?.Date
                        }
                      </p>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row>
                {Booking?.Medical_Information?.Immunizations_an_Dates
                  ?.Chickenpox2?.checked && (
                  <Col md="6">
                    <FormGroup>
                      <p className="description">{"Chickenpox"}</p>
                    </FormGroup>
                  </Col>
                )}

                {Booking?.Medical_Information?.Immunizations_an_Dates
                  ?.Chickenpox2?.Date && (
                  <Col>
                    <FormGroup>
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Immunizations_an_Dates
                            ?.Chickenpox2?.Date
                        }
                      </p>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row>
                {Booking?.Medical_Information?.Immunizations_an_Dates
                  ?.Hepatitis_B?.checked && (
                  <Col md="6">
                    <FormGroup>
                      <p className="description">{"Hepatitis B"}</p>
                    </FormGroup>
                  </Col>
                )}

                {Booking?.Medical_Information?.Immunizations_an_Dates
                  ?.Hepatitis_B?.Date && (
                  <Col>
                    <FormGroup>
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Immunizations_an_Dates
                            ?.Hepatitis_B?.Date
                        }
                      </p>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row>
                {Booking?.Medical_Information?.Immunizations_an_Dates?.MMR
                  ?.checked && (
                  <Col md="6">
                    <FormGroup>
                      <p className="description">{"MMR"}</p>
                    </FormGroup>
                  </Col>
                )}

                {Booking?.Medical_Information?.Immunizations_an_Dates?.MMR
                  ?.Date && (
                  <Col>
                    <FormGroup>
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Immunizations_an_Dates
                            ?.MMR?.Date
                        }
                      </p>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row>
                {Booking?.Medical_Information?.Immunizations_an_Dates
                  ?.Meningococcal?.checked && (
                  <Col md="6">
                    <FormGroup>
                      <p className="description">{"Meningococcal"}</p>
                    </FormGroup>
                  </Col>
                )}

                {Booking?.Medical_Information?.Immunizations_an_Dates
                  ?.Meningococcal?.Date && (
                  <Col>
                    <FormGroup>
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Immunizations_an_Dates
                            ?.Meningococcal?.Date
                        }
                      </p>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row>
                {Booking?.Medical_Information?.Immunizations_an_Dates?.Pneumonia
                  ?.checked && (
                  <Col md="6">
                    <FormGroup>
                      <p className="description">{"Pneumonia"}</p>
                    </FormGroup>
                  </Col>
                )}

                {Booking?.Medical_Information?.Immunizations_an_Dates?.Pneumonia
                  ?.Date && (
                  <Col>
                    <FormGroup>
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Immunizations_an_Dates
                            ?.Pneumonia?.Date
                        }
                      </p>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row>
                {Booking?.Medical_Information?.Immunizations_an_Dates?.influenza
                  ?.checked && (
                  <Col md="6">
                    <FormGroup>
                      <p className="description">{"influenza"}</p>
                    </FormGroup>
                  </Col>
                )}

                {Booking?.Medical_Information?.Immunizations_an_Dates?.influenza
                  ?.Date && (
                  <Col>
                    <FormGroup>
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Immunizations_an_Dates
                            ?.influenza?.Date
                        }
                      </p>
                    </FormGroup>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          {/* SURGERIES */}
          <Row>
            <Col>
              <FormGroup>
                <span>Surgeries: </span>
              </FormGroup>
              <Row>
                <Col md="6">
                  <h5 className="card-category">Reason</h5>
                  <FormGroup>
                    {Booking?.Medical_Information?.Surgries?.Surgry1st
                      ?.ReasonOfSurgry && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Surgries?.Surgry1st
                            ?.ReasonOfSurgry
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <h5 className="card-category">Date</h5>
                  <FormGroup>
                    {Booking?.Medical_Information?.Surgries?.Surgry1st
                      ?.YearOfSurgry && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Surgries?.Surgry1st
                            ?.YearOfSurgry
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <h5 className="card-category">Hospital</h5>
                  <FormGroup>
                    {Booking?.Medical_Information?.Surgries?.Surgry1st
                      ?.HospitalOfSurgry && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Surgries?.Surgry1st
                            ?.HospitalOfSurgry
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    {Booking?.Medical_Information?.Surgries?.Surgry2nd
                      ?.ReasonOfSurgry && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Surgries?.Surgry2nd
                            ?.ReasonOfSurgry
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    {Booking?.Medical_Information?.Surgries?.Surgry2nd
                      ?.YearOfSurgry && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Surgries?.Surgry2nd
                            ?.YearOfSurgry
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    {Booking?.Medical_Information?.Surgries?.Surgry2nd
                      ?.HospitalOfSurgry && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Surgries?.Surgry2nd
                            ?.HospitalOfSurgry
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    {Booking?.Medical_Information?.Surgries?.Surgry3rd
                      ?.ReasonOfSurgry && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Surgries?.Surgry3rd
                            ?.ReasonOfSurgry
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    {Booking?.Medical_Information?.Surgries?.Surgry3rd
                      ?.YearOfSurgry && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Surgries?.Surgry3rd
                            ?.YearOfSurgry
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    {Booking?.Medical_Information?.Surgries?.Surgry3rd
                      ?.HospitalOfSurgry && (
                      <p className="description">
                        {
                          Booking?.Medical_Information?.Surgries?.Surgry3rd
                            ?.HospitalOfSurgry
                        }
                      </p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <FormGroup>
                  <span>Social History: </span>
                </FormGroup>
                <Row>
                  <Col md="3">
                    <label>Occupation :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.occupation && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.occupation
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                  <Col md="3">
                    <label>Maritial Status :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.maritalstatus && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.maritalstatus
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                  <Col md="3">
                    <label>Exercise :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.exercise && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.exercise
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                  <Col md="3">
                    <label>Sexual Orientation :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.sexualorientation && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.sexualorientation
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <label>Stress Level :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.stressfactor && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.stressfactor
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                  <Col md="3">
                    <label>Home Language :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.language && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.language
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                  <Col md="3">
                    <label>Dependents :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.children && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.children
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                  <Col md="3">
                    <label>Diet :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.diet && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.diet
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <label>Alcohol Consumption :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.alcoholconsumption && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              .SocialHistory?.alcoholconsumption
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                  <Col md="3">
                    <label>Smoking :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.smoking && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.smoking
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                  <Col md="3">
                    <label>Substance Abuse :</label>
                    {Booking?.Medical_Information?.LifeStyle_History
                      ?.SocialHistory?.substanceabuse && (
                      <FormGroup>
                        <p className="description">
                          {
                            Booking?.Medical_Information?.LifeStyle_History
                              ?.SocialHistory?.substanceabuse
                          }
                        </p>
                      </FormGroup>
                    )}
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
          <FormGroup>
            <FormGroup color="default" tag="span">
              Your Current Medical History
            </FormGroup>
          </FormGroup>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Anemia && (
              <Col md="4">
                <FormGroup>
                  <p className="description">{"Anemia"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Blood_Colts && (
              <Col>
                <FormGroup>
                  <p className="description">{"Blood Colts"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Alcohol_Abuse && (
              <Col>
                <FormGroup>
                  <p className="description">{"Alcohol Abuse"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Heart_Disease && (
              <Col md="4">
                <FormGroup>
                  <p className="description">{"Heart Disease"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Anesthetic_Complication && (
              <Col>
                <FormGroup>
                  <p className="description">{"Anesthetic Complication"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Anxiety_Disorder && (
              <Col>
                <FormGroup>
                  <p className="description">{"Anxiety Disorder"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Arthritis && (
              <Col>
                <FormGroup>
                  <p className="description">{"Arthritis"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Asthma && (
              <Col>
                <FormGroup>
                  <p className="description">{"Asthma"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>

          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Birth_Defects && (
              <Col>
                <FormGroup>
                  <p className="description">{"Birth Defects"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Bladder_Problems && (
              <Col>
                <FormGroup>
                  <p className="description">{"Bladder Problems"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Bleeding_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Bleeding Disease"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Blood_Clots && (
              <Col>
                <FormGroup>
                  <p className="description">{"Blood Clots"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Blood_Transfusions && (
              <Col>
                <FormGroup>
                  <p className="description">{"Blood Transfusions"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Bowel_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Bowel_Disease"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Breast_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Breast Cancer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Cervical_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Cervical Cancer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Colon_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Colon Cancer"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Depression && (
              <Col>
                <FormGroup>
                  <p className="description">{"Depression"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Diabetes && (
              <Col>
                <FormGroup>
                  <p className="description">{"Diabetes"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.GrowthDevelopment_Disorder && (
              <Col>
                <FormGroup>
                  <p className="description">{"GrowthDevelopment Disorder"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.HIV && (
              <Col>
                <FormGroup>
                  <p className="description">{"HIV"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Hearing_Impairment && (
              <Col>
                <FormGroup>
                  <p className="description">{"Hearing Impairment"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Heart_Attack && (
              <Col>
                <FormGroup>
                  <p className="description">{"Heart Attack"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Heart_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Heart Disease"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Heart_Pain_Angina && (
              <Col>
                <FormGroup>
                  <p className="description">{"Heart Pain Angina"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Hepatitis_A && (
              <Col>
                <FormGroup>
                  <p className="description">{"Hepatitis A"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Hepatitis_B && (
              <Col>
                <FormGroup>
                  <p className="description">{"Hepatitis B"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Hepatitis_C && (
              <Col>
                <FormGroup>
                  <p className="description">{"Hepatitis C"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.High_Blood_Pressure && (
              <Col>
                <FormGroup>
                  <p className="description">{"High Blood Pressure"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>

          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.High_Cholesterol && (
              <Col>
                <FormGroup>
                  <p className="description">{"High Cholesterol"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Hives && (
              <Col>
                <FormGroup>
                  <p className="description">{"Hives"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Kidney_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Kidney Disease"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Liver_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Liver Cancer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Liver_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Liver Disease"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Lung_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Lung Cancer"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Liver_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Liver Disease"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Lung_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Lung Cancer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Lung_Respiratory_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Lung Respiratory Disease"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>

          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Mental_Illness && (
              <Col>
                <FormGroup>
                  <p className="description">{"Mental Illness"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Migraines && (
              <Col>
                <FormGroup>
                  <p className="description">{"Migraines"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Osteoporosis && (
              <Col>
                <FormGroup>
                  <p className="description">{"Osteoporosis"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>

          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Other_Disease_Cancer_or_Significant_Medical_Illness && (
              <Col>
                <FormGroup>
                  <p className="description">{"Other Disease Cancers"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Other_Disease_Cancer_or_Significant_Medical_Illness && (
              <Col>
                <FormGroup>
                  <p className="description">{"Other Signficant Illness"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Prostate_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Prostate Cancer"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>

          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Rectal_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Rectal Cancer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Reflux_GERD && (
              <Col>
                <FormGroup>
                  <p className="description">{"Reflux Gerd"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Severe_Allergy && (
              <Col>
                <FormGroup>
                  <p className="description">{"Severe Allergy"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Sexually_Transmitted_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"STD"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Skin_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Skin Cancer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Stroke_CVA_of_the_Brain && (
              <Col>
                <FormGroup>
                  <p className="description">{"Stroke of the Brain"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Suicide_Attempt && (
              <Col>
                <FormGroup>
                  <p className="description">{"Suicide Attempt"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Thyroid_Problems && (
              <Col>
                <FormGroup>
                  <p className="description">{"Thyroid Problems"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Ulcer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Ulcer"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medical_History?.medicalhistory
              ?.Visual_Impairment && (
              <Col>
                <FormGroup>
                  <p className="description">{"Visual Impairment"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            <FormGroup>
              <span>Medication: </span>
            </FormGroup>
            {Booking?.Medical_Information?.Medication?.Medication1?.Drug && (
              <Col md="6">
                <label>Medication Name</label>
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication1?.Drug
                    }
                  </p>
                </FormGroup>
              </Col>
            )}

            {Booking?.Medical_Information?.Medication?.Medication1?.Dose && (
              <Col>
                <label>Dose</label>
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication1?.Dose
                    }
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medication?.Medication2?.Drug && (
              <Col md="6">
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication2?.Drug
                    }
                  </p>
                </FormGroup>
              </Col>
            )}

            {Booking?.Medical_Information?.Medication?.Medication2?.Dose && (
              <Col>
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication2?.Dose
                    }
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medication?.Medication3?.Drug && (
              <Col md="6">
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication3?.Drug
                    }
                  </p>
                </FormGroup>
              </Col>
            )}

            {Booking?.Medical_Information?.Medication?.Medication3?.Dose && (
              <Col>
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication3?.Dose
                    }
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medication?.Medication4?.Drug && (
              <Col md="6">
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication4?.Drug
                    }
                  </p>
                </FormGroup>
              </Col>
            )}

            {Booking?.Medical_Information?.Medication?.Medication4?.Dose && (
              <Col>
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication4?.Dose
                    }
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medication?.Medication5?.Drug && (
              <Col md="6">
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication5?.Drug
                    }
                  </p>
                </FormGroup>
              </Col>
            )}

            {Booking?.Medical_Information?.Medication?.Medication5?.Dose && (
              <Col>
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication5?.Dose
                    }
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Medication?.Medication6?.Drug && (
              <Col md="6">
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication6?.Drug
                    }
                  </p>
                </FormGroup>
              </Col>
            )}

            {Booking?.Medical_Information?.Medication?.Medication6?.Dose && (
              <Col>
                <FormGroup>
                  <p className="description">
                    {
                      Booking?.Medical_Information?.Basic_info?.Medication
                        ?.Medication6?.Dose
                    }
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            <FormGroup>
              <span>Family Medical History: </span>
            </FormGroup>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.medicalhistory?.Family_Medical_History?.Fmedicalhistory
              ?.Adopted && (
              <Col>
                <FormGroup>
                  <p className="description">{"Adopted"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Alcohol_Abuse && (
              <Col>
                <FormGroup>
                  <p className="description">{" Alcohol Abuse"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Anemia && (
              <Col>
                <FormGroup>
                  <p className="description">{"Aneamia"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Anesthetic_Complication && (
              <Col>
                <FormGroup>
                  <p className="description">{"Anesthetic Complication"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Anxiety_Disorder && (
              <Col>
                <FormGroup>
                  <p className="description">{"Anxiety Disorder"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Arthritis && (
              <Col>
                <FormGroup>
                  <p className="description">{"Arthritis"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Asthma && (
              <Col>
                <FormGroup>
                  <p className="description">{"Asthma"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Autoimmune_Problems && (
              <Col>
                <FormGroup>
                  <p className="description">{"Autoimmune Problems"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Birth_Defects && (
              <Col>
                <FormGroup>
                  <p className="description">{"Birth Defects"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Bladder_Problems && (
              <Col>
                <FormGroup>
                  <p className="description">{"Bladder Problems"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Bleeding_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{" Bleeding Disease"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Blood_Clots && (
              <Col>
                <FormGroup>
                  <p className="description">{" Blood Clots"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Blood_Transfusions && (
              <Col>
                <FormGroup>
                  <p className="description">{"Blood Transfusions"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Bowel_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{" Bowel Disease"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Breast_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{" Breast Cancer"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Cervical_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Cervical Cancer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Colon_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Colon Cancer "}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Depression && (
              <Col>
                <FormGroup>
                  <p className="description">{"Depression"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Diabetes && (
              <Col>
                <FormGroup>
                  <p className="description">{"Diabetes"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory.F_GrowthDevelopment_Disorder && (
              <Col>
                <FormGroup>
                  <p className="description">{"Growth Development Disorder"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_HIV && (
              <Col>
                <FormGroup>
                  <label>Height</label>
                  <p className="description">{" HIV"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Hearing_Impairment && (
              <Col>
                <FormGroup>
                  <p className="description">{"Hearing Impairment"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Heart_Attack && (
              <Col>
                <FormGroup>
                  <p className="description">{" Heart Attack"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Heart_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Heart Disease"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Heart_Pain_Angina && (
              <Col>
                <FormGroup>
                  <p className="description">{" Heart Pain Angina "}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Hepatitis_A && (
              <Col>
                <FormGroup>
                  <p className="description">{"Hepatitis A"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Hepatitis_B && (
              <Col>
                <FormGroup>
                  <p className="description">{"Hepatitis B"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Hepatitis_C && (
              <Col>
                <FormGroup>
                  <p className="description">{"Hepatitis C"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_High_Blood_Pressure && (
              <Col>
                <FormGroup>
                  <p className="description">{"High Blood Pressure"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>

          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_High_Cholesterol && (
              <Col>
                <FormGroup>
                  <p className="description">{"High Cholesterol"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Hives && (
              <Col>
                <FormGroup>
                  <p className="description">{" Hives"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Kidney_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Kidney Disease "}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Liver_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Liver Cancer "}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Liver_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Liver Disease "}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Lung_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{" Lung Cancer"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory.F_Lung_Respiratory_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">{"Lung Respiratory Disease"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Mental_Illness && (
              <Col>
                <FormGroup>
                  <p className="description">{"Mental Illness"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Migraines && (
              <Col>
                <FormGroup>
                  <p className="description">{"Migraines"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_NONE_of_the_Above && (
              <Col>
                <FormGroup>
                  <p className="description">{"NONE"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Osteoporosis && (
              <Col>
                <FormGroup>
                  <p className="description">{"Osteoporosis"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory
              ?.F_Other_Disease_Cancer_or_Significant_Medical_Illness && (
              <Col>
                <FormGroup>
                  <p className="description">
                    {"Other Disease Cancer or Significant Medical Illness"}
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Prostate_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Prostate Cancer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Rectal_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Rectal Cancer"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Reflux_GERD && (
              <Col>
                <FormGroup>
                  <p className="description">{"Reflux GERD"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Seizures_Convulsions && (
              <Col>
                <FormGroup>
                  <p className="description">{"Seizures Convulsions"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Severe_Allergy && (
              <Col>
                <FormGroup>
                  <p className="description">{"Severe Allergy"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Sexually_Transmitted_Disease && (
              <Col>
                <FormGroup>
                  <p className="description">
                    {"Sexually Transmitted Disease"}
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Skin_Cancer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Skin Cancer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Stroke_CVA_of_the_Brain && (
              <Col>
                <FormGroup>
                  <p className="description">{"Stroke CVA of the Brain"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Suicide_Attempt && (
              <Col>
                <FormGroup>
                  <p className="description">{"Suicide Attempt"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Thyroid_Problems && (
              <Col>
                <FormGroup>
                  <p className="description">{"Thyroid Problems"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Ulcer && (
              <Col>
                <FormGroup>
                  <p className="description">{"Ulcer"}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.F_Visual_Impairment && (
              <Col>
                <FormGroup>
                  <p className="description">{"Visual Impairment"}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.Family_History_unknow && (
              <Col>
                <FormGroup>
                  <p className="description">{"Family History unknown "}</p>
                </FormGroup>
              </Col>
            )}
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.Female_Family && (
              <Col>
                <FormGroup>
                  <p className="description">
                    {
                      "Mother, Grandmother, or Sister developed heart disease before the age of 65"
                    }
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            {Booking?.Medical_Information?.Family_Medical_History
              ?.Fmedicalhistory?.Male_Family && (
              <Col>
                <FormGroup>
                  <p className="description">
                    {
                      "Father, Grandfather, or Brother developed heart disease before the age of 65"
                    }
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={closeMed}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  };
  const renderBookingData = () => {
    return (
      <Row>
        <Col>
          <FormGroup>
            <span>Booking Name: </span>
            <p className="description">{Booking?.bookingName}</p>
          </FormGroup>

          <FormGroup>
            <span>Type of Service: </span>
            <p className="description">{Booking?.serviceType}</p>
          </FormGroup>

          <FormGroup>
            <span>Booking ID: </span>
            <p className="description">{Booking?.id}</p>
          </FormGroup>

          <FormGroup>
            <span>Booking Status: </span>
            <p className="description">{Booking?.status}</p>
          </FormGroup>

          <FormGroup>
            <span>Type of Doctor/Practice They Looking for: </span>
            <p className="description">{Booking?.specialityType}</p>
          </FormGroup>

          <Row>
            <Col md="6">
              {" "}
              <Card className={classes.rootTwo}>
                <CardHeader>
                  <CardTitle tag="h4">Patient Details</CardTitle>
                  <div className="picture">
                    <img
                      alt="..."
                      className="picture-src"
                      src={Booking?.patientImage}
                    />
                  </div>
                  {""}
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <span>Name of Patient </span>
                    <p className="description">{Booking?.patientName}</p>
                  </FormGroup>

                  <FormGroup>
                    <span>Patient Contant Number: </span>
                    <p className="description">{Booking?.patientContact}</p>
                  </FormGroup>

                  <FormGroup>
                    <span>Email of Patient : </span>
                    <p className="description">{Booking?.patientEmail}</p>
                  </FormGroup>

                  {Booking?.adjustedtimeStart ? (
                    <FormGroup>
                      <span>New Booking Date and Time of Booking: </span>
                      <p className="description">{Booking?.adjustedtimeStart}</p>
                    </FormGroup>
                  ) : null}
                  {!Booking?.adjustedtimeStart ? (
                    <FormGroup>
                      <span>Date and Time of Booking: </span>
                      <p className="description">{Booking?.eventDate}</p>
                    </FormGroup>
                  ) : null}
                </CardBody>
                <CardFooter>
                  <Button
                    color="primary"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Medical History
                  </Button>
                </CardFooter>
              </Card>
              {Booking?.status !== "NEW" && Booking?.doctor ? (
                <Col>
                  <Card className={classes.rootTwo}>
                    <CardHeader>
                      <CardTitle tag="h4">Doctor Practioner Assigned</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Col md="12">
                        <FormGroup>
                          <span>Name of Doctor </span>
                          <p className="description">
                            {Booking?.doctor?.firstName +
                              " " +
                              Booking?.doctor?.lastName}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <span>Doctor Contact Number: </span>
                          <p className="description">
                            {Booking?.doctor?.mobile}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <span>Email of Doctor: </span>
                          <p className="description">
                            {Booking?.doctor?.email}
                          </p>
                        </FormGroup>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              ) : null}
              {Booking?.status !== "NEW" && !Booking?.doctor ? <Col></Col> : null}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
  const renderButtons = () => {
    return (
      <Col md="12">
        <Row>
          {Booking?.status === "PENDING" &&
          auth.info.profile.userType === "patient" ? (
            <Col md="2">
              <Button
                color="primary"
                onClick={() => {
                  HandleAccept();
                }}
              >
                Accept Booking
              </Button>
            </Col>
          ) : null}
          {Booking?.status === "NEW" ? (
            <Col md="2">
              <Button
                color="primary"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Assign Booking
              </Button>
            </Col>
          ) : null}
          {Booking?.status === "NEW" || Booking?.status === "PENDING" ? (
            <Col md="2">
              <Button color="primary" onClick={HandleReject}>
                Reject Booking
              </Button>
            </Col>
          ) : null}{" "}
          {/* {Booking?.status === "ACCEPTED" ? (
            <Col md="2">
              <Button
                onClick={() => {
                  dispatch(fetchChatMessages(Booking?.id));
                  navigate({
                    pathname: "/chats",
                    state: { Booking },
                  });

                  setOpen(true);
                }}
              >
                Chat
              </Button>
            </Col>
          ) : null} */}
          {Booking?.status === "ACCEPTED" ? (
            <Col md="2">
              <Button
                color="primary"
                onClick={() => {
                  navigate("/nurseappointments", { state: { Booking } });
                }}
              >
                Start Consultation
              </Button>
            </Col>
          ) : null}
        </Row>
      </Col>
    );
  };
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Booking Details</CardTitle>
      </CardHeader>
      <CardBody>
        {renderAlertDialog()}
        {renderModal()}
        {renderBookingData()}
      </CardBody>
      <CardFooter>{renderButtons()}</CardFooter>
    </Card>
  );
};

export default NurseBookingInformation;
