/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../../components/CircularLoading";
import { features, dateStyle, language } from "config";
import { FirebaseContext } from "common";
import AlertDialog from "../../components/AlertDialog";
import { useNavigate } from "react-router-dom";
export default function Users(props) {
  const { api } = useContext(FirebaseContext);
  const {
    addUser,
    editUser,
    deleteUser,
    checkUserExists,
    emailSignUp,
    clearLoginError,
    createOpenfireUser,
  } = api;
  const [state, setState] = useState({
    userType: "nurse",
    firstName: "",
    lastName: "",
    referralId: "",
    practiceNumber: "",
    practiceName: "",
    practiceLicenseNumber: "",
    specialityType: "Default",
    bankAccount: "",
    bankCode: "",
    bankName: "",
    bankType: "",
    licenseImage: null,
    doctorImage: null,
    nurseImage: null,
    other_info: "",
    password: "",
    email: "",
  });
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [practices, setPractices] = useState({});
  const usersdata = useSelector((state) => state.usersdata);
  const practicetypes = useSelector((state) => state.practicetypes);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const doctortypes = useSelector((state) => state.doctortypes.doctortypes);
  const specialities = useSelector(
    (state) => state.specialitytypes.specialities
  );
  const [specialityTypes, setSpecialityTypes] = useState({});
  const [doctorTypes, setDoctorTypes] = useState(null);
  const [bankTypes, setbankTypes] = useState([]);

  useEffect(() => {
    console.log("i am the auth", auth);
    console.log("i am the nurses", usersdata.users);
    if (usersdata.users) {
      setData(
        usersdata.users.filter(
          (user) =>
            user.userType === "nurse" &&
            user.practiceNumber === auth.info.profile.practiceNumber
        )
      );
      console.log("test", data);
    } else {
      setData(usersdata.users);
    }
  }, [usersdata.users, auth.info.profile.userType, auth.info.uid]);

  useEffect(() => {
    if (bankTypes) {
      let arr = {
        savings: "Savings",
        check: "Check",
        debit: "Debit",
        credit: "Credit",
      };

      for (let i = 0; i < bankTypes.length; i++) {
        arr.push({
          label: bankTypes[i],
          value: bankTypes[i],
        });
      }
      setbankTypes(arr);
    }
    if (specialities) {
      let obj = {};
      for (let i = 0; i < specialities.length; i++) {
        specialities.map((speciality) => (obj[speciality] = speciality));
      }
      setSpecialityTypes(obj);
    }
    if (doctortypes) {
      let arr = {};
      for (let i = 0; i < doctortypes.length; i++) {
        doctortypes.map((doctypes) => (arr[doctypes] = doctypes));
      }
      setDoctorTypes(arr);
    }
  }, []);
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
    if (auth.error.flag) {
      dispatch(clearLoginError());
    }
  };

  const columns = [
    { title: language.first_name, field: "firstName", initialEditValue: "" },
    { title: language.last_name, field: "lastName", initialEditValue: "" },
    { title: "Identity Number", field: "identityNumber", initialEditValue: "" },
    {
      title: "Practice Name",
      field: "practiceName",
      initialEditValue: "",
      editable: "never",
      render: (rowData) =>
        auth.info.profile.practiceName
          ? rowData.practiceName
          : auth.info.profile.practiceName,
    },
    {
      title: "Annual Practice Certificates",
      field: "practiceLicenseNumber",
      // need to add "annualPracticeCertificate" to the nurse functions
      initialEditValue: "",
    },
    {
      title: language.email,
      field: "email",
      editable: "onAdd",
      render: (rowData) =>
        features.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo",
    },
    {
      title: language.mobile,
      field: "mobile",
      editable: "onAdd",
      render: (rowData) =>
        features.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo",
    },
    { title: "Password", field: "password", initialEditValue: "" },

    { title: "Practice Type", field: "doctorType", lookup: doctorTypes },
    {
      title: "Department",
      field: "specialityType",
      lookup: specialityTypes,
    },

    {
      title: language.profile_image,
      field: "profile_image",
      render: (rowData) =>
        rowData.profile_image ? (
          <img
            alt="Profile"
            src={rowData.profile_image}
            style={{ width: 50, borderRadius: "50%" }}
          />
        ) : null,
      editable: "never",
    },
    {
      title: language.lisence_image,
      field: "licenseImage",
      render: (rowData) =>
        rowData.licenseImage ? (
          <img
            alt="License"
            src={rowData.licenseImage}
            style={{ width: 100 }}
          />
        ) : null,
      editable: "never",
    },
    {
      title: "Nurse Image",
      field: "nurseImage",
      render: (rowData) =>
        rowData.nurseImage ? (
          <img
            alt="nurseImage"
            src={rowData.nurseImage}
            style={{ width: 100 }}
          />
        ) : null,
      editable: "never",
    },

    { title: language.bankName, field: "bankName", initialEditValue: "" },
    { title: language.bankCode, field: "bankCode", initialEditValue: "" },
    { title: language.bankAccount, field: "bankAccount", initialEditValue: "" },
    {
      title: language.bankType,
      field: "bankType",
      lookup: bankTypes,
      editable: "onAdd",
    },
    {
      title: "Private Practice",
      field: "privatePractice",
      type: "boolean",
      initialEditValue: true,
    },
    {
      title: language.account_approve,
      field: "nurseActiveStatus",
      type: "boolean",
      initialEditValue: true,
    },
    {
      title: language.referralId,
      field: "referralId",
      editable: "never",
      initialEditValue: "",
    },
    //{ title: language.queue,  field: 'queue', type:'boolean', initialEditValue: false },
  ];

  return usersdata.loading ? (
    <CircularLoading />
  ) : (
    ((
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    ),
    (
      <MaterialTable
        title={"Nurses"}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          sorting: true,
          actionsColumnIndex: -1,
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                checkUserExists(newData).then((res) => {
                  if (res.users && res.users.length > 0) {
                    alert(language.user_exists);
                    reject();
                  } else if (res.error) {
                    alert(language.email_or_mobile_issue);
                    reject();
                  } else {
                    newData["createdByAdmin"] = true;
                    newData["userType"] = "nurse";

                    newData["isIndependent"] = false;
                    newData["username"] =
                      newData.firstName.toLowerCase() +
                      newData.lastName.toLowerCase() +
                      Math.floor(1000 + Math.random() * 9000).toString();
                    setState({
                      ...state,
                      newData,
                    });
                    newData["createdAt"] = new Date().toISOString();
                    newData["referralId"] =
                      newData.firstName.toLowerCase() +
                      Math.floor(1000 + Math.random() * 9000).toString();
                    setState({
                      ...state,
                      newData,
                    });
                    let role = auth.info.profile.userType;
                    if (role === "practiceadmin") {
                      newData["practiceadmin"] = auth.info.uid;
                      newData["practiceNumber"] =
                        auth.info.profile.practiceNumber;
                    } else if (role === "nurse") {
                      newData["nurse"] = auth.info.uid;
                      newData["practiceNumber"] =
                        auth.info.profile.practiceNumber;
                    } else if (role === "doctor") {
                      newData["doctor"] = auth.info.uid;
                      newData["practiceNumber"] =
                        auth.info.profile.practiceNumber;
                    }
                    emailSignUp(newData).then((res) => {
                      resolve();
                      console.log("i am the back data", res);
                      if (res.uid) {
                        dispatch(
                          createOpenfireUser(
                            newData.username,
                            newData.password,
                            newData.firstName,
                            newData.lastName,
                            newData.email
                          )
                        );
                        setTimeout(() => {
                          setCommonAlert({
                            open: true,
                            msg: language.account_create_successfully,
                          });

                          navigate("/login");
                          console.log(newData);
                        }, 3000);
                      } else {
                        setCommonAlert({
                          open: true,
                          msg: language.reg_error,
                        });
                      }
                    });
                  }
                });
              }, 600);
            }),

          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                dispatch(editUser(oldData.id, newData));
              }, 600);
            }),
          onRowDelete: (oldData) =>
            features.AllowCriticalEditsAdmin
              ? new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    dispatch(deleteUser(oldData.id));
                  }, 600);
                })
              : new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    alert(language.demo_mode);
                  }, 600);
                }),
        }}
      />
    ))
  );
}
