import {
  CREATE_OPENFIREUSER,
  CREATE_OPENFIREUSER_SUCCESS,
  CREATE_OPENFIREUSER_FAIL,
  CREATE_DOCTORROOM,
  CREATE_DOCTORROOM_SUCCESS,
  CREATE_DOCTORROOM_FAIL,
} from "../store/types";
const INITIAL_STATE = {
  openfireuser: null,
  loading: false,
  error: {
    flag: false,
    msg: null,
  },
};

export const openfireuserreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_OPENFIREUSER:
      return {
        ...state,
        loading: true,
      };
    case CREATE_OPENFIREUSER_SUCCESS:
      return {
        ...state,
        openfireuser: action.payload,
        loading: false,
      };
    case CREATE_OPENFIREUSER_FAIL: {
      return INITIAL_STATE;
    }

    case CREATE_DOCTORROOM:
      return {
        ...state,
        loading: true,
      };
    case CREATE_DOCTORROOM_SUCCESS:
      return {
        ...state,
        openfireuser: action.payload,
        loading: false,
      };
    case CREATE_DOCTORROOM_FAIL: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};
