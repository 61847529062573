import {
  UPDATE_USER_PROFILE,
} from "../store/types";

export const updateUserConsentform =
  (userid, file) => (dispatch) => (firebase) => {
    const { singleUserRef, ConsentFormRef } = firebase;
    try {
    ConsentFormRef(userid)
      .put(file)
      .then(() => {
        return ConsentFormRef(userid).getDownloadURL();
      })
      .then((url) => {
        singleUserRef(userid).update({
          Consent_form:url,
        });

      });
    }
    catch (e) {
    
    }
  };
