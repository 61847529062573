import {
  FETCH_MEDICAL_HISTORY,
  FETCH_MEDICAL_HISTORY_SUCCESS,
  FETCH_MEDICAL_HISTORY_FAILURE,
  FETCH_MEDICAL_HISTORY_EDIT,
  NO_MEDICAL_HISTORY,
} from "../store/types";
import { language } from "config";
export const fetchMedicalHistory = () => (dispatch) => (firebase) => {
  const { auth, singleUserRef } = firebase;
  dispatch({
    type: FETCH_MEDICAL_HISTORY,
    payload: null,
  });
  singleUserRef(auth.currentUser.uid).on(
    "value",
    (snapshot) => {
      const medicalRecords = snapshot.val().Medical_Information;
      if (medicalRecords) {
        dispatch({
          type: FETCH_MEDICAL_HISTORY_SUCCESS,
          payload: medicalRecords,
        });
        return medicalRecords;
      } else {
        dispatch({
          type: NO_MEDICAL_HISTORY,
          payload: language.no_medical_history,
        });
      }
    },
    (errorObject) => {
      console.log("The read failed: " + errorObject.name);
    }
  );
};
export const editMedicalHistory =
  (medicalRecords, method, blob, metadata) =>
  (dispatch) =>
  async (firebase) => {
    try {
      const { auth, singleUserRef, clinicalDiseaseRef } = firebase;

      if (method === "Add medical history") {
        let inRichMed = {
          Medical_Information: {
            Basic_info: medicalRecords.Medical_Information.Basic_info
              ? medicalRecords.Medical_Information.Basic_info
              : null,
            Childhood_illness: medicalRecords.Medical_Information
              .Childhood_illness
              ? medicalRecords.Medical_Information.Childhood_illness
              : null,
            Family_Medical_History: medicalRecords.Medical_Information
              .Family_Medical_History
              ? medicalRecords.Medical_Information.Family_Medical_History
              : null,
            Immunizations_an_Dates: medicalRecords.Medical_Information
              .Immunizations_an_Dates
              ? medicalRecords.Medical_Information.Immunizations_an_Dates
              : null,
            LifeStyle_History: medicalRecords.Medical_Information
              .LifeStyle_History
              ? medicalRecords.Medical_Information.LifeStyle_History
              : null,
            Medical_History: medicalRecords.Medical_Information.Medical_History
              ? medicalRecords.Medical_Information.Medical_History
              : null,
            Medication: medicalRecords.Medical_Information.Medication
              ? medicalRecords.Medical_Information.Medication
              : null,
            Clinical_Diseases: medicalRecords.Medical_Information
              .Clinical_Diseases
              ? medicalRecords.Medical_Information.Clinical_Diseases
              : null,
              Clinical_Diseases_Check: medicalRecords.Medical_Information
              .Clinical_Diseases_Check
              ? medicalRecords.Medical_Information.Clinical_Diseases_Check
              : null,
            Surgries: medicalRecords.Medical_Information.Surgries
              ? medicalRecords.Medical_Information.Surgries
              : null,
          },
        };
        console.log("i am the medical history record", inRichMed);
        singleUserRef(auth.currentUser.uid).update(inRichMed);
        let createDate = new Date();
        let timestamp = createDate.getTime();

        await clinicalDiseaseRef(auth.currentUser.uid, blob, metadata)
          .put(inRichMed.Medical_Information.Clinical_Diseases)
          .then((url) => {
            console.log("this is it", url);
            return clinicalDiseaseRef(blob, metadata).getDownloadURL();
          });
        // inRichMed.Medical_Information.Clinical_Diseases =
        //   await clinicalDiseaseRef(timestamp).getDownloadURL();
        dispatch({
          type: FETCH_MEDICAL_HISTORY_EDIT,
          payload: inRichMed,
        });
      }
    } catch (e) {
      console.log("Error", e.message);
    }
  };
