/*eslint-disable*/
import React, { useState, useEffect } from "react";
// import MaterialTable from "material-table";
import CircularLoading from "../../components/CircularLoading";
import { useSelector } from "react-redux";

// import { features, dateStyle, language } from "config";
import { FirebaseContext } from "common";
// import { Book } from "@material-ui/icons";
import FlatList from "flatlist-react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
const BookingRequest = (props) => {
  const auth = useSelector((state) => state.auth);

  const [data, setData] = useState([]);

  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const navigate = useNavigate();
  useEffect(() => {
    console.log("bookings", bookinglistdata.bookings);
    if (
      (auth.info.profile.userType === "nurse" ||
      auth.info.profile.userType === "doctor" ||
      auth.info.profile.userType === "practiceadmin") 
    ) {
      if (bookinglistdata.bookings) {
        setData(
          bookinglistdata.bookings.filter(
            
            (bookings) => 
           
            bookings.status === "NEW" && bookings.specialityType === auth.info.profile.specialityType 
            
          )
        );
      } else {
        setData([]);
      }
    }
  }, [bookinglistdata.bookings]);

  const renderBooking = (Booking, index) => {
    return (
      <Col md="4">
        <Card border="danger">
          <CardHeader as="h5">
            <CardTitle tag="h4">
              <div style={{ marginLeft: "90%" }}>
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-round btn-icon"
                    color="default"
                    outline
                  >
                    <i className="now-ui-icons loader_gear" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>Call Patient</DropdownItem>
                    <DropdownItem>Email Patient</DropdownItem>
                    <DropdownItem>Refer</DropdownItem>
                    <DropdownItem>Booking Procedure</DropdownItem>
                    <DropdownItem>Pre-approve Procedure</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div className="picture">
                <img
                  alt="..."
                  className="picture-src"
                  src={Booking.patientImage}
                />{" "}
              </div>
              {Booking.patientName}
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Row mb="12">
              <Col md="12">
                <FormGroup>
                  <span>Type of Booking Service: </span>
                  <p className="description">{Booking.serviceType}</p>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <span>Date and Time of Booking: </span>
                  <p className="description">{Booking.eventDate}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row mb="12">
              <Col md="12">
                <FormGroup>
                  <span>Booking Status: </span>
                  <p className="description">{Booking.status}</p>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              onClick={() => {
                navigate("/pabookingdetails", { state: { Booking } });
              }}
            >
              View Request
            </Button>
          </CardFooter>
        </Card>
      </Col>
    );
  };

  return data.loading ? (
    <CircularLoading />
  ) : (
    <Row>
      <Col md="12">
        <Card style={{ backgroundColor: "#4c4b4b" }}>
          <CardHeader>
            <CardTitle tag="h4">BOOKING REQUESTS</CardTitle>
          </CardHeader>
          <CardBody>
            <ul>
              <Row>
                <FlatList
                  keyExtractor={(item, index) => {
                    return index.toString();
                  }}
                  list={data}
                  renderItem={renderBooking}
                  renderWhenEmpty={() => (
                    <div>There currently is no bookings!</div>
                  )}
                />
              </Row>
            </ul>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default BookingRequest;
