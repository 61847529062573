/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import CircularLoading from "../../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationDialogRaw from "../../components/ConfirmationDialogRaw";
import { features, dateStyle, language } from "config";
import {
  Select,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import { FirebaseContext } from "common";
import { LaptopWindows } from "@material-ui/icons";

const NurseFinancialReport = () => {
  const { api } = useContext(FirebaseContext);
  const { fetchBookings, GetDateString } = api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const usersdata = useSelector((state) => state.doctors);
  const [role, setRole] = useState(null);
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [Doctors, setDoctors] = useState([]);
  let [columns, setColumn] = useState([]);

  const [openFPModal, setOpenFPModal] = React.useState(false);
  const [selectedBooking, setSelectedBooking] = useState("");
  const [patientName, setPatientName] = useState("");
  const [treatmentFee, setTreatmentFee] = useState("");
  const [discount, setDiscount] = useState("");
  const [dispensingFee, setDispensingFee] = useState("");
  const [consultationFee, setConsultatinFee] = useState("");
  const [fareCost, setFareCst] = useState("");
  const [grandTotal, setGrandTotal] = useState("");
  const [paidOn, setPaidOn] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [status, setStatus] = useState("");
  //const [onRowsSelect, setOnRowSelect] = useState("");

  //////////////////////////////////////////////////
  const [bookingType, setBookingType] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(GetDateString());
  const [selectedEndDate, setSelectedEndDate] = useState(GetDateString());
  const [doctorname, setDoctorName] = useState("");
  const [doctoremail, setDoctoremail] = useState("");
  const [doctormobile, setDoctormobile] = useState("");
  const [titles, setTitles] = useState("");
  const [practicesType, setPracticesType] = useState("");
  const [serviceTypes, setServiceTypes] = useState("");
  const [description, setDescription] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  //const [selectedBooking, setSelectedBooking] = useState("");
  //const [status, setStatus] = useState("")
  //const classes = useStyles();
  ///////////////////////////////////////////////////////////

  const bookinglistdata = useSelector(
    (state) => state.bookinglistdata.bookings
  );
  useEffect(() => {
    console.log("Booking data ", bookinglistdata);
    if (bookinglistdata !== null) {
      setData(bookinglistdata);
    } else if (bookinglistdata === null) {
      setData([]);
    }
  }, []);

  columns = [
    {
      title: "Invoice No",
    },
    {
      title: "Patient Name",
      field: "patientName",
      render: (rowData) => rowData.patientName,
    },
    {
      title: "Treatment Fee",
      field: "treatmentFee",
      render: (rowData) => rowData.estimate_treatment_fee,
    },

    {
      title: "Discount",
      field: "discount",
      render: (rowData) => rowData.discount,
    },

    {
      title: "Dispensing Fee",
      field: "dispensingFee",
      render: (rowData) => rowData.estimate_treatment_fee,
    },

    {
      title: "Consultation Fee",
      field: "consultationFee",
    },

    {
      title: "Fare Cost",
      field: "fareCost",
    },

    {
      title: "Total",
      field: "grandTotal",
      render: (rowData) => rowData.estimate_bigGrand,
    },

    {
      title: "Paid On",
      field: "paidOn",
      render: (rowData) => rowData.bookingDate,
    },

    {
      title: "Payment Type",
      field: "paymentType",
    },
    {
      title: "Payment Amount",
      field: "paymentAmount",
    },

    {
      title: "Status",
      field: "status",
      render: (rowData) => rowData.status,
    },

    { title: "View/Print" },
    //{ title: "Status", field: "status", render: rowData => rowData.status },
    // { title: 'Follow Up' },  this is optional
  ];

  // eslint-disable-next-line no-unused-expressions

  // eslint-disable-next-line no-unused-expressions


  const onRowsSelect = (curRowSelected, allRowsSelected) => {
    console.log("---RowSelect");
    console.log("Row Selected: ", curRowSelected);
    console.log("All Selected: ", allRowsSelected);
  };

  const selectedEvent = (event) => {
    setOpenFPModal(true);
    console.log("Event : ", event.practice.name);
    console.log("Event : ", event.status);
    setTitles(event.title);
    setPracticesType(event.practice.name);
    setDescription(event.description);
    setSelectedStartDate(event.start);
    setSelectedEndDate(event.end);
    setServiceTypes(event.service);
    setDoctorName(event.doctorname);
    setDoctoremail(event.doctoremail);
    setDoctormobile(event.doctormobile);
    setStatus(event.status);
  };

  return bookinglistdata.loading ? (
    <CircularLoading />
  ) : (
    <div>
      <MaterialTable
        title="Financial Report"
        columns={columns}
        data={bookinglistdata}
        options={{
          actionsColumnIndex: -1,
          print: true,
          download: true,
          selection: true,
        }}
        onSelectionChange={(rows) => window.print(bookinglistdata)}
      />
    </div>
  );
};

export default NurseFinancialReport;
