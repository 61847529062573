/*eslint-disable*/
import CircularLoading from "../../../components/CircularLoading";
import { Select } from "@material-ui/core";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  FormText,
  Row,
  Col,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "common";
import CustomInput from "components/CustomInput/CustomInput";
import MenuItem from "@material-ui/core/MenuItem";
function PatientMedicalHistory() {
  /////////////////////////////////////////////////////////
  //REdux
  const { api } = useContext(FirebaseContext);
  const { editMedicalHistory } = api;
  const dispatch = useDispatch();
  const medical = useSelector(
    (state) => state.medicalRecords.medicalHistoryRecords
  );
  const auth = useSelector((state) => state.auth);
  /////////////////////////////////////////////////////////
  //Users basic infomation
  /////////////////////////////////////////////////////////
  //Basic infomation
  const [firstName, setFirstName] = useState();
  const [DOB, setDOB] = useState(null);
  const [Weight, setWeight] = useState(null);
  const [Height, setHeight] = useState(null);
  const [Sex, setSex] = useState(null);
  const [BloodType, setBloodType] = useState(null);
  ////////////////////////////////////////////////////////
  //Childhood illensises
  const [Measles, setMeasles] = useState(false);
  const [Mumps, setMumps] = useState(false);
  const [Rubellac, setRubellac] = useState(false);
  const [Chickenpox, setChickenpox] = useState(false);
  const [Rheumatic, setRheumatic] = useState(false);
  const [Polio, setPolio] = useState(false);
  const [All_the_Above, setAll_the_Above] = useState(false);
  const [None, setNone] = useState(false);
  //////////////////////////////////////////////////////////////
  //Immunisation and dates
  const [Tetanus, setTetanus] = useState({
    checked: false,
    Date: null,
  });
  const [Pneumonia, setPneumonia] = useState({
    checked: false,
    Date: null,
  });
  const [Hepatitis_A, setHepatitis_A] = useState({
    checked: false,
    Date: null,
  });
  const [Hepatitis_B, setHepatitis_B] = useState({
    checked: false,
    Date: null,
  });
  const [Chickenpox2, setChickenpox2] = useState({
    checked: false,
    Date: null,
  });
  const [influenza, setinfluenza] = useState({
    checked: false,
    Date: null,
  });
  const [MMR, setMMR] = useState({
    checked: false,
    Date: null,
  });
  const [Meningococcal, setMeningococcal] = useState({
    checked: false,
    Date: null,
  });
  const [None2, setNone2] = useState({
    checked: false,
  });
  ///////////////////////////////////////////////////////////////
  //Surgies
  const [Surgry1st, setSurgry1st] = useState({
    YearOfSurgry: " ",
    ReasonOfSurgry: " ",
    HospitalOfSurgry: " ",
  });
  const [Surgry2nd, setSurgry2nd] = useState({
    YearOfSurgry: " ",
    ReasonOfSurgry: " ",
    HospitalOfSurgry: " ",
  });
  const [Surgry3rd, setSurgry3rd] = useState({
    YearOfSurgry: " ",
    ReasonOfSurgry: " ",
    HospitalOfSurgry: " ",
  });
  const [NoSurgeries, setNoSurgeries] = useState(false);
  ///////////////////////////////////////////////////////////////
  //Medical History
  const [medicalhistory, setmedicalhistory] = useState({
    Alcohol_Abuse: false,
    Anemia: false,
    Anesthetic_Complication: false,
    Anxiety_Disorder: false,
    Arthritis: false,
    Asthma: false,
    Autoimmune_Problems: false,
    Birth_Defects: false,
    Bladder_Problems: false,
    Bleeding_Disease: false,
    Blood_Clots: false,
    Blood_Transfusions: false,
    Bowel_Disease: false,
    Breast_Cancer: false,
    Cervical_Cancer: false,
    Colon_Cancer: false,
    Depression: false,
    Diabetes: false,
    GrowthDevelopment_Disorder: false,
    Hearing_Impairment: false,
    Heart_Attack: false,
    Heart_Disease: false,
    Heart_Pain_Angina: false,
    Hepatitis_A: false,
    Hepatitis_B: false,
    Hepatitis_C: false,
    High_Blood_Pressure: false,
    High_Cholesterol: false,
    HIV: false,
    Hives: false,
    Kidney_Disease: false,
    Liver_Cancer: false,
    Liver_Disease: false,
    Lung_Cancer: false,
    Lung_Respiratory_Disease: false,
    Mental_Illness: false,
    Migraines: false,
    Osteoporosis: false,
    Prostate_Cancer: false,
    Rectal_Cancer: false,
    Reflux_GERD: false,
    Seizures_Convulsions: false,
    Severe_Allergy: false,
    Sexually_Transmitted_Disease: false,
    Skin_Cancer: false,
    Stroke_CVA_of_the_Brain: false,
    Suicide_Attempt: false,
    Thyroid_Problems: false,
    Ulcer: false,
    Visual_Impairment: false,
    Other_Disease_Cancer_or_Significant_Medical_Illness: false,
    NONE_of_the_Above: false,
  });
  ///////////////////////////////////////////////////////////////
  //Medication
  const [Medication1, setMedication1] = useState({
    Drug: " ",
    Dose: " ",
  });
  const [Medication2, setMedication2] = useState({
    Drug: " ",
    Dose: " ",
  });
  const [Medication3, setMedication3] = useState({
    Drug: " ",
    Dose: " ",
  });
  const [Medication4, setMedication4] = useState({
    Drug: " ",
    Dose: " ",
  });
  const [Medication5, setMedication5] = useState({
    Drug: " ",
    Dose: " ",
  });
  const [Medication6, setMedication6] = useState({
    Drug: " ",
    Dose: " ",
  });
  ///////////////////////////////////////////////////////////////
  //Family Medical History

  const [Fmedicalhistory, setFmedicalhistory] = useState({
    Family_History_unknow: false,
    Adopted: false,
    Male_Family: false,
    Female_Family: false,
    F_Alcohol_Abuse: false,
    F_Anemia: false,
    F_Anesthetic_Complication: false,
    F_Anxiety_Disorder: false,
    F_Arthritis: false,
    F_Asthma: false,
    F_Autoimmune_Problems: false,
    F_Birth_Defects: false,
    F_Bladder_Problems: false,
    F_Bleeding_Disease: false,
    F_Blood_Clots: false,
    F_Blood_Transfusions: false,
    F_Bowel_Disease: false,
    F_Breast_Cancer: false,
    F_Cervical_Cancer: false,
    F_Colon_Cancer: false,
    F_Depression: false,
    F_Diabetes: false,
    F_GrowthDevelopment_Disorder: false,
    F_Hearing_Impairment: false,
    F_Heart_Attack: false,
    F_Heart_Disease: false,
    F_Heart_Pain_Angina: false,
    F_Hepatitis_A: false,
    F_Hepatitis_B: false,
    F_Hepatitis_C: false,
    F_High_Blood_Pressure: false,
    F_High_Cholesterol: false,
    F_HIV: false,
    F_Hives: false,
    F_Kidney_Disease: false,
    F_Liver_Cancer: false,
    F_Liver_Disease: false,
    F_Lung_Cancer: false,
    F_Lung_Respiratory_Disease: false,
    F_Mental_Illness: false,
    F_Migraines: false,
    F_Osteoporosis: false,
    F_Prostate_Cancer: false,
    F_Rectal_Cancer: false,
    F_Reflux_GERD: false,
    F_Seizures_Convulsions: false,
    F_Severe_Allergy: false,
    F_Sexually_Transmitted_Disease: false,
    F_Skin_Cancer: false,
    F_Stroke_CVA_of_the_Brain: false,
    F_Suicide_Attempt: false,
    F_Thyroid_Problems: false,
    F_Ulcer: false,
    F_Visual_Impairment: false,
    F_Other_Disease_Cancer_or_Significant_Medical_Illness: false,
    F_NONE_of_the_Above: false,
  });
  ///////////////////////////////////////////////////////////////
  //SOCIAL MEDICAL HISTORY
  const [SocialHistory, setSocialHistory] = useState({
    occupation: "",
    maritalstatus: "",
    exercise: "",
    alcoholconsumption: "",
    smoking: "",
    sexualorientation: "",
    diet: "",
    children: "",
    substanceabuse: "",
    stressfactor: "",
    language: "",
  });
  ///////////////////////////////////////////////////////////////
  //useEffect 1
  useEffect(() => {
    //Setting Basic infomation
    setDOB(medical?.Basic_info?.DOB);
    setWeight(medical?.Basic_info?.Weight);
    setHeight(medical?.Basic_info?.Height);
    setSex(medical?.Basic_info?.Sex);
    setBloodType(medical?.Basic_info?.BloodType);

    //Setting Childhood illensises
    setMeasles(medical?.Childhood_illness?.Measles);
    setMumps(medical?.Childhood_illness?.Mumps);
    setRubellac(medical?.Childhood_illness?.Rubellac);
    setChickenpox(medical?.Childhood_illness?.Chickenpox);
    setRheumatic(medical?.Childhood_illness?.Rheumatic);
    setPolio(medical?.Childhood_illness?.Polio);
    setAll_the_Above(medical?.Childhood_illness?.All_the_Above);
    setNone(medical?.Childhood_illness?.None);

    //////////////////////////////////////////////////////////////
    //Setting Immunisation and dates
    setTetanus(medical?.Immunizations_an_Dates?.Tetanus);

    setPneumonia(medical?.Immunizations_an_Dates?.Pneumonia);

    setHepatitis_A(medical?.Immunizations_an_Dates?.Hepatitis_A);

    setHepatitis_B(medical?.Immunizations_an_Dates?.Hepatitis_B);

    setChickenpox2(medical?.Immunizations_an_Dates?.Chickenpox2);

    setinfluenza(medical?.Immunizations_an_Dates?.influenza);

    setMMR(medical?.Immunizations_an_Dates?.MMR);

    setMeningococcal(medical?.Immunizations_an_Dates?.Meningococcal);

    setNone2(medical?.Immunizations_an_Dates?.None2);

    ///////////////////////////////////////////////////////////////
    //Surgies

    setSurgry1st({
      YearOfSurgry: medical?.Surgries?.Surgry1st?.YearOfSurgry,
      ReasonOfSurgry: medical?.Surgries?.Surgry1st?.ReasonOfSurgry,
      HospitalOfSurgry: medical?.Surgries?.Surgry1st?.HospitalOfSurgry,
    });
    setSurgry2nd({
      YearOfSurgry: medical?.Surgries?.Surgry1st?.YearOfSurgry,
      ReasonOfSurgry: medical?.Surgries?.Surgry1st?.ReasonOfSurgry,
      HospitalOfSurgry: medical?.Surgries?.Surgry1st?.HospitalOfSurgry,
    });
    setSurgry3rd({
      YearOfSurgry: medical?.Surgries?.Surgry1st?.YearOfSurgry,
      ReasonOfSurgry: medical?.Surgries?.Surgry1st?.ReasonOfSurgry,
      HospitalOfSurgry: medical?.Surgries?.Surgry1st?.HospitalOfSurgry,
    });
    setNoSurgeries(medical?.Surgries?.NoSurgeries);

    ///////////////////////////////////////////////////////////////
    //Family Medical History
    setFmedicalhistory(medical?.Family_Medical_History?.Fmedicalhistory);

    ///////////////////////////////////////////////////////////////
    //Medical History
    setmedicalhistory(medical?.Medical_History?.medicalhistory);

    ///////////////////////////////////////////////////////////////
    //SOCIAL MEDICAL HISTORY
    setSocialHistory(medical?.LifeStyle_History?.SocialHistory);

    ///////////////////////////////////////////////////////////////
    //Medication
    setMedication1(medical?.Medication?.Medication1);
    setMedication2(medical?.Medication?.Medication2);
    setMedication3(medical?.Medication?.Medication3);
    setMedication4(medical?.Medication?.Medication4);
    setMedication5(medical?.Medication?.Medication5);
    setMedication6(medical?.Medication?.Medication6);

    ///////////////////////////////////////////////////////////////
    //cl
    console.log(
      "I am the medical history from the reducer",
      medical?.Surgries?.Surgry1st
    );
    ///////////////////////////////////////////////////////////////
  }, [medical]);
  return auth.loading ? (
    <CircularLoading />
  ) : (
    <>
      <div className="content">
        <div className="medicalhistory-form">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">YOUR MEDICAL HISTORY </CardTitle>
                </CardHeader>
                <CardBody>
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Basic Information</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <FormGroup>
                        <FormText color="default" tag="span">
                          Please Fill in you basic Information:
                        </FormText>
                      </FormGroup>
                      <Row mb="12">
                        {/* //sexdropdown */}
                        <Col md="4">
                          <FormGroup>
                            <FormText color="default" tag="span">
                              Select Gender
                            </FormText>
                          </FormGroup>
                          <Select
                            fullWidth
                            styles={{ fullWidth: "30px" }}
                            onChange={(event) => {
                              setSex(event.target.value);
                            }}
                            value={Sex}
                          >
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                          </Select>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <FormText color="default" tag="span">
                              Date of Birth
                            </FormText>
                          </FormGroup>
                          <Input
                            type="date"
                            placeholder="Date of Birth"
                            onChange={(event) => {
                              setDOB(event.target.value);
                            }}
                            value={DOB}
                          />
                        </Col>
                        {/* /////////////////////////////////////////////////////////////////////// */}
                      </Row>
                      <Row>
                        <Col mb="4">
                          <FormGroup>
                            <FormText color="default" tag="span">
                              Weight
                            </FormText>
                          </FormGroup>
                          <Input
                            type="number"
                            onChange={(event) => {
                              setWeight(event.target.value);
                            }}
                            value={Weight}
                          />
                        </Col>
                        <Col mb="4">
                          <FormGroup>
                            <FormText color="default" tag="span">
                              height(in cm)
                            </FormText>
                          </FormGroup>
                          <Input
                            type="number"
                            onChange={(event) => {
                              setHeight(event.target.value);
                            }}
                            value={Height}
                          />
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <FormText color="default" tag="span">
                              Select Blood Type:
                            </FormText>
                          </FormGroup>
                          <Select
                            styles={{ fullWidth: "30px" }}
                            fullWidth
                            onChange={(event) =>
                              setBloodType(event.target.value)
                            }
                            value={BloodType}
                          >
                            <MenuItem value="A+">A+</MenuItem>
                            <MenuItem value="A-">A-</MenuItem>
                            <MenuItem value="B+">B+</MenuItem>
                            <MenuItem value="B-">B-</MenuItem>
                            <MenuItem value="AB+">AB+</MenuItem>
                            <MenuItem value="AB-">AB-</MenuItem>
                            <MenuItem value="O+">O+</MenuItem>
                            <MenuItem value="O-">O-</MenuItem>
                          </Select>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">PERSONAL HEALTH HISTORY</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <p>Childhood Illness:</p>
                      <Row>
                        <Col>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={() => {
                                  setMeasles(!Measles);
                                }}
                                checked={All_the_Above || Measles}
                              />
                              <span className="form-check-sign" />
                              Measles
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={() => {
                                  setMumps(!Mumps);
                                }}
                                checked={All_the_Above || Mumps}
                              />
                              <span className="form-check-sign" />
                              Mumps
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={() => {
                                  setRubellac(!Rubellac);
                                }}
                                checked={All_the_Above || Rubellac}
                              />
                              <span className="form-check-sign" />
                              Rubellac
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={() => {
                                  setChickenpox(!Chickenpox);
                                }}
                                checked={All_the_Above || Chickenpox}
                              />
                              <span className="form-check-sign" />
                              Chickenpox
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={() => {
                                  setRheumatic(!Rheumatic);
                                }}
                                checked={All_the_Above || Rheumatic}
                              />
                              <span className="form-check-sign" />
                              Rheumatic Fever
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={() => {
                                  setPolio(!Polio);
                                }}
                                checked={All_the_Above || Polio}
                              />
                              <span className="form-check-sign" />
                              Polio
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={() => {
                                  if (!All_the_Above) {
                                    setMeasles(true);
                                    setMumps(true);
                                    setAll_the_Above(!All_the_Above);
                                    setPolio(true);
                                    setRheumatic(true);
                                    setRubellac(true);
                                    setChickenpox(true);
                                    setNone(false);
                                  }
                                  if (All_the_Above) {
                                    setMeasles(false);
                                    setMumps(false);
                                    setAll_the_Above(!All_the_Above);
                                    setPolio(false);
                                    setRheumatic(false);
                                    setRubellac(false);
                                    setChickenpox(false);
                                  }
                                }}
                                checked={All_the_Above}
                              />
                              <span className="form-check-sign" />
                              All of the Above
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={() => {
                                  setNone(!None);
                                  setMeasles(false);
                                  setMumps(false);
                                  setAll_the_Above(false);
                                  setPolio(false);
                                  setRheumatic(false);
                                  setRubellac(false);
                                  setChickenpox(false);
                                }}
                                checked={None}
                              />
                              <span className="form-check-sign" />
                              None of the Above
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <p style={{ marginTop: "25px" }}>
                        Immunizations and Dates:
                      </p>
                      <Row>
                        <Col>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={Tetanus.checked}
                                onChange={() => {
                                  setTetanus({
                                    checked: !Tetanus.checked,
                                    Date: null,
                                  });
                                }}
                              />
                              <span className="form-check-sign" />
                              Tetanus
                              <Input
                                type="date"
                                placeholder="Date of Birth"
                                //disabled={!Tetanus.checked}
                                onChange={(event) => {
                                  setTetanus({
                                    checked: true,
                                    Date: event.target.value,
                                  });
                                }}
                                value={Tetanus.Date}
                              />
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={Pneumonia.checked}
                                onChange={() => {
                                  setPneumonia({
                                    checked: !Pneumonia.checked,
                                    Date: null,
                                  });
                                }}
                              />
                              <span className="form-check-sign" />
                              Pneumonia
                              <Input
                                type="date"
                                placeholder="Date of Birth"
                                //disabled={!Pneumonia.checked}
                                onChange={(event) => {
                                  setPneumonia({
                                    checked: true,
                                    Date: event.target.value,
                                  });
                                }}
                                value={Pneumonia.Date}
                              />
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={Hepatitis_A.checked}
                                onChange={() => {
                                  setHepatitis_A({
                                    checked: !Hepatitis_A.checked,
                                    Date: null,
                                  });
                                }}
                              />
                              <span className="form-check-sign" />
                              Hepatitis A
                              <Input
                                type="date"
                                placeholder="Date of Birth"
                                //disabled={!Hepatitis_A.checked}
                                onChange={(event) => {
                                  setHepatitis_A({
                                    checked: true,
                                    Date: event.target.value,
                                  });
                                }}
                                value={Hepatitis_A.Date}
                              />
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={Hepatitis_B.checked}
                                onChange={() => {
                                  setHepatitis_B({
                                    checked: !Hepatitis_B.checked,
                                    Date: null,
                                  });
                                }}
                              />
                              <span className="form-check-sign" />
                              Hepatitis B
                              <Input
                                type="date"
                                placeholder="Date of Birth"
                                //disabled={!Hepatitis_B.checked}
                                onChange={(event) => {
                                  setHepatitis_B({
                                    checked: true,
                                    Date: event.target.value,
                                  });
                                }}
                                value={Hepatitis_B.Date}
                              />
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={Chickenpox2.checked}
                                onChange={() => {
                                  setChickenpox2({
                                    checked: !Chickenpox2.checked,
                                    Date: null,
                                  });
                                }}
                              />
                              <span className="form-check-sign" />
                              Chickenpox
                              <Input
                                type="date"
                                placeholder="Date of Birth"
                                //disabled={!Chickenpox2.checked}
                                onChange={(event) => {
                                  setChickenpox2({
                                    checked: true,
                                    Date: event.target.value,
                                  });
                                }}
                                value={Chickenpox2.Date}
                              />
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                checked={influenza.checked}
                                type="checkbox"
                                onChange={() => {
                                  setinfluenza({
                                    checked: !influenza.checked,
                                    Date: null,
                                  });
                                }}
                              />
                              <span className="form-check-sign" />
                              influenza
                              <Input
                                type="date"
                                placeholder="Date of Birth"
                                //disabled={!influenza.checked}
                                onChange={(event) => {
                                  setinfluenza({
                                    checked: true,
                                    Date: event.target.value,
                                  });
                                }}
                                value={influenza.Date}
                              />
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={MMR.checked}
                                onChange={() => {
                                  setMMR({
                                    checked: !MMR.checked,
                                    Date: null,
                                  });
                                }}
                              />
                              <span className="form-check-sign" />
                              MMR Measles, Mumps, Rubella
                              <Input
                                type="date"
                                placeholder="Date of Birth"
                                //disabled={!MMR.checked}
                                onChange={(event) => {
                                  setMMR({
                                    checked: true,
                                    Date: event.target.value,
                                  });
                                }}
                                value={MMR.Date}
                              />
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={Meningococcal.checked}
                                onChange={() => {
                                  setMeningococcal({
                                    checked: !Meningococcal.checked,
                                    Date: null,
                                  });
                                }}
                              />
                              <span className="form-check-sign" />
                              Meningococcal
                              <Input
                                type="date"
                                placeholder="Date of Birth"
                                //disabled={!Meningococcal.checked}
                                onChange={(event) => {
                                  setMeningococcal({
                                    checked: true,
                                    Date: event.target.value,
                                  });
                                }}
                                value={Meningococcal.Date}
                              />
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={() => {
                                  setNone2({
                                    checked: !None2.checked,
                                  });
                                  if (!None2.checked) {
                                    setTetanus({
                                      checked: false,
                                      Date: null,
                                    });
                                    setPneumonia({
                                      checked: false,
                                      Date: null,
                                    });
                                    setHepatitis_A({
                                      checked: false,
                                      Date: null,
                                    });
                                    setHepatitis_B({
                                      checked: false,
                                      Date: null,
                                    });
                                    setChickenpox2({
                                      checked: false,
                                      Date: null,
                                    });
                                    setinfluenza({
                                      checked: false,
                                      Date: null,
                                    });
                                    setMMR({
                                      checked: false,
                                      Date: null,
                                    });
                                    setMeningococcal({
                                      checked: false,
                                      Date: null,
                                    });
                                  }
                                }}
                              />
                              <span className="form-check-sign" />
                              None
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <p style={{ marginTop: "25px" }}>Surgeries</p>
                      <Row>
                        <Col mb="4">
                          <FormGroup inline>
                            <FormGroup inline>
                              <Label style={{ padding: "5px" }}>
                                Your First Surgery
                              </Label>
                            </FormGroup>
                            <CustomInput
                              labelText={"Year of Surgery"}
                              fullWidth
                              // formControlProps={{
                              //   fullWidth: false,
                              // }}
                              inputProps={{
                                type: "text",
                                required: true,
                                margin: "5px",
                              }}
                              onChange={(event) => {
                                setSurgry1st({
                                  ...Surgry1st,
                                  YearOfSurgry: event.target.value,
                                });
                              }}
                              value={Surgry1st.YearOfSurgry}
                            />
                          </FormGroup>
                          <FormGroup>
                            <CustomInput
                              labelText={"Reason for Surgery"}
                              fullWidth
                              // formControlProps={{
                              //   fullWidth: false,
                              // }}
                              inputProps={{
                                type: "text",
                              }}
                              onChange={(event) => {
                                setSurgry1st({
                                  ...Surgry1st,
                                  ReasonOfSurgry: event.target.value,
                                });
                              }}
                              value={Surgry1st.ReasonOfSurgry}
                            />
                          </FormGroup>
                          <FormGroup>
                            <CustomInput
                              labelText={"Hospital"}
                              formControlProps={{
                                fullWidth: false,
                              }}
                              inputProps={{
                                type: "text",
                              }}
                              onChange={(event) => {
                                setSurgry1st({
                                  ...Surgry1st,
                                  HospitalOfSurgry: event.target.value,
                                });
                              }}
                              value={Surgry1st.HospitalOfSurgry}
                            />
                          </FormGroup>
                        </Col>
                        <Col mb="4">
                          <FormGroup inline>
                            <Label style={{ padding: "5px" }}>
                              Your Second Surgery
                            </Label>
                          </FormGroup>
                          <FormGroup inline>
                            <CustomInput
                              labelText={"Year of Surgery"}
                              formControlProps={{
                                fullWidth: false,
                              }}
                              inputProps={{
                                type: "text",
                                required: true,
                                margin: "10px",
                              }}
                              onChange={(event) => {
                                setSurgry2nd({
                                  ...Surgry2nd,
                                  YearOfSurgry: event.target.value,
                                });
                              }}
                              value={Surgry2nd.YearOfSurgry}
                            />
                          </FormGroup>
                          <FormGroup>
                            <CustomInput
                              labelText={"Reason for Surgery"}
                              formControlProps={{
                                fullWidth: false,
                              }}
                              inputProps={{
                                type: "text",
                              }}
                              onChange={(event) => {
                                setSurgry2nd({
                                  ...Surgry2nd,
                                  ReasonOfSurgry: event.target.value,
                                });
                              }}
                              value={Surgry2nd.ReasonOfSurgry}
                            />
                          </FormGroup>
                          <FormGroup>
                            <CustomInput
                              labelText={"Hospital"}
                              formControlProps={{
                                fullWidth: false,
                              }}
                              inputProps={{
                                type: "text",
                              }}
                              onChange={(event) => {
                                setSurgry2nd({
                                  ...Surgry2nd,
                                  HospitalOfSurgry: event.target.value,
                                });
                              }}
                              value={Surgry2nd.HospitalOfSurgry}
                            />
                          </FormGroup>
                        </Col>
                        <Col mb="4">
                          <FormGroup inline>
                            <Label style={{ padding: "5px" }}>
                              Your Third Surgery
                            </Label>
                          </FormGroup>
                          <FormGroup inline>
                            <CustomInput
                              labelText={"Year of Surgery"}
                              formControlProps={{
                                fullWidth: false,
                              }}
                              inputProps={{
                                type: "text",
                                required: true,
                                margin: "10px",
                              }}
                              onChange={(event) => {
                                setSurgry3rd({
                                  ...Surgry3rd,
                                  YearOfSurgry: event.target.value,
                                });
                              }}
                              value={Surgry3rd.YearOfSurgry}
                            />
                          </FormGroup>
                          <FormGroup>
                            <CustomInput
                              labelText={"Reason for Surgery"}
                              formControlProps={{
                                fullWidth: false,
                              }}
                              inputProps={{
                                type: "text",
                              }}
                              onChange={(event) => {
                                setSurgry3rd({
                                  ...Surgry3rd,
                                  ReasonOfSurgry: event.target.value,
                                });
                              }}
                              value={Surgry3rd.ReasonOfSurgry}
                            />
                          </FormGroup>
                          <FormGroup>
                            <CustomInput
                              labelText={"Hospital"}
                              formControlProps={{
                                fullWidth: false,
                              }}
                              inputProps={{
                                type: "text",
                              }}
                              onChange={(event) => {
                                setSurgry3rd({
                                  ...Surgry3rd,
                                  HospitalOfSurgry: event.target.value,
                                });
                              }}
                              value={Surgry3rd.HospitalOfSurgry}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <FormGroup inline>
                          <Input
                            type="checkbox"
                            onChange={() => {
                              setNoSurgeries(!NoSurgeries);
                              setSurgry1st({
                                YearOfSurgry: " ",
                                ReasonOfSurgry: " ",
                                HospitalOfSurgry: " ",
                              });
                              setSurgry2nd({
                                YearOfSurgry: " ",
                                ReasonOfSurgry: " ",
                                HospitalOfSurgry: " ",
                              });
                              setSurgry3rd({
                                YearOfSurgry: " ",
                                ReasonOfSurgry: " ",
                                HospitalOfSurgry: " ",
                              });
                            }}
                          />
                          <Label style={{ padding: "5px" }}>
                            I Have Had No Surgeries
                          </Label>
                        </FormGroup>
                      </Row>
                    </CardBody>
                  </Card>
                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">YOUR MEDICAL HISTORY </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <FormGroup>
                          <FormText color="default" tag="span">
                            Please indicate if YOU have a history of the
                            following:
                          </FormText>
                        </FormGroup>
                        <Row>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Alcohol_Abuse}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Alcohol_Abuse:
                                        !medicalhistory.Alcohol_Abuse,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Alcohol Abuse
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Anemia}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Anemia: !medicalhistory.Anemia,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Anemia
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={
                                    medicalhistory.Anesthetic_Complication
                                  }
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Anesthetic_Complication:
                                        !medicalhistory.Anesthetic_Complication,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Anesthetic Complication
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Anxiety_Disorder}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Anxiety_Disorder:
                                        !medicalhistory.Anxiety_Disorder,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Anxiety Disorder
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={
                                    medicalhistory.Anesthetic_Complication
                                  }
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Anesthetic_Complication:
                                        !medicalhistory.Anesthetic_Complication,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Anesthetic Complication
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Arthritis}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Arthritis: !medicalhistory.Arthritis,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Arthritis
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Asthma}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Asthma: !medicalhistory.Asthma,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Asthma
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Autoimmune_Problems}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Autoimmune_Problems:
                                        !medicalhistory.Autoimmune_Problems,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Autoimmune Problems
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Birth_Defects}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Birth_Defects:
                                        !medicalhistory.Birth_Defects,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Birth Defects
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Bladder_Problems}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Bladder_Problems:
                                        !medicalhistory.Bladder_Problems,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Bladder Problems
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Bleeding_Disease}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Bleeding_Disease:
                                        !medicalhistory.Bleeding_Disease,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Bleeding Disease
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Blood_Clots}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Blood_Clots: !medicalhistory.Blood_Clots,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Blood Clots
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Blood_Transfusions}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Blood_Transfusions:
                                        !medicalhistory.Blood_Transfusions,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Blood Transfusion
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Bowel_Disease}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Bowel_Disease:
                                        !medicalhistory.Bowel_Disease,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Bowel
                                Disease
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Breast_Cancer}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Breast_Cancer:
                                        !medicalhistory.Breast_Cancer,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Breast
                                Cancer
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Cervical_Cancer}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Cervical_Cancer:
                                        !medicalhistory.Cervical_Cancer,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Cervical
                                Cancer
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Colon_Cancer}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Colon_Cancer:
                                        !medicalhistory.Colon_Cancer,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Colon
                                Cancer
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Depression}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Depression: !medicalhistory.Depression,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Depression
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Diabetes}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Diabetes: !medicalhistory.Diabetes,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Diabetes
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Migraines}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Migraines: !medicalhistory.Migraines,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Migraines
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Osteoporosis}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Osteoporosis:
                                        !medicalhistory.Osteoporosis,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />{" "}
                                Osteoporosis
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Prostate_Cancer}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Prostate_Cancer:
                                        !medicalhistory.Prostate_Cancer,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Prostate
                                Cancer
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Rectal_Cancer}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Rectal_Cancer:
                                        !medicalhistory.Rectal_Cancer,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Rectal
                                Cancer
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Reflux_GERD}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Reflux_GERD: !medicalhistory.Reflux_GERD,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Reflux/GERD
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Seizures_Convulsions}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Seizures_Convulsions:
                                        !medicalhistory.Seizures_Convulsions,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />{" "}
                                Seizures/Convulsions
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Severe_Allergy}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Severe_Allergy:
                                        !medicalhistory.Severe_Allergy,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Severe
                                Allergy
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={
                                    medicalhistory.Sexually_Transmitted_Disease
                                  }
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Sexually_Transmitted_Disease:
                                        !medicalhistory.Sexually_Transmitted_Disease,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Sexually
                                Transmitted Disease
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Skin_Cancer}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Skin_Cancer: !medicalhistory.Skin_Cancer,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Skin Cancer
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={
                                    medicalhistory.Stroke_CVA_of_the_Brain
                                  }
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Stroke_CVA_of_the_Brain:
                                        !medicalhistory.Stroke_CVA_of_the_Brain,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Stroke/CVA
                                of the Brain
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Suicide_Attempt}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Suicide_Attempt:
                                        !medicalhistory.Suicide_Attempt,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Suicide
                                Attempt
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Thyroid_Problems}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Thyroid_Problems:
                                        !medicalhistory.Thyroid_Problems,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Thyroid
                                Problems
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Ulcer}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Ulcer: !medicalhistory.Ulcer,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Ulcer
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.Visual_Impairment}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Visual_Impairment:
                                        !medicalhistory.Visual_Impairment,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Visual
                                Impairment
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={
                                    medicalhistory.Other_Disease_Cancer_or_Significant_Medical_Illness
                                  }
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      Other_Disease_Cancer_or_Significant_Medical_Illness:
                                        !medicalhistory.Other_Disease_Cancer_or_Significant_Medical_Illness,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Other
                                Disease, Cancer, or Significant Medical Illness
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={medicalhistory.NONE_of_the_Above}
                                  onChange={() => {
                                    setmedicalhistory({
                                      ...medicalhistory,
                                      NONE_of_the_Above:
                                        !medicalhistory.NONE_of_the_Above,
                                    });
                                    setmedicalhistory({
                                      Alcohol_Abuse: false,
                                      Anemia: false,
                                      Anesthetic_Complication: false,
                                      Anxiety_Disorder: false,
                                      Arthritis: false,
                                      Asthma: false,
                                      Autoimmune_Problems: false,
                                      Birth_Defects: false,
                                      Bladder_Problems: false,
                                      Bleeding_Disease: false,
                                      Blood_Clots: false,
                                      Blood_Transfusions: false,
                                      Bowel_Disease: false,
                                      Breast_Cancer: false,
                                      Cervical_Cancer: false,
                                      Colon_Cancer: false,
                                      Depression: false,
                                      Diabetes: false,
                                      GrowthDevelopment_Disorder: false,
                                      Hearing_Impairment: false,
                                      Heart_Attack: false,
                                      Heart_Disease: false,
                                      Heart_Pain_Angina: false,
                                      Hepatitis_A: false,
                                      Hepatitis_B: false,
                                      Hepatitis_C: false,
                                      High_Blood_Pressure: false,
                                      High_Cholesterol: false,
                                      HIV: false,
                                      Hives: false,
                                      Kidney_Disease: false,
                                      Liver_Cancer: false,
                                      Liver_Disease: false,
                                      Lung_Cancer: false,
                                      Lung_Respiratory_Disease: false,
                                      Mental_Illness: false,
                                      Migraines: false,
                                      Osteoporosis: false,
                                      Prostate_Cancer: false,
                                      Rectal_Cancer: false,
                                      Reflux_GERD: false,
                                      Seizures_Convulsions: false,
                                      Severe_Allergy: false,
                                      Sexually_Transmitted_Disease: false,
                                      Skin_Cancer: false,
                                      Stroke_CVA_of_the_Brain: false,
                                      Suicide_Attempt: false,
                                      Thyroid_Problems: false,
                                      Ulcer: false,
                                      Visual_Impairment: false,
                                      Other_Disease_Cancer_or_Significant_Medical_Illness: false,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> NONE of the
                                Above
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">Medication </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <FormGroup>
                          <FormText color="default" tag="span">
                            Please Fill in the Medication you are Currently
                            using:
                          </FormText>
                        </FormGroup>
                        <Row>
                          <Col>
                            <FormGroup inline>
                              <Label>
                                Drug
                                <Input
                                  value={Medication1.Drug}
                                  type="text"
                                  onChange={(event) => {
                                    setMedication1({
                                      ...Medication1,
                                      Drug: event.target.value,
                                    });
                                  }}
                                />
                              </Label>
                              <Label>
                                Dose/Frequency
                                <Input
                                  value={Medication1.Dose}
                                  type="text"
                                  onChange={(event) => {
                                    setMedication1({
                                      ...Medication1,
                                      Dose: event.target.value,
                                    });
                                  }}
                                />
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup inline>
                              <Label>
                                Drug
                                <Input
                                  value={Medication2.Drug}
                                  type="text"
                                  onChange={(event) => {
                                    setMedication2({
                                      ...Medication2,
                                      Drug: event.target.value,
                                    });
                                  }}
                                />
                              </Label>
                              <Label>
                                Dose/Frequency
                                <Input
                                  value={Medication2.Dose}
                                  type="text"
                                  onChange={(event) => {
                                    setMedication2({
                                      ...Medication2,
                                      Dose: event.target.value,
                                    });
                                  }}
                                />
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup inline>
                              <Label>
                                Drug
                                <Input
                                  value={Medication3.Drug}
                                  type="text"
                                  onChange={(event) => {
                                    setMedication3({
                                      ...Medication3,
                                      Drug: event.target.value,
                                    });
                                  }}
                                />
                              </Label>
                              <Label>
                                Dose/Frequency
                                <Input
                                  value={Medication3.Dose}
                                  type="text"
                                  onChange={(event) => {
                                    setMedication3({
                                      ...Medication3,
                                      Dose: event.target.value,
                                    });
                                  }}
                                />
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup inline>
                              <Label>
                                Drug
                                <Input
                                  value={Medication4.Drug}
                                  type="text"
                                  onChange={(event) => {
                                    setMedication4({
                                      ...Medication4,
                                      Drug: event.target.value,
                                    });
                                  }}
                                />
                              </Label>
                              <Label>
                                Dose/Frequency
                                <Input
                                  value={Medication4.Dose}
                                  type="text"
                                  onChange={(event) => {
                                    setMedication4({
                                      ...Medication4,
                                      Dose: event.target.value,
                                    });
                                  }}
                                />
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup inline>
                              <Label>
                                Drug
                                <Input
                                  value={Medication5.Drug}
                                  type="text"
                                  onChange={(event) => {
                                    setMedication5({
                                      ...Medication5,
                                      Drug: event.target.value,
                                    });
                                  }}
                                />
                              </Label>
                              <Label>
                                Dose/Frequency
                                <Input
                                  value={Medication5.Dose}
                                  type="text"
                                  onChange={(event) => {
                                    setMedication5({
                                      ...Medication5,
                                      Dose: event.target.value,
                                    });
                                  }}
                                />
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup inline>
                              <Label>
                                Drug
                                <Input
                                  value={Medication6.Drug}
                                  type="text"
                                  onChange={(event) => {
                                    setMedication6({
                                      ...Medication6,
                                      Drug: event.target.value,
                                    });
                                  }}
                                />
                              </Label>
                              <Label>
                                Dose/Frequency
                                <Input
                                  value={Medication6.Dose}
                                  type="text"
                                  onChange={(event) => {
                                    setMedication6({
                                      ...Medication6,
                                      Dose: event.target.value,
                                    });
                                  }}
                                />
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <CardHeader>
                          <CardTitle tag="h4">
                            {" "}
                            FAMILY MEDICAL HISTORY
                          </CardTitle>
                        </CardHeader>
                        <Col>
                          <FormGroup>
                            <FormText color="default" tag="span">
                              Please indicate if YOUR FAMILY has a history of
                              the following: (ONLY include parents,
                              grandparents, siblings, and children)
                            </FormText>
                          </FormGroup>
                        </Col>
                        <Row>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.Adopted}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      Adopted: !Fmedicalhistory.Adopted,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />I am adopted
                                and do not know biological family history
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={
                                    Fmedicalhistory.Family_History_unknow
                                  }
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      Family_History_unknow:
                                        !Fmedicalhistory.Family_History_unknow,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Family History Unknown
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Alcohol_Abuse}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Alcohol_Abuse:
                                        !Fmedicalhistory.F_Alcohol_Abuse,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Alcohol Abuse
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Anemia}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Anemia: !Fmedicalhistory.F_Anemia,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Anemia
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={
                                    Fmedicalhistory.F_Anesthetic_Complication
                                  }
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Anesthetic_Complication:
                                        !Fmedicalhistory.F_Anesthetic_Complication,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Anesthetic Complication
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Anxiety_Disorder}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Anxiety_Disorder:
                                        !Fmedicalhistory.F_Anxiety_Disorder,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Anxiety Disorder
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Arthritis}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      FArthritis: !Fmedicalhistory.F_Arthritis,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Arthritis
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Asthma}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Asthma: !Fmedicalhistory.F_Asthma,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Asthma
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={
                                    Fmedicalhistory.F_Autoimmune_Problems
                                  }
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Autoimmune_Problems:
                                        !Fmedicalhistory.F_Autoimmune_Problems,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Autoimmune Problems
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Birth_Defects}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Birth_Defects:
                                        !Fmedicalhistory.F_Birth_Defects,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Birth Defects
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Bladder_Problems}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Bladder_Problems:
                                        !Fmedicalhistory.F_Bladder_Problems,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Bladder Problems
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Bleeding_Disease}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Bleeding_Disease:
                                        !Fmedicalhistory.F_Bleeding_Disease,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Bleeding Disease
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Blood_Clots}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Blood_Clots:
                                        !Fmedicalhistory.F_Blood_Clots,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Blood Clots
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Blood_Transfusions}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Blood_Transfusions:
                                        !Fmedicalhistory.F_Blood_Transfusions,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Blood Transfusion
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Bowel_Disease}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Bowel_Disease:
                                        !Fmedicalhistory.F_Bowel_Disease,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Bowel
                                Disease
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Breast_Cancer}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Breast_Cancer:
                                        !Fmedicalhistory.F_Breast_Cancer,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Breast
                                Cancer
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Cervical_Cancer}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Cervical_Cancer:
                                        !Fmedicalhistory.F_Cervical_Cancer,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Cervical
                                Cancer
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Colon_Cancer}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Colon_Cancer:
                                        !Fmedicalhistory.F_Colon_Cancer,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Colon
                                Cancer
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Depression}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Depression:
                                        !Fmedicalhistory.F_Depression,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Depression
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Diabetes}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Diabetes: !Fmedicalhistory.F_Diabetes,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Diabetes
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Migraines}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Migraines: !Fmedicalhistory.F_Migraines,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Migraines
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Osteoporosis}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Osteoporosis:
                                        !Fmedicalhistory.F_Osteoporosis,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />{" "}
                                Osteoporosis
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Prostate_Cancer}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Prostate_Cancer:
                                        !Fmedicalhistory.F_Prostate_Cancer,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Prostate
                                Cancer
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Rectal_Cancer}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Rectal_Cancer:
                                        !Fmedicalhistory.F_Rectal_Cancer,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Rectal
                                Cancer
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Reflux_GERD}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Reflux_GERD:
                                        !Fmedicalhistory.F_Reflux_GERD,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Reflux/GERD
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={
                                    Fmedicalhistory.F_Seizures_Convulsions
                                  }
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Seizures_Convulsions:
                                        !Fmedicalhistory.F_Seizures_Convulsions,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />{" "}
                                Seizures/Convulsions
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Severe_Allergy}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Severe_Allergy:
                                        !Fmedicalhistory.F_Severe_Allergy,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Severe
                                Allergy
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={
                                    Fmedicalhistory.F_Sexually_Transmitted_Disease
                                  }
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Sexually_Transmitted_Disease:
                                        !Fmedicalhistory.F_Sexually_Transmitted_Disease,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Sexually
                                Transmitted Disease
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Skin_Cancer}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Skin_Cancer:
                                        !Fmedicalhistory.F_Skin_Cancer,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Skin Cancer
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={
                                    Fmedicalhistory.F_Stroke_CVA_of_the_Brain
                                  }
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Stroke_CVA_of_the_Brain:
                                        !Fmedicalhistory.F_Stroke_CVA_of_the_Brain,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Stroke/CVA
                                of the Brain
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Suicide_Attempt}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Suicide_Attempt:
                                        !Fmedicalhistory.F_Suicide_Attempt,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Suicide
                                Attempt
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Thyroid_Problems}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Thyroid_Problems:
                                        !Fmedicalhistory.F_Thyroid_Problems,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Thyroid
                                Problems
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Ulcer}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Ulcer: !Fmedicalhistory.F_Ulcer,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Ulcer
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_Visual_Impairment}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Visual_Impairment:
                                        !Fmedicalhistory.F_Visual_Impairment,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Visual
                                Impairment
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={
                                    Fmedicalhistory.F_Other_Disease_Cancer_or_Significant_Medical_Illness
                                  }
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_Other_Disease_Cancer_or_Significant_Medical_Illness:
                                        !Fmedicalhistory.F_Other_Disease_Cancer_or_Significant_Medical_Illness,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> Other
                                Disease, Cancer, or Significant Medical Illness
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.F_NONE_of_the_Above}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      F_NONE_of_the_Above:
                                        !Fmedicalhistory.F_NONE_of_the_Above,
                                    });
                                    setFmedicalhistory({
                                      F_Alcohol_Abuse: false,
                                      F_Anemia: false,
                                      F_Anesthetic_Complication: false,
                                      F_Anxiety_Disorder: false,
                                      F_Arthritis: false,
                                      F_Asthma: false,
                                      F_Autoimmune_Problems: false,
                                      F_Birth_Defects: false,
                                      F_Bladder_Problems: false,
                                      F_Bleeding_Disease: false,
                                      F_Blood_Clots: false,
                                      F_Blood_Transfusions: false,
                                      F_Bowel_Disease: false,
                                      F_Breast_Cancer: false,
                                      F_Cervical_Cancer: false,
                                      F_Colon_Cancer: false,
                                      F_Depression: false,
                                      F_Diabetes: false,
                                      F_GrowthDevelopment_Disorder: false,
                                      F_Hearing_Impairment: false,
                                      F_Heart_Attack: false,
                                      F_Heart_Disease: false,
                                      F_Heart_Pain_Angina: false,
                                      F_Hepatitis_A: false,
                                      F_Hepatitis_B: false,
                                      F_Hepatitis_C: false,
                                      F_High_Blood_Pressure: false,
                                      F_High_Cholesterol: false,
                                      F_HIV: false,
                                      F_Hives: false,
                                      F_Kidney_Disease: false,
                                      F_Liver_Cancer: false,
                                      F_Liver_Disease: false,
                                      F_Lung_Cancer: false,
                                      F_Lung_Respiratory_Disease: false,
                                      F_Mental_Illness: false,
                                      F_Migraines: false,
                                      F_Osteoporosis: false,
                                      F_Prostate_Cancer: false,
                                      F_Rectal_Cancer: false,
                                      F_Reflux_GERD: false,
                                      F_Seizures_Convulsions: false,
                                      F_Severe_Allergy: false,
                                      F_Sexually_Transmitted_Disease: false,
                                      F_Skin_Cancer: false,
                                      F_Stroke_CVA_of_the_Brain: false,
                                      F_Suicide_Attempt: false,
                                      F_Thyroid_Problems: false,
                                      F_Ulcer: false,
                                      F_Visual_Impairment: false,
                                      F_Other_Disease_Cancer_or_Significant_Medical_Illness: false,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" /> NONE of the
                                Above
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.Female_Family}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      Female_Family:
                                        !Fmedicalhistory.Female_Family,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Mother, Grandmother, or Sister developed heart
                                disease before the age of 65
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={Fmedicalhistory.Male_Family}
                                  onChange={() => {
                                    setFmedicalhistory({
                                      ...Fmedicalhistory,
                                      Male_Family: !Fmedicalhistory.Male_Family,
                                    });
                                  }}
                                />
                                <span className="form-check-sign" />
                                Father, Grandfather, or Brother developed heart
                                disease before the age of 65
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4"> SOCIAL MEDICAL HISTORY</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md="4">
                          <FormText color="default" tag="span">
                            Occupation
                          </FormText>
                        </Col>
                        <Col md="4">
                          <FormText color="default" tag="span">
                            Select Your Marital Status:
                          </FormText>
                        </Col>
                        <Col md="4">
                          <FormText color="default" tag="span">
                            How many children do you have:
                          </FormText>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <Input
                            //returnKeyType={'next'}
                            fullWidth
                            label={"Your Occupation"}
                            value={SocialHistory.occupation}
                            onChange={(text) => {
                              setSocialHistory({
                                ...SocialHistory,
                                occupation: text.target.value,
                              });
                            }}
                            customLabelStyles={{
                              colorFocused: "red",
                              colorBlurred: "black",
                              fontSizeFocused: 12,
                            }}
                          />
                        </Col>

                        <Col md="4">
                          <FormGroup>
                            <Select
                              fullWidth
                              styles={{ fullWidth: "30px" }}
                              onChange={(event) => {
                                setSocialHistory({
                                  ...SocialHistory,
                                  maritalstatus: event.target.value,
                                });
                              }}
                              value={SocialHistory.maritalstatus}
                            >
                              <MenuItem value="Married">Married</MenuItem>
                              <MenuItem value="Single">Single</MenuItem>
                              <MenuItem value="Divorced">Divorced</MenuItem>
                              <MenuItem value="Widowed">Widowed</MenuItem>
                            </Select>
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <Input
                            //returnKeyType={'next'}
                            fullWidth
                            label={"Number of Dependents"}
                            value={SocialHistory.children}
                            onChange={(text) => {
                              setSocialHistory({
                                ...SocialHistory,
                                children: text.target.value,
                              });
                            }}
                            customLabelStyles={{
                              colorFocused: "red",
                              colorBlurred: "black",
                              fontSizeFocused: 12,
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <FormText color="default" tag="span">
                            Your General Diet:
                          </FormText>
                        </Col>
                        <Col md="4">
                          <FormText color="default" tag="span">
                            Select Your Sexual Orientation
                          </FormText>
                        </Col>
                        <Col md="4">
                          <FormText color="default" tag="span">
                            How often/Hard you Exercise ?
                          </FormText>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <Input
                            //returnKeyType={'next'}

                            label={"Your Diet"}
                            value={SocialHistory.diet}
                            onChange={(text) => {
                              setSocialHistory({
                                ...SocialHistory,
                                diet: text.target.value,
                              });
                            }}
                            customLabelStyles={{
                              colorFocused: "red",
                              colorBlurred: "black",
                              fontSizeFocused: 12,
                            }}
                          />
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Select
                              fullWidth
                              styles={{ fullWidth: "30px" }}
                              onChange={(event) => {
                                setSocialHistory({
                                  ...SocialHistory,
                                  sexualorientation: event.target.value,
                                });
                              }}
                              value={SocialHistory.sexualorientation}
                            >
                              <MenuItem value="Heterosexual">
                                Heterosexual
                              </MenuItem>
                              <MenuItem value="Homosexual">Homosexual</MenuItem>
                              <MenuItem value="Bisexual">Bisexual</MenuItem>
                            </Select>
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Select
                              fullWidth
                              styles={{ fullWidth: "30px" }}
                              onChange={(event) => {
                                setSocialHistory({
                                  ...SocialHistory,
                                  exercise: event.target.value,
                                });
                              }}
                              value={SocialHistory.exercise}
                            >
                              <MenuItem value="Sedentary">Sedentary</MenuItem>
                              <MenuItem value="Moderately">Moderately</MenuItem>
                              <MenuItem value="Vigorous">Vigorous</MenuItem>
                              <MenuItem value="Not Specified">
                                Not Specified
                              </MenuItem>
                            </Select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <FormText color="default" tag="span">
                            Do you drink Alcohol ?
                          </FormText>
                        </Col>
                        <Col md="4">
                          <FormText color="default" tag="span">
                            Do you Smoke ?
                          </FormText>
                        </Col>
                        <Col md="4">
                          <FormText color="default" tag="span">
                            How often/Hard you Exercise ?
                          </FormText>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <Label>
                              <Input
                                //returnKeyType={'next'}
                                type="checkbox"
                                checked={SocialHistory.alcoholconsumption}
                                onChange={(text) => {
                                  setSocialHistory({
                                    ...SocialHistory,
                                    alcoholconsumption: true,
                                  });
                                }}
                                customLabelStyles={{
                                  colorFocused: "red",
                                  colorBlurred: "black",
                                  fontSizeFocused: 12,
                                }}
                              />
                              Yes
                            </Label>
                            <Label>
                              <Input
                                //returnKeyType={'next'}
                                type="checkbox"
                                checked={!SocialHistory.alcoholconsumption}
                                onChange={(text) => {
                                  setSocialHistory({
                                    ...SocialHistory,
                                    alcoholconsumption: false,
                                  });
                                }}
                                customLabelStyles={{
                                  colorFocused: "red",
                                  colorBlurred: "black",
                                  fontSizeFocused: 12,
                                }}
                              />
                              No
                            </Label>
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup>
                            <Label>
                              <Input
                                //returnKeyType={'next'}
                                type="checkbox"
                                checked={SocialHistory.smoking}
                                onChange={(text) => {
                                  setSocialHistory({
                                    ...SocialHistory,
                                    smoking: true,
                                  });
                                }}
                                customLabelStyles={{
                                  colorFocused: "red",
                                  colorBlurred: "black",
                                  fontSizeFocused: 12,
                                }}
                              />
                              Yes
                            </Label>
                            <Label>
                              <Input
                                //returnKeyType={'next'}
                                type="checkbox"
                                checked={!SocialHistory.smoking}
                                onChange={(text) => {
                                  setSocialHistory({
                                    ...SocialHistory,
                                    smoking: false,
                                  });
                                }}
                                customLabelStyles={{
                                  colorFocused: "red",
                                  colorBlurred: "black",
                                  fontSizeFocused: 12,
                                }}
                              />
                              No
                            </Label>
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup>
                            <Select
                              fullWidth
                              styles={{ fullWidth: "30px" }}
                              onChange={(event) => {
                                setSocialHistory({
                                  ...SocialHistory,
                                  stressfactor: event.target.value,
                                });
                              }}
                              value={SocialHistory.stressfactor}
                            >
                              <MenuItem value="Relaxed">Relaxed</MenuItem>
                              <MenuItem value="Moderately Stressed">
                                Moderately Stressed
                              </MenuItem>
                              <MenuItem value="Vigorous">Vigorous</MenuItem>
                              <MenuItem value="Highly Stressed">
                                Highly Stressed
                              </MenuItem>
                            </Select>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardBody>
                <CardFooter>
                  <Button
                    onClick={() => {
                      const FullName =
                        auth.info.profile.firstName +
                        " " +
                        auth.info.profile.lastName;
                      let BMI = Weight / (((Height / 100) * Height) / 100);
                      BMI = BMI.toFixed(2);
                      let Payload = {
                        Medical_Information: {
                          Basic_info: {
                            FullName,
                            DOB,
                            Weight,
                            Height,
                            BMI,
                            Sex,
                            BloodType,
                          },
                          Childhood_illness: {
                            Measles,
                            Mumps,
                            None,
                            All_the_Above,
                            Polio,
                            Rheumatic,
                            Rubellac,
                            Chickenpox,
                          },
                          Immunizations_an_Dates: {
                            Tetanus,
                            Pneumonia,
                            Hepatitis_A,
                            Hepatitis_B,
                            Chickenpox2,
                            influenza,
                            MMR,
                            Meningococcal,
                            None2,
                          },
                          Surgries: {
                            Surgry1st,
                            Surgry2nd,
                            Surgry3rd,
                            NoSurgeries,
                          },
                          Medical_History: {
                            medicalhistory,
                          },
                          Medication: {
                            Medication1,
                            Medication2,
                            Medication3,
                            Medication4,
                            Medication5,
                            Medication6,
                          },
                          Family_Medical_History: {
                            Fmedicalhistory,
                          },
                          LifeStyle_History: {
                            SocialHistory,
                          },
                        },
                      };
                      dispatch(
                        editMedicalHistory("Add medical history", Payload)
                      );
                      console.log("Add medical history", Payload);
                      // console.log("Iam the measles", { Childhoodill });
                    }}
                  >
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default PatientMedicalHistory;
