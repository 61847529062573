/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import CircularLoading from "../../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationDialogRaw from "../../components/ConfirmationDialogRaw";
import { features, dateStyle, language } from "config";

import { FirebaseContext } from "common";

const NurseBookingsReport = () => {
  const { api } = useContext(FirebaseContext);
  const { fetchBookings } = api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const usersdata = useSelector((state) => state.doctors);
  const [role, setRole] = useState(null);
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [Doctors, setDoctors] = useState([]);
  let [columns, setColumn] = useState([]);

  const bookinglistdata = useSelector(
    (state) => state.bookinglistdata.bookings
  );
  useEffect(() => {
    console.log("Booking data ", bookinglistdata);
    if (bookinglistdata !== null) {
      setData(bookinglistdata);
    } else if (bookinglistdata === null) {
      setData([]);
    }
  }, []);

  columns = [
    {
      title: "Booking ID",
      field: "bookingId",
      render: (rowData) => (rowData ? rowData.id : " "),
    },
    {
      title: "Patient Name",
      field: "patient",
      render: (rowData) => (rowData ? rowData.patientName : " "),
    },
    {
      title: "Booking Name",
      field: "bookingName",
      render: (rowData) => (rowData ? rowData.bookingName : " "),
    },
    {
      title: "Appointment Date",
      field: "appointmentDate",
      render: (rowData) => (rowData ? rowData.eventDate : " "),
    },
    // {
    //   title: "Booking Date",
    //   field: "bookingDate",
    //   render: (rowData) => (rowData ? rowData.bookingDate : " "),
    // },
    {
      title: "Consultation Duration",
      field: "consultationDuration",
      render: (rowData) =>
        rowData ? rowData.practiceType.consultation_duration : " ",
    },
    {
      title: "Service Type",
      field: "serviceType",
      render: (rowData) => (rowData ? rowData.serviceType : " "),
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) => (rowData ? rowData.status : " "),
    },
    // { title: 'Follow Up' },  this is optional
  ];

  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState("");

  return (
    <div>
      <MaterialTable
        title="Bookings Report"
        columns={columns}
        data={data}
        options={{
          actionsColumnIndex: -1,
        }}
      />
    </div>
  );
};

export default NurseBookingsReport;
