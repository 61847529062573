/*eslint-disable*/
import CircularLoading from "../../../components/CircularLoading";
import { Select } from "@material-ui/core";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "common";
import AlertDialog from "../../../components/AlertDialog";
import CustomInput from "components/CustomInput/CustomInput";
import MenuItem from "@material-ui/core/MenuItem";
function PatientImmunization({ route, navigation, props }) {
  const { api } = useContext(FirebaseContext);
  const { editMedicalHistory } = api;
  const dispatch = useDispatch();
  const medical = useSelector(
    (state) => state.medicalRecords.medicalHistoryRecords
  );
  const auth = useSelector((state) => state.auth);
  const [medicalData, setMedicalData] = useState(medical);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  //Immunisation and dates
  const [Tetanus, setTetanus] = useState({
    checked: false,
    Date: null,
  });
  const [Pneumonia, setPneumonia] = useState({
    checked: false,
    Date: null,
  });
  const [Hepatitis_A, setHepatitis_A] = useState({
    checked: false,
    Date: null,
  });
  const [Hepatitis_B, setHepatitis_B] = useState({
    checked: false,
    Date: null,
  });
  const [Chickenpox2, setChickenpox2] = useState({
    checked: false,
    Date: null,
  });
  const [influenza, setinfluenza] = useState({
    checked: false,
    Date: null,
  });
  const [covid, setCovid] = useState({
    checked: false,
    Date: null,
  });
  const [MMR, setMMR] = useState({
    checked: false,
    Date: null,
  });
  const [Meningococcal, setMeningococcal] = useState({
    checked: false,
    Date: null,
  });
  const [None2, setNone2] = useState({
    checked: false,
  });
  ///////////////////////////////////////////////////////////////
  //Surgies
  const [Surgry1st, setSurgry1st] = useState({
    YearOfSurgry: " ",
    ReasonOfSurgry: " ",
    HospitalOfSurgry: " ",
  });
  const [Surgry2nd, setSurgry2nd] = useState({
    YearOfSurgry: " ",
    ReasonOfSurgry: " ",
    HospitalOfSurgry: " ",
  });
  const [Surgry3rd, setSurgry3rd] = useState({
    YearOfSurgry: " ",
    ReasonOfSurgry: " ",
    HospitalOfSurgry: " ",
  });
  const [NoSurgeries, setNoSurgeries] = useState(false);

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setMedicalData(medical);
    }
  }, [medical]);

  const OnSave = () => {
    let payload = {
      Medical_Information: {
        ...medical,

        Immunizations_an_Dates: medicalData?.Immunizations_an_Dates
          ? medicalData?.Immunizations_an_Dates
          : null,
      },
    };
    console.log("this is imm payload:", payload);
    dispatch(editMedicalHistory(payload, "Add medical history"));
    setCommonAlert({
      open: true,
      msg: "Your Immunization History Has Been Saved",
    });
  };
  return auth.loading ? (
    <CircularLoading />
  ) : (
    <>
      <div className="content">
        <div className="medicalhistory-form">
          <Row>
            <Col md="12">
              <AlertDialog
                open={commonAlert.open}
                onClose={handleCommonAlertClose}
              >
                {commonAlert.msg}
              </AlertDialog>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Immunization and Date</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Immunizations_an_Dates?.Tetanus
                                ?.checked
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Immunizations_an_Dates: {
                                  ...medicalData.Immunizations_an_Dates,
                                  Tetanus: {
                                    ...medicalData.Immunizations_an_Dates
                                      .Tetanus,
                                    checked: event.target.checked,
                                    Date: null,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Tetanus
                          <Input
                            type="date"
                            placeholder="Date of Birth"
                            // //disabled={!Tetanus.checked}
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Immunizations_an_Dates: {
                                  ...medicalData.Immunizations_an_Dates,
                                  Tetanus: {
                                    ...medicalData.Immunizations_an_Dates
                                      .Tetanus,
                                    checked: true,
                                    Date: event.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              medicalData?.Immunizations_an_Dates?.Tetanus?.Date
                            }
                          />
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Immunizations_an_Dates?.Pneumonia
                                ?.checked
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Immunizations_an_Dates: {
                                  ...medicalData.Immunizations_an_Dates,
                                  Pneumonia: {
                                    ...medicalData.Immunizations_an_Dates
                                      .Pneumonia,
                                    checked: event.target.checked,
                                    Date: null,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Pneumonia
                          <Input
                            type="date"
                            placeholder="Date of Birth"
                            //disabled={!Pneumonia.checked}
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Immunizations_an_Dates: {
                                  ...medicalData.Immunizations_an_Dates,
                                  Pneumonia: {
                                    ...medicalData.Immunizations_an_Dates
                                      .Pneumonia,
                                    checked: true,
                                    Date: event.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              medicalData?.Immunizations_an_Dates?.Pneumonia
                                ?.Date
                            }
                          />
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Immunizations_an_Dates?.Hepatitis_A
                                ?.checked
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Immunizations_an_Dates: {
                                  ...medicalData.Immunizations_an_Dates,
                                  Hepatitis_A: {
                                    ...medicalData.Immunizations_an_Dates
                                      .Hepatitis_A,
                                    checked: event.target.checked,
                                    Date: null,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Hepatitis A
                          <Input
                            type="date"
                            placeholder="Date of Birth"
                            //disabled={!Hepatitis_A.checked}
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Immunizations_an_Dates: {
                                  ...medicalData.Immunizations_an_Dates,
                                  Hepatitis_A: {
                                    ...medicalData.Immunizations_an_Dates
                                      .Hepatitis_A,
                                    checked: true,
                                    Date: event.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              medicalData?.Immunizations_an_Dates?.Hepatitis_A
                                ?.Date
                            }
                          />
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Immunizations_an_Dates?.Hepatitis_B
                                ?.checked
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Immunizations_an_Dates: {
                                  ...medicalData.Immunizations_an_Dates,
                                  Hepatitis_B: {
                                    ...medicalData.Immunizations_an_Dates
                                      .Hepatitis_B,
                                    checked: event.target.checked,
                                    Date: null,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Hepatitis B
                          <Input
                            type="date"
                            placeholder="Date of Birth"
                            //disabled={!Hepatitis_B.checked}
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Immunizations_an_Dates: {
                                  ...medicalData.Immunizations_an_Dates,
                                  Hepatitis_B: {
                                    ...medicalData.Immunizations_an_Dates
                                      .Hepatitis_B,
                                    checked: true,
                                    Date: event.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              medicalData?.Immunizations_an_Dates?.Hepatitis_B
                                ?.Date
                            }
                          />
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Immunizations_an_Dates?.Chickenpox2
                                ?.checked
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Immunizations_an_Dates: {
                                  ...medicalData.Immunizations_an_Dates,
                                  Chickenpox2: {
                                    ...medicalData.Immunizations_an_Dates
                                      .Chickenpox2,
                                    checked: event.target.checked,
                                    Date: null,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Chickenpox
                          <Input
                            type="date"
                            placeholder="Date of Birth"
                            //disabled={!Chickenpox2.checked}
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Immunizations_an_Dates: {
                                  ...medicalData.Immunizations_an_Dates,
                                  Chickenpox2: {
                                    ...medicalData.Immunizations_an_Dates
                                      .Chickenpox2,
                                    checked: true,
                                    Date: event.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              medicalData?.Immunizations_an_Dates?.Chickenpox2
                                ?.Date
                            }
                          />
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            checked={
                              medicalData?.Immunizations_an_Dates?.influenza
                                ?.checked
                            }
                            type="checkbox"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Immunizations_an_Dates: {
                                  ...medicalData.Immunizations_an_Dates,
                                  influenza: {
                                    ...medicalData.Immunizations_an_Dates
                                      .influenza,
                                    checked: event.target.checked,
                                    Date: null,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          influenza
                          <Input
                            type="date"
                            placeholder="Date of Birth"
                            //disabled={!influenza.checked}
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Immunizations_an_Dates: {
                                  ...medicalData.Immunizations_an_Dates,
                                  influenza: {
                                    ...medicalData.Immunizations_an_Dates
                                      .influenza,
                                    checked: true,
                                    Date: event.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              medicalData?.Immunizations_an_Dates?.influenza
                                ?.Date
                            }
                          />
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Immunizations_an_Dates?.MMR?.checked
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Immunizations_an_Dates: {
                                  ...medicalData.Immunizations_an_Dates,
                                  MMR: {
                                    ...medicalData.Immunizations_an_Dates.MMR,
                                    checked: event.target.checked,
                                    Date: null,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          MMR Measles, Mumps, Rubella
                          <Input
                            type="date"
                            placeholder="Date of Birth"
                            //disabled={!MMR.checked}
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Immunizations_an_Dates: {
                                  ...medicalData.Immunizations_an_Dates,
                                  MMR: {
                                    ...medicalData.Immunizations_an_Dates.MMR,
                                    checked: true,
                                    Date: event.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              medicalData?.Immunizations_an_Dates?.MMR?.Date
                            }
                          />
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Immunizations_an_Dates?.Meningococcal
                                ?.checked
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Immunizations_an_Dates: {
                                  ...medicalData.Immunizations_an_Dates,
                                  Meningococcal: {
                                    ...medicalData.Immunizations_an_Dates
                                      .Meningococcal,
                                    checked: event.target.checked,
                                    Date: null,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Meningococcal
                          <Input
                            type="date"
                            placeholder="Date of Birth"
                            //disabled={!Meningococcal.checked}
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Immunizations_an_Dates: {
                                  ...medicalData.Immunizations_an_Dates,
                                  Meningococcal: {
                                    ...medicalData.Immunizations_an_Dates
                                      .Meningococcal,
                                    checked: true,
                                    Date: event.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              medicalData?.Immunizations_an_Dates?.Meningococcal
                                ?.Date
                            }
                          />
                        </Label>
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Immunizations_an_Dates?.Covid
                                ?.checked
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Immunizations_an_Dates: {
                                  ...medicalData.Immunizations_an_Dates,
                                  Covid: {
                                    ...medicalData.Immunizations_an_Dates.Covid,
                                    checked: event.target.checked,
                                    Date: null,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Covid
                          <Input
                            type="date"
                            placeholder="Date of Birth"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Immunizations_an_Dates: {
                                  ...medicalData.Immunizations_an_Dates,
                                  Covid: {
                                    ...medicalData.Immunizations_an_Dates.Covid,
                                    checked: true,
                                    Date: event.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              medicalData?.Immunizations_an_Dates?.Covid?.Date
                            }
                          />
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            onChange={() => {
                              setNone2({
                                checked:
                                  !medical?.Immunizations_an_Dates?.None2
                                    ?.checked,
                              });
                              if (
                                !medical?.Immunizations_an_Dates?.None2?.checked
                              ) {
                                setTetanus({
                                  checked: false,
                                  Date: null,
                                });
                                setPneumonia({
                                  checked: false,
                                  Date: null,
                                });
                                setHepatitis_A({
                                  checked: false,
                                  Date: null,
                                });
                                setHepatitis_B({
                                  checked: false,
                                  Date: null,
                                });
                                setChickenpox2({
                                  checked: false,
                                  Date: null,
                                });
                                setinfluenza({
                                  checked: false,
                                  Date: null,
                                });
                                setMMR({
                                  checked: false,
                                  Date: null,
                                });
                                setMeningococcal({
                                  checked: false,
                                  Date: null,
                                });

                                setCovid({
                                  checked: false,
                                  Date: null,
                                });
                              }
                            }}
                          />
                          <span className="form-check-sign" />
                          None
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Col>
                    <Button variant="gradient" color="primary" onClick={OnSave}>
                      SAVE
                    </Button>
                  </Col>
                </CardBody>
              </Card>
              {/* </CardBody>
                              
              </Card> */}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default PatientImmunization;
