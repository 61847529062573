/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { features, language, icd10codes, treatment } from "config";
import { FirebaseContext } from "common";
import { Button, Input, FormText, Alert } from "reactstrap";
import { Paper, Select, MenuItem, Tab, Tabs } from "@material-ui/core";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Collapse,
} from "reactstrap";
// import Select from "react-select";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

export default function PracticeDetails() {
  const { api } = useContext(FirebaseContext);
  const { editPracticeType } = api;

  const [data, setData] = useState([]);
  const practicetypes = useSelector((state) => state.practicetypes);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [practices, setPractices] = useState({});
  const usersdata = useSelector((state) => state.doctors);

  const specialities = useSelector(
    (state) => state.specialitytypes.specialities
  );
  // const codes = useSelector((state) => state.icdcodes.codes);
  const [specialityTypes, setSpecialityTypes] = useState({});
  const [selectedSpecialityDetails, setSelectedSpecialityDetails] =
    useState(null);
  const [openFPModal, setOpenFPModal] = React.useState(false);
  const [service, setservice] = useState([]);
  const [serviceT, setserviceT] = useState([]);
  const [treatments, settreatments] = useState([]);
  useEffect(() => {
    // console.log("treat", treatment);
    if (treatment) {
      let arr = [];
      for (let i = 0; i < Object.keys(treatment).length; i++) {
        arr.push({
          value: Object.values(treatment)[i],
          label: Object.keys(treatment)[i],
        });
      }
      setservice(arr);
    }
  }, []);
  useEffect(() => {
    if (serviceT) {
      let arr = [];
      for (let i = 0; i < Object.keys(serviceT).length; i++) {
        arr.push({
          value: Object.values(serviceT)[i],
          label: Object.keys(serviceT)[i],
        });
      }
      settreatments(arr);
    }
  }, [serviceT]);

  useEffect(() => {
    if (specialities) {
      let arr = [{}];

      for (let i = 0; i < specialities.length; i++) {
        arr.push({
          value: specialities[i],
        });
      }

      setSpecialityTypes(arr);
      // console.log("specialityTypes2", arr);
    }
  }, []);
  const [state, setstate] = useState({
    practiceName: "",
    practiceNumber: auth.info.profile.practiceNumber,
    specialityType: false,
    treatmentType: false,
    // fees: "",
    extra_info: "",
    base_fare: "",
    base_rate: "",
    consultation_duration: false,
    convenience_fee_type: false,
    convenience_fees: "",
    dispensing_fee: "",
    min_charge: "",
    min_fare: "",
    rate_per_unit_distance: "",
    rate_per_hour: "",
  });
  // const [practiceNamestate, setpracticeNamestate] = useState("");
  // const [practiceNumstate, setpracticeNumstate] = useState("");
  // const [serviceTypestate, setserviceTypestate] = useState(false);
  // const [treatmentTypestate, settreatmentTypestate] = useState(false);
  // const [feesState, setfeesState] = useState("");
  // const [extainfostate, setextainfostate] = useState("");
  useEffect(() => {
    if (specialities) {
      let obj = {};
      for (let i = 0; i < specialities.length; i++) {
        specialities.map((speciality) => (obj[speciality] = speciality));
      }
      setSpecialityTypes(obj);
    }
  }, [specialities]);

  useEffect(() => {
    if (practicetypes.practices) {
      let arr = practicetypes.practices.filter((prac) => {
        // console.log(prac);
        // console.log(auth.info.profile.practiceNumber);
        if (prac.practiceNumber === auth.info.profile.practiceNumber) {
          return prac;
        }
      });
      setData(arr);
    } else {
      let arr = practicetypes.practices.filter((prac) => {
        console.log(prac);
        console.log(auth.info.profile.practiceNumber);

        return prac;
      });
      setData(arr);
    }
  }, [auth.info.profile.practiceNumber, practicetypes.practices]);
  //does all the practice details require a prac Num or can it be handled in the code auto
  // but does it also mean that there wont be different practice numbers

  const handleOpenEvent = (e) => {
    e.preventDefault();
    setOpenFPModal(true);
  };
  const handleCloseEvent = (e) => {
    e.preventDefault();
    setOpenFPModal(false);
  };
  const toggle = () => setModal(!modal);
  const toggleTwo = () => setIsOpen(!isOpen);
  const onAdd = () => {
    dispatch(editPracticeType(state, "Add"));
    return toggle;
  };
  const columns = [
    { title: "Practice Name", field: "practiceName" },
    { title: "Practice Number", field: "practiceNumber" },
    {
      title: "Service Type",
      field: "specialityType",
    },
    {
      title: "Treatment type",
      field: "treatmentType",
    },
    { title: "Base Fare", field: "base_fare" },
    { title: "Base Rate", field: "base_rate" },
    { title: "Consultation Duration", field: "consultation_duration" },
    {
      title: "Convenience fee type",
      field: "convenience_fee_type",
      lookup: { flat: language.flat, percentage: language.percentage },
    },
    { title: "Convenience fee", field: "convenience_fees", type: "numeric" },
    { title: "Dispensing Fee", field: "dispensing_fee", type: "numeric" },
    { title: "Min Charge", field: "min_charge", type: "numeric" },
    { title: language.min_fare, field: "min_fare", type: "numeric" },
    {
      title: language.rate_per_unit_distance,
      field: "rate_per_unit_distance",
      type: "numeric",
    },
    { title: language.rate_per_hour, field: "rate_per_hour", type: "numeric" },

    { title: language.extra_info, field: "extra_info" },
  ];
  const [modal, setModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  return practicetypes.loading ? (
    <CircularLoading />
  ) : (
    <>
      <div>
        {/* <Select
          // value={selectedOption}
          // onChange={this.handleChange}
          options={icd10codes}
        /> */}

        <Button color="danger" onClick={toggle}>
          Add Details
        </Button>
        <Modal
          centered={true}
          fade={true}
          size="lg"
          isOpen={modal}
          toggle={toggle}
        >
          <ModalHeader>Practice Details</ModalHeader>
          <ModalBody>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <GridContainer spacing={1}>
                <GridItem xs={12}>
                  <FormText color="default" tag="span">
                    Pratice Name :
                  </FormText>

                  <Input
                    //returnKeyType={'next'}
                    fullWidth
                    bsSize="lg"
                    // placeholder={"practice name"}
                    value={state.practiceName}
                    onChange={(event) => {
                      setstate({
                        ...state,
                        practiceName: event.target.value,
                      });
                    }}
                    customLabelStyles={{
                      colorFocused: "red",
                      colorBlurred: "black",
                      fontSizeFocused: 12,
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <FormText color="default" tag="span">
                    Pratice Number :
                  </FormText>
                  <Input
                    //returnKeyType={'next'}
                    fullWidth
                    bsSize="lg"
                    disabled={true}
                    value={state.practiceNumber}
                    // onChange={(event) => {
                    //   setstate({
                    //     ...state,
                    //     practiceNumber: event.target.value,
                    //   });
                    // }}
                    customLabelStyles={{
                      colorFocused: "red",
                      colorBlurred: "black",
                      fontSizeFocused: 12,
                    }}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <FormText color="default" tag="span">
                    Service Type :
                  </FormText>
                  <Select
                    id="serviceType"
                    // value={state.serviceType}
                    onChange={(event, text) => {
                      setstate({
                        ...state,
                        specialityType: text.props.children,
                      });
                      setserviceT(event.target.value);
                      console.log(event.target.value);

                      console.log("items ttt", serviceT);
                    }}
                    variant="outlined"
                    fullWidth
                  >
                    {service.map((special) => (
                      <MenuItem value={special.value}>{special.label}</MenuItem>
                    ))}
                  </Select>
                </GridItem>
                <GridItem xs={6}>
                  <FormText color="default" tag="span">
                    Treatment Type :
                  </FormText>
                  <Select
                    id="practice-type-native"
                    // value={state.treatmentType}
                    onChange={(event) => {
                      console.log("treatment: ", event.target.value);
                      setstate((prevState) => ({
                        ...prevState,
                        treatmentType: event.target.value,
                      }));
                    }}
                    variant="outlined"
                    fullWidth
                  >
                    {treatments.map((special) => (
                      <MenuItem value={special.value}>{special.label}</MenuItem>
                    ))}
                  </Select>
                </GridItem>

                <GridItem xs={12}>
                  <Button color="danger" fullWidth onClick={toggleTwo}>
                    Add Fees
                  </Button>
                </GridItem>
                <Collapse isOpen={isOpen}>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer spacing={1}>
                      <GridItem xs={6}>
                        <FormText color="default" tag="span">
                          Base Fare :
                        </FormText>
                        <Input
                          //returnKeyType={'next'}
                          fullWidth
                          bsSize="lg"
                          // placeholder={"fees"}
                          value={state.base_fare}
                          onChange={(event) => {
                            setstate({
                              ...state,
                              base_fare: event.target.value,
                            });
                          }}
                          customLabelStyles={{
                            colorFocused: "red",
                            colorBlurred: "black",
                            fontSizeFocused: 12,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <FormText color="default" tag="span">
                          Base Rate :
                        </FormText>
                        <Input
                          //returnKeyType={'next'}
                          fullWidth
                          bsSize="lg"
                          // placeholder={"fees"}
                          value={state.base_rate}
                          onChange={(event) => {
                            setstate({
                              ...state,
                              base_rate: event.target.value,
                            });
                          }}
                          customLabelStyles={{
                            colorFocused: "red",
                            colorBlurred: "black",
                            fontSizeFocused: 12,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <FormText color="default" tag="span">
                          Consultation Duration :
                        </FormText>
                        <Input
                          //returnKeyType={'next'}
                          type="select"
                          fullWidth
                          // bsSize="lg"
                          // placeholder={"fees"}
                          value={state.consultation_duration}
                          onChange={(event) => {
                            console.log(event);
                            setstate({
                              ...state,
                              consultation_duration: event.target.value,
                            });
                          }}
                          customLabelStyles={{
                            colorFocused: "red",
                            colorBlurred: "black",
                            fontSizeFocused: 12,
                          }}
                        >
                          {/* <option>{service[0].label}</option> */}
                        </Input>
                      </GridItem>
                      <GridItem xs={6}>
                        <FormText color="default" tag="span">
                          Convience Fee Type :
                        </FormText>
                        <Input
                          //returnKeyType={'next'}
                          type="select"
                          fullWidth
                          // bsSize="lg"
                          // placeholder={"fees"}
                          value={state.convenience_fee_type}
                          onChange={(event) => {
                            setstate({
                              ...state,
                              convenience_fee_type: event.target.value,
                            });
                          }}
                          customLabelStyles={{
                            colorFocused: "red",
                            colorBlurred: "black",
                            fontSizeFocused: 12,
                          }}
                        >
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </Input>
                      </GridItem>
                      <GridItem xs={6}>
                        <FormText color="default" tag="span">
                          Convience Fee :
                        </FormText>
                        <Input
                          //returnKeyType={'next'}
                          fullWidth
                          bsSize="lg"
                          // placeholder={"fees"}
                          value={state.convenience_fees}
                          onChange={(event) => {
                            setstate({
                              ...state,
                              convenience_fees: event.target.value,
                            });
                          }}
                          customLabelStyles={{
                            colorFocused: "red",
                            colorBlurred: "black",
                            fontSizeFocused: 12,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4}>
                        <FormText color="default" tag="span">
                          Dispensing Fee :
                        </FormText>
                        <Input
                          //returnKeyType={'next'}
                          fullWidth
                          bsSize="lg"
                          // placeholder={"fees"}
                          value={state.dispensing_fee}
                          onChange={(event) => {
                            setstate({
                              ...state,
                              dispensing_fee: event.target.value,
                            });
                          }}
                          customLabelStyles={{
                            colorFocused: "red",
                            colorBlurred: "black",
                            fontSizeFocused: 12,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4}>
                        <FormText color="default" tag="span">
                          Min Charge :
                        </FormText>
                        <Input
                          //returnKeyType={'next'}
                          fullWidth
                          bsSize="lg"
                          // placeholder={"fees"}
                          value={state.min_charge}
                          onChange={(event) => {
                            setstate({
                              ...state,
                              min_charge: event.target.value,
                            });
                          }}
                          customLabelStyles={{
                            colorFocused: "red",
                            colorBlurred: "black",
                            fontSizeFocused: 12,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4}>
                        <FormText color="default" tag="span">
                          Min Fare :
                        </FormText>
                        <Input
                          //returnKeyType={'next'}
                          fullWidth
                          bsSize="lg"
                          // placeholder={"fees"}
                          value={state.min_fare}
                          onChange={(event) => {
                            setstate({
                              ...state,
                              min_fare: event.target.value,
                            });
                          }}
                          customLabelStyles={{
                            colorFocused: "red",
                            colorBlurred: "black",
                            fontSizeFocused: 12,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <FormText color="default" tag="span">
                          Distance Rate (km/mile) :
                        </FormText>
                        <Input
                          //returnKeyType={'next'}
                          fullWidth
                          bsSize="lg"
                          // placeholder={"fees"}
                          value={state.rate_per_unit_distance}
                          onChange={(event) => {
                            setstate({
                              ...state,
                              rate_per_unit_distance: event.target.value,
                            });
                          }}
                          customLabelStyles={{
                            colorFocused: "red",
                            colorBlurred: "black",
                            fontSizeFocused: 12,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <FormText color="default" tag="span">
                          Rate (Per Hour) :
                        </FormText>
                        <Input
                          //returnKeyType={'next'}
                          fullWidth
                          bsSize="lg"
                          // placeholder={"fees"}
                          value={state.rate_per_hour}
                          onChange={(event) => {
                            setstate({
                              ...state,
                              rate_per_hour: event.target.value,
                            });
                          }}
                          customLabelStyles={{
                            colorFocused: "red",
                            colorBlurred: "black",
                            fontSizeFocused: 12,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </Collapse>
                <GridItem xs={12}>
                  <FormText color="default" tag="span">
                    Extra Information :
                  </FormText>
                  <Input
                    type="textarea"
                    // fullWidth
                    bsSize="lg"
                    value={state.extra_info}
                    onChange={(event) => {
                      setstate({
                        ...state,
                        extra_info: event.target.value,
                      });
                    }}
                    // customLabelStyles={{
                    //   colorFocused: "red",
                    //   colorBlurred: "black",
                    //   fontSizeFocused: 12,
                    // }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => onAdd()}>
              Add
            </Button>{" "}
            <Button color="primary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <MaterialTable
        title={"Practice Details"}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          sorting: true,
          actionsColumnIndex: -1,
        }}
        editable={{
          onRowAdd: (newData) =>
            features.AllowCriticalEditsAdmin
              ? new Promise((resolve) => {
                  setTimeout(() => {
                    newData["createdAt"] = new Date().toISOString();
                    dispatch(editPracticeType(newData, "Add"));
                    resolve();
                  }, 600);
                })
              : new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    alert(language.demo_mode);
                  }, 600);
                }),
          onRowUpdate: (newData, oldData) =>
            features.AllowCriticalEditsAdmin
              ? new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    dispatch(editPracticeType(newData, "Update"));
                  }, 600);
                })
              : new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    alert(language.demo_mode);
                  }, 600);
                }),
          onRowDelete: (oldData) =>
            features.AllowCriticalEditsAdmin
              ? new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    dispatch(editPracticeType(oldData, "Delete"));
                  }, 600);
                })
              : new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    alert(language.demo_mode);
                  }, 600);
                }),
        }}
      />
    </>
  );
}
