/*eslint-disable*/
import React from "react";
import { Input } from "reactstrap";
function Payfast(props) {
  const Booking = (props.location && props.location.state.Booking) || {};
  return (
    <>
      <form action="https://www.payfast.co.za/eng/process" method="post">
        <input type="hidden" name="merchant_id" value="13785014" />
        <input type="hidden" name="merchant_key" value="d8ryffhzh9t2s" />
        <input type="hidden" name="amount" value={Booking.estimate_bigGrand} />
        <input type="hidden" name="item_name" value="Test Product" />
        <input type="submit" />
      </form>
    </>
  );
}
export default Payfast;
