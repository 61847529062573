/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
// import MaterialTable from "material-table";
import CircularLoading from "../../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";

// import { features, dateStyle, language } from "config";
import { FirebaseContext } from "common";
// import { Book } from "@material-ui/icons";
import FlatList from "flatlist-react";
import { Button } from "@material-ui/core";
import {
  // Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Row,
  Col,
  Badge,
  Label,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { useNavigate } from "react-router-dom";

const PaClinicBookings = (props) => {
  const { api } = useContext(FirebaseContext);
  const { fetchMedicalHistory } = api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  // const [role, setRole] = useState(null);
  // const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [render, setRender] = useState(false);
 
  // const [openConfirm, setOpenConfirm] = useState(false);
  // const [selectedBooking, setSelectedBooking] = useState("");
  const navigate = useNavigate();
  const bookinglistdata = useSelector((state) => state.bookinglistdata);

  useEffect(() => {
    console.log("auth", auth.info.uid);
    console.log("booking", bookinglistdata.bookings);
    let arr = [];
    if (auth.info.profile.userType === "practiceadmin") {
      if (bookinglistdata.bookings) {
        bookinglistdata.bookings.map((booking) => {
          if (booking?.doctor?.uid !== undefined) {
            if (
              booking.doctor.practiceNumber ===
                auth.info.profile.practiceNumber &&
              booking.status === "ACCEPTED"
            ) {
              arr.push(booking);
            }
          }
          arr.sort((a,b) => new Date(a.eventDate) - new Date(b.eventDate));
          setData(arr);
        });
      } else {
        setData([]);
      }
    }
  }, [bookinglistdata.bookings]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      //assign interval to a variable to clear it.
      console.log('first', render)
      if (render){
        setRender(!render);
      } else {
        setRender(true)
      }
    }, 60000);
    return () => clearInterval(intervalId);
  }, [render]);

  const renderBooking = (Booking) => {
    let currentTime = new Date();
    let t20 = new Date(currentTime.setMinutes(currentTime.getMinutes()+20));
    let t40 = new Date(currentTime.setMinutes(currentTime.getMinutes()+20));
    let t60 = new Date(currentTime.setMinutes(currentTime.getMinutes()+60));
    currentTime = new Date("Sat Feb 10 2024 07:00:00 GMT+0200 (South Africa Standard Time)");
    let eventTime = new Date(Booking.eventDate);
     console.log("t20", t20);
     console.log("t40", t40);
     console.log("t60", t60);
    if (Booking.status === "ACCEPTED" || Booking.status === "STARTED") {
      if (eventTime >= currentTime || Booking.status === "STARTED") {
        return (
          <>
            <Col md="2">
              <Card
                style={{
                  border: "4px solid",
                  borderColor:
                    eventTime < t20
                      ? "lime"
                      : t20 < eventTime && eventTime < t40
                      ? "blue"
                      : t40 < eventTime && eventTime < t60
                      ? "Yellow"
                      : "black",
                }}
              >
                <GridContainer spacing={4}>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <CardHeader as="h6">
                      <CardTitle tag="h4">
                        <div style={{ marginLeft: "80%" }}>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-round btn-icon"
                              color="default"
                              outline
                            >
                              <i className="now-ui-icons loader_gear" />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem>Call Patient</DropdownItem>
                              <DropdownItem>Email Patient</DropdownItem>
                              <DropdownItem>Refer</DropdownItem>
                              <DropdownItem>Booking Procedure</DropdownItem>
                              <DropdownItem>Pre-approve Procedure</DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        {Booking.patientName}
                        {/* <div className="picture">
                      <img
                        alt="..."
                        className="picture-src"
                        src={Booking.patientImage}
                      />
                    </div>{" "} */}
                      </CardTitle>
                    </CardHeader>
                  </GridItem>
                  <CardBody>
                    {/* <Row> */}
                    <Col md="12">
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <FormGroup>
                          <span>Estimate Time: </span>
                          <p className="description">{Booking.eventDate}</p>
                        </FormGroup>
                      </GridItem>
                    </Col>
                    {/* <Col md="12">
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <FormGroup>
                      <span>Patient: </span>
                      <p className="description">{Booking.patientName}</p>
                    </FormGroup>
                  </GridItem>
                </Col> */}

                    <Col md="12">
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <FormGroup>
                          <span>Doctor: </span>
                          <p className="description">
                            DR. {Booking.doctor.firstName}{" "}
                            {Booking.doctor.lastnameName}
                          </p>
                        </FormGroup>
                      </GridItem>
                    </Col>
                    {/* </Row> */}
                  </CardBody>
                  <GridItem xs={2} sm={2} md={2} lg={2}>
                    {" "}
                    <CardFooter>
                      {/* {Booking.status === "ACCEPTED" ? (
                  <h6>
                    <Button
                      style={{
                        width: "150px",
                        height: "50px",
                        marginTop: 20,
                        border: "5px solid",
                        borderColor: "lime",
                        color: "#fff",
                      }}
                      color="#000"
                    >
                      ACTIVE
                    </Button>
                  </h6>
                ) : null}
                {Booking.status === "NEXT" ? (
                  <h6>
                    <Button
                      style={{
                        width: "150px",
                        height: "50px",
                        marginTop: 20,
                        border: "5px solid",
                        borderColor: "orange",
                      }}
                      color="#000"
                    >
                      NEXT
                    </Button>
                  </h6>
                ) : null} */}
                    </CardFooter>
                  </GridItem>
                </GridContainer>
              </Card>
            </Col>
          </>
        );
      } else {
        return <div></div>;
      }
    } else {
      return <div></div>;
    }
  };

  return data.loading ? (
    <CircularLoading />
  ) : (
    <>
      <hr />
      <Row>
        <Col md="12">
          {/* <Label tag="h4">{auth.info.profile.specialityType}</Label> */}
          <Row>
            <FlatList
              keyExtractor={(item, index) => {
                return index.toString();
              }}
              extraData = {render}
              list={data}
              renderItem={renderBooking}
              renderWhenEmpty={() => <div>There currently is no bookings!</div>}
            />
          </Row>

          {/* <hr /> */}
        </Col>
      </Row>
    </>
  );
};

export default PaClinicBookings;
