import {
  FETCH_BOOKING_OFFERS,
  FETCH_BOOKING_OFFERS_SUCCESS,
  FETCH_BOOKING_OFFERS_FAILED,
  EDIT_BOOKING_OFFERS,
  CANCEL_BOOKING_OFFER,
  NO_BOOKING_OFFERS,
  UPDATE_BOOKING
  } from "../store/types";
import { updateBooking } from "./bookinglistactions";
 
  
  export const fetchOffers = (bookingoffersdata) => (dispatch) => (firebase) => {
  
    const {
      offersRef
    } = firebase;
  
    dispatch({
      type: FETCH_BOOKING_OFFERS,
      payload:bookingoffersdata,
    });
    
    offersRef.on("value", snapshot => {
      const bookingoffersdata = snapshot.val().BookingOffers;
      if (bookingoffersdata) {
        dispatch({
          type:  FETCH_BOOKING_OFFERS_SUCCESS,
          payload: bookingoffersdata,
        });
        return bookingoffersdata;
      } else {
        dispatch({
          type:  NO_BOOKING_OFFERS,
          payload: "NO BOOKING OFFERS",
        });
      }
    },
    (errorObject) => {
      console.log("The read failed: " + errorObject.name);
    }
  );
 
};
  
  export const addOffers = (bookingoffersdata) => (dispatch) => (firebase) => {
 
   const {
     offersRef, 
     
      
    } = firebase;
   
   
    
      let inRichOffer = {
      
        practiceNumber:bookingoffersdata.practiceNumber? bookingoffersdata.practiceNumber : null,
        doctor:bookingoffersdata.doctor? bookingoffersdata.doctor : null,
        nurse: bookingoffersdata.nurse? bookingoffersdata.nurse : null,
        offered_consultation_fee: bookingoffersdata.estimate? bookingoffersdata.estimate : null,
        offered_treatment_fee: bookingoffersdata.estimate_treatment_fee? bookingoffersdata.estimate_treatment_fee : null,
        offered_total: bookingoffersdata.estimate_bigGrand? bookingoffersdata.estimate_bigGrand : null,
        time_start_offered: bookingoffersdata.adjustedtimeStart? bookingoffersdata.adjustedtimeStart : null,
        time_end_offered: bookingoffersdata.adjustedtimeEnd? bookingoffersdata.adjustedtimeEnd : null,
        markedDot: bookingoffersdata.markedDot? bookingoffersdata.markedDot : " ",
        offerstatus: bookingoffersdata.offerstatus? bookingoffersdata.offerstatus : " ",
    
      }
      console.log('bookingoffersdata', inRichOffer)
     
     offersRef(bookingoffersdata.id).push(inRichOffer);
     
    
      
     
   
    dispatch({
      type:  EDIT_BOOKING_OFFERS,
      payload: {bookingoffersdata }
    });
 
  }
    export const updateOffers = (bookingid, bookingoffersdata) => (dispatch) => (firebase) => {
 
      const {
        offersEditRef, 

        
       } = firebase;
       console.log('upadate offers', bookingoffersdata);
       if (bookingoffersdata.offerstatus ==="ACCEPTED") {
        offersEditRef(bookingid,bookingoffersdata.id).update(bookingoffersdata);
        dispatch(updateBooking(bookingoffersdata));
       }
         
         dispatch({
         type:  EDIT_BOOKING_OFFERS,
         payload: bookingoffersdata
       });
    
}



