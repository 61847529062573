import {
  CONFIRM_BOOKING,
  CONFIRM_BOOKING_SUCCESS,
  CONFIRM_BOOKING_FAILED,
  CLEAR_BOOKING,
} from "../store/types";

export const clearBooking = () => (dispatch) => (firebase) => {
  dispatch({
    type: CLEAR_BOOKING,
    payload: null,
  });
};

export const addBooking = (bookingData) => (dispatch) => (firebase) => {
  const { bookingRef, auth } = firebase;

  dispatch({
    type: CONFIRM_BOOKING,
    payload: bookingData,
  });
  let pickUp = "";
  let drop = "";
  console.log('I am the Booking Data', bookingData);
  if (
    bookingData.serviceType === "Home Consultation" ||
    bookingData.serviceType === "Medication Delivery"
  ) {
    pickUp = {
      lat: bookingData.pickup.coords.lat,
      lng: bookingData.pickup.coords.lng,
      add: bookingData.pickup.description,
    };
    drop = {
      lat: bookingData.drop.coords.lat,
      lng: bookingData.drop.coords.lng,
      add: bookingData.drop.description,
    };
  }
  var otp;
  if (bookingData.settings.otp_secure)
    otp = Math.floor(Math.random() * 90000) + 10000;
  else {
    otp = false;
  }
  let today = new Date().toString();
  console.log("i am the mark dot", bookingData.serviceType);
  var data = {
    title: bookingData.bookingName,
    patientuid: auth.currentUser.uid,
    patient: bookingData.userDetails.uid ? bookingData.userDetails.uid : " ",
    specialityType: bookingData.specialityType
      ? bookingData.specialityType
      : "",
    treatmentType: bookingData.treatmentType ? bookingData.treatmentType : "",
    clinicType: bookingData.clinicType ? bookingData.clinicType : "",
    patientName:
      bookingData.userDetails.profile.firstName +
      " " +
      bookingData.userDetails.profile.lastName,
    patientContact: bookingData.userDetails.profile.mobile
      ? bookingData.userDetails.profile.mobile
      : " ",
    // patientGender: bookingData.userDetails.profile.gender
    // ? bookingData.userDetails.profile.gender
    // : " ",
    // patientAge: bookingData.userDetails.profile.age
    // ? bookingData.userDetails.profile.age
    // : " ",
    patientToken: bookingData.userDetails.profile.pushToken
      ? bookingData.userDetails.profile.pushToken
      : " ",
    patientEmail: bookingData.userDetails.profile.email
      ? bookingData.userDetails.profile.email
      : " ",
    patientImage: bookingData.userDetails.profile.profile_image
      ? bookingData.userDetails.profile.profile_image
      : " ",
    bookingName: bookingData.bookingName,
    practiceType: bookingData.practiceDetails,
    serviceType: bookingData.serviceType,

    //bookingType: bookingData.bookingType,
    description: bookingData.description,
    endDate: bookingData.endDate,
    eventDate: bookingData.bookLater ? bookingData.eventdate : today,
    bookingDate: bookingData.bookLater ? bookingData.startDate : today,
    bookLater: bookingData.bookLater,
    drop:
      bookingData.serviceType === "Home Consultation" ||
      bookingData.serviceType === "Medication Delivery"
        ? drop
        : { lat: "0", lng: "0", add: "0" },
    pickup:
      bookingData.serviceType === "Home Consultation" ||
      bookingData.serviceType === "Medication Delivery"
        ? pickUp
        : { lat: "0", lng: "0", add: "0" },
    estimate: bookingData.estimate.estimateFare,
    estimate_treatment_fee: bookingData.estimate.treatment_fee,

    estimate_bigGrand: bookingData.estimate.bigGrand,
    estimateDistance: bookingData.estimate.estimateDistance,
    estimateTime: bookingData.estimate.estimateTime,
    status: bookingData.status,
    adjustedtimeStart: bookingData.adjustedtimeStart
      ? bookingData.adjustedtimeStart
      : null,
    adjustedtimeEnd: bookingData.adjustedtimeEnd
      ? bookingData.adjustedtimeEnd
      : null,
    accepted_consultation_fee: bookingData.accepted_consultation_fee
      ? bookingData.accepted_consultation_fee
      : null,
    accepted_treatment_fee: bookingData.accepted_treatment_fee
      ? bookingData.accepted_treatment_fee
      : null,
    accepted_total: bookingData.accepted_total
      ? bookingData.accepted_total
      : null,
    videoconferencelink:bookingData.videoconferencelink? bookingData.videoconferencelink:" ",
    refferal: bookingData.refferal ? bookingData.refferal : " ",
    prescription: bookingData.prescription ? bookingData.prescription : "",
    deviceExposure: bookingData.deviceExposure
      ? bookingData.deviceExposure
      : "",
    medicalNote: bookingData.medicalNote ? bookingData.medicalNote : "",
    procedures: bookingData.procedures ? bookingData.procedures : "",
    observations: bookingData.observations ? bookingData.observations : "",
    Medical_Information: bookingData.Medical_Information
      ? bookingData.Medical_Information
      : " ",
    markedDot: bookingData.markedDot ? bookingData.markedDot : " ",
    otp: bookingData.settings.otp_secure ? otp : " ",
    booking_type_web: bookingData.booking_type_web,
    doctor: bookingData.doctor ? bookingData.doctor : "",
    nurse: bookingData.nurse ? bookingData.nurse : "",
  };
  if (
    bookingData.serviceType === "Home Consultation" ||
    bookingData.serviceType === "Medication Delivery"
  ) {
    data = { ...data, coords: bookingData.estimate.waypoints };
  }

  bookingRef
    .push(data)
    .then((res) => {
      console.log(res);
      var bookingKey = res.key;
      dispatch({
        type: CONFIRM_BOOKING_SUCCESS,
        payload: {
          booking_id: bookingKey,
          mainData: data,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: CONFIRM_BOOKING_FAILED,
        payload: error.code + ": " + error.message,
      });
    });
};

export const addRefBooking = (bookingData) => (dispatch) => (firebase) => {
  const { bookingRef, auth } = firebase;

  dispatch({
    type: CONFIRM_BOOKING,
    payload: bookingData,
  });
  let pickUp = "";
  let drop = "";
  console.log("I am the Booking Data", bookingData);
  if (
    bookingData.serviceType === "Home Consultation" ||
    bookingData.serviceType === "Medication Delivery"
  ) {
    pickUp = {
      lat: bookingData.pickup.coords.lat,
      lng: bookingData.pickup.coords.lng,
      add: bookingData.pickup.description,
    };
    drop = {
      lat: bookingData.drop.coords.lat,
      lng: bookingData.drop.coords.lng,
      add: bookingData.drop.description,
    };
  }
  var otp;
  if (bookingData.settings.otp_secure)
    otp = Math.floor(Math.random() * 90000) + 10000;
  else {
    otp = false;
  }
  var data = bookingData;
  if (
    bookingData.serviceType === "Home Consultation" ||
    bookingData.serviceType === "Medication Delivery"
  ) {
    data = { ...data, coords: bookingData.estimate.waypoints };
  }

  bookingRef
    .push(data)
    .then((res) => {
      console.log(res);
      var bookingKey = res.key;
      dispatch({
        type: CONFIRM_BOOKING_SUCCESS,
        payload: {
          booking_id: bookingKey,
          mainData: data,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: CONFIRM_BOOKING_FAILED,
        payload: error.code + ": " + error.message,
      });
    });
};
