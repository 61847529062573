/*eslint-disable*/
import CircularLoading from "../../../components/CircularLoading";
import { Select } from "@material-ui/core";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  FormText,
  Row,
  Col,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "common";
import AlertDialog from "../../../components/AlertDialog";
import CustomInput from "components/CustomInput/CustomInput";
import MenuItem from "@material-ui/core/MenuItem";
function PatientFamilyMedicalHistory({ route, props }) {
  /////////////////////////////////////////////////////////
  //REdux
  const { api } = useContext(FirebaseContext);
  const { editMedicalHistory } = api;
  const dispatch = useDispatch();
  const medical = useSelector(
    (state) => state.medicalRecords.medicalHistoryRecords
  );
  const auth = useSelector((state) => state.auth);
  const [medicalData, setMedicalData] = useState(medical);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  ///////////////////////////////////////////////////////////////
  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setMedicalData(medical);
    }
  }, [medical]);

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  const OnSave = () => {
    let payload = {
      Medical_Information: medicalData,
    };
    console.log("this is imm payload:", payload);
    dispatch(editMedicalHistory(payload, "Add medical history"));
    setCommonAlert({ open: true, msg: "Your Family Medical History Has Been Saved" });
  };
  return auth.loading ? (
    <CircularLoading />
  ) : (
    <>
      <div className="content">
        <div className="medicalhistory-form">
          <Row>
            <Col md="12">
              <AlertDialog
                open={commonAlert.open}
                onClose={handleCommonAlertClose}
              >
                {commonAlert.msg}
              </AlertDialog>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4"> FAMILY MEDICAL HISTORY</CardTitle>
                </CardHeader>
                <CardBody>
                  <Col>
                    <FormGroup>
                      <FormText color="default" tag="span">
                        Please indicate if YOUR FAMILY has a history of the
                        following: (ONLY include parents, grandparents,
                        siblings, and children)
                      </FormText>
                    </FormGroup>
                  </Col>
                  <Row>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.Adopted
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    Adopted: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />I am adopted and
                          do not know biological family history
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.Family_History_unknow
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    Family_History_unknow: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Family History Unknown
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Alcohol_Abuse
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Alcohol_Abuse: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Alcohol Abuse
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.Fmedicalhistory?.F_Anemia
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Anemia: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Anemia
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Anesthetic_Complication
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Anesthetic_Complication:
                                      event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Anesthetic Complication
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Anxiety_Disorder
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Anxiety_Disorder: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Anxiety Disorder
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.Fmedicalhistory?.F_Arthritis
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Arthritis: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Arthritis
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.Fmedicalhistory?.F_Asthma
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Asthma: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Asthma
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Autoimmune_Problems
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Autoimmune_Problems: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Autoimmune Problems
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Birth_Defects
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Birth_Defects: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Birth Defects
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Bladder_Problems
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Bladder_Problems: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Bladder Problems
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Bleeding_Disease
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Bleeding_Disease: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Bleeding Disease
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Blood_Clots
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Blood_Clots: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Blood Clots
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Blood_Transfusions
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Blood_Transfusions: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Blood Transfusion
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Bowel_Disease
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Bowel_Disease: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Bowel Disease
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Breast_Cancer
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Breast_Cancer: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Breast Cancer
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Cervical_Cancer
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Cervical_Cancer: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Cervical Cancer
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Colon_Cancer
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Colon_Cancer: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Colon Cancer
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.Fmedicalhistory?.F_Depression
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Depression: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Depression
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.Fmedicalhistory?.F_Diabetes
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Diabetes: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Diabetes
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.Fmedicalhistory?.F_Migraines
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Migraines: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Migraines
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Osteoporosis
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Osteoporosis: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Osteoporosis
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Prostate_Cancer
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Prostate_Cancer: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Prostate Cancer
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Rectal_Cancer
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Rectal_Cancer: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Rectal Cancer
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Reflux_GERD
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Reflux_GERD: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Reflux/GERD
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Seizures_Convulsions
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Seizures_Convulsions:
                                      event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />{" "}
                          Seizures/Convulsions
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Severe_Allergy
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Severe_Allergy: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Severe Allergy
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory
                                ?.F_Sexually_Transmitted_Disease
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Sexually_Transmitted_Disease:
                                      event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Sexually
                          Transmitted Disease
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Skin_Cancer
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Skin_Cancer: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Skin Cancer
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Stroke_CVA_of_the_Brain
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Stroke_CVA_of_the_Brain:
                                      event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Stroke/CVA of the
                          Brain
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Suicide_Attempt
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Suicide_Attempt: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Suicide Attempt
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Thyroid_Problems
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Thyroid_Problems: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Thyroid Problems
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.Fmedicalhistory?.F_Ulcer
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Ulcer: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Ulcer
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_Visual_Impairment
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Visual_Impairment: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Visual Impairment
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory
                                ?.F_Other_Disease_Cancer_or_Significant_Medical_Illness
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_Other_Disease_Cancer_or_Significant_Medical_Illness:
                                      event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" /> Other Disease,
                          Cancer, or Significant Medical Illness
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.F_NONE_of_the_Above
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    F_NONE_of_the_Above: event.target.checked,
                                  },
                                },
                              });
                              setMedicalData({
                                F_Alcohol_Abuse: false,
                                F_Anemia: false,
                                F_Anesthetic_Complication: false,
                                F_Anxiety_Disorder: false,
                                F_Arthritis: false,
                                F_Asthma: false,
                                F_Autoimmune_Problems: false,
                                F_Birth_Defects: false,
                                F_Bladder_Problems: false,
                                F_Bleeding_Disease: false,
                                F_Blood_Clots: false,
                                F_Blood_Transfusions: false,
                                F_Bowel_Disease: false,
                                F_Breast_Cancer: false,
                                F_Cervical_Cancer: false,
                                F_Colon_Cancer: false,
                                F_Depression: false,
                                F_Diabetes: false,
                                F_GrowthDevelopment_Disorder: false,
                                F_Hearing_Impairment: false,
                                F_Heart_Attack: false,
                                F_Heart_Disease: false,
                                F_Heart_Pain_Angina: false,
                                F_Hepatitis_A: false,
                                F_Hepatitis_B: false,
                                F_Hepatitis_C: false,
                                F_High_Blood_Pressure: false,
                                F_High_Cholesterol: false,
                                F_HIV: false,
                                F_Hives: false,
                                F_Kidney_Disease: false,
                                F_Liver_Cancer: false,
                                F_Liver_Disease: false,
                                F_Lung_Cancer: false,
                                F_Lung_Respiratory_Disease: false,
                                F_Mental_Illness: false,
                                F_Migraines: false,
                                F_Osteoporosis: false,
                                F_Prostate_Cancer: false,
                                F_Rectal_Cancer: false,
                                F_Reflux_GERD: false,
                                F_Seizures_Convulsions: false,
                                F_Severe_Allergy: false,
                                F_Sexually_Transmitted_Disease: false,
                                F_Skin_Cancer: false,
                                F_Stroke_CVA_of_the_Brain: false,
                                F_Suicide_Attempt: false,
                                F_Thyroid_Problems: false,
                                F_Ulcer: false,
                                F_Visual_Impairment: false,
                                F_Other_Disease_Cancer_or_Significant_Medical_Illness: false,
                              });
                            }}
                          />
                          <span className="form-check-sign" /> NONE of the Above
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.Female_Family
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    Female_Family: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Mother, Grandmother, or Sister developed heart disease
                          before the age of 65
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              medicalData?.Family_Medical_History
                                ?.Fmedicalhistory?.Fmedicalhistory?.Male_Family
                            }
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,

                                Family_Medical_History: {
                                  Fmedicalhistory: {
                                    ...medicalData.Family_Medical_History
                                      .Fmedicalhistory,
                                    Male_Family: event.target.checked,
                                  },
                                },
                              });
                            }}
                          />
                          <span className="form-check-sign" />
                          Father, Grandfather, or Brother developed heart
                          disease before the age of 65
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Col>
                    <Button variant="gradient" color="primary" onClick={OnSave}>
                      UPDATE
                    </Button>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default PatientFamilyMedicalHistory;
