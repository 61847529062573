/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import CircularLoading from "../../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationDialogRaw from "../../components/ConfirmationDialogRaw";
import { features, dateStyle, language } from "config";
import { FirebaseContext } from "common";

const Appointments = () => {
  const { api } = useContext(FirebaseContext);
  const {
    getEstimate,
    clearEstimate,
    addBooking,
    clearBooking,
    MinutesPassed,
    GetDateString,
  } = api;
  const dispatch = useDispatch();
  //const classes = useStyles();
  const practicetypes = useSelector((state) => state.practicetypes.practices);
  const estimatedata = useSelector((state) => state.estimatedata);
  const bookingdata = useSelector((state) => state.bookingdata);
  const userdata = useSelector((state) => state.usersdata);
  const settings = useSelector((state) => state.settingsdata.settings);
  const [practiceType, setPracticeType] = useState(language.select_practice);
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropAddress, setDropAddress] = useState(null);
  const [estimateModalStatus, setEstimateModalStatus] = useState(false);
  const [selectedPracticeDetails, setSelectedPracticeDetails] = useState(null);
  const [users, setUsers] = useState(null);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [userCombo, setUserCombo] = useState(null);
  const [estimateRequested, setEstimateRequested] = useState(false);
  const [bookingType, setBookingType] = useState("Book Now");
  const [selectedDate, setSelectedDate] = useState(GetDateString());
  const { cancelBooking } = api;
  const auth = useSelector((state) => state.auth);
  const [role, setRole] = useState(null);

  const columns = [
    { title: language.booking_id, field: "id" },
    {
      title: language.booking_date,
      field: "eventdate",
      render: (rowData) =>
        rowData.eventdate
          ? new Date(rowData.eventdate).toLocaleString(dateStyle)
          : null,
    },
    { title: "Appointment Time" },
    { title: "Doctor Name" },
    { title: "Doctor ContactNumber" },

    {
      title: language.booking_status,
      field: "status",
      render: (rowData) => <span>{language[rowData.status]}</span>,
    },
    { title: language.cancellation_reason, field: "reason" },
    {
      title: language.otp,
      field: "otp",
      render: (rowData) =>
        rowData.status === "NEW" || rowData.status === "ACCEPTED" ? (
          <span>{rowData.otp}</span>
        ) : null,
    },

    { title: language.event_start_time, field: "event_start_time" },
    { title: language.event_end_time, field: "event_end_time" },
    { title: language.total_time, field: "total_event_time" },
    // { title: language.distance, field: "distance" },
    // { title: language.vehicle_no, field: "vehicle_number" },
    // { title: language.event_cost_driver_share, field: "driver_share" },
    // { title: language.convenience_fee, field: "convenience_fees" },
    // { title: language.discount_ammount, field: "discount" },
    //{ title: language.Customer_paid, field: "customer_paid" },
    { title: language.payment_mode, field: "payment_mode" },
    { title: language.payment_gateway, field: "gateway" },
    { title: language.cash_payment_amount, field: "cashPaymentAmount" },
    { title: language.card_payment_amount, field: "cardPaymentAmount" },
    { title: language.wallet_payment_amount, field: "usedWalletMoney" },
  ];
  const [data, setData] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState("");
  const bookinglistdata = useSelector((state) => state.bookinglistdata);

  useEffect(() => {
    if (bookinglistdata.bookings) {
      setData(bookinglistdata.bookings);
    } else {
      setData([]);
    }
  }, [bookinglistdata.bookings]);

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.userType);
    }
  }, [auth.info]);

  const onConfirmClose = (value) => {
    if (value) {
      dispatch(
        cancelBooking({
          reason: value,
          booking: selectedBooking,
        })
      );
    }
    setOpenConfirm(false);
  };

  return bookinglistdata.loading ? (
    <CircularLoading />
  ) : (
    <div>
      <MaterialTable
        title="My Appointments"
        columns={columns}
        data={data}
        options={{
          actionsColumnIndex: -1,
        }}
        actions={[
          (rowData) => ({
            icon: "cancel",
            tooltip: language.cancel_booking,
            disabled:
              rowData.status === "NEW" || rowData.status === "ACCEPTED"
                ? false
                : true,
            onClick: (event, rowData) => {
              if (
                features.AllowCriticalEditsAdmin &&
                (role === "patient" || role === "admin")
              ) {
                setSelectedBooking(rowData);
                setOpenConfirm(true);
              } else {
                alert(language.demo_mode);
              }
            },
          }),
        ]}
      />
      <ConfirmationDialogRaw
        open={openConfirm}
        onClose={onConfirmClose}
        value={""}
      />
    </div>
  );
};

export default Appointments;
