import {
  FETCH_REFERRALDR_TYPES,
  FETCH_REFERRALDR_TYPES_SUCCESS,
  FETCH_REFERRALDR_TYPES_FAILED,
  EDIT_REFERRALDR_TYPE,
  UPDATE_REFERRALS_TYPE,
  UPDATE_REFERRALS_TYPE_SUCCESS,
  UPDATE_REFERRALS_TYPE_FAILED,
  DELETE_REFERRALS_TYPE,
  DELETE_REFERRALS_TYPE_SUCCESS,
  DELETE_REFERRALS_TYPE_FAILED,
} from "../store/types";

const INITIAL_STATE = {
  referraldrs: null,
  loading: false,
  error: {
    flag: false,
    msg: null,
  },
};

export const referraldoctorsreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_REFERRALDR_TYPES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REFERRALDR_TYPES_SUCCESS:
      return {
        ...state,
        referraldrs: action.payload,
        loading: false,
      };
    case FETCH_REFERRALDR_TYPES_FAILED:
      return {
        ...state,
        referraldrs: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
      };
    case EDIT_REFERRALDR_TYPE:
      return {
        ...state,
        referraldrs: action.payload,
        loading: false,
      };
    case UPDATE_REFERRALS_TYPE:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_REFERRALS_TYPE_SUCCESS:
      return {
        ...state,
        referraldrs: action.payload,
        loading: false,
      };
    case UPDATE_REFERRALS_TYPE_FAILED:
      return {
        ...state,
        referraldrs: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
      };
    case DELETE_REFERRALS_TYPE:
      return {
        ...state,
        loading: true,
      };
    case DELETE_REFERRALS_TYPE_SUCCESS:
      return {
        ...state,
        referraldrs: action.payload,
        loading: false,
      };
    case DELETE_REFERRALS_TYPE_FAILED:
      return {
        ...state,
        referraldrs: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
      };
    default:
      return state;
  }
};
