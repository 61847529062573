/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
// import MaterialTable from "material-table";
import CircularLoading from "../../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import FlatList from "flatlist-react";
// import { features, dateStyle, language } from "config";
import { FirebaseContext } from "common";
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  FormGroup,
} from "reactstrap";
import GridItem from "components/Grid/GridItem";
const PatientTimeLine = () => {
  const { api } = useContext(FirebaseContext);
  const { fetchMedicalHistory } = api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  // const [role, setRole] = useState(null);
  // const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  // const [openConfirm, setOpenConfirm] = useState(false);
  // const [selectedBooking, setSelectedBooking] = useState("");

  const bookinglistdata = useSelector((state) => state.bookinglistdata);

  useEffect(() => {
    if (auth.info.profile.userType === "patient") {
      if (bookinglistdata.bookings) {
        setData(
          bookinglistdata.bookings.filter(
            (bookings) =>
              bookings.status === "ACCEPTED" && auth.info.uid === bookings?.id
          )
        );
      } else {
        setData([]);
      }
    } else if (auth.info.profile.userType === "patient") {
      if (bookinglistdata.bookings) {
        setData(
          bookinglistdata.bookings.filter(
            (bookings) =>
              bookings.status === "ACCEPTED" && auth.info.uid === bookings?.id
          )
        );
      } else {
        setData([]);
      }
    } else {
      setData([]);
    }
  }, [bookinglistdata.bookings]);

  const renderBooking = (Booking, index) => {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="6">
              <Card className="card-timeline card-plain">
                <CardBody>
                  <ul className="timeline timeline-simple">
                    <li className="timeline-inverted">
                      <div className="timeline-badge danger">
                        <i className="now-ui-icons objects_planet" />
                      </div>
                      <div className="timeline-panel">
                        <div
                          className="timeline-heading"
                          style={{ marginLeft: "90%" }}
                        >
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-round btn-icon"
                              color="default"
                              outline
                            >
                              <i className="now-ui-icons loader_gear" />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem>Request Certificate</DropdownItem>
                              <DropdownItem>Book Appointment</DropdownItem>
                              <DropdownItem>Call Clinic</DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <div className="timeline-body">
                          <Col md="12">
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                              <FormGroup>
                                <p className="h4">{Booking?.doctor?.desc}</p>
                              </FormGroup>
                            </GridItem>
                          </Col>
                          <Col md="12">
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                              <FormGroup>
                                <p className="h4">{Booking?.nurse?.desc}</p>
                              </FormGroup>
                            </GridItem>
                          </Col>
                          <Col md="12">
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                              <FormGroup>
                                <span>Estimate Time: </span>
                                <p className="description">
                                  {Booking.eventDate}
                                </p>
                              </FormGroup>
                            </GridItem>
                          </Col>
                          <Col md="12">
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                              <FormGroup>
                                <span>Estimate Time: </span>
                                <p className="description">
                                  {Booking?.specialityType}
                                </p>
                              </FormGroup>
                            </GridItem>
                          </Col>
                        </div>
                      </div>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  return data.loading ? (
    <CircularLoading />
  ) : (
    <Row>
      <Col md="12">
        <Card style={{ backgroundColor: "#4c4b4b" }}>
          <CardHeader>
            <CardTitle tag="h4">CONSULATIONS</CardTitle>
          </CardHeader>
          <CardBody>
            <ul>
              <Row>
                <FlatList
                  keyExtractor={(item, index) => {
                    return index.toString();
                  }}
                  list={data}
                  renderItem={renderBooking}
                  renderWhenEmpty={() => (
                    <div>There currently is no bookings!</div>
                  )}

                  //groupBy={(person) => (person.info.age > 18 ? "Over 18" : "Under 18")}
                />
              </Row>
            </ul>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
export default PatientTimeLine;
