import { MainConfig } from "./mainconfig";
import { features } from "./features";

import {
  language,
  dateStyle,
  clinicaldisease,
  icd10codes,
  treatment,
  otc,
  prescription,
  condition,
} from "./language";

import { countries, default_country_code } from "./countries";

const AppDetails = MainConfig.AppDetails;
const FirebaseConfig = MainConfig.FirebaseConfig;
const Google_Map_Key = MainConfig.Google_Map_Key;
const facebookAppId = MainConfig.facebookAppId;
const PurchaseDetails = MainConfig.PurchaseDetails;
const Google_Calendar_Key = MainConfig.Google_Calendar_Key;
const Calendar_Client_Id = MainConfig.Calendar_Client_Id;
const Calendar_Discovery_Docs = MainConfig.Calendar_Discovery_Docs;
const Calender_Scope = MainConfig.Calender_Scope;

const mainUrl = "cloudfunctions.net";
const cloud_function_server_url = `https://us-central1-${FirebaseConfig.projectId}.${mainUrl}`;
//const cloud_function_server_url = `http://127.0.0.1:5055/okdoc-ac3c0/us-central1`;
const meetinglinkurl =
  "https://dev.okdoc.co.za:9091/plugins/restapi/v1/chatrooms";

export {
  Google_Calendar_Key,
  Calendar_Client_Id,
  Calendar_Discovery_Docs,
  Calender_Scope,
  AppDetails,
  language,
  clinicaldisease,
  FirebaseConfig,
  Google_Map_Key,
  PurchaseDetails,
  dateStyle,
  facebookAppId,
  meetinglinkurl,
  cloud_function_server_url,
  features,
  countries,
  default_country_code,
  mainUrl,
  icd10codes,
  treatment,
  otc,
  prescription,
  condition,
};
