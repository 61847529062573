/*eslint-disable*/
/* eslint-disable quotes */
import React, { Component, useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { FirebaseContext } from "common";
// import { text } from "stream/consumers";

// class Wysiwig extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       editorState: EditorState.createEmpty(),
//     };
//   }

//   onEditorStateChange = (editorState) => {
//     this.setState({
//       editorState,
//     });
//   };

//   render() {
//     const { editorState } = this.state;

//     return (
//       <div>
//         <Editor
//           editorState={editorState}
//           wrapperClassName="rich-editor demo-wrapper"
//           editorClassName="demo-editor"
//           onEditorStateChange={this.onEditorStateChange}
//           placeholder="The message goes here..."
//         />

//         <div dangerouslySetInnerHTML={{ __html: getHtml(editorState) }}/>

//       </div>
//     );
//   }
// }
function Wysiwig(props) {
  const { api } = useContext(FirebaseContext);
  const {
    GetDateString,
    updateBooking,
    fetchChatMessages,
    createMeetingRoom,
    clearLoginError,
  } = api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const Booking = (props.location && props.location.state.Booking) || {};
  const [updatebooking, setupdatebooking] = useState(Booking);
  const [value, setValue] = useState("");
  // const getHtml = (value) =>
  // draftToHtml(convertToRaw(value.getCurrentContent()));
  const enterChange = (e) => {
    if (value !== " ") {
      let arr = [];
      for (let i = 0; i < e.blocks.length; i++) {
        arr = e.blocks[i].text;
      }
      // setValue(value);
      setValue({ arr });
      setupdatebooking({
        ...updatebooking,
        persciption: arr,
      });
      console.log("this is the value", arr);
    } else {
      console.log("this is the else");
    }
  };
  const onSave = () => {
    if (Booking.status === "ACCEPTED") {
      dispatch(updateBooking(updatebooking));
    } else {
      // setupdatebooking({ ...updatebooking, status: "PENDING" });
    }
  };
  return (
    <>
      {" "}
      <Editor theme="snow" value={value} onChange={enterChange} />
      <button onClick={onSave}>SAVE</button>
      {/* <div dangerouslySetInnerHTML={{ __html: getHtml(value) }}/> */}
    </>
  );
}
export { Wysiwig };
