/*eslint-disable*/
import React, { useState, useEffect, useContext, Alert } from "react";
import MaterialTable from "material-table";
import CircularLoading from "../../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationDialogRaw from "../../components/ConfirmationDialogRaw";
import { features, dateStyle, language } from "config";

import { FirebaseContext } from "common";

const FinancialReport = () => {
  const { api } = useContext(FirebaseContext);
  const { fetchBookings } = api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const usersdata = useSelector((state) => state.doctors);
  const [role, setRole] = useState(null);
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [Doctors, setDoctors] = useState([]);
  let [columns, setColumn] = useState([]);

  const bookinglistdata = useSelector(
    (state) => state.bookinglistdata.bookings
  );
  useEffect(() => {
    console.log("Booking data ", bookinglistdata);
    if (bookinglistdata !== null) {
      setData(bookinglistdata);
    } else if (bookinglistdata === null) {
      setData([]);
    }
  }, []);

  columns = [
    {
      title: "Invoice No",
    },
    {
      title: "Patient Name",
      field: "patientName",
      render: (rowData) => rowData.patientName,
    },
    {
      title: "Treatment Fee",
      field: "treatmentFee",
      render: (rowData) => rowData.estimate_treatment_fee,
    },

    {
      title: "Discount",
      field: "discount",
      render: (rowData) => rowData.discount,
    },

    {
      title: "Dispensing Fee",
      field: "dispensingFee",
      render: (rowData) => rowData.estimate_treatment_fee,
    },

    {
      title: "Consultation Fee",
      field: "consultationFee",
    },
    ///service fee, grand total, Fare cost

    {
      title: "Fare Cost",
      field: "fareCost",
    },

    {
      title: "Total",
      field: "grandTotal",
      render: (rowData) => rowData.estimate_bigGrand,
    },

    {
      title: "Paid On",
      field: "paidOn",
      render: (rowData) => rowData.bookingDate,
    },

    {
      title: "Payment Type",
      field: "paymentType",
      //render: (rowData) => rowData.bookingDate,
    },
    {
      title: "Payment Amount",
      field: "paymentAmount",
      // render: (rowData) => rowData.bookingDate,
    },

    {
      title: "Status",
      field: "status",
      // render: (rowData) => rowData.bookingDate,
    },

    { title: "View/Print" },
    //{ title: "Status", field: "status", render: rowData => rowData.status },
    // { title: 'Follow Up' },  this is optional
  ];

  useEffect(() => {
    let arr = [];
    usersdata.users.map((user) => {
      if (
        user.practiceNumber === auth.info.profile.practiceNumber &&
        user.userType === "doctor"
      ) {
        arr.push({
          firstName: user.firstName,
          lastName: user.lastName,
          mobile: user.mobile,
          email: user.email,
          uid: user.id,
          profile_image: user.profile_image,
          desc:
            user.firstName +
            " " +
            user.lastName +
            " (" +
            user.mobile +
            ") " +
            user.email,
          pushToken: user.pushToken,
        });
      }
    });
    setDoctors(arr);
  }, []);

  return (
    <div>
      <MaterialTable
        title="Financial Report"
        columns={columns}
        data={data}
        options={{
          actionsColumnIndex: -1,
        }}
      />
    </div>
  );
};

export default FinancialReport;
