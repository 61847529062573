/*eslint-disable*/
import React, { Fragment, useState, useCallback } from "react";
import MaterialTable, { MTableAction } from "material-table";
import AddIcon from "@material-ui/icons/AddAlarm";
import { Container, IconButton } from "@material-ui/core";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import DocumentScanner from "../../components/DocumentScanner";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function NursePrescriptionForm(props) {
  const tableRef = React.createRef();
  const addActionRef = React.useRef();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userSet, setUsers] = useState([]);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const TitleImg = (rowData) => {
    return (
      <div>
        <input type="file" />
      </div>
    );
  };

  const showImage = () => {};

  const tableColumns = [
    {
      title: "Patient ID",
      field: "patientId",
      validate: (rowData) => Boolean(rowData.patientId),
    },
    {
      title: "Patient Name",
      field: "patientName",
      validate: (rowData) => Boolean(rowData.patientName),
    },
    {
      title: "Prescription",
      field: "prescription",
      validate: (rowData) => Boolean(rowData.prescription),
    },

    {
      title: "Date",
      field: "date",
      validate: (rowData) => Boolean(rowData.date),
    },

    {
      title: "Upload Document",
      field: "uploadDocument",
      render: (rowData) => <TitleImg rowData={rowData} />,
    },
    {
      title: "Consent Form",
      field: "consentForm",
      render: (rowData) => <TitleImg rowData={rowData} />,
    },

    // {
    //   title: "Consent Form",
    //   field: "internal_action",
    //   editable: false,
    //   render: (rowData) =>
    //     rowData && (
    //       <IconButton
    //         color="secondary"
    //         onClick={() => addActionRef.current.click()}
    //       >
    //         <AddIcon />
    //       </IconButton>
    //     )
    // },
  ];

  const [tableData, setTableData] = useState([
    {
      patientId: "ytskckd343n",
      patientName: "Mthu",
      prescription: "Cough Syrup",

      date: "18-05-2022",
    },
    {
      patientId: "jfudgg4svd",
      patientName: "Tsepo",
      prescription: "Med Lemon",

      date: "18-05-2022",
    },
  ]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  // const handleBulkDelete = () => {
  //   const updatedData = tableData.filter(row => !selectedRows.includes(row))
  //   setTableData(updatedData)
  // }

  return (
    <Fragment>
      <MaterialTable
        tableRef={tableRef}
        columns={tableColumns}
        data={tableData}
        title="Prescription"
        options={{
          search: false,
          actionsColumnIndex: -1,
          toolbar: true,
        }}
        components={{
          Action: (props) => {
            console.log(props.action);
            if (
              typeof props.action === typeof Function ||
              props.action.tooltip !== "Add"
            ) {
              return <MTableAction {...props} />;
            } else {
              return <div ref={addActionRef} onClick={props.action.onClick} />;
            }
          },
        }}
        actions={[
          {
            icon: "add_box",
            tooltip: "Add Patient",
            isFreeAction: true,
            //   onClick: () => {
            //     console.log("clicked");

            //   },

            onClick: () => {
              addActionRef.current.click();
            },
          },
          {
            icon: "delete",
            tooltip: "Delete Patient",
            onClick: (event, rowData) => alert("You want to delete "),
          },
        ]}
        editable={{
          onRowAdd: (newData) =>
            Promise.resolve(setTableData([...tableData, newData])),
        }}
      />
    </Fragment>
  );
}
