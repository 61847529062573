import {
  FETCH_MEDICAL_HISTORY,
  FETCH_MEDICAL_HISTORY_SUCCESS,
  FETCH_MEDICAL_HISTORY_FAILURE,
  FETCH_MEDICAL_HISTORY_EDIT,
  NO_MEDICAL_HISTORY,
} from "../store/types";
const INITIAL_STATE = {
  medicalHistoryRecords: {
    Basic_info: {
      DOB: " ",
      Weight: " ",
      Height: " ",
      BMI: " ",
      Sex: " ",
      BloodType: " ",
    },
    Childhood_illness: {
      Measles: false,
      Mumps: false,
      None: false,
      All_the_Above: false,
      Polio: false,
      Rheumatic: false,
      Rubellac: false,
      Chickenpox: false,
    },
    Immunizations_an_Dates: {
      Tetanus: {
        checked: false,
        Date: " ",
      },
      Pneumonia: {
        checked: false,
        Date: " ",
      },
      Hepatitis_A: {
        checked: false,
        Date: " ",
      },
      Hepatitis_B: {
        checked: false,
        Date: " ",
      },
      Chickenpox2: {
        checked: false,
        Date: " ",
      },
      influenza: {
        checked: false,
        Date: " ",
      },
      MMR: {
        checked: false,
        Date: " ",
      },
      Meningococcal: {
        checked: false,
        Date: " ",
      },
      None2: {
        checked: false,
      },
    },
    Surgries: {
      Surgry1st: {
        YearOfSurgry: " ",
        ReasonOfSurgry: " ",
        HospitalOfSurgry: " ",
      },
      Surgry2nd: {
        YearOfSurgry: " ",
        ReasonOfSurgry: " ",
        HospitalOfSurgry: " ",
      },
      Surgry3rd: {
        YearOfSurgry: " ",
        ReasonOfSurgry: " ",
        HospitalOfSurgry: " ",
      },
      NoSurgeries: false,
    },
    Medical_History: {
      medicalhistory: {
        Alcohol_Abuse: false,
        Anemia: false,
        Anesthetic_Complication: false,
        Anxiety_Disorder: false,
        Arthritis: false,
        Asthma: false,
        Autoimmune_Problems: false,
        Birth_Defects: false,
        Bladder_Problems: false,
        Bleeding_Disease: false,
        Blood_Clots: false,
        Blood_Transfusions: false,
        Bowel_Disease: false,
        Breast_Cancer: false,
        Cervical_Cancer: false,
        Colon_Cancer: false,
        Depression: false,
        Diabetes: false,
        GrowthDevelopment_Disorder: false,
        Hearing_Impairment: false,
        Heart_Attack: false,
        Heart_Disease: false,
        Heart_Pain_Angina: false,
        Hepatitis_A: false,
        Hepatitis_B: false,
        Hepatitis_C: false,
        High_Blood_Pressure: false,
        High_Cholesterol: false,
        HIV: false,
        Hives: false,
        Kidney_Disease: false,
        Liver_Cancer: false,
        Liver_Disease: false,
        Lung_Cancer: false,
        Lung_Respiratory_Disease: false,
        Mental_Illness: false,
        Migraines: false,
        Osteoporosis: false,
        Prostate_Cancer: false,
        Rectal_Cancer: false,
        Reflux_GERD: false,
        Seizures_Convulsions: false,
        Severe_Allergy: false,
        Sexually_Transmitted_Disease: false,
        Skin_Cancer: false,
        Stroke_CVA_of_the_Brain: false,
        Suicide_Attempt: false,
        Thyroid_Problems: false,
        Ulcer: false,
        Visual_Impairment: false,
        Other_Disease_Cancer_or_Significant_Medical_Illness: false,
        NONE_of_the_Above: false,
      },
    },
    Medication: {
      Medication1: {
        Drug: " ",
        Dose: " ",
      },
      Medication2: {
        Drug: " ",
        Dose: " ",
      },
      Medication3: {
        Drug: " ",
        Dose: " ",
      },
      Medication4: {
        Drug: " ",
        Dose: " ",
      },
      Medication5: {
        Drug: " ",
        Dose: " ",
      },
      Medication6: {
        Drug: " ",
        Dose: " ",
      },
    },
    Family_Medical_History: {
      Fmedicalhistory: {
        Family_History_unknow: false,
        Adopted: false,
        Male_Family: false,
        Female_Family: false,
        F_Alcohol_Abuse: false,
        F_Anemia: false,
        F_Anesthetic_Complication: false,
        F_Anxiety_Disorder: false,
        F_Arthritis: false,
        F_Asthma: false,
        F_Autoimmune_Problems: false,
        F_Birth_Defects: false,
        F_Bladder_Problems: false,
        F_Bleeding_Disease: false,
        F_Blood_Clots: false,
        F_Blood_Transfusions: false,
        F_Bowel_Disease: false,
        F_Breast_Cancer: false,
        F_Cervical_Cancer: false,
        F_Colon_Cancer: false,
        F_Depression: false,
        F_Diabetes: false,
        F_GrowthDevelopment_Disorder: false,
        F_Hearing_Impairment: false,
        F_Heart_Attack: false,
        F_Heart_Disease: false,
        F_Heart_Pain_Angina: false,
        F_Hepatitis_A: false,
        F_Hepatitis_B: false,
        F_Hepatitis_C: false,
        F_High_Blood_Pressure: false,
        F_High_Cholesterol: false,
        F_HIV: false,
        F_Hives: false,
        F_Kidney_Disease: false,
        F_Liver_Cancer: false,
        F_Liver_Disease: false,
        F_Lung_Cancer: false,
        F_Lung_Respiratory_Disease: false,
        F_Mental_Illness: false,
        F_Migraines: false,
        F_Osteoporosis: false,
        F_Prostate_Cancer: false,
        F_Rectal_Cancer: false,
        F_Reflux_GERD: false,
        F_Seizures_Convulsions: false,
        F_Severe_Allergy: false,
        F_Sexually_Transmitted_Disease: false,
        F_Skin_Cancer: false,
        F_Stroke_CVA_of_the_Brain: false,
        F_Suicide_Attempt: false,
        F_Thyroid_Problems: false,
        F_Ulcer: false,
        F_Visual_Impairment: false,
        F_Other_Disease_Cancer_or_Significant_Medical_Illness: false,
        F_NONE_of_the_Above: false,
      },
    },
    LifeStyle_History: {
      SocialHistory: {
        occupation: " ",
        maritalstatus: " ",
        exercise: " ",
        alcoholconsumption: " ",
        smoking: " ",
        sexualorientation: " ",
        diet: " ",
        children: " ",
        substanceabuse: " ",
        stressfactor: " ",
        language: " ",
      },
    },
    Clinical_Diseases_Check:{
    Arteriosclerosis: false,
    Bipolar_disorder: false,
    Cancer: false,
    Cervical_Kyphosis: false,
    Chronic_fatigue_syndrome: false,
    Chronic_kidney_disease: false,
    Chronic_obstructive_pulmonary_disease: false,
    Crohns_disease: true,
    Cystic_fibrosis: false,
    Dementia_and_Alzheimer_disease: false,
    Diabetes: false,
    Down_syndrome: false,
    Endometriosis: false,
    Epilepsy: false,
    Fibromyalgia: false,
    HIV_AIDS: false,
    earing_impairments: false,
    Heart_disease: false,
    Hemophilia: false,
    Hepatitis_B_and_C: false,
    Irritable_bowel_syndrome: false,
    Kidney_Failure: false,
    Major_depressive_disorder: false,
    Migraine: false,
    Multiple_sclerosis: true,
    Muscular_dystrophy: false,
    Narcolepsy: false,
    Obsessive_compulsive_disorder: false,
    Osteoporosis: true,
    Parkinsons_disease: false,
    Post_traumatic_stress_disorder: false,
    Psoriasis: false,
    Rheumatoid_arthritis: false,
    Schizophrenia: false,
    Sickle_cell_disease: false,
    Speech_Disorder: false,
    Spinal_cord_injuries: false,
    Spine_Fusion: false,
    Stroke: false,
    Systemic_lupus_erythematosus: false,
    Thrombotic_thrombocytopenic_Purpura: false,
    Traumatic_brain_injury: false,
    Ulcerative_colitis: false,
    Visual_impairments: false
    },
    Clinical_Diseases: {
      Multiplesclerosis: {
        Magneticresonanceimagingscan: false,
        Evokedpotentialtests: false,
        Lumbarpuncturespinaltap: false,
        Bloodtests: false,
        Neurologicalexamination: false,
      },
      Parkinsonsdisease: {
        Medicalhistoryandneurologicalexamination: false,
        DaTscan: false,
        Bloodtests: false,
        Magneticresonanceimaging: false,
        Computerizedtomographyscan: false,
        Positronemissiontomographyscan: false,
      },
      Epilepsy: {
        Electroencephalogram: false,
        Magneticresonanceimaging: false,
        Computerizedtomographyscan: false,
        Neuropsychologicaltest: false,
        VideoEEGmonitoring: false,
        Bloodtests: false,
      },
      Chronicfatiguesyndrome: {
        Medicalhistoryandphysicalexamination: false,
        Symptomquestionnaires: false,
        Bloodtests: false,
        Exercisetolerancetesting: false,
        Sleepstudies: false,
        Psychologicalevaluation: false,
      },
      Fibromyalgia: {
        Medicalhistoryandphysicalexamination: false,
        Painassessment: false,
        Bloodtests: false,
        Tenderpointexam: false,
        Sleepstudies: false,
        Psychologicalevaluation: false,
      },
      Musculardystrophy: {
        Medicalhistoryandphysicalexamination: false,
        Bloodtests: false,
        Electromyography: false,
        Musclebiopsy: false,
        Genetictesting: false,
      },
      Systemiclupuserythematosus: {
        Medicalhistoryandphysicalexamination: false,
        Bloodtests: false,
        Urinetests: false,
        Skinbiopsy: false,
        Imagingtests: false,
      },
      Rheumatoidarthritis: {
        Medicalhistoryandphysicalexamination: false,
        Bloodtests: false,
        Jointimaging: false,
        Jointfluidanalysis: false,
      },
      Crohnsdisease: {
        Medicalhistoryandphysicalexamination: false,
        Bloodtests: false,
        Stooltests: false,
        Imagingtests: false,
        Endoscopyandbiopsy: false,
      },
      HIVAIDS: {
        HIVantibodytest: false,
        HIVantigentest: false,
        HIVRNAtest: false,
        CD4Tcellcount: false,
      },
      Diabetes: {
        Fastingplasmaglucosetest: false,
        Oralglucosetolerancetest: false,
        HemoglobinA1ctest: false,
        Randomplasmaglucosetest: false,
      },
      Chronickidneydisease: {
        Bloodtests: false,
        Urinetests: false,
        Imagingtests: false,
        Kidneybiopsy: false,
      },
      Cysticfibrosis: {
        Newbornscreening: false,
        Sweattest: false,
        Genetictesting: false,
        Lungfunctiontests: false,
      },
      Chronicobstructivepulmonarydisease: {
        Spirometry: false,
        ChestXrayorCTscan: false,
        Arterialbloodgastest: false,
        Pulseoximetry: false,
        Alpha1antitrypsindeficiencytest: false,
      },
      Cancer: {
        Biopsy: false,
        Imagingtests: false,
        Bloodtests: false,
        Endoscopy: false,
        Moleculartesting: false,
      },
      HepatitisBandC: {
        Bloodtests: false,
        Liverfunctiontests: false,
        Ultrasound: false,
        Liverbiopsy: false,
      },
      Heartdisease: {
        Electrocardiogram: false,
        Echocardiogram: false,
        Stresstest: false,
        Cardiaccatheterization: false,
        Bloodtests: false,
      },
      Stroke: {
        Magneticresonanceimaging: false,
        Computerizedtomographyscan: false,
        Carotidultrasound: false,
        Bloodtests: false,
        Electrocardiogramandechocardiogram: false,
      },
      Schizophrenia: {
        Psychiatricevaluation: false,
        Physicalexam: false,
        Psychologicaltesting: false,
        Magneticresonanceimaging: false,
        Computerizedtomographyscan: false,
        Bloodtests: false,
        Substanceuseassessment: false,
      },
      Bipolardisorder: {
        Psychiatricevaluation: false,
        Moodcharting: false,
        Psychologicaltesting: false,
        Magneticresonanceimaging: false,
        Computerizedtomographyscan: false,
        Bloodtests: false,
      },
      Majordepressivedisorder: {
        Psychiatricevaluation: false,
        Physicalexam: false,
        Psychologicaltesting: false,
        Bloodtests: false,
      },
      Posttraumaticstressdisorder: {
        Psychiatricevaluation: false,
        Physicalexam: false,
        Psychologicaltesting: false,
        Diagnosticcriteria: false,
        PTSDSymptomScale: false,
      },
      Obsessivecompulsivedisorder: {
        Psychiatricevaluation: false,
        Physicalexam: false,
        Psychologicaltesting: false,
        Diagnosticcriteria: false,
        YaleBrownObsessiveCompulsiveScale: false,
      },
      Autismspectrumdisorder: {
        Developmentalscreening: false,
        Diagnosticcriteria: false,
        Behaviouralobservation: false,
        AutismDiagnosticObservationSchedule: false,
        Parentalorcaregiverinterview: false,
      },
      Downsyndrome: {
        Prenataltesting: false,
        Physicalexam: false,
        Genetictesting: false,
      },
      Traumaticbraininjury: {
        Physicalexam: false,
        Imagingtests: false,
        GlasgowComaScale: false,
        Cognitivetesting: false,
        Neuropsychologicalevaluation: false,
      },
      Spinalcordinjuries: {
        Physicalexam: false,
        Imagingtests: false,
        Electromyography: false,
        SomatosensoryEvokedPotentials: false,
        MagneticResonanceImagingwithcontrast: false,
      },
      Visualimpairments: {
        Visualacuitytest: false,
        Refractiontest: false,
        Eyemovementtest: false,
        Visualfieldtest: false,
        Retinalexam: false,
        OpticalcoherencetomographyElectroretinogram: false,
      },
      Hearingimpairments: {
        Puretoneaudiometry: false,
        Speechaudiometry: false,
        Tympanometry: false,
        Auditorybrainstemresponsetesting: false,
        Otoacousticemissionstesting: false,
        Vestibulartesting: false,
      },
      DementiaandAlzheimersdisease: {
        Cognitivetesting: false,
        Brainimaging: false,
        Bloodtests: false,
        Spinaltap: false,
        Genetictesting: false,
      },
      Sicklecelldisease: {
        Haemoglobinelectrophoresis: false,
        Haemoglobinsolubilitytest: false,
        Completebloodcount: false,
        Hematocrit: false,
        Genetictesting: false,
      },
      Hemophilia: {
        Activatedpartialthromboplastintime: false,
        FactorVIIIorIXactivityassay: false,
        Genetictesting: false,
        Bleedingtimetest: false,
        vonWillebrandfactorantigenandactivityassay: false,
      },
      Arteriosclerosis: {
        BloodtestsElectrocardiogram: false,
        Echocardiogram: false,
        Carotidultrasound: false,
        Angiography: false,
      },
      Psoriasis: {
        Skinbiopsy: false,
        Physicalexamination: false,
        Medicalhistory: false,
        Bloodtests: false,
      },
      Endometriosis: {
        Pelvicexam: false,
        Imagingtests: false,
        Laparoscopy: false,
        Bloodtests: false,
      },
      Irritablebowelsyndrome: {
        Reviewyourmedicalhistoryandsymptoms: false,
        Physicalexam: false,
        Bloodtests: false,
        Stooltests: false,
        Colonoscopy: false,
      },
      Ulcerativecolitis: {
        Colonoscopy: false,
        Imagingtests: false,
        Bloodtests: false,
        Stooltests: false,
      },
      Narcolepsy: {
        Polysomnography: false,
        Multiplesleeplatencytest: false,
        HLAhumanleukocyteantigentyping: false,
        Orexintest: false,
      },
      Migraine: {
        Magneticresonanceimaging: false,
        Computerizedtomographyscan: false,
      },
      Osteoporosis: {
        Bonemineraldensitytest: false,
        Bloodtests: false,
      },
      SpineFusion: {
        Imagingtests: false,
        Physicalexamination: false,
        Nerveconductionstudies: false,
        Bonedensitytest: false,
      },
      CervicalKyphosis: {
        Medicalhistoryandphysicalexamination: false,
        Imagingtests: false,
        Neurologicalexam: false,
        Magneticresonanceimaging: false,
        Computerizedtomographyscan: false,
        Xrays: false,
      },
      SpeechDisorder: {
        Speechevaluation: false,
        Articulationtest: false,
        Languageassessment: false,
        Voiceevaluation: false,
        Fluencyassessment: false,
        Hearingevaluation: false,
        Orofacialexamination: false,
        Neurologicalevaluation: false,
      },
      KidneyFailure: {
        Bloodtests: false,
        Urinetests: false,
        Imagingtests: false,
        Kidneybiopsy: false,
        Glomerularfiltrationrate: false,
      },
      ThromboticthrombocytopenicPurpura: {
        Bloodtests: false,
        Coagulationtests: false,
        ADAMTS13activitytest: false,
        ADAMTS13inhibitortest: false,
        Urinetests: false,
        Imagingtests: false,
      },
    },
  },
  loading: false,
  error: {
    flag: false,
    msg: null,
  },
};
export const medicalHistoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_MEDICAL_HISTORY:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MEDICAL_HISTORY_SUCCESS:
      console.log("I am the state", state);
      return {
        ...state,
        medicalHistoryRecords: action.payload,
        loading: false,
      };
    case NO_MEDICAL_HISTORY:
      return {
        ...state,
      };
    case FETCH_MEDICAL_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
      };
    case FETCH_MEDICAL_HISTORY_EDIT:
      return {
        ...state,
        medicalHistoryRecords: {
          ...state.medicalHistoryRecords,
          medicalHistoryRecords: action.payload,
        },
        loading: false,
      };
    default:
      return state;
  }
};
