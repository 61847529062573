const templateLib = require("./template");

const PAYFAST_MERCHANT_ID = "13785014";
const PAYFAST_MERCHANT_KEY = "d8ryffhzh9t2s";
const PAYFAST_CHECKOUT_URL = "https://www.payfast.co.za/eng/process";

module.exports.render_checkout = function (request, response) {
  var order_id = request.body.order_id;
  var amount = request.body.amount;
  var description = request.body.description;
  var customStr1 = request.body.custom_str1;
  var customStr2 = request.body.custom_str2;

  var full_url = request.protocol + "://" + request.get("host");
  var success_url = full_url + "/success";
  var cancel_url = full_url + "/cancel";

  response.send(
    templateLib.getTemplate(
      PAYFAST_CHECKOUT_URL,
      PAYFAST_MERCHANT_ID,
      PAYFAST_MERCHANT_KEY,
      order_id,
      amount,
      success_url,
      cancel_url,
      description,
      customStr1,
      customStr2
    )
  );
};
