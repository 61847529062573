/*eslint-disable*/
import React, { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "common";
import { features, language } from "config";
import { useDispatch, useSelector } from "react-redux";
import CircularLoading from "../../components/CircularLoading";
import MaterialTable from "material-table";

export default function ReferralDoctors() {
  const { api } = useContext(FirebaseContext);
  const {
    updateRefDoctor,
    deleteRefDoctor,
    checkUserExists,
    editReferralDrType
  } = api;
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [practices, setPractices] = useState({});
  const usersdata = useSelector((state) => state.doctors);
  const referraldoctors = useSelector(
    (state) => state.referraldoctors.referraldrs
  );

  const doctortypes = useSelector((state) => state.doctortypes.doctortypes);
  const specialities = useSelector(
    (state) => state.specialitytypes.specialities
  );
  const [specialityTypes, setSpecialityTypes] = useState(null);
  const [doctorTypes, setDoctorTypes] = useState(null);
  const [state, setState] = useState({
    userType: "nurse",
    firstName: "",
    lastName: "",
    referralId: "",
    practiceNumber: "",
    practiceName: "",
    practiceLicenseNumber: "",
    specialityType: "Default",
    bankAccount: "",
    bankCode: "",
    bankName: "",
    bankType: "",
    licenseImage: null,
    doctorImage: null,
    nurseImage: null,
    other_info: "",
    password: "",
    email: ""
  });
  // refrral doctors to return
  useEffect(() => {
    if (referraldoctors) {
      let ref = referraldoctors.filter((doctor) => {
        if (doctor.practiceNumber === auth.info.profile.practiceNumber) {
          return doctor;
        }
      });

      setData(ref);
    } else {
      if (referraldoctors !== null) {
        setData(referraldoctors);
      }
    }
  }, [referraldoctors]);

  useEffect(() => {
    if (specialities) {
      let obj = {};
      for (let i = 0; i < specialities.length; i++) {
        specialities.map((speciality) => (obj[speciality] = speciality));
      }
      setSpecialityTypes(obj);
    }
    if (doctortypes) {
      let arr = {};
      for (let i = 0; i < doctortypes.length; i++) {
        doctortypes.map((doctypes) => (arr[doctypes] = doctypes));
      }
      setDoctorTypes(arr);
    }
  }, []);

  const columns = [
    { title: language.first_name, field: "firstName", initialEditValue: "" },
    { title: language.last_name, field: "lastName", initialEditValue: "" },
    {
      title: language.email,
      field: "email",
      editable: "onAdd",
      render: (rowData) =>
        features.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo"
    },
    {
      title: language.mobile,
      field: "mobile",
      editable: "onAdd",
      render: (rowData) =>
        features.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo"
    },
    {
      title: "Practice Name",
      field: "practiceName",
      initialEditValue: "",
      editable: "never",
      render: (rowData) =>
        auth.info.profile.practiceName
          ? rowData.practiceName
          : auth.info.profile.practiceName
    },
    {
      title: "Practice Number",
      field: "refferalPracticeNumber",
      initialEditValue: ""
    },
    { title: "DoctorType", field: "doctorTypes", lookup: doctorTypes },
    { title: "Speciality", field: "specialityTypes", lookup: specialityTypes },
    { title: language.other_info, field: "other_info", initialEditValue: "" },
    {
      title: language.referralId,
      field: "referralId",
      editable: "never",
      initialEditValue: ""
    }
  ];

  return usersdata.loading ? (
    <CircularLoading />
  ) : (
    <MaterialTable
      title={"Referral Doctors"}
      columns={columns}
      data={data}
      options={{
        exportButton: true,
        sorting: true,
        actionsColumnIndex: -1
      }}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              checkUserExists(newData).then((res) => {
                if (res.users && res.users.length > 0) {
                  alert(language.user_exists);
                  reject();
                } else if (res.error) {
                  alert(language.email_or_mobile_issue);
                  reject();
                } else {
                  newData["createdByAdmin"] = true;

                  newData["createdAt"] = new Date().toISOString();
                  newData["referralId"] =
                    newData.firstName.toLowerCase() +
                    Math.floor(1000 + Math.random() * 9000).toString();
                  setState({
                    ...state,
                    newData
                  });
                  let role = auth.info.profile.userType;
                  if (role === "practiceadmin") {
                    newData["practiceadmin"] = auth.info.uid;
                    newData["practiceNumber"] =
                      auth.info.profile.practiceNumber;
                  }
                  if (role === "nurse") {
                    newData["nurse"] = auth.info.uid;
                    newData["practiceNumber"] =
                      auth.info.profile.practiceNumber;
                  }
                  if (role === "doctor") {
                    newData["doctor"] = auth.info.uid;
                    newData["practiceNumber"] =
                      auth.info.profile.practiceNumber;
                  }
                  dispatch(editReferralDrType(newData, "Add"));
                  console.log("newData", newData);
                  resolve();
                }
              });
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              dispatch(updateRefDoctor(oldData.id, newData));
            }, 600);
          }),
        onRowDelete: (oldData) =>
          features.AllowCriticalEditsAdmin
            ? new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  dispatch(deleteRefDoctor(oldData.id));
                }, 600);
              })
            : new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  alert(language.demo_mode);
                }, 600);
              })
      }}
    />
  );
}
