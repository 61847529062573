import {
  FETCH_DOCTORS_EARNING,
  FETCH_DOCTORS__EARNING_SUCCESS,
  FETCH_DOCTORS__EARNING_FAILED,
} from "../store/types";

export const fetchDoctorEarnings = (uid,role) => (dispatch) => (firebase) => {

  const {
    bookingListRef
  } = firebase;

  dispatch({
    type: FETCH_DOCTORS_EARNING,
    payload: null
  });
  bookingListRef(uid,role).on("value", snapshot => {
    if (snapshot.val()) {
      const mainArr = snapshot.val();
      var monthsName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      var renderobj = {};
      Object.keys(mainArr).map(j => {
        if ((mainArr[j].status === 'PAID' || mainArr[j].status === 'COMPLETE') && mainArr[j].doctor_share !== undefined) {
          let bdt = new Date(mainArr[j].eventdate);
          let uniqueKey = bdt.getFullYear() + '_' + bdt.getMonth() + '_' + mainArr[j].doctor;
          if (renderobj[uniqueKey] && renderobj[uniqueKey].doctorShare > 0) {
            renderobj[uniqueKey].doctorShare = parseFloat(renderobj[uniqueKey].doctorShare) + mainArr[j].doctor_share;
          } else {
            renderobj[uniqueKey] = {};
            renderobj[uniqueKey]['dated'] = mainArr[j].eventdate;
            renderobj[uniqueKey]['year'] = bdt.getFullYear();
            renderobj[uniqueKey]['month'] = bdt.getMonth();
            renderobj[uniqueKey]['monthsName'] = monthsName[bdt.getMonth()];
            renderobj[uniqueKey]['doctorName'] = mainArr[j].doctor_name;
            renderobj[uniqueKey]['doctorShare'] = parseFloat(mainArr[j].doctor_share);
            renderobj[uniqueKey]['doctorVehicleNo'] = mainArr[j].vehicle_number;
            renderobj[uniqueKey]['doctorUId'] = mainArr[j].doctor;
            renderobj[uniqueKey]['uniqueKey'] = uniqueKey;
          }
        }
        return null;
      });
      if (renderobj) {
        const arr = Object.keys(renderobj).map(i => {
          renderobj[i].doctorShare = parseFloat(renderobj[i].doctorShare).toFixed(2)
          return renderobj[i]
        })
        dispatch({
          type: FETCH_DOCTORS__EARNING_SUCCESS,
          payload: arr
        });
      }

    } else {
      dispatch({
        type: FETCH_DOCTORS__EARNING_FAILED,
        payload: "No data available."
      });
    }
  });
};

