/* eslint-disable no-unused-vars */
import {
  FETCH_ESTIMATE,
  FETCH_ESTIMATE_SUCCESS,
  FETCH_ESTIMATE_FAILED,
  CLEAR_ESTIMATE,
} from "../store/types";
import Polyline from "@mapbox/polyline";

//import { FareCalculator } from "../other/FareCalculator";
import { getRouteDetails } from "../other/GoogleAPIFunctions";
import { store } from "../store/store";

export const getEstimate = (bookingData) => (dispatch) => (firebase) => {
  dispatch({
    type: FETCH_ESTIMATE,
    payload: bookingData,
  });

  console.log("testing bookingData", bookingData);
  if (
    bookingData.serviceType === "Home Consultation" ||
    bookingData.serviceType === "Medication Delivery"
  ) {
    let startLoc =
      bookingData.pickup.coords.lat + "," + bookingData.pickup.coords.lng;

    let destLoc =
      bookingData.drop.coords.lat + "," + bookingData.drop.coords.lng;

    let settings = store.getState().settingsdata.settings;
    console.log("startLoc on estimate", startLoc);
    console.log("destLoc on estimate", destLoc);

    getRouteDetails(startLoc, destLoc).then((res) => {
      console.log("res", res);
      if (res) {
        let points = Polyline.decode(res.polylinePoints);
        let waypoints = points.map((point) => {
          return {
            latitude: point[0],
            longitude: point[1],
          };
        });

        let distance = settings.convert_to_mile
          ? parseFloat(res.distance / 1.609344)
          : parseFloat(res.distance);

        let fareCalculation = FareCalculator(
          distance,
          parseFloat(res.duration),
          bookingData.practiceDetails
        );
        console.log("distance", distance);
        console.log("estimate calculation to check", fareCalculation);

        dispatch({
          type: FETCH_ESTIMATE_SUCCESS,
          payload: {
            pickup: bookingData.pickup ? bookingData.pickup : " ",
            drop: bookingData.drop ? bookingData.drop : " ",
            bookLater: bookingData.bookLater,
            bookingDate: bookingData.bookingDate,

            practiceDetails: bookingData.practiceDetails,
            estimateDistance: parseFloat(distance.toFixed(2)),
            fareCost: fareCalculation
              ? parseFloat(fareCalculation.totalCost.toFixed(2))
              : 0,
            estimateFare: fareCalculation
              ? parseFloat(fareCalculation.grandTotal.toFixed(2))
              : 0,
            estimateTime: res.duration,
            dispensing_fees:
              bookingData.practiceDetails.length > 0
                ? bookingData.practiceDetails.dispensing_fee
                : 0,
            consultation_fee: bookingData.practiceDetails.base_rate,

            treatment_fee: fareCalculation
              ? parseFloat(bookingData.practiceDetails.dispensing_fee) +
                parseFloat(bookingData.practiceDetails.base_rate)
              : 0,
            bigGrand: fareCalculation
              ? parseFloat(fareCalculation.grandTotal.toFixed(2)) +
                parseFloat(bookingData.practiceDetails.dispensing_fee) +
                parseFloat(bookingData.practiceDetails.base_rate)
              : 0,
            waypoints: waypoints,
          },
        });
      } else {
        dispatch({
          type: FETCH_ESTIMATE_FAILED,
          payload: "No Route ",
        });
      }
    });
  } else {
    if (
      bookingData.serviceType === "Video Conference" ||
      bookingData.serviceType === "Surgery Consultation" ||
      bookingData.serviceType === "Antenatal Care Assessment A" ||
      bookingData.serviceType === "Antenatal Care Assessment B" ||
      bookingData.serviceType === "Antenatal Care Assessment C" ||
      bookingData.serviceType === "Home Care"
    ) {
      if (
        bookingData.practiceDetails !== null ||
        bookingData.practiceDetails !== undefined
      ) {
        dispatch({
          type: FETCH_ESTIMATE_SUCCESS,
          payload: {
            pickup: bookingData.pickup ? bookingData.pickup : {},
            drop: bookingData.drop ? bookingData.drop : {},
            bookLater: bookingData.bookLater,

            bookingDate: bookingData.bookingDate,
            practiceDetails: bookingData.practiceDetails,
            estimateDistance: 0,
            fareCost: 0,
            estimateFare: 0,
            estimateTime: 30,

            dispensing_fees: bookingData.practiceDetails.dispensing_fee,
            consultation_fee: bookingData.practiceDetails.base_rate,
            treatment_fee: bookingData.practiceDetails
              ? parseFloat(bookingData.practiceDetails.dispensing_fee) +
                parseFloat(bookingData.practiceDetails.base_rate)
              : 0,
            waypoints: {},
            bigGrand: bookingData.practiceDetails
              ? parseFloat(bookingData.practiceDetails.dispensing_fee) +
                parseFloat(bookingData.practiceDetails.base_rate)
              : 0,
          },
        });
      } else {
        dispatch({
          type: FETCH_ESTIMATE_FAILED,
          payload: "Error reading practice costs",
        });
      }
    }
  }
};

export const clearEstimate = () => (dispatch) => (firebase) => {
  dispatch({
    type: CLEAR_ESTIMATE,
    payload: null,
  });
};

function FareCalculator(distance, time, rateDetails) {
  try {
    console.log("distance", distance);
    console.log("time", time);
    console.log("rateDetails", rateDetails);
    let baseCalculated =
      parseFloat(rateDetails.rate_per_unit_distance) * parseFloat(distance) +
      parseFloat(rateDetails.rate_per_hour) * (parseFloat(time) / 3600);
    if (rateDetails.base_fare > 0) {
      baseCalculated = baseCalculated + rateDetails.base_fare;
    }
    let total =
      baseCalculated > parseFloat(rateDetails.min_fare)
        ? baseCalculated
        : parseFloat(rateDetails.min_fare);
    let convenienceFee = 0;
    if (
      rateDetails.convenience_fee_type &&
      rateDetails.convenience_fee_type === "flat"
    ) {
      convenienceFee = rateDetails.convenience_fees;
    } else {
      convenienceFee = (total * parseFloat(rateDetails.convenience_fees)) / 100;
    }
    let grand = total + convenienceFee;

    return {
      totalCost: parseFloat(total),
      grandTotal: parseFloat(grand),
      convenience_fees: parseFloat(convenienceFee),
    };
  } catch (error) {
    console.log("im am the error in the fare", error);
  }
}
