/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import moment from "moment";
import { language } from "config";
import { TextField } from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";
import UsersCombo from "../../components/UsersCombo";
import AlertDialog from "../../components/AlertDialog";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { FirebaseContext } from "common";


const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    alignContent: "center",
  },
  title: {
    color: "#000",
  },
  gridcontainer: {
    alignContent: "center",
  },
  items: {
    margin: 0,
    width: "100%",
  },
  input: {
    fontSize: 18,
    color: "#000",
  },
  inputdimmed: {
    fontSize: 18,
    color: "#737373",
  },
  practicephoto: {
    height: "18px",
    marginRight: "10px",
  },
  buttonStyle: {
    margin: 0,
    width: "100%",
    height: "100%",
  },
  rootTwo: {
    minWidth: 275,
    borderRight: "2px solid",
    borderRightColor: "#ff0000",
    borderBlockStartColor: "#ff0000",
    borderBlockStart: "2px solid",
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  },
}));

const PABookingDetails = (props) => {
  ////////////////////REduX////////////////////
  const { api } = useContext(FirebaseContext);
  const {
    GetDateString,
    updateBooking,
    fetchChatMessages,
    clearLoginError,
    addOffers,
  } = api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  ////////////////////constant////////////////////
  const Booking = (props.location && props.details) || {};

  const usersdata = useSelector((state) => state.doctors);
  const [Doctors, setDoctors] = useState([]);

  const [Nurses, setNurses] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const [userCombo, setUserCombo] = useState();
  const classes = useStyles();
  const [selectedStartDate, setSelectedStartDate] = useState(GetDateString());
  const [selectedEndDate, setSelectedEndDate] = useState(GetDateString());
  const [open, setOpen] = useState(false);
  const [redodate, setredodate] = useState(false);
  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const [redoestimate, setredoestimate] = useState(false);
  const [ButtonText, setButtonText] = useState("Accept Booking");
  const [rejectbuttonclicked, setrejectbuttonclicked] = useState(false);
  const [acceptbuttonclicked, setacceptbuttonclicked] = useState(false);
  const [commonAlert, setCommonAlert] = useState({
    open: false,
    msg: "",
    title: "",
  });
  /////////////////////////////////
  const [newEstimate, setnewEstimate] = useState("");
  const [newTreamentFee, setnewTreamentFee] = useState("");

  const [newTotal, setnewTotal] = useState("");
  const [details, setdetails] = useState([]);
  useEffect(() => {
    setdetails(location.state.Booking);
    let arr = [];
    usersdata.users.map((user) => {
      if (
        user.practiceNumber === auth.info.profile.practiceNumber &&
        user.userType === "doctor"
      ) {
        arr.push({
          firstName: user.firstName,
          lastName: user.lastName,
          mobile: user.mobile,
          email: user.email,
          uid: user.id,
          practiceNumber: user.practiceNumber,

          profile_image: user.profile_image ? user.profile_image : " ",
          desc:
            user.firstName +
            " " +
            user.lastName +
            " (" +
            user.mobile +
            ") " +
            user.email,
          //pushToken: user.pushToken,
        });
      }
    });
    setDoctors(arr);
  }, []);
  const [updatebooking, setupdatebooking] = useState(details);
  useEffect(() => {
    let arr = [];
    usersdata.users.map((user) => {
      if (
        user.practiceNumber === auth.info.profile.practiceNumber &&
        user.userType === "nurse"
      ) {
        arr.push({
          firstName: user.firstName,
          lastName: user.lastName,
          mobile: user.mobile,
          email: user.email,
          uid: user.id,

          practiceNumber: user.practiceNumber,
          profile_image: user.profile_image ? user.profile_image : " ",
          desc:
            user.firstName +
            " " +
            user.lastName +
            " (" +
            user.mobile +
            ") " +
            user.email,
          // pushToken: user.pushToken,
        });
      }
    });
    setNurses(arr);
    // console.log("this", updatebooking);
  }, []);

  /////////////useeffect used for the reject button & accept button//////////////
  useEffect(() => {
    console.log("..+>>>", updatebooking);
    if (rejectbuttonclicked === true) {
      navigate("/bookingrequest");
    } else {
      if (acceptbuttonclicked === true) {
        // if (updatebooking.status === "ACCEPTED") {
        //   setCommonAlert({
        //     open: true,
        //     msg: "You have made an offer",
        //     title: "Thank you",
        //   });
        //   dispatch(addOffers(updatebooking));
        // }

        if (details.status === "NEW") {
          setCommonAlert({
            open: true,
            msg: "Booking has been assign",
            title: "Thank you",
          });
          dispatch(updateBooking(updatebooking));
        }
      }
    }

    // console.log("this is the booking:", Booking);
  }, [rejectbuttonclicked, acceptbuttonclicked, updatebooking]);

  //////////////functions needed for code to run//////////////
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    if (updatebooking.status === "ACCEPTED") {
      navigate("/bookingrequest");
    }
    setCommonAlert({ open: false, msg: "" });
    if (auth.error.flag) {
      dispatch(clearLoginError());
    }
  };

  const onDateStartChange = (event) => {
    setSelectedStartDate(event.target.value);
    setupdatebooking({
      ...updatebooking,
      adjustedtimeStart: event.target.value,
      markedDot: {
        ...updatebooking.markedDot,
        date: `${moment(event.target.value).format("YYYY")}-${moment(
          event.target.value
        ).format("MM")}-${moment(event.target.value).format("DD")}`,
      },
    });
  };
  const onDateEndChange = (event) => {
    setSelectedEndDate(event.target.value);
    setupdatebooking({
      ...updatebooking,
      adjustedtimeEnd: event.target.value,
    });
  };

  const HandleReject = () => {
    if (auth.info.profile.userType === "patient") {
      setupdatebooking({ ...updatebooking, status: "NEW", doctor: null });
      setrejectbuttonclicked(true);
    }
  };
  const HandleAccept = () => {
    console.log(details);

    if (details.status === "NEW") {
      setupdatebooking({
        ...details,
        status: "OFFERED",
        practiceNumber: auth.info.profile.practiceNumber,
        offerstatus: "OFFERED",
        nurse: updatebooking.nurse ? updatebooking.nurse : "",
        doctor: updatebooking.doctor ? updatebooking.doctor : "",
      });
      setOpen(false);
      setacceptbuttonclicked(true);
    }
  };
  ///////////
  //Offer new Booking Fee
  const offeredEstimate = (event) => {
    setnewEstimate(event.target.value);
    setupdatebooking({
      ...updatebooking,
      estimate: event.target.value,
    });
    console.log("estimate: ", newEstimate);
  };
  const offeredTreament = (event) => {
    setnewTreamentFee(event.target.value);
    setupdatebooking({
      ...updatebooking,
      estimate_treatment_fee: event.target.value,
    });
    console.log("new estimate: ", newTreamentFee);
  };
  const offeredTotal = (event) => {
    setnewTotal(event.target.value);
    setupdatebooking({
      ...updatebooking,
      estimate_bigGrand: event.target.value,
    });
    console.log("new estimate: ", newTotal);
  };

  //////////////////////Render the alert Dialog/////////////////
  const renderAlertDialog = () => {
    return (
      <AlertDialog
        open={commonAlert.open}
        onClose={handleCommonAlertClose}
        title={commonAlert.title}
      >
        {commonAlert.msg}
      </AlertDialog>
    );
  };

  const renderModal = () => {
    return (
      <Modal
        isOpen={open}
        toggle={() => setOpen(false)}
        centered={true}
        size="lg"
      >
        <ModalBody>
          {auth.info.profile.isIndependent &&
          auth.info.profile.userType === "doctor" ? (
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <FormGroup>
                  <UsersCombo
                    className={classes.items}
                    placeholder={"Assign a Doctor to the Booking"}
                    users={Doctors}
                    value={userCombo}
                    onChange={(event, newValue) => {
                      setupdatebooking({
                        ...updatebooking,
                        doctor: newValue,
                      });
                    }}
                  />
                </FormGroup>
              </GridItem>
            </GridContainer>
          ) : null}
          {auth.info.profile.isIndependent &&
          auth.info.profile.userType === "nurse" ? (
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <FormGroup>
                  <UsersCombo
                    className={classes.items}
                    placeholder={"Assign a Nurse to the Booking"}
                    users={Nurses}
                    value={userCombo}
                    onChange={(event, newValue) => {
                      setupdatebooking({
                        ...updatebooking,
                        nurse: newValue,
                      });
                    }}
                  />
                </FormGroup>
              </GridItem>
            </GridContainer>
          ) : null}
          {!auth.info.profile.isIndependent &&
          auth.info.profile.userType === "practiceadmin" ? (
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <FormGroup>
                  <UsersCombo
                    className={classes.items}
                    placeholder={"Assign a Doctor to the Booking"}
                    users={Doctors}
                    value={userCombo}
                    onChange={(event, newValue) => {
                      setupdatebooking({
                        ...updatebooking,
                        doctor: newValue,
                      });
                    }}
                  />
                </FormGroup>
              </GridItem>
            </GridContainer>
          ) : null}
          {!auth.info.profile.isIndependent &&
          auth.info.profile.userType === "practiceadmin" ? (
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <FormGroup>
                  <UsersCombo
                    className={classes.items}
                    placeholder={"Assign a Nurse to the Booking"}
                    users={Nurses}
                    value={userCombo}
                    onChange={(event, newValue) => {
                      setupdatebooking({
                        ...details,
                        nurse: newValue,
                      });
                    }}
                  />
                </FormGroup>
              </GridItem>
            </GridContainer>
          ) : null}
          <GridContainer spacing={2}>
            <GridItem xs={6} sm={6} md={6} lg={6}>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="checkbox"
                    onChange={() => {
                      setredodate(!redodate);
                      if (ButtonText === "Accept Booking") {
                        setButtonText("Offer New Time");
                      } else {
                        setButtonText("Assign Booking");
                      }
                    }}
                    checked={redodate}
                  />
                  <span className="form-check-sign" />
                  Would you like to change the Date and time?
                </Label>
              </FormGroup>
            </GridItem>
            <GridItem xs={6} sm={6} md={6} lg={6}>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="checkbox"
                    onChange={() => {
                      setredoestimate(!redoestimate);
                      if (ButtonText === "Accept Booking") {
                        setButtonText("Offer New Price");
                      } else {
                        setButtonText("Assign Booking");
                      }
                    }}
                    checked={redoestimate}
                  />
                  <span className="form-check-sign" />
                  Offer a Quote
                </Label>
              </FormGroup>
            </GridItem>
          </GridContainer>
          {redodate && (
            <GridContainer spacing={2}>
              <GridItem xs={6} sm={6} md={6} lg={6}>
                <FormGroup>
                  <TextField
                    id="datetime-local"
                    label={language.booking_start_date_time}
                    type="datetime-local"
                    variant="outlined"
                    fullWidth
                    className={classes.commonInputStyle}
                    InputProps={{
                      className: classes.input,
                    }}
                    value={selectedStartDate}
                    onChange={onDateStartChange}
                  />
                </FormGroup>
              </GridItem>
              <GridItem xs={6} sm={6} md={6} lg={6}>
                <FormGroup>
                  <TextField
                    id="datetime-local"
                    label={language.booking_end_date_time}
                    type="datetime-local"
                    variant="outlined"
                    fullWidth
                    className={classes.commonInputStyle}
                    InputProps={{
                      className: classes.input,
                    }}
                    value={selectedEndDate}
                    onChange={onDateEndChange}
                  />
                </FormGroup>
              </GridItem>
            </GridContainer>
          )}
          {redoestimate && (
            <GridContainer spacing={2}>
              <GridItem xs={3} sm={3} md={3} lg={3}>
                <Label>Estimate Amount:</Label>
              </GridItem>
              <GridItem xs={3} sm={3} md={3} lg={3}>
                <Input value={details?.estimate} disabled={true} />
              </GridItem>

              <GridItem xs={3} sm={3} md={3} lg={3}>
                <Label>Offered Consultation Amount:</Label>
              </GridItem>
              <GridItem xs={3} sm={3} md={3} lg={3}>
                <Input value={newEstimate} onChange={offeredEstimate} />
              </GridItem>

              <GridItem xs={3} sm={3} md={3} lg={3}>
                <Label>Treatment Amount:</Label>
              </GridItem>
              <GridItem xs={3} sm={3} md={3} lg={3}>
                <Input
                  value={details?.estimate_treatment_fee}
                  disabled={true}
                />
              </GridItem>

              <GridItem xs={3} sm={3} md={3} lg={3}>
                <Label> Offered Treatment Amount:</Label>
              </GridItem>
              <GridItem xs={3} sm={3} md={3} lg={3}>
                <Input value={newTreamentFee} onChange={offeredTreament} />
              </GridItem>

              <GridItem xs={3} sm={3} md={3} lg={3}>
                <Label>Grand Total:</Label>
              </GridItem>
              <GridItem xs={3} sm={3} md={3} lg={3}>
                <Input value={details?.estimate_bigGrand} disabled={true} />
              </GridItem>

              <GridItem xs={3} sm={3} md={3} lg={3}>
                <Label> Offered Total:</Label>
              </GridItem>
              <GridItem xs={3} sm={3} md={3} lg={3}>
                <Input value={newTotal} onChange={offeredTotal} />
              </GridItem>
            </GridContainer>
          )}
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={HandleAccept}>
            {ButtonText}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };
  const renderBookingData = () => {
    return (
      <Card className={classes.rootTwo}>
        <CardBody>
          <Row>
            <Col>
              <FormGroup>
                <span>Booking Name: </span>
                <p className="description">{details?.bookingName}</p>
              </FormGroup>

              <FormGroup>
                <span>Type of Service: </span>
                <p className="description">{details?.serviceType}</p>
              </FormGroup>

              <FormGroup>
                <span>Booking ID: </span>
                <p className="description">{details?.id}</p>
              </FormGroup>

              <FormGroup>
                <span>Booking Status: </span>
                <p className="description">{details?.status}</p>
              </FormGroup>

              <FormGroup>
                <span>Type of Doctor/Practice They Looking for: </span>
                <p className="description">{details?.specialityType}</p>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <span>Location: </span>
                <p className="description">{details?.pickup?.add}</p>
              </FormGroup>

              <FormGroup>
                <span>details? ID: </span>
                <p className="description">{details?.id}</p>
              </FormGroup>

              <FormGroup>
                <span>Estimate Amount: </span>
                <p className="description">{details?.estimate_bigGrand}</p>
              </FormGroup>

              <FormGroup>
                <span>Patient Contant Number: </span>
                <p className="description">{details?.patientContact}</p>
              </FormGroup>

              <FormGroup>
                <span>Email of Patient : </span>
                <p className="description">{details?.patientEmail}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              {/* <FormGroup>
                <span>Type of Doctor/Practice They Looking for: </span>
                <p className="description">{Booking.specialityTypes}</p>
              </FormGroup> */}

              {details?.adjustedtimeStart ? (
                <FormGroup>
                  <span>New Booking Date and Time of Booking: </span>
                  <p className="description">{details?.adjustedtimeStart}</p>
                </FormGroup>
              ) : null}
              {!details?.adjustedtimeStart ? (
                <FormGroup>
                  <span>Date and Time of Booking: </span>
                  <p className="description">{details?.eventDate}</p>
                </FormGroup>
              ) : null}
            </Col>

            {details?.status !== "NEW" ? (
              <Col>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Doctor Details</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Col md="12">
                      <FormGroup>
                        <span>Name of Doctor </span>
                        <p className="description">
                          {details?.doctor?.firstName +
                            " " +
                            details?.doctor?.lastName}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <span>Doctor Contact Number: </span>
                        <p className="description">{details?.doctor?.mobile}</p>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <span>Email of Doctor: </span>
                        <p className="description">{details?.doctor?.email}</p>
                      </FormGroup>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : null}
          </Row>
        </CardBody>
      </Card>
    );
  };
  const renderButtons = () => {
    return (
      <Col md="12">
        <Row>
          {details?.status === "PENDING" &&
          auth.info.profile.userType === "patient" ? (
            <Col md="2">
              <Button
                color="primary"
                onClick={() => {
                  HandleAccept();
                }}
              >
                Accept Booking
              </Button>
            </Col>
          ) : null}
          {details?.status === "NEW" ? (
            <Col md="2">
              <Button
                color="primary"
                onClick={() => {
                  navigate({
                    pathname: "/bookingrequest",
                    state: { details },
                  });
                  setOpen(true);
                }}
              >
                Back
              </Button>
            </Col>
          ) : null}
          {details?.status === "NEW" ? (
            <Col md="2">
              <Button
                color="primary"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Assign Booking
              </Button>
            </Col>
          ) : null}
          {details?.status === "NEW" || details?.status === "PENDING" ? (
            <Col md="2">
              <Button color="primary" onClick={HandleReject}>
                Reject Booking
              </Button>
            </Col>
          ) : null}{" "}
          {details?.status === "ACCEPTED" ? (
            <Col md="2">
              <Button
                color="primary"
                onClick={() => {
                  dispatch(fetchChatMessages(details?.id));
                  navigate({
                    pathname: "/chats",
                    state: { details },
                  });

                  setOpen(true);
                }}
              >
                Chat
              </Button>
            </Col>
          ) : null}
          {details?.status === "ACCEPTED" ? (
            <Col md="2">
              <Button
                color="primary"
                onClick={() => {
                  navigate({
                    pathname: "/videoconference",
                    state: { details },
                  });
                }}
              >
                Video Conference
              </Button>
            </Col>
          ) : null}
        </Row>
      </Col>
    );
  };
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Booking Details</CardTitle>
      </CardHeader>
      <CardBody>
        {renderAlertDialog()}
        {renderModal()}
        {renderBookingData()}
      </CardBody>
      <CardFooter>{renderButtons()}</CardFooter>
    </Card>
  );
};

export default PABookingDetails;
