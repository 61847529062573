/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  ListItemIcon,
  Divider,
  MenuList,
  MenuItem,
  RadioGroup
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { Collapse, Card, CardTitle, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import HomeIcon from "@material-ui/icons/Home";
// import ListIcon from "@material-ui/icons/Home";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CarIcon from "@material-ui/icons/DirectionsCar";
import ListIcon from "@material-ui/icons/ListAlt";

import ExitIcon from "@material-ui/icons/ExitToApp";
import OfferIcon from "@material-ui/icons/LocalOffer";
import PeopleIcon from "@material-ui/icons/People";
import MoneyIcon from "@material-ui/icons/AttachMoney";
import NotifyIcon from "@material-ui/icons/NotificationsActive";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import Calendar from "@material-ui/icons/CalendarTodayOutlined";

// import  Setting  from '@material-ui/icons/Settings';

import { language, features } from "config";
import assets from "assets";
import { FirebaseContext } from "common";

const useStyles = makeStyles((theme) => ({
  rootOne: {
    background: "#6b7a83",
  },
  rootTwo: {
    background: "#6b7a83",
    textAlign: "center",
    alignItems: "flex-start",
  },
}));
function AppMenu() {
  const { api } = useContext(FirebaseContext);
  const { signOut } = api;
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const LogOut = () => {
    dispatch(signOut());
    navigate("/");
  };
  let isNoPhonePatient =
    auth.info &&
    auth.info.profile &&
    auth.info.profile.userType === "nophonepatients";
  let isPatient =
    auth.info && auth.info.profile && auth.info.profile.userType === "patient";
  let isAdmin =
    auth.info && auth.info.profile && auth.info.profile.userType === "admin";
  let isPracticeAdmin =
    auth.info &&
    auth.info.profile &&
    auth.info.profile.userType === "practiceadmin";
  let isDoctor =
    auth.info && auth.info.profile && auth.info.profile.userType === "doctor";
  let isNurse =
    auth.info && auth.info.profile && auth.info.profile.userType === "nurse";
  let isIndependentDoctor =
    auth.info &&
    auth.info.profile &&
    !auth.info.profile.privatePractice &&
    auth.info.profile.userType === "doctor";
  let isIndependentNurse =
    auth.info &&
    auth.info.profile &&
    !auth.info.profile.privatePractice &&
    auth.info.profile.userType === "nurse";

  //Collapse for menus
  const classes = useStyles();
  const [openedCollapses, setOpenCollapses] = React.useState([]);
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  const collapsesToggle = (collapse) => {
    if (openedCollapses.includes(collapse)) {
      setOpenCollapses([]);
    } else {
      setOpenCollapses([collapse]);
    }
  };

  return (
    <div
      aria-multiselectable={true}
      className="card-collapse"
      id="accordion"
      role="tablist"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#6b7a83",
        }}
      >
        <img
          style={{
            marginTop: "17px",
            marginBottom: "17px",
            width: "120px",
            height: "120px",
          }}
          src={assets.logo192x192}
          alt="Logo"
        />
      </div>
      <Divider />

      <MenuList>
        {features.WebsitePagesEnabled ? (
          <MenuItem component={Link} to="/">
            <ListItemIcon>
              <HomeIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{language.home}</Typography>
          </MenuItem>
        ) : null}
        {isAdmin || isPracticeAdmin || isDoctor || isNurse ? (
          <MenuItem component={Link} to="/dashboard">
            <ListItemIcon>
              <PeopleIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"Dashboard"}</Typography>
          </MenuItem>
        ) : null}
        {isAdmin || isPracticeAdmin ? (
          <MenuItem component={Link} to="/profile">
            <ListItemIcon>
              <PersonOutlineIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{language.profile}</Typography>
          </MenuItem>
        ) : null}
        {isDoctor ? (
          <MenuItem component={Link} to="/doctorprofile">
            <ListItemIcon>
              <PersonOutlineIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"My Profile"}</Typography>
          </MenuItem>
        ) : null}
        {isDoctor ? (
          <MenuItem component={Link} to="/editprofile">
            <ListItemIcon>
              <PersonOutlineIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"Edit Profile"}</Typography>
          </MenuItem>
        ) : null}
        {isDoctor ? (
          <MenuItem component={Link} to="/doctorcalendar">
            <ListItemIcon>
              <PersonOutlineIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"Calendar"}</Typography>
          </MenuItem>
        ) : null}
        {isNurse ? (
          <MenuItem component={Link} to="/nurseprofile">
            <ListItemIcon>
              <PersonOutlineIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"My Profile"}</Typography>
          </MenuItem>
        ) : null}
        {isNurse || isIndependentNurse ? (
          <>
            <MenuItem component={Link} to="/nurseeditprofile">
              <ListItemIcon>
                <PersonOutlineIcon style={{ color: "#ffffff" }} />
              </ListItemIcon>
              <Typography variant="inherit">{"Edit Profile"}</Typography>
            </MenuItem>
            <MenuItem component={Link} to="/nursecalendar">
              <ListItemIcon>
                <PersonOutlineIcon style={{ color: "#ffffff" }} />
              </ListItemIcon>
              <Typography variant="inherit">{"Calendar"}</Typography>
            </MenuItem>
            <MenuItem component={Link} to="/nurseclinicbooking">
              <ListItemIcon>
                <PersonOutlineIcon style={{ color: "#ffffff" }} />
              </ListItemIcon>
              <Typography variant="inherit">{"Clinic"}</Typography>
            </MenuItem>
          </>
        ) : null}
        {/* Bookings */}
        {isPracticeAdmin ||
        isAdmin ||
        isIndependentDoctor ||
        isNurse ||
        isIndependentNurse ? (
          <>
            <Card
              className={classes.rootTwo}
              onClick={(e) => {
                collapsesToggle("collapseOne");
              }}
              data-parent="#accordion"
              data-toggle="collapse"
              aria-expanded={openedCollapses.includes("collapseOne")}
            >
              <CardTitle>
                <MenuItem>
                  <ListItemIcon>
                    <DashboardIcon style={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <Typography variant="inherit">{"Bookings"}</Typography>
                </MenuItem>
              </CardTitle>
            </Card>

            <Collapse
              role="tabpanel"
              isOpen={openedCollapses.includes("collapseOne")}
            >
              {isPracticeAdmin ||
              isAdmin ||
              isIndependentDoctor ||
              isNurse ||
              isIndependentNurse ? (
                <>
                  {isPracticeAdmin ? (
                    <MenuItem component={Link} to="/paclinicbookings">
                      <ListItemIcon>
                        <DashboardIcon style={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <Typography variant="inherit">{"Clinic"}</Typography>
                    </MenuItem>
                  ) : null}
                  {isIndependentDoctor ? (
                    <>
                      <MenuItem component={Link} to="/bookings">
                        <ListItemIcon>
                          <DashboardIcon style={{ color: "#ffffff" }} />
                        </ListItemIcon>
                        <Typography variant="inherit">
                          {"Consultations"}
                        </Typography>
                      </MenuItem>
                    </>
                  ) : null}
                  {isNurse || isIndependentNurse ? (
                    <MenuItem component={Link} to="/nursebookingshistory">
                      <ListItemIcon>
                        <DashboardIcon style={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {"Consultations"}
                      </Typography>
                    </MenuItem>
                  ) : null}
                  <MenuItem component={Link} to="/bookingrequest">
                    <ListItemIcon>
                      <DashboardIcon style={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <Typography variant="inherit">
                      {"Booking Requests"}
                    </Typography>
                  </MenuItem>
                  <MenuItem component={Link} to="/addadminbookings">
                    <ListItemIcon>
                      <ListIcon style={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <Typography variant="inherit">
                      {"Create Booking "}
                    </Typography>
                  </MenuItem>
                </>
              ) : null}
            </Collapse>

            <Card
              className={classes.rootTwo}
              onClick={(e) => {
                collapsesToggle("collapseTwo");
              }}
              // className={classes.rootOne}
              data-parent="#accordion"
              data-toggle="collapse"
              aria-expanded={openedCollapses.includes("collapseTwo")}
            >
              {/* <Row> */}
              <MenuItem>
                <ListItemIcon>
                  <ListIcon style={{ color: "#ffffff" }} />
                </ListItemIcon>
                <Typography variant="inherit">{"Users"}</Typography>
              </MenuItem>
            </Card>
            <Collapse
              role="tabpanel"
              isOpen={openedCollapses.includes("collapseTwo")}
            >
              <CardBody>
                {isAdmin ||
                isPracticeAdmin ||
                isIndependentDoctor ||
                isNurse ||
                isIndependentNurse ? (
                  <>
                    {isAdmin ||
                    isPracticeAdmin ||
                    isIndependentDoctor ||
                    isNurse ||
                    isIndependentNurse ? (
                      <MenuItem component={Link} to="/referraldoctors">
                        <ListItemIcon>
                          <ListIcon style={{ color: "#ffffff" }} />
                        </ListItemIcon>
                        <Typography variant="inherit">
                          {" "}
                          {"Referral Doctors"}
                        </Typography>
                      </MenuItem>
                    ) : null}
                    {isAdmin ? (
                      <MenuItem component={Link} to="/admindoctors">
                        <ListItemIcon>
                          <PeopleIcon style={{ color: "#ffffff" }} />
                        </ListItemIcon>
                        <Typography variant="inherit">
                          {"AdminDoctors"}
                        </Typography>
                      </MenuItem>
                    ) : null}
                    {isAdmin || isPracticeAdmin || isIndependentDoctor ? (
                      <MenuItem component={Link} to="/doctors">
                        <ListItemIcon>
                          <PeopleIcon style={{ color: "#ffffff" }} />
                        </ListItemIcon>
                        <Typography variant="inherit">{"Doctors"}</Typography>
                      </MenuItem>
                    ) : null}
                    {isAdmin || isPracticeAdmin || isIndependentDoctor ? (
                      <MenuItem component={Link} to="/nurses">
                        <ListItemIcon>
                          <PeopleIcon style={{ color: "#ffffff" }} />
                        </ListItemIcon>
                        <Typography variant="inherit">{"Nurses"}</Typography>
                      </MenuItem>
                    ) : null}
                  </>
                ) : null}

                {isAdmin || isPracticeAdmin ? (
                  <MenuItem component={Link} to="/practiceadmin">
                    <ListItemIcon>
                      <PeopleIcon style={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <Typography variant="inherit">
                      {"Practice Admins"}
                    </Typography>
                  </MenuItem>
                ) : null}
                {isIndependentDoctor || isIndependentNurse || isNurse ? (
                  <MenuItem component={Link} to="/addpracticeadmin">
                    <ListItemIcon>
                      <PeopleIcon style={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <Typography variant="inherit">
                      {"Practice Admins"}
                    </Typography>
                  </MenuItem>
                ) : null}
                {/* PRIVATE PRACTICE */}
                {isAdmin ||
                isPracticeAdmin ||
                isIndependentDoctor ||
                isNurse ||
                isIndependentNurse ? (
                  <MenuItem component={Link} to="/nophonepatients">
                    <ListItemIcon>
                      <PeopleIcon style={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <Typography variant="inherit">{"Patients"}</Typography>
                  </MenuItem>
                ) : null}
              </CardBody>
            </Collapse>

            <>
              {/* <Card className={classes.rootOne}> */}
              <Card
                className={classes.rootTwo}
                onClick={(e) => {
                  // e.preventDefault();
                  collapsesToggle("collapseThree");
                }}
                data-parent="#accordion"
                data-toggle="collapse"
                aria-expanded={openedCollapses.includes("collapseThree")}
              >
                <MenuItem>
                  <ListItemIcon>
                    <DashboardIcon style={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <Typography variant="inherit">{"Reports"}</Typography>
                </MenuItem>
              </Card>
              <Collapse
                role="tabpanel"
                isOpen={openedCollapses.includes("collapseThree")}
              >
                <CardBody>
                  {isPracticeAdmin ||
                  isIndependentDoctor ||
                  isIndependentNurse ? (
                    <MenuItem component={Link} to="/practicereports">
                      <ListItemIcon>
                        <DashboardIcon style={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {"Practice Report"}
                      </Typography>
                    </MenuItem>
                  ) : null}
                  {isPracticeAdmin ||
                  isIndependentDoctor ||
                  isIndependentNurse ? (
                    <MenuItem component={Link} to="/financereports">
                      <ListItemIcon>
                        <DashboardIcon style={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {" Finance Report"}
                      </Typography>
                    </MenuItem>
                  ) : null}
                  {isAdmin ||
                  isPracticeAdmin ||
                  isIndependentDoctor ||
                  isIndependentNurse ? (
                    <MenuItem component={Link} to="/earningreports">
                      <ListItemIcon>
                        <MoneyIcon style={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {language.earning_reports}
                      </Typography>
                    </MenuItem>
                  ) : null}
                  {isDoctor ? (
                    <MenuItem component={Link} to="/financialreport">
                      <ListItemIcon>
                        <ListIcon style={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {"Financial Report"}
                      </Typography>
                    </MenuItem>
                  ) : null}
                  {isAdmin || isPracticeAdmin || isIndependentDoctor ? (
                    <MenuItem component={Link} to="/doctorearning">
                      <ListItemIcon>
                        <MoneyIcon style={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {language.doctor_earning}
                      </Typography>
                    </MenuItem>
                  ) : null}
                  {isDoctor ? (
                    <MenuItem component={Link} to="/bookingsreport">
                      <ListItemIcon>
                        <ListIcon style={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {"Bookings Report"}
                      </Typography>
                    </MenuItem>
                  ) : null}
                </CardBody>
              </Collapse>
              {/* </Card> */}
            </>
          </>
        ) : null}
        {isAdmin || isPracticeAdmin || isIndependentDoctor ? (
          <MenuItem component={Link} to="/practicedetails">
            <ListItemIcon>
              <CarIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"Practice Details"}</Typography>
          </MenuItem>
        ) : null}
        {/* Settings */}
        {isAdmin ? (
          <MenuItem component={Link} to="/settings">
            <ListItemIcon>
              <LocalAtmIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{language.settings_title}</Typography>
          </MenuItem>
        ) : null}
        {isAdmin ? (
          <MenuItem component={Link} to="/cancelreasons">
            <ListItemIcon>
              <CarIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">
              {language.cancellation_reasons}
            </Typography>
          </MenuItem>
        ) : null}
        {isAdmin || isPracticeAdmin ? (
          <MenuItem component={Link} to="/addtowallet">
            <ListItemIcon>
              <MoneyIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{language.add_to_wallet}</Typography>
          </MenuItem>
        ) : null}
        {/* {isAdmin || isPatient || isDoctor? (
          <MenuItem component={Link} to="/forms">
            <ListItemIcon>
              <DashboardIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{language.tehillahforms}</Typography>
          </MenuItem>
        ) : null} */}
        {isAdmin || isPracticeAdmin ? (
          <MenuItem component={Link} to="/withdraws">
            <ListItemIcon>
              <MoneyIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{language.withdraws}</Typography>
          </MenuItem>
        ) : null}
        {isAdmin ? (
          <MenuItem component={Link} to="/promos">
            <ListItemIcon>
              <OfferIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{language.promo}</Typography>
          </MenuItem>
        ) : null}
        {isDoctor ? (
          <>
            <MenuItem component={Link} to="/bookings">
              <ListItemIcon>
                <DashboardIcon style={{ color: "#ffffff" }} />
              </ListItemIcon>
              <Typography variant="inherit">{"Consultations"}</Typography>
            </MenuItem>
            <MenuItem component={Link} to="/clinicbookings">
              <ListItemIcon>
                <DashboardIcon style={{ color: "#ffffff" }} />
              </ListItemIcon>
              <Typography variant="inherit">{"Clinic"}</Typography>
            </MenuItem>
          </>
        ) : null}
        {isDoctor && !isIndependentDoctor ? (
          <MenuItem component={Link} to="/addpracticeadmin">
            <ListItemIcon>
              <PeopleIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"Practice Admins"}</Typography>
          </MenuItem>
        ) : null}
        {isDoctor || isIndependentDoctor ? (
          <MenuItem component={Link} to="/timeline">
            <ListItemIcon>
              <DashboardIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"Timeline"}</Typography>
          </MenuItem>
        ) : null}
        {isNurse || isIndependentNurse ? (
          <MenuItem component={Link} to="/nursetimeline">
            <ListItemIcon>
              <DashboardIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"Timeline"}</Typography>
          </MenuItem>
        ) : null}
        {isDoctor ? (
          <MenuItem component={Link} to="/doctortreatmentnotes">
            <ListItemIcon>
              <ListIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"Treament Notes"}</Typography>
          </MenuItem>
        ) : null}
        {isDoctor ? (
          <MenuItem component={Link} to="/doctorrefreq">
            <ListItemIcon>
              <ListIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"Refferal Request"}</Typography>
          </MenuItem>
        ) : null}

        {isDoctor ? (
          <MenuItem component={Link} to="/doctorprescriptionform">
            <ListItemIcon>
              <DashboardIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"Perscription"}</Typography>
          </MenuItem>
        ) : null}
        {/* Nurse////////////////////////////////////////////////////////////// */}
        {isNurse ? (
          <MenuItem component={Link} to="/nursetreatmentnotes">
            <ListItemIcon>
              <ListIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"Treatement Notes"}</Typography>
          </MenuItem>
        ) : null}
        {isNurse ? (
          <MenuItem component={Link} to="/nurseprescription">
            <ListItemIcon>
              <DashboardIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"Perscription"}</Typography>
          </MenuItem>
        ) : null}
        {/* Patient/////////////////////////////////////////////// */}
        {isPatient ? (
          <MenuItem component={Link} to="/patientprofile">
            <ListItemIcon>
              <PersonOutlineIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"My Profile"}</Typography>
          </MenuItem>
        ) : null}
        {isPatient ? (
          <MenuItem component={Link} to="/patientmedicalhistory">
            <ListItemIcon>
              <ListIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"My Medical History"}</Typography>
          </MenuItem>
        ) : null}
        {isPatient ? (
          <MenuItem component={Link} to="/addbookings">
            <ListItemIcon>
              <ListIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">
              {language.addbookinglable}
            </Typography>
          </MenuItem>
        ) : null}
        {isPatient ? (
          <MenuItem component={Link} to="/patientbookingoffers">
            <ListItemIcon>
              <ListIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"Pending Bookings"}</Typography>
          </MenuItem>
        ) : null}
        {isPatient ? (
          <MenuItem component={Link} to="/confirmedBookings">
            <ListItemIcon>
              <ListIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"Confirmed Bookings"}</Typography>
          </MenuItem>
        ) : null}
        {isPatient ? (
          <MenuItem component={Link} to="/mytimeline">
            <ListItemIcon>
              <DashboardIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"My Timeline"}</Typography>
          </MenuItem>
        ) : null}
        {/* {isPatient ? (
          <MenuItem component={Link} to="/patientwallet">
            <ListItemIcon>
              <ListIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"My Wallet"}</Typography>
          </MenuItem>
        ) : null} */}
        {isPatient ? (
          <MenuItem component={Link} to="/calendar" i>
            <ListItemIcon>
              <Calendar style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"My Calendar"}</Typography>
          </MenuItem>
        ) : null}
        {isAdmin || isDoctor || isPatient || isNurse || isPracticeAdmin ? (
          <MenuItem component={Link} to="/notifications">
            <ListItemIcon>
              <NotifyIcon style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <Typography variant="inherit">{"Notifications"}</Typography>
          </MenuItem>
        ) : null}
        <MenuItem onClick={LogOut}>
          <ListItemIcon>
            <ExitIcon style={{ color: "#ffffff" }} />
          </ListItemIcon>
          <Typography variant="inherit">{language.logout}</Typography>
        </MenuItem>
      </MenuList>
    </div>
  );
}

export default AppMenu;
