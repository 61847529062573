/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
// import MaterialTable from "material-table";
import CircularLoading from "../../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";

// import { features, dateStyle, language } from "config";
import { FirebaseContext } from "common";
// import { Book } from "@material-ui/icons";
import FlatList from "flatlist-react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
const BookingHistory = (props) => {
  const { api } = useContext(FirebaseContext);
  const { fetchMedicalHistory } = api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  // const [role, setRole] = useState(null);
  // const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  // const [openConfirm, setOpenConfirm] = useState(false);
  // const [selectedBooking, setSelectedBooking] = useState("");
const navigate = useNavigate();
  const bookinglistdata = useSelector((state) => state.bookinglistdata);

  useEffect(() => {
    console.log("auth", auth.info.uid);
    console.log("booking", bookinglistdata.bookings);
    let arr = [];
    if (auth.info.profile.userType === "doctor") {
      if (bookinglistdata.bookings) {
        bookinglistdata.bookings.map((booking) => {
          if (booking?.doctor?.uid !== undefined) {
            if (
              booking.doctor.uid === auth.info.uid &&
              booking.status === "ACCEPTED"
            ) {
              arr.push(booking);
            }
          }

          setData(arr);
        });
      } else {
        setData([]);
      }
    }
  }, [bookinglistdata.bookings]);

  const renderBooking = (Booking, index) => {
    return (
      <Col md="4">
        <Card border="danger">
          <CardHeader as="h5">
            <CardTitle tag="h4">
              <div className="picture">
                <img
                  alt="..."
                  className="picture-src"
                  src={Booking.patientImage}
                />
              </div>{" "}
              {Booking.patientName}
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Row mb="12">
              <Col md="12">
                <FormGroup>
                  <span>Type of Booking Service: </span>
                  <p className="description">{Booking.serviceType}</p>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <span>Date and Time of Booking: </span>
                  <p className="description">{Booking.eventDate}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row mb="12">
              <Col md="12">
                <FormGroup>
                  <span>Booking Status: </span>
                  <p className="description">{Booking.status}</p>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button
              onClick={() => {
                console.log('Booking',Booking);
                  navigate("/bookinginformation", { state: { Booking } });
                dispatch(fetchMedicalHistory(Booking.patient));
              }}
            >
              View Booking
            </Button>
          </CardFooter>
        </Card>
      </Col>
    );
  };

  return data.loading ? (
    <CircularLoading />
  ) : (
    <Row>
      <Col md="12">
        <Card>
          <CardHeader>
            <CardTitle tag="h4">CONSULATIONS</CardTitle>
          </CardHeader>
          <CardBody>
            <ul>
              <Row>
                <FlatList
                  keyExtractor={(item, index) => {
                    return index.toString();
                  }}
                  list={data}
                  renderItem={renderBooking}
                  renderWhenEmpty={() => (
                    <div>There currently is no bookings!</div>
                  )}

                  //groupBy={(person) => (person.info.age > 18 ? "Over 18" : "Under 18")}
                />
              </Row>
            </ul>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default BookingHistory;
