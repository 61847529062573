import {
  FETCH_BOOKING_OFFERS,
  FETCH_BOOKING_OFFERS_SUCCESS,
  FETCH_BOOKING_OFFERS_FAILED,
  EDIT_BOOKING_OFFERS,
  CANCEL_BOOKING_OFFER,
  NO_BOOKING_OFFERS
  } from "../store/types";
  
  const INITIAL_STATE = {
    bookingOffers:null,
    loading: false,
    error: {
      flag: false,
      msg: null
    }
  }
  
  export const bookingoffersreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_BOOKING_OFFERS:
        return {
          ...state,
          loading: true
        };
      case FETCH_BOOKING_OFFERS_SUCCESS:
        return {
          ...state,
          bookingOffers: action.payload,
          loading: false
        };
        case NO_BOOKING_OFFERS:
      return {
        ...state,
      };
      case FETCH_BOOKING_OFFERS_FAILED:
        return {
          ...state,
          bookingOffers: null,
          loading: false,
          error: {
            flag: true,
            msg: action.payload
          }
        };
        case CANCEL_BOOKING_OFFER:
      return {
        ...state
      };
      case EDIT_BOOKING_OFFERS:
        return state;
      default:
        return state;
    }
  };
  