/*eslint-disable*/
import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Input, Button, Label, DropdownToggle, UncontrolledDropdown, DropdownMenu, DropdownItem, Dropdown } from "reactstrap";
import GoogleMapsAutoComplete from "components/GoogleMapsAutoComplete";
import { FormGroup, Select } from "@mui/material";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
const WalkInForm = () => {
  const [state, setstate] = useState({
    descName: "",
    address: "",
    carerDescName: "",
    signIn: false,
    signOut: false,
    progress: "",
    signature: "",
  });
  return (
    <div>
      <GridContainer spacing={4}>
      <GridItem xs={3} sm={3} md={3} lg={3}>
          <Label>Date: </Label>
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
          <Input
            type="text"
          />
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
          <Label>LMP:</Label>
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
          <Input
            type="text"
          />
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
          <Label>Bloed Druk:</Label>
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
          <Input
            type="text"
          />
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
          <Label>Massa Mass:</Label>
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
          <Input
            type="text"
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} lg={6}>
          <Label>Noteer-Enige problem Aksieplanne, voorligting, breaadvoering t.o.v gesondheidspovoeding, seksualiteit, menopaus of kontasepsie
Record-Any problems and action plans, education, counselling are health education, sexually, menopause or contraception:</Label>
        </GridItem>
        <GridItem xs={6} sm={6} md={6} lg={6}>
          <Input
            fullWidth
            type="textarea"
          />
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
          <Label>Follow Up date:</Label>
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3}>
          <Input
            type="datetime"
          />
        </GridItem>
        <GridItem xs={1} sm={1} md={1} lg={1}>
          <Label>Signature</Label>
        </GridItem>
        <GridItem xs={5} sm={5} md={5} lg={5}>
          <Input
            type="text"
            value={state.signature}
            onChange={(value) => {
              setstate({ ...state, signature: value });
            }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default WalkInForm;
