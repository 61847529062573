import {
  FETCH_MEDNOTES,
  FETCH_MEDNOTES_SUCCESS,
  FETCH_MEDNOTES_FAILED,
  ADD_MEDNOTES,
  ADD_MEDNOTES_SUCCESS,
  ADD_MEDNOTES_FAILED,
  EDIT_MEDNOTES,
  EDIT_MEDNOTES_SUCCESS,
  EDIT_MEDNOTES_FAILED,
  DELETE_MEDNOTES,
  DELETE_MEDNOTES_SUCCESS,
  DELETE_MEDNOTES_FAILED,
} from "../store/types";

export const fetchMedNotes = () => (dispatch) => {
  dispatch({
    type: FETCH_MEDNOTES,
    payload: null,
  });

  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // change url to deployed api url

    fetch("localhost:32784/api/notes", requestOptions)
      .then((response) => {
        console.log("i am the response", response);
        dispatch({
          type: FETCH_MEDNOTES_SUCCESS,
          payload: response,
        });
      })
      .then((result) => console.log("this is created user results: ", result))
      .catch((error) => console.log("error", error));
  } catch (err) {
    dispatch({
      type: FETCH_MEDNOTES_FAILED,

      payload: err,
    });
    console.log("fail", err);
  }
};

export const addMedNotes = (medNotes) => (dispatch) => {
  dispatch({
    type: ADD_MEDNOTES,
    payload: null,
  });

  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "text/plain");

    const raw = medNotes;

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // change url to deployed api url

    fetch("localhost:32784/api/notes", requestOptions)
      .then((response) => {
        console.log("i am the response", response);
        dispatch({
          type: ADD_MEDNOTES_SUCCESS,
          payload: response,
        });
      })
      .then((result) => console.log("this is created user results: ", result))
      .catch((error) => console.log("error", error));
  } catch (err) {
    dispatch({
      type: ADD_MEDNOTES_FAILED,

      payload: err,
    });
    console.log("fail", err);
  }
};
export const editMedNotes = (id, medNotes) => (dispatch) => {
  dispatch({
    type: EDIT_MEDNOTES,
    payload: null,
  });

  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "text/plain");

    const raw = medNotes;

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // change url to deployed api url

    fetch(`localhost:32784/api/notes/${id}`, requestOptions)
      .then((response) => {
        console.log("i am the response", response);
        dispatch({
          type: EDIT_MEDNOTES_SUCCESS,
          payload: response,
        });
      })
      .then((result) => console.log("this is created user results: ", result))
      .catch((error) => console.log("error", error));
  } catch (err) {
    dispatch({
      type: EDIT_MEDNOTES_FAILED,

      payload: err,
    });
    console.log("fail", err);
  }
};

export const deleteMedNotes = (id) => (dispatch) => {
  dispatch({
    type: DELETE_MEDNOTES,
    payload: null,
  });

  try {
    const requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };
    fetch(`localhost:32784/api/notes/${id}`, requestOptions)
      .then((response) => {
        console.log("i am the response", response);
        dispatch({
          type: DELETE_MEDNOTES_SUCCESS,
          payload: response,
        });
      })
      .then((result) => console.log("this is created user results: ", result))
      .catch((error) => console.log("error", error));
  } catch (err) {
    dispatch({
      type: DELETE_MEDNOTES_FAILED,

      payload: err,
    });
    console.log("fail", err);
  }
};
