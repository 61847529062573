/*eslint-disable*/
import React from "react";

import { Button } from "reactstrap";
import ProgressReport from "./Forms/ProgressReport";
const Forms = () => {
  return (
    <div>
      {/* <Button
        simple
        color="primary"
        size="lg"
        // href="https://rd4a.com/index.php?option=com_rsform&view=rsform&formId=12"
      >
        Dental Form
      </Button>
      <Button
        simple
        color="primary"
        size="lg"
        href="https://rd4a.com/index.php?option=com_rsform&view=rsform&formId=11"
      >
        Audiology Form
      </Button>
      <Button
        simple
        color="primary"
        size="lg"
        href="https://rd4a.com/index.php?option=com_rsform&view=rsform&formId=14"
      >
        Primary Health Care Assessment
      </Button>
      <Button
        simple
        color="primary"
        size="lg"
        href="https://rd4a.com/index.php?option=com_rsform&view=rsform&formId=13"
      >
        OPT Form
      </Button> */}
      <div className="content">
        <div className="videoconference">
          <ProgressReport />
        </div>
      </div>
    </div>
  );
};

export default Forms;
