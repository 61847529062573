/*eslint-disable*/
import React from "react";

const OPTForm = () => {
  return (
    <div className="content">
      <div className="videoconference">
        <iframe
          allow="camera; microphone; fullscreen; display-capture; autoplay"
          src="https://rd4a.com/index.php/doctors/sioc-audiology-assessment/mensana-sioc-primary-healthcare-assessment"
          height="650px"
          width="100%"
          border="0px"
       />
      </div>
    </div>
  );
};

export default OPTForm;
