/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import CircularLoading from "../../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationDialogRaw from "../../components/ConfirmationDialogRaw";
import Visibility from "@material-ui/icons/Visibility";
import { features, dateStyle, language } from "config";
import { FirebaseContext } from "common";
import AlertDialog from "../../components/AlertDialog";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import { Button } from "reactstrap";
import PaInvoice from "./PaInvoice";
import PABookingDetails from "./PABookingDetails";

const PracticeReports = () => {
  const { api } = useContext(FirebaseContext);
  const { cancelBooking, clearLoginError } = api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [role, setRole] = useState(null);
  const [openFPModal, setOpenFPModal] = React.useState(false);
  const [openFPModaltwo, setOpenFPModalTwo] = React.useState(false);
  const userdata = useSelector((state) => state.usersdata);
  const [users, setUsers] = useState(null);
  const [data, setData] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState("");
  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const handleOpenEvent = (e) => {
    e.preventDefault();
    setOpenFPModal(true);
  };
  const handleCloseEvent = (e) => {
    e.preventDefault();
    setOpenFPModal(false);
  };
  const handleOpenEvents = (e) => {
    e.preventDefault();
    setOpenFPModalTwo(true);
  };
  const handleCloseEvents = (e) => {
    e.preventDefault();
    setOpenFPModalTwo(false);
  };

  const columns = [
    { title: "Practice Type ", field: "specialityType" },
    // { title: language.booking_id, field: "id" },
    {
      title: language.booking_date,
      field: "eventDate",
      render: (rowData) =>
        rowData.eventDate
          ? new Date(rowData.eventDate).toUTCString(dateStyle)
          : null
    },
    // { title: "Location", field: "location" },
    {
      title: "Nurse Name",
      field: "nurse.firstName",
      render: (rowData) =>
        rowData.nurse.firstName ? rowData.nurse.firstName : "No Nurse Assigned"
    },
    {
      title: "Doctor Name",
      field: "doctor.firstName",
      render: (rowData) =>
        rowData.doctor ? rowData.doctor.firstName : "No Doctor Assigned"
    },
    {
      title: "Patient Name",
      field: "patientName",
      render: (rowData) =>
        rowData.patientName ? rowData.patientName : "No Patient Name found"
    },
    {
      title: "Patient Number",
      field: "patientContact",
      render: (rowData) =>
        rowData.patientContact
          ? rowData.patientContact
          : "No Patient Contact Number found"
    },

    // {
    //   title: "Patient Contact Number",
    //   field: "patientContact",
    //   render: (rowData) =>
    //     rowData.patientContact
    //       ? rowData.patientContact
    //       : "No Patient Contact Number found"
    // },
    {
      title: "Gender",
      field: "patientGender",
      render: (rowData) =>
        rowData.patientGender
          ? rowData.patientGender
          : "No Patient Gender found"
    },
    {
      title: "Age",
      field: "patientAge",
      render: (rowData) =>
        rowData.patientAge ? rowData.patientAge : "No patient age found"
    },
    {
      title: "Referred To",
      field: "referraldoctor.desc",
      render: (rowData) =>
        rowData.referraldoctor
          ? rowData.referraldoctor.desc
          : "No referral doctor found"
    },
    {
      title: "Referral Reason",
      field: "referralreason",
      render: (rowData) =>
        rowData.referralreason
          ? rowData.referralreason
          : "No referral reason found"
    }
  ];

  useEffect(() => {
    const arr = [];
    // console.log("auth.info.profile", auth.info.profile);
    if (bookinglistdata.bookings) {
      bookinglistdata.bookings.map((booking) => {
        if (
          (booking?.doctor &&
            booking?.doctor?.practiceNumber ===
              auth.info.profile.practiceNumber &&
            booking.status === "ACCEPTED") ||
          (booking?.nurse &&
            booking?.nurse?.practiceNumber === auth.info.profile.practiceNumber)
        ) {
          arr.push(booking);
        } else if (
          booking?.nurse &&
          !booking?.doctor &&
          booking?.doctor?.practiceNumber === auth.info.profile.practiceNumber
        ) {
          arr.push(booking);
        } else {
          console.log(bookinglistdata.bookings);
        }
      });
      setData(arr);
    } else {
      setData([]);
      // console.log("bookings: ",   bookinglistdata.bookings[3].doctor );
    }
  }, [bookinglistdata.bookings]);

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.userType);
    }
  }, [auth.info]);

  const onConfirmClose = (value) => {
    if (value) {
      dispatch(
        cancelBooking({
          reason: value,
          booking: selectedBooking
        })
      );
    }
    setOpenConfirm(false);
  };
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
    if (auth.error.flag) {
      dispatch(clearLoginError());
    }
  };
  return bookinglistdata.loading ? (
    <CircularLoading />
  ) : (
    ((
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    ),
    (
      <div>
        <MaterialTable
          title="Booking History"
          columns={columns}
          data={data}
          options={{
            exportButton: true,
            sorting: true,
            actionsColumnIndex: -1
          }}
          // detailPanel={rowData => {
          //   return (
          //     <iframe
          //       width="100%"
          //       height="315"
          //       src="https://www.youtube.com/embed/C0DPdy98e4c"
          //       frameborder="0"
          //       allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          //       allowfullscreen
          //     />
          //   )
          // }}
          // actions={[
          //   (rowData) => ({
          //     icon: "description",
          //     tooltip: "Booking Information",
          //     disabled:
          //       rowData.status === "NEW" || rowData.status === "ACCEPTED"
          //         ? false
          //         : true,
          //     onClick: () => {
          //       if (
          //         features.AllowCriticalEditsAdmin &&
          //         role === "practiceadmin"
          //       ) {
          //         // setSelectedBooking(rowData);
          //         setOpenFPModalTwo(true);
          //       } else {
          //         alert(language.demo_mode);
          //       }
          //     }
          //   })
          // ]}
        />
        {/* <ConfirmationDialogRaw
        open={openConfirm}
        onClose={onConfirmClose}
        value={""}
      /> */}
        <Dialog
          open={openFPModaltwo}
          onClose={setOpenFPModalTwo}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          size="lg"
          centered={true}
          // size="lg"
        >
          <DialogTitle id="form-dialog-title">{"Booking Details"}</DialogTitle>
          <DialogContent>
            <PABookingDetails Booking={data} />
          </DialogContent>

          <DialogActions>
            <Button color="primary">Save</Button>
            <Button onClick={handleCloseEvent} color="primary">
              Close
            </Button>
            <Button color="primary" onClick={() => window.print(this)}>
              PRINT
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    ))
  );
};

export default PracticeReports;
