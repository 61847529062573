import {
  FETCH_SPECIALITY_TYPES,
  FETCH_SPECIALITY_TYPES_SUCCESS,
  FETCH_SPECIALITY_TYPES_FAILED,
  EDIT_SPECIALITY_TYPE
} from "../store/types";
import { language } from 'config';

export const fetchSpecialityTypes = () => (dispatch) => (firebase) => {

  const {
    specialityTypesRef
  } = firebase;

  dispatch({
    type: FETCH_SPECIALITY_TYPES,
    payload: null
  });
  specialityTypesRef.on("value", snapshot => {
    if (snapshot.val()) {
      let data = snapshot.val();
      const arr = Object.keys(data).map(i => {
        data[i] = i;
        return data[i]
      });
      dispatch({
        type: FETCH_SPECIALITY_TYPES_SUCCESS,
        payload: arr
      });
    } else {
      dispatch({
        type: FETCH_SPECIALITY_TYPES_FAILED,
        payload: language.no_specialitys
      });
    }
  });
};

export const editSpecialityType = (specialitytypes, method) => (dispatch) => (firebase) => {
  const {
    specialityTypesRef, 
    specialityTypesEditRef
  } = firebase;
  dispatch({
    type: EDIT_SPECIALITY_TYPE,
    payload: { method, specialitytypes }
  });
  if (method === 'Add') {
    specialityTypesRef.push(specialitytypes);
  } else if (method === 'Delete') {
    specialityTypesEditRef(specialitytypes.id).remove();
  } else {
    specialityTypesEditRef(specialitytypes.id).set(specialitytypes);
  }
}