/*eslint-disable*/
/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/now-ui-dashboard.scss?v=1.5.0";
import "assets/css/demo.css";

ReactDOM.render(<App />, document.getElementById("root"));
