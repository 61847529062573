import {
  FETCH_DOCTOR_TYPES,
  FETCH_DOCTOR_TYPES_SUCCESS,
  FETCH_DOCTOR_TYPES_FAILED,
  EDIT_DOCTOR_TYPE
} from "../store/types";

const INITIAL_STATE = {
  doctorstypes: {
    AMBULANCE_EMERGENCY_ASSISTANT: "AMBULANCE EMERGENCY ASSISTANT",
    ANAESTHETIST: "ANAESTHETIST",
    ANAESTHETISTs_ASSISTANT: "ANAESTHETIST'S ASSISTANT",
    "ARTS THERAPIST": "ARTS THERAPIST",
    ARTS_THERAPIST_INTERNS: "ARTS THERAPIST INTERNS",
    ARTS_THERAPY_STUDENT: "ARTS THERAPY STUDENT",
    ASSISTANT_CLINICAL_TECHNOLOGIST: "ASSISTANT CLINICAL TECHNOLOGIST",
    "ASST_MED_ORTH_PROST_&_LEATHERWORKER":
      "ASST MED ORTH PROST & LEATHERWORKER",
    AUDIOLOGIST: "AUDIOLOGIST",
    AUDIOMETRICIAN: "AUDIOMETRICIAN",
    BASIC_AMBULANCE_ASSISTANT: "BASIC AMBULANCE ASSISTANT",
    BIOKINETICIST: "BIOKINETICIST",
    BIOMEDICAL_ENGINEER: "BIOMEDICAL ENGINEER",
    CLINICAL_ASSOCIATE: "CLINICAL ASSOCIATE",
    CLINICAL_BIOCHEMIST: "CLINICAL BIOCHEMIST",
    CLINICAL_TECHNOLOGIST: "CLINICAL TECHNOLOGIST",
    COMMUNITY_SPEECH_AND_HEARING_WORKER:
      "COMMUNITY SPEECH AND HEARING WORKER",
    DENTAL_ASSISTANT: "DENTAL ASSISTANT",
    "DENTAL_ASSISTANT_(SUPPLEMENTARY_REGISTER)":
      "DENTAL ASSISTANT (SUPPLEMENTARY REGISTER) - CLOSED",
    DENTAL_THERAPIST: "DENTAL THERAPIST",
    DENTIST: "DENTIST",
    DIETETICS_VISITING_STUDENT: "DIETETICS VISITING STUDENT",
    DIETITIAN: "DIETITIAN",
    DISPENSING_OPTICIAN: "DISPENSING OPTICIAN",
    ECP_VISITING_STUDENT: "ECP VISITING STUDENT",
    ECT_VISITING_STUDENT: "ECT VISITING STUDENT",
    ELECTRO_ENCEPHALOGRAPHIC_TECHNICIAN:
      "ELECTRO-ENCEPHALOGRAPHIC TECHNICIAN",
    EMERGENCY_CARE_ASSISTANT: "EMERGENCY CARE ASSISTANT",
    EMERGENCY_CARE_PRACTITIONER: "EMERGENCY CARE PRACTITIONER",
    EMERGENCY_CARE_TECHNICIAN: "EMERGENCY CARE TECHNICIAN",
    ENVIRONMENTAL_HEALTH_ASSISTANT: "ENVIRONMENTAL HEALTH ASSISTANT",
    ENVIRONMENTAL_HEALTH_PRACTITIONER: "ENVIRONMENTAL HEALTH PRACTITIONER",
    FOOD_INSPECTOR: "FOOD INSPECTOR",
    GENETIC_COUNSELLOR: "GENETIC COUNSELLOR",
    GRADUATE_CLINICAL_TECHNOLOGIST: "GRADUATE CLINICAL TECHNOLOGIST",
    GRADUATE_CLINICAL_TECHNOLOGIST_STUDENT:
      "GRADUATE CLINICAL TECHNOLOGIST STUDENT",
    HEALTH_ASSISTANT: "HEALTH ASSISTANT",
    HEARING_AID_ACOUSTICIAN: "HEARING AID ACOUSTICIAN",
    INTERN: "INTERN",
    INTERN_BIOKINETICIST: "INTERN BIOKINETICIST",
    INTERN_BIOMEDICAL_ENGINEER: "INTERN BIOMEDICAL ENGINEER",
    INTERN_CLINICAL_BIOCHEMIST: "INTERN CLINICAL BIOCHEMIST",
    INTERN_GENETIC_COUNSELLOR: "INTERN GENETIC COUNSELLOR",
    INTERN_MEDICAL_ORTHOTIST_AND_PROSTHETIST:
      "INTERN MEDICAL ORTHOTIST AND PROSTHETIST",
    INTERN_MEDICAL_PHYSICIST: "INTERN MEDICAL PHYSICIST",
    INTERN_MEDICAL_SCIENTIST: "INTERN MEDICAL SCIENTIST",
    INTERN_PSYCHOLOGIST: "INTERN PSYCHOLOGIST",
    LABORATORY_ASSISTAN: "LABORATORY ASSISTANT",
    MASSEUR: "MASSEUR",
    MEDICAL_BIOLOGICAL_SCIENTIST: "MEDICAL BIOLOGICAL SCIENTIST",
    MEDICAL_LABORATORY_SCIENTIST: "MEDICAL LABORATORY SCIENTIST",
    MEDICAL_ORTHOTIST_AND_PROSTHETIST: "MEDICAL ORTHOTIST AND PROSTHETIST",
    MEDICAL_PHYSICIST: "MEDICAL PHYSICIST",
    MEDICAL_PRACTITIONER: "MEDICAL PRACTITIONER",
    MEDICAL_STUDENT: "MEDICAL STUDENT",
    MEDICAL_TECHNICIAN: "MEDICAL TECHNICIAN",
    MEDICAL_TECHNOLOGIST: "MEDICAL TECHNOLOGIST",
    MEDICAL_TECHNOLOGY_INTERN: "MEDICAL TECHNOLOGY INTERN",
    NUTRITION_VISITING_STUDENT: "NUTRITION VISITING STUDENT",
    NUTRITIONIST: "NUTRITIONIST",
    OCCUPATIONAL_THERAPIST: "OCCUPATIONAL THERAPIST",
    OCCUPATIONAL_THERAPY_ASSISTANT: "OCCUPATIONAL THERAPY ASSISTANT",
    OCCUPATIONAL_THERAPY_TECHNICIAN: "OCCUPATIONAL THERAPY TECHNICIAN",
    OPERATIONAL_EMERGENCY_CARE_ORDERLY: "OPERATIONAL EMERGENCY CARE ORDERLY",
    OPTOMETRIST: "OPTOMETRIST",
    "ORAL-HYGIENIST": "ORAL HYGIENIST",
    ORTHOPAEDIC_FOOTWEAR_TECHNICIAN: "ORTHOPAEDIC FOOTWEAR TECHNICIAN",
    RTHOPAEDIC_TECHNICAL_ASSISTANT: "ORTHOPAEDIC TECHNICAL ASSISTANT",
    ORTHOPTIST: "ORTHOPTIST",
    PARAMEDIC: "PARAMEDIC",
    PHYSIOTHERAPIST: "PHYSIOTHERAPIST",
    PHYSIOTHERAPY_ASSISTANT: "PHYSIOTHERAPY ASSISTANT",
    PHYSIOTHERAPY_TECHNICIAN: "PHYSIOTHERAPY TECHNICIAN",
    PODIATRIST: "PODIATRIST",
    PSYCHO_TECHNICIAN: "PSYCHO-TECHNICIAN",
    PSYCHOLOGIST: "PSYCHOLOGIST",
    PSYCHOLOGY_VISITING_STUDENT: "PSYCHOLOGY VISITING STUDENT",
    PSYCHOMETRIST: "PSYCHOMETRIST",
    RADIATION_TECHNOLOGIST: "RADIATION TECHNOLOGIST",
    RADIOGRAPHER: "RADIOGRAPHER",
    REGISTERED_COUNSELLOR: "REGISTERED COUNSELLOR",
    REMEDIAL_GYMNAST: "REMEDIAL GYMNAST",
    RESTRICTED_SUPP_DIAG_RADIOGRAPHER: "RESTRICTED SUPP DIAG RADIOGRAPHER",
    SPEECH_AND_AUDIOLOGY_ASSISTANT: "SPEECH AND AUDIOLOGY ASSISTANT",
    SPEECH_AND_HEARING_ASSISTANT: "SPEECH AND HEARING ASSISTANT",
    SPEECH_AND_HEARING_CORRECTIONIST: "SPEECH AND HEARING CORRECTIONIST",
    SPEECH_THERAPIST: "SPEECH THERAPIST",
    SPEECH_THERAPIST_AND_AUDIOLOGIST: "SPEECH THERAPIST AND AUDIOLOGIST",
    SPEECH_THERAPY_ASSISTANT: "SPEECH THERAPY ASSISTANT",
    STA_VISITNG_STUDENT: "STA VISITNG STUDENT",
    STUDENT_AMBULANCE_EMERGENCY_ASSISTANT:
      "STUDENT AMBULANCE EMERGENCY ASSISTANT",
    STUDENT_ANAESTHETIST: "STUDENT ANAESTHETIST",
    "STUDENT_ASST_MED_ORTH_PROST_&_EATHERWORKER":
      "STUDENT ASST MED ORTH PROST & LEATHERWORKER",
    STUDENT_AUDIOLOGIST: "STUDENT AUDIOLOGIST",
    STUDENT_AUDIOMETRICIAN: "STUDENT AUDIOMETRICIAN",
    STUDENT_BIOKINETICIST: "STUDENT BIOKINETICIST",
    STUDENT_BIOMEDICAL_ENGINEER: "STUDENT BIOMEDICAL ENGINEER",
    STUDENT_CLINICAL_ASSOCIATE: "STUDENT CLINICAL ASSOCIATE",
    STUDENT_CLINICAL_BIOCHEMIST: "STUDENT CLINICAL BIOCHEMIST",
    STUDENT_CLINICAL_TECHNOLOGIST: "STUDENT CLINICAL TECHNOLOGIST",
    STUDENT_COMMUNITY_SPEECH_AND_HEARING_WORKER:
      "STUDENT COMMUNITY SPEECH AND HEARING WORKER",
    STUDENT_DENTAL_ASSISTANT: "STUDENT DENTAL ASSISTANT",
    STUDENT_DENTAL_THERAPIST: "STUDENT DENTAL THERAPIST",
    STUDENT_DENTIST: "STUDENT DENTIST",
    STUDENT_DIETITIAN: "STUDENT DIETITIAN",
    STUDENT_DISPENSING_OPTICIAN: "STUDENT DISPENSING OPTICIAN",
    "STUDENT_ELECTRO-ENCEPHALOGRAPHIC_TECHNICIAN":
      "STUDENT ELECTRO-ENCEPHALOGRAPHIC TECHNICIAN",
    STUDENT_EMERGENCY_CARE_ASSISTANTS: "STUDENT EMERGENCY CARE ASSISTANTS",
    STUDENT_EMERGENCY_CARE_PRACTITIONER:
      "STUDENT EMERGENCY CARE PRACTITIONER",
    STUDENT_EMERGENCY_CARE_TECHNICIAN: "STUDENT EMERGENCY CARE TECHNICIAN",
    STUDENT_ENVIRONMENTAL_HEALTH_OFFICER:
      "STUDENT ENVIRONMENTAL HEALTH OFFICER",
    STUDENT_FOOD_INSPECTOR: "STUDENT FOOD INSPECTOR",
    STUDENT_GENETIC_COUNSELLOR: "STUDENT GENETIC COUNSELLOR",
    STUDENT_HEARING_AID_ACOUSTICIAN: "STUDENT HEARING AID ACOUSTICIAN",
    STUDENT_INTERN: "STUDENT INTERN",
    STUDENT_LABORATORY_ASSISTANT: "STUDENT LABORATORY ASSISTANT",
    STUDENT_MASSEUR: "STUDENT MASSEUR",
    STUDENT_MEDICAL_ORTHOTIST_AND_PROSTHETIST:
      "STUDENT MEDICAL ORTHOTIST AND PROSTHETIST",
    STUDENT_MEDICAL_PHYSICIST: "STUDENT MEDICAL PHYSICIST",
    STUDENT_MEDICAL_SCIENTIST: "STUDENT MEDICAL SCIENTIST",
    STUDENT_MEDICAL_TECHNICIAN: "STUDENT MEDICAL TECHNICIAN",
    STUDENT_MEDICAL_TECHNOLOGIST: "STUDENT MEDICAL TECHNOLOGIST",
    STUDENT_NUTRITIONIST: "STUDENT NUTRITIONIST",
    STUDENT_OCCUPATIONAL_THERAPIST: "STUDENT OCCUPATIONAL THERAPIST",
    STUDENT_OCCUPATIONAL_THERAPY_ASSISTANT:
      "STUDENT OCCUPATIONAL THERAPY ASSISTANT",
    STUDENT_OCCUPATIONAL_THERAPY_TECHNICIAN:
      "STUDENT OCCUPATIONAL THERAPY TECHNICIAN",
    STUDENT_OPTOMETRIST: "STUDENT OPTOMETRIST",
    TUDENT_ORAL_HYGIENIST: "STUDENT ORAL HYGIENIST",
    STUDENT_ORTHOPAEDIC_FOOTWEAR_TECHNICIAN:
      "STUDENT ORTHOPAEDIC FOOTWEAR TECHNICIAN",
    STUDENT_ORTHOPAEDIC_TECHNICAL_ASSISTANT:
      "STUDENT ORTHOPAEDIC TECHNICAL ASSISTANT",
    STUDENT_ORTHOPTIST: "STUDENT ORTHOPTIST",
    STUDENT_PARAMEDIC: "STUDENT PARAMEDIC",
    STUDENT_PHYSIOTHERAPIST: "STUDENT PHYSIOTHERAPIST",
    STUDENT_PHYSIOTHERAPY_ASSISTANT: "STUDENT PHYSIOTHERAPY ASSISTANT",
    STUDENT_PODIATRIST: "STUDENT PODIATRIST",
    STUDENT_PSYCHOLOGIST: "STUDENT PSYCHOLOGIST",
    STUDENT_PSYCHOMETRIST: "STUDENT PSYCHOMETRIST",
    STUDENT_RADIATION_TECHNOLOGIST: "STUDENT RADIATION TECHNOLOGIST",
    STUDENT_RADIOGRAPHER: "STUDENT RADIOGRAPHER",
    STUDENT_REGISTERED_COUNSELLOR: "STUDENT REGISTERED COUNSELLOR",
    STUDENT_REMEDIAL_GYMNAST: "STUDENT REMEDIAL GYMNAST",
    STUDENT_RESTRICTED_SUPP_DIAG_RADIOGRAPHER:
      "STUDENT RESTRICTED SUPP DIAG RADIOGRAPHER",
    STUDENT_SPEECH_AND_HEARING_CORRECTIONIST:
      "STUDENT SPEECH AND HEARING CORRECTIONIST",
    STUDENT_SPEECH_THERAPIST: "STUDENT SPEECH THERAPIST",
    STUDENT_SPEECH_THERAPIST_AND_AUDIOLOGIST:
      "STUDENT SPEECH THERAPIST AND AUDIOLOGIST",
    STUDENT_SUPPLEMENTARY_AUDIOLOGIST: "STUDENT SUPPLEMENTARY AUDIOLOGIST",
    STUDENT_SUPPLEMENTARY_DIAGNOSTIC_RADIOGRAPHER:
      "STUDENT SUPPLEMENTARY DIAGNOSTIC RADIOGRAPHER",
    STUDENT_SUPPLEMENTARY_DIETITIAN: "STUDENT SUPPLEMENTARY DIETITIAN",
    STUDENT_SUPPLEMENTARY_MEDICAL_ORTHOTIST_AND_PROSTHETIST:
      "STUDENT SUPPLEMENTARY MEDICAL ORTHOTIST AND PROSTHETIST",
    STUDENT_SUPPLEMENTARY_MEDICAL_TECHNICIAN:
      "STUDENT SUPPLEMENTARY MEDICAL TECHNICIAN",
    "STUDENT_SUPPLEMENTARY_OCCUPATIONA_ THERAPIST":
      "STUDENT SUPPLEMENTARY OCCUPATIONAL THERAPIST",
    STUDENT_SUPPLEMENTARY_OPTICAL_DISPENSER:
      "STUDENT SUPPLEMENTARY OPTICAL DISPENSER",
    STUDENT_SUPPLEMENTARY_OPTOMETRIST: "STUDENT SUPPLEMENTARY OPTOMETRIST",
    STUDENT_SUPPLEMENTARY_PHYSIOTHERAPIST:
      "STUDENT SUPPLEMENTARY PHYSIOTHERAPIST",
    STUDENT_SUPPLEMENTARY_PODIATRIST: "STUDENT SUPPLEMENTARY PODIATRIST",
    STUDENT_SUPPLEMENTARY_SPEECH_THERAPIST_AND_AUDIOLOGIST:
      "STUDENT SUPPLEMENTARY SPEECH THERAPIST AND AUDIOLOGIST",
    "SUPPL_ELECTRO-ENCEPH_TECHNICIAN": "SUPPL ELECTRO-ENCEPH TECHNICIAN",
    SUPPLEMENTARY_AUDIOLOGIST: "SUPPLEMENTARY AUDIOLOGIST",
    SUPPLEMENTARY_BIOKINETICIST: "SUPPLEMENTARY BIOKINETICIST",
    SUPPLEMENTARY_CLINICAL_TECHNOLOGIST:
      "SUPPLEMENTARY CLINICAL TECHNOLOGIST",
    SUPPLEMENTARY_DIAGNOSTIC_RADIOGRAPHER:
      "SUPPLEMENTARY DIAGNOSTIC RADIOGRAPHER",
    SUPPLEMENTARY_DIETITIAN: "SUPPLEMENTARY DIETITIAN",
    SUPPLEMENTARY_HEARING_AID_ACOUSTICIAN:
      "SUPPLEMENTARY HEARING AID ACOUSTICIAN",
    SUPPLEMENTARY_LABORATORY_ASSISTANT: "SUPPLEMENTARY LABORATORY ASSISTANT",
    SUPPLEMENTARY_MEDICAL_ORTHOTIST_AND_PROSTHETIST:
      "SUPPLEMENTARY MEDICAL ORTHOTIST AND PROSTHETIST",
    SUPPLEMENTARY_MEDICAL_SCIENTIST: "SUPPLEMENTARY MEDICAL SCIENTIST",
    SUPPLEMENTARY_MEDICAL_TECHNICIAN: "SUPPLEMENTARY MEDICAL TECHNICIAN",
    SUPPLEMENTARY_NUTRITIONIST: "SUPPLEMENTARY NUTRITIONIST",
    SUPPLEMENTARY_OCCUPATIONAL_THERAPIST:
      "SUPPLEMENTARY OCCUPATIONAL THERAPIST",
    SUPPLEMENTARY_OPTICAL_DISPENSER: "SUPPLEMENTARY OPTICAL DISPENSER",
    SUPPLEMENTARY_OPTOMETRIST: "SUPPLEMENTARY OPTOMETRIST",
    SUPPLEMENTARY_PHYSIOTHERAPIST: "SUPPLEMENTARY PHYSIOTHERAPIST",
    SUPPLEMENTARY_PODIATRIST: "SUPPLEMENTARY PODIATRIST",
    SUPPLEMENTARY_RADIATION_TECHNOLOGIST:
      "SUPPLEMENTARY RADIATION TECHNOLOGIST",
    SUPPLEMENTARY_SPEECH_THERAPIST_AND_AUDIOLOGIST:
      "SUPPLEMENTARY SPEECH THERAPIST AND AUDIOLOGIST",
    VISITING_STUDENT: "VISITING STUDENT",
    VISITING_STUDENT_OPTOMETRY: "VISITING STUDENT OPTOMETRY",
    VISITING_STUDENT_RADIOGRAPHER: "VISITING STUDENT RADIOGRAPHER",
  },
  loading: false,
  error: {
    flag: false,
    msg: null
  }
}

export const doctortypesreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DOCTOR_TYPES:
      return {
        ...state,
        loading: true
      };
    case FETCH_DOCTOR_TYPES_SUCCESS:
      return {
        ...state,
        doctortypes: action.payload,
        loading: false
      };
    case FETCH_DOCTOR_TYPES_FAILED:
      return {
        ...state,
        doctortypes: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload
        }
      };
    case EDIT_DOCTOR_TYPE:
      return state;
    default:
      return state;
  }
};