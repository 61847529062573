import {
    UPDATE_EVENT_PICKUP,
    UPDATE_EVENT_DROP,
    UPDATE_EVENT_PRACTICE,
    UPDATE_SELECTED_POINT_TYPE,
    CLEAR_EVENT_POINTS
} from "../store/types";

export const updateEventPickup = (pickupAddress) => (dispatch) => (firebase) => {
    dispatch({
        type: UPDATE_EVENT_PICKUP,
        payload: pickupAddress
    });
};

export const updateEventDrop = (dropAddress) => (dispatch) => (firebase) => {
    dispatch({
        type: UPDATE_EVENT_DROP,
        payload: dropAddress
    });
};

export const updateEventPractice = (selectedPractice) => (dispatch) => (firebase) => {
    dispatch({
        type: UPDATE_EVENT_PRACTICE,
        payload: selectedPractice
    });
};

export const updatSelPointType = (selection) => (dispatch) => (firebase) => {
    dispatch({
        type: UPDATE_SELECTED_POINT_TYPE,
        payload: selection
    });
};

export const clearEventPoints = () => (dispatch) => (firebase) => {
    dispatch({
        type: CLEAR_EVENT_POINTS,
        payload: null
    });
};


