/*eslint-disable*/
import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import Person from "@material-ui/icons/PersonOutline";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import image from "assets/img/bg24.jpg";
import { useSelector, useDispatch } from "react-redux";
import { Paper, Select, MenuItem, Tab, Tabs } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import AlertDialog from "../components/AlertDialog";
import CountrySelect from "../components/CountrySelect";
import { language, default_country_code, features } from "config";
import { FirebaseContext } from "common";
import { Input, Label, FormGroup, Row, Col, FormText } from "reactstrap";
// import { Stats } from "fs";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import PasswordChecklist from "react-password-checklist";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles(styles);

export default function RegisterPage(props) {
  const { api } = useContext(FirebaseContext);
  const {
    clearLoginError,
    emailSignUp,
    checkUserExists,
    validateReferer,
    createOpenfireUser,
    fetchUsers,
  } = api;
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const openfireuser = useSelector(
    (state) => state.openfireuserdata.openfireuser
  );
const navigate = useNavigate();
  const [fileState, setFileState] = useState(null);
  const [fileState1, setFileState1] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(props.avatar);
  const [imagePreviewUrl1, setImagePreviewUrl1] = useState(props.avatar);
  const fileInput = useRef();
  const fileInput1 = useRef();
  const [userName, setuserName] = useState(" ");
  const [state, setState] = useState({
    userType: "patient",
    email: "",
    firstName: "",
    lastName: "",
    username: "",
    identityNumber: "",
    isIndependent: true,
    referralId: "",
    practiceNumber: "",
    practiceName: "",
    practiceLicenseNumber: "",
    specialityType: false,
    doctorType: false,
    bankAccount: "",
    bankCode: "",
    bankName: "",
    bankType: false,
    licenseImage: null,
    doctorImage: null,
    nurseImage: null,
    other_info: "",
    password: "",
  });

  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [countryCode, setCountryCode] = useState(default_country_code);
  const [mobileWithoutCountry, setMobileWithoutCountry] = useState("");
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { ...rest } = props;
  const doctortypes = useSelector((state) => state.doctortypes.doctorstypes);
  const specialities = useSelector(
    (state) => state.specialitytypes.specialities
  );
  const [specialityTypes, setSpecialityTypes] = useState([]);
  const [doctorTypes, setDoctorTypes] = useState([]);
  const [selectedSpecialityDetails, setSelectedSpecialityDetails] =
    useState(null);
  const [selectedDoctorTypeDetails, setSelectedDoctorTypeDetails] =
    useState(null);

  useEffect(() => {
    if (specialities) {
      let arr = [];
      for (let i = 0; i < Object.keys(specialities).length; i++) {
        arr.push({
          value: Object.keys(specialities)[i],
          label: Object.values(specialities)[i],
        });
      }
      setSpecialityTypes(arr);
      // console.log('here', Object.values(specialities));
    }
    if (doctortypes) {
      let arr = [];
      for (let i = 0; i < Object.keys(doctortypes).length; i++) {
        arr.push({
          label: Object.values(doctortypes)[i],
          value: Object.keys(doctortypes)[i],
        });
      }
      setDoctorTypes(arr);
      // console.log('practiceTypes',arr);
    }
  }, [doctortypes, specialities]);
  const handleSpecialitySelect = (event) => {
    let specialityDetails = null;
    for (let i = 0; i < Object.keys(specialities).length; i++) {
      if (Object.keys(specialities)[i] === event.target.value) {
        specialityDetails = Object.keys(specialities)[i];
      }
    }
    setState({ ...state, specialityType: event.target.value });
    console.log("this is the speciality details: ", specialityDetails);
    setSelectedSpecialityDetails(specialityDetails);
  };
  const handleBankType = (event) => {
    setState({ ...state, bankType: event.target.value });
  };
  const handleDoctorSelect = (event) => {
    let doctorTypeDetails = null;
    for (let i = 0; i < Object.keys(doctortypes).length; i++) {
      if (Object.keys(doctortypes)[i] === event.target.value) {
        doctorTypeDetails = Object.keys(doctortypes)[i];
      }
    }
    setState({ ...state, doctorType: event.target.value });
    console.log("this is the doctor details: ", doctorTypeDetails);
    setSelectedDoctorTypeDetails(doctorTypeDetails);
  };
  const onReferral =(evant) => {
    setState({
      ...state,
      [event.target.id]: event.target.value,
      username:
        state.firstName +
        state.lastName +
        Math.floor(1000 + Math.random() * 9000).toString(),
    });
  }
  const onInputChange = (event) => {

    setState({
      ...state,
      [event.target.id]: event.target.value,
      username:
        state.firstName +
        state.lastName +
        Math.floor(1000 + Math.random() * 9000).toString(),
    });
  };
  // const UsernameChange = (event) => {
  //   setState({
  //     ...state,
  //     username:
  //       state.firstName +
  //       state.lastName +
  //       Math.floor(1000 + Math.random() * 9000).toString(),
  //   });
  // };

  const validateMobile = () => {
    let mobileValid = true;
    if (mobileWithoutCountry.length < 6) {
      mobileValid = false;
      setCommonAlert({ open: true, msg: language.mobile_no_blank_error });
    }
    if (
      mobileWithoutCountry.includes("+") ||
      mobileWithoutCountry.includes(" ") ||
      mobileWithoutCountry.includes("-")
    ) {
      mobileValid = false;
      setCommonAlert({ open: true, msg: language.mobile_no_blank_error });
    }
    return mobileValid;
  };

  const handleRemove = () => {
    fileInput.current.value = null;
    setFileState(null);
    setImagePreviewUrl(props.avatar);
  };
  const handleRemove1 = () => {
    fileInput1.current.value = null;
    setFileState1(null);
    setImagePreviewUrl1(props.avatar);
  };

  const handleClick = () => {
    fileInput.current.click();
  };
  const handleClick1 = () => {
    fileInput1.current.click();
  };
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    // console.log("the reader", reader);
    let file = e.target.files[0];
    // console.log("the file", file);
    reader.onloadend = async () => {
      setFileState(file);
      console.log("the fileState results ", reader);

      setState({ ...state, licenseImage: file });

      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      console.log("We made it here:", file);
    }
  };
  const handleImageChange1 = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    // console.log("the reader", reader);
    let file = e.target.files[0];
    // console.log("the file", file);
    reader.onloadend = () => {
      setFileState1(file);
      console.log("the fileState results ", reader);
      if (state.userType === "nurse") {
        setState({ ...state, nurseImage: file });
      } else if (state.userType === "doctor") {
        setState({ ...state, doctorImage: file });
      }
      setImagePreviewUrl1(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      console.log("We made it here:", file);
    }
  };
  const validatePractice = () => {
    let practiceValid = true;

    if (state.userType === "patient") {
      practiceValid = true;
      // Alert.alert('you are registering as a patient');
    } else {
      if (
        (state.specialityType === false ||
          state.specialityType === "" ||
          state.specialityType === null) &&
        (state.doctorType === false ||
          state.doctorType === " " ||
          state.doctorType === null)
      ) {
        practiceValid = false;
        setCommonAlert({ open: true, msg: "Please select practice type" });
      }
    }
    return practiceValid;
  };
  const validatePassword = (complexity) => {
    let passwordValid = true;
    const regx1 = /^([a-zA-Z0-9@*#]{8,15})$/;
    const regx2 =
      /(?=^.{6,10}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/;
    if (complexity === "any") {
      passwordValid = state.password.length >= 1;
      if (!passwordValid) {
        setCommonAlert({ open: true, msg: language.password_blank_messege });
      }
    } else if (complexity === "alphanumeric") {
      passwordValid = regx1.test(state.password);
      if (!passwordValid) {
        setCommonAlert({
          open: true,
          msg: language.password_alphaNumeric_check,
        });
      }
    } else if (complexity === "complex") {
      passwordValid = regx2.test(state.password);
      if (!passwordValid) {
        setCommonAlert({ open: true, msg: language.password_complexity_check });
      }
    }
    return passwordValid;
  };
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };
  const openFireUserDetails = () => {
    setOpen(false);
    setTimeout(() => {
      navigate("/login");
    }, 3000);
  };
  ///ID Number Validations
  const validateIdentity = (identityNumber) => {
    // let idValid = true;
    // const regID =
    //   /(?<Year>[0-9][0-9])(?<Month>([0][1-9])|([1][0-2]))(?<Day>([0-2][0-9])|([3][0-1]))(?<Gender>[0-9])(?<Series>[0-9]{3})(?<Citizenship>[0-9])(?<Uniform>[0-9])(?<Control>[0-9])/;
    // if (state.identityNumber.length === 13) {
    //   idValid = true;

    //   if ((idValid === regID.test(state.identityNumber)) === true) {
    //     idValid = true;
    //     console.log("Checking ID...", identityNumber);
    //     // Alert.alert(language.alert, language.identity_valid);
    //   }
    //   return true;
    // } else {
    //   // setCommonAlert({ open: true, msg: language.proper_identity_number });
    return true;
    //}
  };
  const handleRegister = (e) => {
    e.preventDefault();

    const re =
      /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (state.firstName.length > 0 && state.lastName.length > 0) {
      if (re.test(state.email)) {
        if (validatePassword()) {
          if (validateMobile()) {
            if (validateIdentity(state.identityNumber)) {
              if (validatePractice()) {
                checkUserExists(state).then((res) => {
                  if (res.users && res.users.length > 0) {
                    setCommonAlert({ open: true, msg: language.user_exists });
                  } else if (res.error) {
                    setCommonAlert({
                      open: true,
                      msg: language.email_or_mobile_issue,
                    });
                  } else {
                    if (state.referralId && state.referralId.length > 0) {
                      validateReferer(state.referralId)
                        .then((referralInfo) => {
                          if (referralInfo.uid && referralInfo.pnum) {
                            emailSignUp({
                              ...state,
                              signupViaReferral: referralInfo.uid,
                              practiceNumber: referralInfo.pnum
                            }).then((res) => {
                              if (res.uid) {
                                setCommonAlert({
                                  open: true,
                                  msg: language.account_create_successfully,
                                });
                                setTimeout(() => {
                                  // navigate("/login");
                                  console.log(state);
                                }, 3000);
                              } else {
                                setCommonAlert({
                                  open: true,
                                  msg: language.reg_error,
                                });
                              }
                            });
                          } else if ( referralInfo.uid && !referralInfo.pnum){
                            emailSignUp({
                              ...regData,
                              signupViaReferral: referralInfo.uid
                            }).then((res) => {
                              setLoading(false);
                              if (res.uid) {
                                Alert.alert(
                                  language.alert,
                                  language.account_create_successfully
                                );
                                props.navigation.navigate("LoginScreen");
                              } else {
                                Alert.alert(language.alert, language.reg_error);
                              }
                            });
            
                          } else {
                            setCommonAlert({
                              open: true,
                              msg: language.referer_not_found,
                            });
                          }
                        })
                        .catch((error) => {
                          setCommonAlert({
                            open: true,
                            msg: language.referer_not_found,
                          });
                        });
                    } else {
                      emailSignUp(state).then((res) => {
                        dispatch(
                          createOpenfireUser(
                            state.username,
                            state.password,
                            state.firstName,
                            state.lastName,
                            state.email
                          )
                        );

                        if (res.uid) {
                          setCommonAlert({
                            open: true,
                            msg: "Account created successfully ",
                          });
                          setTimeout(() => {
                            // navigate("/login");
                            console.log("first", state.username);
                            setOpen(true);
                          }, 3000);
                        } else {
                          setCommonAlert({
                            open: true,
                            msg: language.reg_error,
                          });
                        }
                      });
                    }
                  }
                });
              }
            } else {
              setCommonAlert({
                open: true,
                msg: language.proper_identity_number,
              });
            }
            // else {
            //   setCommonAlert({ open: true, msg: 'Please select practice type' });
            // }
          } else {
            setCommonAlert({ open: true, msg: language.mobile_no_blank_error });
          }
        }
      } else {
        setCommonAlert({ open: true, msg: language.proper_email });
      }
    } else {
      setCommonAlert({ open: true, msg: language.proper_input_name });
    }
  };
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
    if (auth.error.flag) {
      dispatch(clearLoginError());
    }
  };
  const handleBack = (e) => {
    e.preventDefault();
    navigate("/login");
  };
  return (
    <div>
      <Header
        absolute
        color="transparent"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div id="sign-in-button" />
        <div className={classes.container}>
          {/*  */}
          <Modal
            isOpen={open}
            toggle={() => setOpen(false)}
            centered={true}
            size="lg"
          >
            <ModalHeader>
              This is your Openfire login details. Please save your information.
            </ModalHeader>
            <ModalBody>
              <GridContainer spacing={2}>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <FormGroup>
                    <Label>Username:</Label>
                    {state.username}
                  </FormGroup>
                </GridItem>
              </GridContainer>
              <GridContainer spacing={2}>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <FormGroup>
                    <Label>Password:</Label>
                    {state.password}
                  </FormGroup>
                </GridItem>
              </GridContainer>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={openFireUserDetails}>
                Continue
              </Button>
            </ModalFooter>
          </Modal>
          {/*  */}
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={5}>
              <Card>
                <form className={classes.form}>
                  <Paper
                    square
                    className={classes.root}
                    style={{
                      paddingTop: 20,
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Tabs
                      value={0}
                      variant="fullWidth"
                      indicatorColor="secondary"
                      textColor="inherit"
                      aria-label="switch login type"
                    >
                      <Tab
                        disabled={true}
                        label="Register"
                        aria-label="email"
                      />
                    </Tabs>
                  </Paper>
                  <CardBody>
                    <CustomInput
                      labelText={language.firstname}
                      id="firstName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        required: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Person className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                      onChange={onInputChange}
                      value={state.firstName}
                    />

                    <CustomInput // LAST NAME
                      labelText={language.lastname}
                      id="lastName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        required: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Person className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                      onChange={onInputChange}
                      value={state.lastName}
                    />
                    <CustomInput
                      labelText={language.email}
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "email",
                        required: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                      onChange={onInputChange}
                      value={state.email}
                    />

                    <CustomInput
                      labelText={language.password}
                      id="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        required: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                      onChange={onInputChange}
                      value={state.password}
                    />
                    <FormGroup
                    // hidden="true"
                    >
                      <PasswordChecklist
                        rules={[
                          "minLength",
                          "specialChar",
                          "number",
                          "capital",
                          // "match",
                        ]}
                        minLength={8}
                        value={state.password}
                        valueAgain={state.password}
                        onChange={(isValid) => {}}
                      />
                    </FormGroup>
                    {features.AllowCountrySelection ? ( // COUNTRY
                      <CountrySelect
                        value={countryCode}
                        onChange={(object, value) => {
                          setCountryCode(value);
                          let formattedNum = mobileWithoutCountry.replace(
                            / /g,
                            ""
                          );
                          formattedNum =
                            "+" + value.phone + formattedNum.replace(/-/g, "");
                          setState({ ...state, mobile: formattedNum });
                        }}
                        style={{ paddingTop: 20 }}
                        disabled={state.verificationId ? true : false}
                      />
                    ) : null}
                    <CustomInput
                      labelText={language.phone}
                      id="mobile"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        required: true,
                        disabled: state.verificationId ? true : false,
                        endAdornment: (
                          <InputAdornment position="end">
                            <PhoneIcon className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) => {
                        setMobileWithoutCountry(event.target.value);
                        let formattedNum = event.target.value.replace(/ /g, "");
                        formattedNum =
                          "+" +
                          countryCode.phone +
                          formattedNum.replace(/-/g, "");
                        setState({ ...state, mobile: formattedNum });
                      }}
                      value={mobileWithoutCountry}
                    />
                    <CustomInput
                      labelText={language.identity_number_placeholder}
                      id="identityNumber"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number",
                        required: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                      onChange={onInputChange}
                      value={state.identityNumber}
                    />
                    <CustomInput
                      labelText={language.referralId}
                      id="referralId"
                      formControlProps={{
                        fullWidth: true,
                       
                      }}
                      inputProps={{
                        
                        
                        type: "text",
                        required: false,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                      onChange={onInputChange}
                      value={state.referralId}
                    />

                    <FormGroup className="form-check form-check-radio form-check-inline">
                      <Label check>
                        <Input
                          value="patient"
                          id="patient"
                          name="userType"
                          type="radio"
                          onChange={handleChange}
                        ></Input>
                        Patient <span className="form-check-sign"></span>
                      </Label>
                    </FormGroup>
                    <FormGroup className="form-check form-check-radio form-check-inline">
                      <Label check>
                        <Input
                          value="doctor"
                          id="doctor"
                          name="userType"
                          type="radio"
                          onChange={handleChange}
                        ></Input>
                        Doctor <span className="form-check-sign"></span>
                      </Label>
                    </FormGroup>
                    <FormGroup className="form-check form-check-radio form-check-inline">
                      <Label check>
                        <Input
                          value="nurse"
                          id="nurse"
                          name="userType"
                          type="radio"
                          onChange={handleChange}
                        ></Input>
                        Nurse <span className="form-check-sign"></span>
                      </Label>
                    </FormGroup>
                    {/* Doctor And Nurse */}
                    {state.userType !== "patient" ? (
                      <CustomInput
                        labelText={language.practice_name}
                        id="practiceName"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          required: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                        }}
                        onChange={onInputChange}
                        value={state.practiceName}
                      />
                    ) : null}

                    {state.userType !== "patient" ? (
                      <>
                        <Label>Speciality Types</Label>
                        <>
                          <Select
                            id="practice-type-native"
                            value={selectedSpecialityDetails}
                            onChange={handleSpecialitySelect}
                            variant="outlined"
                            fullWidth
                            className={
                              specialityTypes === language.select_practice
                                ? classes.inputdimmed
                                : classes.input
                            }
                          >
                            {specialityTypes.map((special) => (
                              <MenuItem value={special.value}>
                                {special.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      </>
                    ) : null}
                    {state.userType !== "patient" ? (
                      <>
                        <Label>Doctor Type</Label>
                        <Select
                          id="speciality-type-native"
                          value={selectedDoctorTypeDetails}
                          onChange={handleDoctorSelect}
                          variant="outlined"
                          fullWidth
                          className={
                            doctorTypes === language.select_speciality
                              ? classes.inputdimmed
                              : classes.input
                          }
                        >
                          {doctorTypes.map((doctor) => (
                            <MenuItem value={doctor.value}>
                              {doctor.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    ) : null}

                    {state.userType !== "patient" ? (
                      <CustomInput
                        labelText={language.practice_license_no}
                        id="practiceNumber"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          required: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                        }}
                        onChange={onInputChange}
                        value={state.practiceNumber}
                      />
                    ) : null}
                    {state.userType !== "patient" &&
                    state.userType !== "nurse" ? (
                      <CustomInput
                        labelText={"Practice Licence Number"}
                        id="practiceLicenseNumber"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          required: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                        }}
                        onChange={onInputChange}
                        value={state.practiceLicenseNumber}
                      />
                    ) : null}
                    {state.userType !== "patient" &&
                    state.userType !== "doctor" ? (
                      <CustomInput
                        labelText={"Annual Practice Certificates"}
                        id="practiceLicenseNumber"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          required: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                        }}
                        onChange={onInputChange}
                        value={state.practiceLicenseNumber}
                      />
                    ) : null}
                    {state.userType !== "patient" ? (
                      <CustomInput
                        labelText={language.other_info}
                        id="other_info"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          required: true,
                        }}
                        onChange={onInputChange}
                        value={state.other_info}
                      />
                    ) : null}
                    {state.userType !== "patient" ? (
                      <CustomInput
                        labelText={language.bankName}
                        id="bankName"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          required: true,
                        }}
                        onChange={onInputChange}
                        value={state.bankName}
                      />
                    ) : null}
                    {state.userType !== "patient" ? (
                      <CustomInput
                        labelText={language.bankCode}
                        id="bankCode"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          required: true,
                        }}
                        onChange={onInputChange}
                        value={state.bankCode}
                      />
                    ) : null}
                    {state.userType !== "patient" ? (
                      <CustomInput
                        labelText={language.bankAccount}
                        id="bankAccount"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          required: true,
                        }}
                        onChange={onInputChange}
                        value={state.bankAccount}
                      />
                    ) : null}
                    {state.userType !== "patient" ? (
                      <>
                        <Label>Bank Type</Label>
                        <>
                          <Select
                            id="bankType"
                            value={state.bankType}
                            onChange={handleBankType}
                            variant="outlined"
                            fullWidth
                            // className={
                            //   specialityTypes === language.select_practice
                            //     ? classes.inputdimmed
                            //     : classes.input
                            // }
                          >
                            <MenuItem value="check">Check</MenuItem>
                            <MenuItem value="savings">Savings</MenuItem>
                            <MenuItem value="credit">Credit</MenuItem>
                            <MenuItem value="debit">Debit</MenuItem>
                          </Select>
                        </>
                      </>
                    ) : null}
                    {state.userType !== "patient" ? (
                      <Col>
                        <Row>
                          <FormGroup>
                            {state.userType !== "patient" &&
                            state.userType !== "nurse" ? (
                              <FormText color="red" tag="header">
                                {language.license_image}{" "}
                              </FormText>
                            ) : state.userType !== "patient" &&
                              state.userType !== "doctor" ? (
                              <FormText color="red" tag="header">
                                {"Annual Practice Certificate"}{" "}
                              </FormText>
                            ) : null}
                            <div className="fileinput text-center">
                              <input
                                type="file"
                                onChange={handleImageChange}
                                ref={fileInput}
                              />
                              <div className={"thumbnail" + props.avatar}>
                                <img src={imagePreviewUrl} />
                              </div>
                              <div>
                                {fileState === null ? (
                                  <Button
                                    className="btn-round"
                                    onClick={() => handleClick()}
                                  >
                                    {null ? "Add Photo" : "Upload Image"}
                                  </Button>
                                ) : (
                                  <span>
                                    <Button
                                      className="btn-round"
                                      onClick={() => handleClick()}
                                    >
                                      Change
                                    </Button>
                                    {props.avatar ? <br /> : null}
                                    <Button
                                      color="danger"
                                      className="btn-round"
                                      onClick={() => handleRemove()}
                                    >
                                      <i className="fa fa-times" />
                                      Remove
                                    </Button>
                                  </span>
                                )}
                              </div>
                            </div>
                          </FormGroup>
                        </Row>
                      </Col>
                    ) : null}

                    {state.userType === "doctor" ? (
                      <Col>
                        <Row>
                          <FormGroup>
                            {state.userType !== "patient" &&
                            state.userType !== "nurse" ? (
                              <FormText color="red" tag="header">
                                {"Doctor Image"}{" "}
                              </FormText>
                            ) : state.userType !== "patient" &&
                              state.userType !== "doctor" ? (
                              <FormText color="red" tag="header">
                                {"Nurse Image"}{" "}
                              </FormText>
                            ) : null}
                            <div className="fileinput text-center">
                              <input
                                type="file"
                                onChange={handleImageChange1}
                                ref={fileInput1}
                              />
                              <div className={"thumbnail" + props.avatar}>
                                <img src={imagePreviewUrl1} />
                              </div>
                              <div>
                                {fileState1 === null ? (
                                  <Button
                                    className="btn-round"
                                    onClick={() => handleClick1()}
                                  >
                                    {null ? "Add Photo" : "Upload Image"}
                                  </Button>
                                ) : (
                                  <span>
                                    <Button
                                      className="btn-round"
                                      onClick={() => handleClick1()}
                                    >
                                      Change
                                    </Button>
                                    {props.avatar ? <br /> : null}
                                    <Button
                                      color="danger"
                                      className="btn-round"
                                      onClick={() => handleRemove1()}
                                    >
                                      <i className="fa fa-times" />
                                      Remove
                                    </Button>
                                  </span>
                                )}
                              </div>
                            </div>
                          </FormGroup>
                        </Row>
                      </Col>
                    ) : null}
                    {state.userType === "nurse" ? (
                      <Col>
                        <Row>
                          <FormGroup>
                            <FormText color="red" tag="header">
                              Nurse Image
                            </FormText>
                            <div className="fileinput text-center">
                              <input
                                type="file"
                                onChange={handleImageChange1}
                                ref={fileInput1}
                              />
                              <div className={"thumbnail" + props.avatar}>
                                <img src={imagePreviewUrl1} />
                              </div>
                              <div>
                                {fileState1 === null ? (
                                  <Button
                                    className="btn-round"
                                    onClick={() => handleClick1()}
                                  >
                                    {null ? "Add Photo" : "Upload Image"}
                                  </Button>
                                ) : (
                                  <span>
                                    <Button
                                      className="btn-round"
                                      onClick={() => handleClick1()}
                                    >
                                      Change
                                    </Button>
                                    {props.avatar ? <br /> : null}
                                    <Button
                                      color="danger"
                                      className="btn-round"
                                      onClick={() => handleRemove1()}
                                    >
                                      <i className="fa fa-times" />
                                      Remove
                                    </Button>
                                  </span>
                                )}
                              </div>
                            </div>
                          </FormGroup>
                        </Row>
                      </Col>
                    ) : null}
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      className={classes.normalButton}
                      simple
                      color="primary"
                      size="lg"
                      onClick={handleRegister}
                    >
                      {language.register}
                    </Button>
                    <Button
                      className={classes.normalButton}
                      simple
                      color="primary"
                      size="lg"
                      onClick={handleBack}
                    >
                      {language.back}
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
        <AlertDialog
          open={commonAlert.open}
          onClose={handleCommonAlertClose}
          title={"Alert"}
        >
          {commonAlert.msg}
        </AlertDialog>
      </div>
    </div>
  );
}
