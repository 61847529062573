import {
  FETCH_TREATMENT_TYPES,
  FETCH_TREATMENT_TYPES_SUCCESS,
  FETCH_TREATMENT_TYPES_FAILED,
  EDIT_TREATMENT_TYPE,
} from "../store/types";

const INITIAL_STATE = {
  tretmeant: null,
  loading: false,
  error: {
    flag: false,
    msg: null,
  },
};

export const treatmenttypesreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TREATMENT_TYPES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TREATMENT_TYPES_SUCCESS:
      return {
        ...state,
        treatment: action.payload,
        loading: false,
      };
    case FETCH_TREATMENT_TYPES_FAILED:
      return {
        ...state,
        treatment: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
      };
    case EDIT_TREATMENT_TYPE:
      return state;
    default:
      return state;
  }
};
