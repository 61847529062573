import {
  FETCH_ICDCODES,
  FETCH_ICDCODES_SUCCESS,
  FETCH_ICDCODES_FAILED,
} from "../store/types";

export const fetchCodes = () => (dispatch) => (firebase) => {

  const {
    codesRef
  } = firebase;

  dispatch({
    type: FETCH_ICDCODES,
    payload: null
  });
  codesRef.on("value", snapshot => {
    console.log("this is action",codesRef);
    if (snapshot.val()) {
      const data = snapshot.val();
      
      const arr = Object.keys(data)
      .filter(i => data[i].userType!='admin')
      .map(i => {
        data[i].id = i;
        return data[i];
      });
      dispatch({
        type: FETCH_ICDCODES_SUCCESS,
        payload: arr
      });
    } else {
      dispatch({
        type: FETCH_ICDCODES_FAILED,
        payload: "No icd 10 codes available."
      });
    }
  });
};

