/*eslint-disable*/
import React, { useContext, useEffect, useState } from "react";
// react component used to create a calendar with events on it
import { Calendar as BigCalendar, dateFnsLocalizer } from "react-big-calendar";
// dependency plugin for react-big-calendar

// import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import Email from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import ChatIcon from "@material-ui/icons/ChatBubbleOutlineRounded";
import { VideoCall } from "@material-ui/icons";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import DatePicker from "react-datetime";
import "react-datepicker/dist/react-datepicker.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CustomInput from "components/CustomInput/CustomInput.js";
import { FirebaseContext } from "common";
import { useSelector, useDispatch } from "react-redux";
import { FormControl, Select, MenuItem, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  CardTitle,
  CardHeader,
  Button,
  CardFooter,
  Label,
} from "reactstrap";
import { language } from "config";
const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const events = [
  {
    title: "Conference",
    start: new Date(2022, 2, 20),
    end: new Date(2022, 2, 23),
    serviceType: "",
    practiceType: "",
    description: "",
  },
];

export default function DoctorCalendar(props) {
  const { api } = useContext(FirebaseContext);
  const {
    getEstimate,
    clearEstimate,
    addBooking,
    clearBooking,
    MinutesPassed,
    GetDateString,
    fetchUser,
    updateBooking,
    cancelBooking,
  } = api;
  const dispatch = useDispatch();
  const practicetypes = useSelector((state) => state.practicetypes.practices);
  const estimatedata = useSelector((state) => state.estimatedata);
  const bookingdata = useSelector((state) => state.bookingdata);
  const userdata = useSelector((state) => state.usersdata);
  const settings = useSelector((state) => state.settingsdata.settings);
  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const [practiceType, setPracticeType] = useState(language.select_practice);
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropAddress, setDropAddress] = useState(null);
  const [estimateModalStatus, setEstimateModalStatus] = useState(false);
  const [selectedPracticeDetails, setSelectedPracticeDetails] = useState(null);
  const [users, setUsers] = useState(null);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [userCombo, setUserCombo] = useState(null);
  const [estimateRequested, setEstimateRequested] = useState(false);
  const [bookingType, setBookingType] = useState(" ");
  const [selectedDate, setSelectedDate] = useState(GetDateString());
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [bookingTypes, setBookingTypes] = useState("");
  const [practicesType, setPracticesType] = useState("");
  const [serviceTypes, setServiceTypes] = useState("");
  const [description, setDescription] = useState("");

  const [newEvent, setNewEvent] = useState({
    title: bookingTypes,
    start: new Date(),
    end: new Date(),
    service: serviceTypes,
    practice: practicesType,
    description: description,
  });
  let bookingData = newEvent;

  // console.log(bookingData);
  const [allEvents, setAllEvents] = useState(events);
  const [openFPModal, setOpenFPModal] = React.useState(false);
  const handleAddEvent = () => {
    setAllEvents([...allEvents, newEvent]);
    //todo dispatch create booking use newEvent as payload
    let bookingData = {
      //pickup: pickupAddress,
      //drop: dropAddress,
      title: newEvent.title,
      startDate: newEvent.start,
      endDate: newEvent.end,
      service: newEvent.service,
      practiceDetails: selectedPracticeDetails,
      //estimate: estimatedata.estimate,
      eventdate: new Date(selectedDate).toString(),
      bookLater: bookingType === "Book Later" ? true : false,
      settings: settings,
      booking_type_web: true,
    };
    // console.log(bookingData);
    dispatch(addBooking(bookingData));
  };

  const confirmBooking = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    let bookingObject = {
      pickup: pickupAddress,
      drop: dropAddress,
      practiceDetails: selectedPracticeDetails,
      userDetails: {
        uid: userCombo.uid,
        profile: {
          firstName: userCombo.firstName,
          lastName: userCombo.lastName,
          mobile: userCombo.mobile,
          pushToken: userCombo.pushToken,
        },
      },
      estimate: estimatedata.estimate,
      eventdate: new Date(selectedDate).toString(),
      bookLater: bookingType === "Book Later" ? true : false,
      settings: settings,
      booking_type_web: true,
    };
    dispatch(addBooking(bookingObject));
  };

  const handlePracSelect = (event) => {
    setPracticeType(event.target.value);
    let practiceDetails = null;
    for (let i = 0; i < practicetypes.length; i++) {
      if (practicetypes[i].name === event.target.value) {
        practiceDetails = practicetypes[i];
      }
    }
    setSelectedPracticeDetails(practiceDetails);
  };
  const onVideoSelect = (event) => {
    // console.log("Video Conference");
    navigate("/videoconference");
  };
  const onPhoneSelect = (event) => {
    console.log("Phone Call");
  };
  const onChatSelect = (event) => {
    console.log("Start Chatting");
  };

  const selectedEvent = (Booking) => {
    navigate("/bookinginformation", { state: { Booking } });
  };
  const handleCloseEvent = (e) => {
    e.preventDefault();
    setOpenFPModal(false);
  };

  const eventColors = (event, start, end, isSelected) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  };
  // useEffect(()=>{
  //   if(practicetypes.practices){
  //       let obj =  {};
  //       practicetypes.practices.map((practice)=> obj[practice.name]=practice.name)
  //       setPractice(obj);
  //   }
  // },[practicetypes.practices]);

  useEffect(() => {
    if (bookinglistdata.bookings) {
      const eventlist = bookinglistdata.bookings.map((bookings) => {
        return {
          ...bookings,
          title: bookings.title,
          start: new Date(bookings.endDate),
          end: new Date(bookings.eventDate),
          eventId: bookings.id,
          description: bookings.description,
          service: bookings.serviceType,
          practice: bookings.practiceType,
          status: bookings.status,
          doctorname: bookings.doctor ? bookings.doctor.firstName : " ",
          doctoremail: bookings.doctor ? bookings.doctor.email : " ",
          doctormobile: bookings.doctor ? bookings.doctor.mobile : " ",
        };
      });
      const dots = bookinglistdata.bookings.map((bookings) => {
        return {
          [bookings.markedDot.date]: {
            selected: true,
            selectedColor: bookings.markedDot.dots[0].selectedDotColor,
          },
        };
      });
      console.log("this is the dots: ", dots);

      setAllEvents(eventlist);
    }
    if (openFPModal !== false) {
      setOpenFPModal(true);
    } else {
      setNewEvent(...allEvents, newEvent);
    }
    console.log("bookings w d: ", auth);
  }, [bookinglistdata]);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <BigCalendar
                  selectable
                  localizer={localizer}
                  events={allEvents}
                  step={15}
                  timeslots={1}
                  startAccessor="start"
                  endAccessor="end"
                  // style={{ height: 500, margin: "50px" }}
                  defaultView="agenda"
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  defaultDate={new Date()}
                  onSelectEvent={(event) => selectedEvent(event)}
                  // onSelectSlot={(slotInfo) => openFPModal(slotInfo)}
                  // eventPropGetter={eventColors}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

// export default Calendar;
