/*eslint-disable*/
import CircularLoading from "../../../components/CircularLoading";
import { Select } from "@material-ui/core";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  FormText,
  Row,
  Col,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "common";
import CustomInput from "components/CustomInput/CustomInput";
import MenuItem from "@material-ui/core/MenuItem";
import AlertDialog from "../../../components/AlertDialog";
function PatientChildhood({ route, navigation, props }) {
  const { api } = useContext(FirebaseContext);
  const { editMedicalHistory } = api;
  const dispatch = useDispatch();
  const medical = useSelector(
    (state) => state.medicalRecords.medicalHistoryRecords
  );
  const auth = useSelector((state) => state.auth);
  const [medicalData, setMedicalData] = useState(medical);

  //Childhood illensises
  const [Measles, setMeasles] = useState(false);
  const [Mumps, setMumps] = useState(false);
  const [Rubellac, setRubellac] = useState(false);
  const [Chickenpox, setChickenpox] = useState(false);
  const [Rheumatic, setRheumatic] = useState(false);
  const [Polio, setPolio] = useState(false);
  const [All_the_Above, setAll_the_Above] = useState(false);
  const [None, setNone] = useState(false);
  //////////////////////////////////////////////////////////////
  //Immunisation and dates
  const [Tetanus, setTetanus] = useState({
    checked: false,
    Date: null,
  });
  const [Pneumonia, setPneumonia] = useState({
    checked: false,
    Date: null,
  });
  const [Hepatitis_A, setHepatitis_A] = useState({
    checked: false,
    Date: null,
  });
  const [Hepatitis_B, setHepatitis_B] = useState({
    checked: false,
    Date: null,
  });
  const [Chickenpox2, setChickenpox2] = useState({
    checked: false,
    Date: null,
  });
  const [influenza, setinfluenza] = useState({
    checked: false,
    Date: null,
  });
  const [MMR, setMMR] = useState({
    checked: false,
    Date: null,
  });
  const [Meningococcal, setMeningococcal] = useState({
    checked: false,
    Date: null,
  });
  const [None2, setNone2] = useState({
    checked: false,
  });
  ///////////////////////////////////////////////////////////////
  //Surgies
  const [Surgry1st, setSurgry1st] = useState({
    YearOfSurgry: " ",
    ReasonOfSurgry: " ",
    HospitalOfSurgry: " ",
  });
  const [Surgry2nd, setSurgry2nd] = useState({
    YearOfSurgry: " ",
    ReasonOfSurgry: " ",
    HospitalOfSurgry: " ",
  });
  const [Surgry3rd, setSurgry3rd] = useState({
    YearOfSurgry: " ",
    ReasonOfSurgry: " ",
    HospitalOfSurgry: " ",
  });
  const [NoSurgeries, setNoSurgeries] = useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };
  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setMedicalData(medical);
    }
  }, [medical]);

  const OnSave = () => {
    let payload = {
      Medical_Information: {
        ...medical,

        Childhood_illness: medicalData?.Childhood_illness
          ? medicalData?.Childhood_illness
          : null,
      },
    };
    console.log("this is child payload:", payload);
    dispatch(editMedicalHistory(payload, "Add medical history"));
    setCommonAlert({
      open: true,
      msg: "Your ChildHood illness Has Been Saved",
    });
  };
  return auth.loading ? (
    <CircularLoading />
  ) : (
    <>
      <div className="content">
        <div className="medicalhistory-form">
          <Row>
            <Col md="12">
              <AlertDialog
                open={commonAlert.open}
                onClose={handleCommonAlertClose}
              >
                {commonAlert.msg}
              </AlertDialog>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Childhood Illness</CardTitle>
                </CardHeader>
                <CardBody>
                  <p>Childhood Illness:</p>
                  <Row>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Childhood_illness: {
                                  ...medicalData.Childhood_illness,
                                  Measles: event.target.checked,
                                },
                              });
                            }}
                            checked={
                              medicalData?.Childhood_illness?.All_the_Above ||
                              medicalData?.Childhood_illness?.Measles
                            }
                          />
                          <span className="form-check-sign" />
                          Measles
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Childhood_illness: {
                                  ...medicalData.Childhood_illness,
                                  Mumps: event.target.checked,
                                },
                              });
                            }}
                            checked={
                              medicalData?.Childhood_illness?.All_the_Above ||
                              medicalData?.Childhood_illness?.Mumps
                            }
                          />
                          <span className="form-check-sign" />
                          Mumps
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Childhood_illness: {
                                  ...medicalData.Childhood_illness,
                                  Rubellac: event.target.checked,
                                },
                              });
                            }}
                            checked={
                              medicalData?.Childhood_illness?.All_the_Above ||
                              medicalData?.Childhood_illness?.Rubellac
                            }
                          />
                          <span className="form-check-sign" />
                          Rubellac
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Childhood_illness: {
                                  ...medicalData.Childhood_illness,
                                  Chickenpox: event.target.checked,
                                },
                              });
                            }}
                            checked={
                              medicalData?.Childhood_illness?.All_the_Above ||
                              medicalData?.Childhood_illness?.Chickenpox
                            }
                          />
                          <span className="form-check-sign" />
                          Chickenpox
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Childhood_illness: {
                                  ...medicalData.Childhood_illness,
                                  Rheumatic: event.target.checked,
                                },
                              });
                            }}
                            checked={
                              medicalData?.Childhood_illness?.All_the_Above ||
                              medicalData?.Childhood_illness?.Rheumatic
                            }
                          />
                          <span className="form-check-sign" />
                          Rheumatic Fever
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            onChange={(event) => {
                              setMedicalData({
                                ...medicalData,
                                Childhood_illness: {
                                  ...medicalData.Childhood_illness,
                                  Polio: event.target.checked,
                                },
                              });
                            }}
                            checked={
                              medicalData?.Childhood_illness?.All_the_Above ||
                              medicalData?.Childhood_illness?.Polio
                            }
                          />
                          <span className="form-check-sign" />
                          Polio
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          {/* to do */}
                          <Input
                            type="checkbox"
                            onChange={() => {
                              if (!All_the_Above) {
                                setMeasles(true);
                                setMumps(true);
                                setAll_the_Above(!All_the_Above);
                                setPolio(true);
                                setRheumatic(true);
                                setRubellac(true);
                                setChickenpox(true);
                                setNone(false);
                              }
                              if (All_the_Above) {
                                setMeasles(false);
                                setMumps(false);
                                setAll_the_Above(!All_the_Above);
                                setPolio(false);
                                setRheumatic(false);
                                setRubellac(false);
                                setChickenpox(false);
                              }
                            }}
                            checked={
                              medicalData?.Childhood_illness?.All_the_Above
                            }
                          />
                          <span className="form-check-sign" />
                          All of the Above
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check>
                          {/* to do */}
                          <Input
                            type="checkbox"
                            onChange={() => {
                              setNone(!None);
                              setMeasles(false);
                              setMumps(false);
                              setAll_the_Above(false);
                              setPolio(false);
                              setRheumatic(false);
                              setRubellac(false);
                              setChickenpox(false);
                            }}
                            checked={medicalData?.Childhood_illness?.None}
                          />
                          <span className="form-check-sign" />
                          None of the Above
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Col>
                    <Button variant="gradient" color="primary" onClick={OnSave}>
                      SAVE
                    </Button>
                  </Col>
                </CardBody>
              </Card>
              {/* </CardBody>
                              
              </Card> */}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default PatientChildhood;
