/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import { Button, Typography, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from "../../../components/AlertDialog";
import { language } from "config";
import { makeStyles } from "@material-ui/core/styles";
import UsersCombo from "../../../components/UsersCombo";
import { FirebaseContext } from "common";
import Grid from "@mui/material/Grid";
import DashboardCard from "../../../components/DashboardCard";
// import PaymentWebView from '../../../components/';

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    alignContent: "center",
  },
  title: {
    color: "#000",
  },
  gridcontainer: {
    alignContent: "center",
  },
  items: {
    margin: 0,
    width: "100%",
  },
  input: {
    fontSize: 18,
    color: "#000",
  },
  inputdimmed: {
    fontSize: 18,
    color: "#737373",
  },
  carphoto: {
    height: "18px",
    marginRight: "10px",
  },
  buttonStyle: {
    margin: 0,
    width: "100%",
    height: "100%",
  },
}));

export default function PatientWallet(props) {
  const { api } = useContext(FirebaseContext);
  const { updateWalletBalance, clearMessage, updateBooking, addToWallet } = api;
  const auth = useSelector((state) => state.auth);
  const settings = useSelector((state) => state.settingsdata.settings);
  const providers = useSelector((state) => state.paymentmethods.providers);

  const dispatch = useDispatch();
  const classes = useStyles();
  const userdata = useSelector((state) => state.usersdata);
  const [users, setUsers] = useState(null);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [userCombo, setUserCombo] = useState(null);
  const [amount, setAmount] = useState(0);

  // const icons = {
  //   'paypal':require('../../../assets/img/paypal-logo.png'),
  //   'stripe':require('../../../assets/img/stripe-logo.png'),

  // }

  // const doReacharge = () => {
  //   if (providers) {
  //     props.navigation.push('addMoney', { userdata: auth.info.profile, providers: providers });
  //   } else {
  //     commonAlert(language.alert,language.provider_not_found)
  //   }
  // }

  // const doWithdraw = () => {
  //   if (auth.info.profile.walletBalance>0) {
  //     props.navigation.push('withdrawMoney', { userdata: auth.info.profile });
  //   } else {
  //     commonAlert(language.alert,language.wallet_zero)
  //   }
  // }

  useEffect(() => {
    if (userdata.users) {
      let arr = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        arr.push({
          firstName: user.firstName,
          lastName: user.lastName,
          mobile: user.mobile,
          email: user.email,
          uid: user.id,
          desc:
            user.firstName +
            " " +
            user.lastName +
            " (" +
            user.mobile +
            ") " +
            user.email,
          pushToken: user.pushToken,
        });
      }
      setUsers(arr);
    }
    console.log("checking user info", userdata);
  }, [userdata.users]);

  const handleAddBalance = () => {
    if (userCombo && userCombo.uid && amount > 0) {
      dispatch(addToWallet(userCombo.uid, amount));
      setCommonAlert({ open: true, msg: language.success });
    } else {
      setCommonAlert({ open: true, msg: language.no_details_error });
    }
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  return (
    <div className={classes.container}>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography component="h1" variant="h5" className={classes.title}>
              {"My Balance"}
              <DashboardCard image={require("../../../assets/img/money2.jpg")}>
                {settings.symbol + " "}
              </DashboardCard>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography component="h1" variant="h5" className={classes.title}>
              {language.add_to_wallet}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {users ? (
              <UsersCombo
                className={classes.items}
                placeholder="Select Providers"
                users={users}
                value={userCombo}
                onChange={(event, newValue) => {
                  setUserCombo(newValue);
                }}
              />
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="datetime-local"
              label={language.amount}
              type="text"
              variant="outlined"
              fullWidth
              className={classes.commonInputStyle}
              InputProps={{
                className: classes.input,
              }}
              value={amount}
              onChange={(event) => {
                const { value } = event.target;
                setAmount(
                  value === "" || value === null || value === undefined
                    ? 0
                    : parseFloat(value)
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              size="large"
              onClick={handleAddBalance}
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
            >
              {language.add_to_wallet}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
}
