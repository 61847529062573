import {
  CREATE_OPENFIREUSER,
  CREATE_OPENFIREUSER_SUCCESS,
  CREATE_OPENFIREUSER_FAIL,
  CREATE_DOCTORROOM,
  CREATE_DOCTORROOM_SUCCESS,
  CREATE_DOCTORROOM_FAIL,
} from "../store/types";

export const createOpenfireUser =
  (username, password,firstName, lastName,email, openfireuser) => (dispatch) => (firebase) => {
    const { openfireuserRef } = firebase;

    dispatch({
      type: CREATE_OPENFIREUSER,
      payload: openfireuser,
    });

    try {
      console.log("open fire registrations : ", openfireuser);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Basic YWRtaW46czd0MTFkYg==");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        "/JSESSIONID=[^;]+/"
      );

      var raw = JSON.stringify({
        username: username,
        password: password,
        name: firstName+lastName,
        email: email
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://dev.okdoc.co.za:9091/plugins/restapi/v1/users",
        requestOptions
      )
        .then((response) => {
          console.log("i am the response", response);
          dispatch({
            type: CREATE_OPENFIREUSER_SUCCESS,
            payload: response,
          });
        })
        .then((result) => console.log("this is created user results: ", result))
        .catch((error) => console.log("error", error));
    } catch (err) {
      dispatch({
        type: CREATE_OPENFIREUSER_FAIL,

        payload: err,
      });
      console.log("fail", err);
    }
  };

export const createDoctorRoom =
  (username, lastName, doctorsroom) => (dispatch) => (firebase) => {
    dispatch({
      type: CREATE_DOCTORROOM,
      payload: doctorsroom,
    });

    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Basic YWRtaW46czd0MTFkYg==");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "JSESSIONID=node0jm79v4bedlrg1haq97xj96794147.node0");
      
      var raw = JSON.stringify({
        "name": username,
        "description": lastName
      });
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
      fetch("https://dev.okdoc.co.za:9091/plugins/restapi/v1/groups", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    } catch (err) {
      dispatch({
        type: CREATE_DOCTORROOM_FAIL,

        payload: err,
      });
      console.log("fail", err);
    }
  };
