/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  ListItemIcon,
  Divider,
  Menu,
  MenuList,
  MenuItem,
  RadioGroup,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { Collapse, Card, CardTitle, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import HomeIcon from "@material-ui/icons/Home";
// import ListIcon from "@material-ui/icons/Home";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CarIcon from "@material-ui/icons/DirectionsCar";
import ListIcon from "@material-ui/icons/ListAlt";

import ExitIcon from "@material-ui/icons/ExitToApp";
import OfferIcon from "@material-ui/icons/LocalOffer";
import PeopleIcon from "@material-ui/icons/People";
import MoneyIcon from "@material-ui/icons/AttachMoney";
import NotifyIcon from "@material-ui/icons/NotificationsActive";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import Calendar from "@material-ui/icons/CalendarTodayOutlined";

// import  Setting  from '@material-ui/icons/Settings';

import { language, features } from "config";
import assets from "assets";
import { FirebaseContext } from "common";

const useStyles = makeStyles((theme) => ({
  rootOne: {
    background: "#6b7a83",
  },
  rootTwo: {
    background: "#6b7a83",
    textAlign: "center",
    alignItems: "flex-start",
  },
}));
function ConsultationMenu() {
  const { api } = useContext(FirebaseContext);
  const { signOut } = api;
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const LogOut = () => {
    dispatch(signOut());
    navigate("/");
  };
  let isNoPhonePatient =
    auth.info &&
    auth.info.profile &&
    auth.info.profile.userType === "nophonepatients";
  let isPatient =
    auth.info && auth.info.profile && auth.info.profile.userType === "patient";
  let isAdmin =
    auth.info && auth.info.profile && auth.info.profile.userType === "admin";
  let isPracticeAdmin =
    auth.info &&
    auth.info.profile &&
    auth.info.profile.userType === "practiceadmin";
  let isDoctor =
    auth.info && auth.info.profile && auth.info.profile.userType === "doctor";
  let isNurse =
    auth.info && auth.info.profile && auth.info.profile.userType === "nurse";
  let isIndependentDoctor =
    auth.info &&
    auth.info.profile &&
    !auth.info.profile.privatePractice &&
    auth.info.profile.userType === "doctor";
  let isIndependentNurse =
    auth.info &&
    auth.info.profile &&
    !auth.info.profile.privatePractice &&
    auth.info.profile.userType === "nurse";

  //Collapse for menus
  const classes = useStyles();
  const [openedCollapses, setOpenCollapses] = React.useState([]);
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  const collapsesToggle = (collapse) => {
    if (openedCollapses.includes(collapse)) {
      setOpenCollapses([]);
    } else {
      setOpenCollapses([collapse]);
    }
  };

  return (
    <div
      aria-multiselectable={true}
      className="card-collapse"
      id="accordion"
      role="tablist"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#6b7a83",
        }}
      >
        <img
          style={{
            marginTop: "17px",
            marginBottom: "17px",
            width: "120px",
            height: "120px",
          }}
          src={assets.logo192x192}
          alt="Logo"
        />
      </div>
      <Divider />

      <MenuList>
       
        <MenuItem component={Link} to="/observations">
          <ListItemIcon>
            <ListIcon style={{ color: "#ffffff" }} />
          </ListItemIcon>
          <Typography variant="inherit">{"Observations"}</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/prescriptions">
          <ListItemIcon>
            <ListIcon style={{ color: "#ffffff" }} />
          </ListItemIcon>
          <Typography variant="inherit">{"Prescriptions"}</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/drugexposure">
          <ListItemIcon>
            <ListIcon style={{ color: "#ffffff" }} />
          </ListItemIcon>
          <Typography variant="inherit">{"Drug Exposure"}</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/procedures">
          <ListItemIcon>
            <ListIcon style={{ color: "#ffffff" }} />
          </ListItemIcon>
          <Typography variant="inherit">{"Procedures"}</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/deviceexposure">
          <ListItemIcon>
            <ListIcon style={{ color: "#ffffff" }} />
          </ListItemIcon>
          <Typography variant="inherit">{"Device Exposure"}</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/notes">
          <ListItemIcon>
            <ListIcon style={{ color: "#ffffff" }} />
          </ListItemIcon>
          <Typography variant="inherit">{"Notes"}</Typography>
        </MenuItem>
      </MenuList>
    </div>
  );
}

export default ConsultationMenu;
