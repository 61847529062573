/*eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import "react-chat-elements/dist/main.css";
import { MessageList, MessageBox, ChatList } from "react-chat-elements";
import image from "../assets/img/mike.jpg";
import { Image } from "@material-ui/icons";
import { Col, Row, Input, Button } from "reactstrap";
import PhoneIcon from "@material-ui/icons/Phone";
import ChatIcon from "@material-ui/icons/ChatBubbleOutlineRounded";
import { VideoCall } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FirebaseContext } from "common";
import { useSelector, useDispatch } from "react-redux";
import { language } from "config";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ImageUpload from "components/CustomUpload/ImageUpload";
import { firebase } from "common";
import { useNavigate } from "react-router-dom";
let clearRef = () => {};
export default function Chats(booking, props) {
  //Redux
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const { api } = useContext(FirebaseContext);
  const {
    fetchChatMessages,
    sendMessage,
    stopFetchMessages,
    SavePictureMessage,
  } = api;
  const dispatch = useDispatch();
  const Booking = booking
    ? booking.booking
    : props.location && props.location.state.Booking;
  // const role = useSelector((state) => state.auth.info.profile.usertype);
  const myChats = useSelector((state) => state.chatdata);
  const auth = useSelector((state) => state.auth);
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const messageListRef = useRef();
  const navigate = useNavigate();
  //UseStates
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [inputMessage, setInputMessage] = useState({
    position: "right",
    type: "text",
    text: " ",
    date: new Date(),
  });
  const [picture, setpicture] = useState(null);
  const [myArray, setmyArray] = useState([]);
  const [Firsttime, setFirsttime] = useState(true);
  const [Replyinfo, setReplyinfo] = useState(null);
  const fileInput = React.useRef();
  const [fileUploaded, setFileUploaded] = React.useState(null);
  const [handleFile, setHandleFile] = useState([]);
  const [openFPModal, setOpenFPModal] = React.useState(false);
  //useEffect
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    console.log("i am the booking id", Booking);
    setmyArray([]);
    //console.log(auth.info);
    if (myChats.messages) {
      myChats.messages.map((sweetItem) => {
        //console.log(sweetItem.from);
        if (sweetItem.from === auth.info.uid) {
          sweetItem.message.position = "right";
        } else {
          sweetItem.message.position = "left";
        }
        setmyArray((oldMessagesData) => [
          ...oldMessagesData,
          sweetItem.message,
        ]);
      });
    }
    console.log("i am the array", myArray);
  }, [myChats, Replyinfo, Booking, myArray, auth.info.uid]);
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const allChat = useSelector((state) => state.chatdata.messages);

  const [myImageArray, setmyImageArray] = useState([]);
  const [fileState, setFileState] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(props.avatar);
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    // console.log("the reader", reader);
    let file = e.target.files[0];
    // console.log("the file", file);
    reader.onloadend = () => {
      setFileState(file);
      console.log("the fileState results ", reader.result);
      setImagePreviewUrl(reader.result);
      setpicture(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      console.log("We made it here:", file);
    }
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    fileInput.current.value = null;
    setFileState(null);
    setImagePreviewUrl(props.avatar);
  };

  const [imageUpload, setimageUpload] = useState({
    type: "photo",
    text: "",
    data: {},
    onDownload: null,
    onOpen: null,
    onLoad: null,
    onPhotoError: null,
  });
  const SendMsg = (mType) => {
    if (mType === "text") {
      if (
        inputMessage == " " ||
        inputMessage == undefined ||
        inputMessage == null
      ) {
        // console.log("message not sent");
      } else {
        let message = {
          reply: Replyinfo,
          forwarded: true,
          replyButton: true,
          removeButton: true,
          retracted: false,
          position: inputMessage.position,
          type: inputMessage.type,
          text: inputMessage.text,
          date: inputMessage.date,
          title: auth.info.profile.firstName + " " + auth.info.profile.lastName,
        };
        dispatch(
          sendMessage({
            message,

            booking: {
              id: Booking.id,
              customer: Booking.patientuid,
              doctor: Booking.doctor.uid,
            },
            role: "patient",
          })
        );

        setInputMessage({
          ...inputMessage,
          text: " ",
        });
      }
    } else if (mType === "picture") {
      // e.preventDefault();
      setOpenFPModal(false);
      dispatch(
        SavePictureMessage(
          {
            booking: {
              id: "-MyJYOljiIiqMAcCREdr",
              customer: "b21jxpsTc1YltGR5Zbya5pDJ0nt2",
              doctor: "NF3xedA0WyYZbNJZ3zZD8FvkzUi2",
            },
            role: "patient",
          },
          picture
        )
      );
    }
  };

  const handleCloseEvent = (e) => {
    e.preventDefault();
    setOpenFPModal(false);
  };

  const handleChange = (event) => {
    setOpenFPModal(true);
  };

  const onFileChange = (fileUploaded) => {
    setFileUploaded({
      inputFile: fileUploaded,
    });
  };
  const onVideoSelect = (event) => {
    // console.log("Video Conference");
    navigate("/videoconference");
  };

  return (
    <>
      <div>
        <Row>
          <div align="left" style={{ width: "100%" }}>
            {/* <h2>Medical Chat</h2> */}

            <div align="right" style={{ width: "70%", height: "100%" }}>
              <body>
                <div style={{ height: "100%" }}>
                  <MessageList
                    referance={messageListRef}
                    className="message-list"
                    onReplyClick={(message) => {
                      setReplyinfo({
                        titleColor: "#8717ae",
                        title: message.title,
                        message: message.text,
                      });
                    }}
                    lockable={true}
                    toBottomHeight={"100%"}
                    dataSource={myArray}
                  />
                </div>
              </body>

              <footer>
                <Row>
                  <Col md="8">
                    <div style={{ width: "100%" }}>
                      <Input
                        ref={fileInput}
                        type="textarea"
                        value={inputMessage.text}
                        clear={(clear) => (clearRef = clear)}
                        placeholder={language.chat_input_title}
                        onChange={(text) => {
                          setInputMessage({
                            ...inputMessage,
                            text: text.target.value,
                          });
                        }}
                        rows="2"
                      />
                    </div>
                  </Col>

                  <Col md="4">
                    <Image onClick={handleChange} />
                    <VideoCall onClick={onVideoSelect} />
                    <Dialog
                      open={openFPModal}
                      onClose={handleCloseEvent}
                      aria-labelledby="form-dialog-title"
                      maxWidth="lg"
                    >
                      <DialogContent>
                        <DialogContentText>
                          <div className="fileinput text-center">
                            <input
                              type="file"
                              onChange={handleImageChange}
                              ref={fileInput}
                            />
                            {/* <div className={"thumbnail" + props.avatar}>
                              {/* <img src={imagePreviewUrl} /> */}
                            {/* </div> */}
                            <div>
                              {fileState === null ? (
                                <Button
                                  className="btn-round"
                                  onClick={() => handleClick()}
                                >
                                  {null ? "Add Photo" : "Send image"}
                                </Button>
                              ) : (
                                <span>
                                  <div style={{ width: "100%" }}>
                                    <Input
                                      ref={fileInput}
                                      type="textarea"
                                      value={inputMessage.text}
                                      clear={(clear) => (clearRef = clear)}
                                      placeholder={language.chat_input_title}
                                      onChange={(text) => {
                                        setInputMessage({
                                          ...inputMessage,
                                          text: text.target.value,
                                        });
                                      }}
                                      rows="2"
                                    />
                                  </div>
                                  <Button
                                    className="btn-round"
                                    onClick={() => handleClick()}
                                  >
                                    Change
                                  </Button>
                                  {props.avatar ? <br /> : null}
                                  <Button
                                    color="danger"
                                    className="btn-round"
                                    onClick={() => handleRemove()}
                                  >
                                    <i className="fa fa-times" />
                                    Remove
                                  </Button>
                                </span>
                              )}
                            </div>
                          </div>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            SendMsg("picture");
                          }}
                          color="primary"
                        >
                          {"Save"}
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Button
                      fullWidth
                      variant="contained"
                      color="danger"
                      onClick={() => SendMsg("text")}
                    >
                      SEND
                    </Button>
                  </Col>
                </Row>
              </footer>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
}
