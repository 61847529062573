/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import {
  Select,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import AlertDialog from "../components/AlertDialog";
import { language } from "config";
import { makeStyles } from "@material-ui/core/styles";
import UsersCombo from "../components/UsersCombo";
import { FirebaseContext } from "common";
import { Calendar as BigCalendar, dateFnsLocalizer } from "react-big-calendar";
import PhoneIcon from "@material-ui/icons/Phone";
import ChatIcon from "@material-ui/icons/ChatBubbleOutlineRounded";
import { VideoCall } from "@material-ui/icons";
import { AddBoxRounded } from "@material-ui/icons";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CustomInput from "components/CustomInput/CustomInput.js";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Label, CardBody, Card } from "reactstrap";
import AuthLoading from "./AuthLoading";
const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    alignContent: "center",
  },
  title: {
    color: "#000",
  },
  gridcontainer: {
    alignContent: "center",
  },
  items: {
    margin: 0,
    width: "100%",
  },
  input: {
    fontSize: 18,
    color: "#000",
  },
  inputdimmed: {
    fontSize: 18,
    color: "#737373",
  },
  practicephoto: {
    height: "18px",
    marginRight: "10px",
  },
  buttonStyle: {
    margin: 0,
    width: "100%",
    height: "100%",
  },
}));

export default function AddBookings(props) {
  const { api } = useContext(FirebaseContext);
  const {
    getEstimate,
    clearEstimate,
    addBooking,
    clearBooking,
    MinutesPassed,
    GetDateString,
    fetchUser,
    fetchUsers,
    updateBooking,
    cancelBooking,
    fetchBookings,
  } = api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const medical = useSelector(
    (state) => state.medicalRecords.medicalHistoryRecords
  );

  const doctortypes = useSelector((state) => state.doctortypes.doctortypes);
  const specialitytypes = useSelector(
    (state) => state.specialitytypes.specialities
  );
  const practicetypes = useSelector((state) => state.practicetypes.practices);
  const estimatedata = useSelector((state) => state.estimatedata);
  const bookingdata = useSelector((state) => state.bookingdata);
  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const userdata = useSelector((state) => state.usersdata);
  const settings = useSelector((state) => state.settingsdata.settings);
  const [practiceType, setPracticeType] = useState("");
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropAddress, setDropAddress] = useState(null);
  const [estimateRequested, setEstimateRequested] = useState(false);
  const [estimateModalStatus, setEstimateModalStatus] = useState(false);
  const [selectedPracticeDetails, setSelectedPracticeDetails] = useState(null);
  const [users, setUsers] = useState(null);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [userCombo, setUserCombo] = useState(null);
  const [specialityType, setSpecialityType] = useState("");
  const [bookingType, setBookingType] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(GetDateString());
  const [selectedEndDate, setSelectedEndDate] = useState(GetDateString());
  const [startDates, setStartDate] = useState(GetDateString());
  const [endDates, setEndDate] = useState(GetDateString());
  const [doctorname, setDoctorName] = useState("");
  const [doctoremail, setDoctoremail] = useState("");
  const [doctormobile, setDoctormobile] = useState("");
  const [titles, setTitles] = useState("");
  const [practicesType, setPracticesType] = useState("");
  const [serviceTypes, setServiceTypes] = useState("");
  const [description, setDescription] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState("");
  const [status, setStatus] = useState("");
  const classes = useStyles();
  const navigate = useNavigate();

  const [newEvent, setNewEvent] = useState({
    title: titles,
    startDate: selectedStartDate,
    endDate: selectedEndDate,
    service: serviceTypes,
    practice: practicesType,
    description: description,
  });

  const [allEvents, setAllEvents] = useState([]);
  const [openFPModal, setOpenFPModal] = React.useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);

  const onVideoSelect = (event) => {
    navigate("/videoconference");
  };
  const onPhoneSelect = (event) => {
    console.log("Phone Call");
  };
  const onChatSelect = (event) => {
    console.log("Start Chatting");
  };

  const selectedEvent = (event) => {
    setOpenFPModal(true);
    console.log("Event : ", event.practice.name);
    console.log("Event : ", event.status);
    setTitles(event.title);
    setPracticesType(event.practice.name);
    setDescription(event.description);
    setStartDate(event.start);
    setEndDate(event.end);
    setServiceTypes(event.service);
    setDoctorName(event.doctorname);
    setDoctoremail(event.doctoremail);
    setDoctormobile(event.doctormobile);
    setStatus(event.status);
  };
  const handleCloseEvent = (e) => {
    e.preventDefault();
    setOpenFPModal(false);
  };
  const selectedAdd = (event) => {
    setOpenAddModal(true);
  };
  const handleCloseAdd = (e) => {
    e.preventDefault();
    setOpenAddModal(false);
  };

  const handleSpecialitySelect = (event) => {
    setSpecialityType(event.target.value);
    console.log(practicetypes)
    let practiceDetails = null;
    for (let i = 0; i < practicetypes.length; i++) {
      if (practicetypes[i].specialityType === event.target.value) {
        practiceDetails = practicetypes[i];
      }
    }
    if (!practiceDetails) {
      practiceDetails = practicetypes.find(
        (x) => x.specialityType === "Default"
      );
    }

    setSelectedPracticeDetails(practiceDetails);
  };

  const handleBookTypeSelect = (event) => {
    setBookingType(event.target.value);
    if (bookingType === "Book Later") {
      setSelectedStartDate(GetDateString());
      setSelectedEndDate(GetDateString());
    }
  };

  const onDateStartChange = (event) => {
    setSelectedStartDate(event.target.value);
  };
  const onDateEndChange = (event) => {
    setSelectedEndDate(event.target.value);
  };

  useEffect(() => {
    if (userdata.users.length === 0) {
      dispatch(fetchUsers());
    }
  });

  useEffect(() => {
    if (estimatedata.estimate && estimateRequested) {
      setEstimateRequested(false);
      setEstimateModalStatus(true);
      // handleGetEstimate();
    }

    if (auth.info.profile) {
      let arr = [];

      if (auth.info.profile.userType === "patient") {
        arr.push({
          firstName: auth.info.profile.firstName,
          lastName: auth.info.profile.lastName,
          mobile: auth.info.profile.mobile,
          email: auth.info.profile.email,
          uid: auth.info.profile.id,

          desc:
            auth.info.profile.firstName +
            " " +
            auth.info.profile.lastName +
            " (" +
            auth.info.profile.mobile +
            ") " +
            auth.info.profile.email,
          pushToken: auth.info.profile.pushToken,
        });
      }

      setUsers(arr);
      // console.log("UserData", arr);
    }
  }, [estimatedata.estimate, auth.info.profile, estimateRequested]);

  useEffect(() => {
    if (bookinglistdata.bookings) {
      const eventlist = bookinglistdata.bookings.map((bookings) => {
        return {
          title: bookings.title,
          start: new Date(bookings.endDate),
          end: new Date(bookings.eventDate),
          eventId: bookings.id,
          description: bookings.description,
          service: bookings.serviceType,
          practice: bookings.practiceType,
          status: bookings.status,
          doctorname: bookings.doctor ? bookings.doctor.firstName : " ",
          doctoremail: bookings.doctor ? bookings.doctor.email : " ",
          doctormobile: bookings.doctor ? bookings.doctor.mobile : " ",
        };
      });
      const dots = bookinglistdata.bookings.map((bookings) => {
        return {
          [bookings.markedDot.date]: {
            selected: true,
            selectedColor: bookings.markedDot.dots[0].selectedDotColor,
          },
        };
      });
      console.log("this is the dots: ", dots);

      setAllEvents(eventlist);
    }
    if (openFPModal !== false) {
      setOpenFPModal(true);
    } else {
      setNewEvent(...allEvents, newEvent);
    }
    // console.log("bookings w d: ", bookinglistdata.bookings);
  }, [bookinglistdata]);

  const handleGetEstimate = (e) => {
    e.preventDefault();
    console.log(newEvent.service);
    console.log(selectedPracticeDetails);
    console.table(pickupAddress);
    console.table(dropAddress);
    console.log("estimate details", estimatedata);

    setEstimateRequested(true);
    if (userCombo && selectedPracticeDetails && newEvent.service) {
      if (newEvent.title) {
        if (newEvent.description) {
          if (bookingType === "Book Now") {
            dispatch(
              getEstimate({
                pickup: pickupAddress,
                drop: dropAddress,
                practiceDetails: selectedPracticeDetails,
                serviceType: newEvent.service,
              })
            );
          } else {
            if (
              bookingType === "Book Later" &&
              selectedStartDate &&
              newEvent.service
            ) {
              if (MinutesPassed(selectedStartDate) >= 15) {
                console.log({
                  pickup: pickupAddress,
                  drop: dropAddress,
                  practiceDetails: selectedPracticeDetails,
                  serviceType: newEvent.service,
                });
                dispatch(
                  getEstimate({
                    pickup: pickupAddress,
                    drop: dropAddress,
                    practiceDetails: selectedPracticeDetails,
                    serviceType: newEvent.service,
                  })
                );
                //console.table("got estimate", estimatedata);
              } else {
                setCommonAlert({
                  open: true,
                  msg: language.past_booking_error,
                });
              }
            } else {
              setCommonAlert({ open: true, msg: language.select_proper });
            }
          }
        } else {
          setCommonAlert({
            open: true,
            msg: "Please give a Booking Description",
          });
        }
      } else {
        setCommonAlert({
          open: true,
          msg: "Please give a Booking Name eg: Heart Pains",
        });
      }
    } else {
      setCommonAlert({ open: true, msg: language.select_proper });
    }
  };

  const confirmBooking = (e) => {
    e.preventDefault();

    setEstimateModalStatus(false);
    let bookingObject = {
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      pickup: pickupAddress,
      drop: dropAddress,

      status: "NEW",
      doctor: "",
      nurse: "",
      practiceDetails: selectedPracticeDetails,
      userDetails: auth.info,
      Medical_Information: medical,
      estimate: estimatedata.estimate,
      eventdate: selectedStartDate,
      bookLater: bookingType === "Book Later" ? true : false,
      settings: settings,
      serviceType: newEvent.service,
      bookingName: newEvent.title,
      specialityType: specialityType,
      description: newEvent.description,
      booking_type_web: true,

      markedDot: {
        date: `${moment(selectedStartDate).format("YYYY")}-${moment(
          selectedStartDate
        ).format("MM")}-${moment(selectedStartDate).format("DD")}`,
        dots: [
          {
            key: uuidv4(),
            color: "#ff0000",
            selectedDotColor: "#ff0000",
          },
        ],
      },
    };
    console.log(bookingObject);
    dispatch(addBooking(bookingObject));
  };

  const handleEstimateModalClose = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    dispatch(clearEstimate());
    setEstimateRequested(false);
  };

  const handleEstimateErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearEstimate());
    setEstimateRequested(false);
  };

  const handleBookingAlertClose = (e) => {
    e.preventDefault();
    setOpenAddModal(false);
    setAllEvents([...allEvents, newEvent]);
    dispatch(clearBooking());
    dispatch(clearEstimate());
    clearForm();
  };

  const clearForm = () => {
    setUserCombo(null);
    setPickupAddress(null);
    setNewEvent({ description: null, title: null });
    setDropAddress(null);
    setSelectedPracticeDetails(null);
    setPracticeType(language.select_practice);
    setBookingType(language.book_now);
    setEstimateRequested(false);
  };

  const handleBookingErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    setEstimateRequested(false);
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  const eventColors = (event, start, end, isSelected) => {
    let newStyle = {
      backgroundColor: "red",
      color: "black",
      borderRadius: "0px",
      border: "none",
    };
    if (event.status === "NEW") {
      newStyle.backgroundColor = "red";
    }
    if (event.status === "PENDING") {
      newStyle.backgroundColor = "purple";
      newStyle.color = "black";
    }
    if (event.status === "ACCEPTED") {
      newStyle.backgroundColor = "green";
    }
    return {
      className: "",
      style: newStyle,
    };
  };

  return (
    <div className={classes.container}>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <AddBoxRounded
                onClick={selectedAdd}
                style={{
                  color: "#ff000f",
                  alignItems: "right",
                  width: 45,
                  height: 45,
                }}
              />
              <Label size="45">Request a Consultation</Label>

              <BigCalendar
                selectable
                localizer={localizer}
                events={allEvents}
                // step={15}
                // timeslots={1}
                startAccessor="start"
                endAccessor="end"
                style={{ margin: "50px", color: "#000000" }}
                // defaultView="day"
                scrollToTime={new Date()}
                defaultDate={new Date()}
                // onSelectEvent={(event) => selectedEvent(event)}
                eventPropGetter={eventColors}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Dialog
        open={openFPModal}
        onClose={handleCloseEvent}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">{"Bookings Details"}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText> */}
          <p>
            {"Title: "}
            {titles}
          </p>

          <hr />

          <p>
            {"Description: "}
            {description}
          </p>

          <hr />

          {/* <p>
              {"Start Date: "}
              {startDates}
            </p> */}

          <hr />

          {/* <p>
              {"End Date: "}
              {endDates}{" "}
            </p> */}

          <hr />

          <p>
            {"Service Type: "}
            {serviceTypes}
          </p>

          <hr />

          <p>
            {"Practice Type: "}
            {specialityType}
          </p>
          {/* </DialogContentText> */}
          {/* ////Doctor Datails */}
        </DialogContent>

        <DialogContent>
          <Button
            className="btn-icon btn-round"
            color="neutral"
            href="#pablo"
            onClick={onPhoneSelect}
            size="lg"
          >
            <PhoneIcon />
          </Button>
          <Button
            className="btn-icon btn-round"
            color="neutral"
            href="#pablo"
            onClick={onChatSelect}
            size="lg"
          >
            <ChatIcon />
          </Button>
          <Button
            className="btn-icon btn-round"
            color="neutral"
            href="#pablo"
            onClick={onVideoSelect}
            size="lg"
          >
            <VideoCall />
          </Button>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseEvent} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* add service dialog */}
      <Dialog
        open={openAddModal}
        onClose={handleCloseAdd}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogContent>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  // component="h1"
                  // variant="h5"
                  className={classes.title}
                >
                  {language.addbookinglable}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {users ? (
                  <UsersCombo
                    className={classes.items}
                    placeholder={language.select_user}
                    users={users}
                    value={userCombo}
                    onChange={(event, newValue) => {
                      setUserCombo(newValue);
                    }}
                  />
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  labelText={"Booking Name"}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  type="text"
                  placeholder="Add Booking Name"
                  onChange={(e) =>
                    setNewEvent({
                      ...newEvent,
                      title: e.target.value,
                    })
                  }
                  value={newEvent.title}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  labelText={"Description"}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  type="text"
                  placeholder="Add Description"
                  onChange={(e) =>
                    setNewEvent({
                      ...newEvent,
                      description: e.target.value,
                    })
                  }
                  value={newEvent.description}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="practice-type-native">
                    Practice Type
                  </InputLabel>
                  {specialitytypes ? (
                    <Select
                      id="practice-type-native"
                      value={specialityType}
                      onChange={handleSpecialitySelect}
                      variant="outlined"
                      fullWidth
                      className={
                        specialityType === language.select_practice
                          ? classes.inputdimmed
                          : classes.input
                      }
                    >
                      {specialitytypes.map((speciality) => {
                        return (
                          <MenuItem key={speciality} value={speciality}>
                            {speciality}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="booking-type-native">Booking Type</InputLabel>
                  <Select
                    id="booking-type-native"
                    value={bookingType}
                    onChange={handleBookTypeSelect}
                    className={classes.input}
                    variant="outlined"
                    fullWidth
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem key={"Book Now"} value={"Book Now"}>
                      {language.book_now}
                    </MenuItem>
                    <MenuItem key={"Book Later"} value={"Book Later"}>
                      {language.book_later}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="service-type-native">Service Type</InputLabel>
                  <Select
                    id="service-type-native"
                    value={newEvent.service}
                    onChange={(e) =>
                      setNewEvent({
                        ...newEvent,
                        service: e.target.value,
                      })
                    }
                    className={classes.input}
                    variant="outlined"
                    fullWidth
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem
                      key={"videoconference"}
                      value={"Video Conference"}
                    >
                      {language.Video_Conference}
                    </MenuItem>
                    <MenuItem
                      key={"homeconsultation"}
                      value={"Home Consultation"}
                    >
                      {language.Home_Consultation}
                    </MenuItem>
                    <MenuItem
                      key={"medicationdelivery"}
                      value={"Medication Delivery"}
                    >
                      {language.Medication_Delivery}
                    </MenuItem>
                    <MenuItem
                      key={"surgeryconsultation"}
                      value={"Surgery Consultation"}
                    >
                      {language.Surgery_Consultation}
                    </MenuItem>

                    <MenuItem
                      key={"Antenatal Care Assessment A"}
                      value={"Antenatal Care Assessment A"}
                    >
                      {language.antenatal_care_assesment_a}
                    </MenuItem>
                    <MenuItem
                      key={"Antenatal Care Assessment B"}
                      value={"Antenatal Care Assessment B"}
                    >
                      {language.antenatal_care_assesment_b}
                    </MenuItem>
                    <MenuItem
                      key={"Antenatal Care Assessment C"}
                      value={"Antenatal Care Assessment C"}
                    >
                      {language.antenatal_care_assesment_c}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {newEvent.service === "Home Consultation" ||
              newEvent.service === "Medication Delivery" ? (
                <>
                  <Grid item xs={12}>
                    <GoogleMapsAutoComplete
                      variant={"outlined"}
                      placeholder={language.pickup_location}
                      value={pickupAddress}
                      className={classes.items}
                      onChange={(value) => {
                        setPickupAddress(value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <GoogleMapsAutoComplete
                      placeholder={language.drop_location}
                      variant={"outlined"}
                      value={dropAddress}
                      className={classes.items}
                      onChange={(value) => {
                        setDropAddress(value);
                      }}
                    />
                  </Grid>
                </>
              ) : null}
              {bookingType === "Book Later" ? (
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="datetime-local"
                    label={language.booking_start_date_time}
                    type="datetime-local"
                    variant="outlined"
                    fullWidth
                    className={classes.commonInputStyle}
                    InputProps={{
                      className: classes.input,
                    }}
                    value={selectedStartDate}
                    onChange={onDateStartChange}
                  />
                </Grid>
              ) : null}
              {bookingType === "Book Later" ? (
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="datetime-local"
                    label={language.booking_end_date_time}
                    type="datetime-local"
                    variant="outlined"
                    fullWidth
                    className={classes.commonInputStyle}
                    InputProps={{
                      className: classes.input,
                    }}
                    value={selectedEndDate}
                    onChange={onDateEndChange}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12} sm={6}>
                <Button
                  size="large"
                  onClick={handleGetEstimate}
                  variant="contained"
                  color="primary"
                  className={classes.buttonStyle}
                >
                  <i className="fas fa-practice" />
                  {language.book}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {/* Estimate */}
      <Dialog
        open={estimateModalStatus}
        onClose={handleEstimateModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{language.estimate}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>Estimate Amount: </span>
            {settings ? settings.symbol : null}{" "}
            {estimatedata.estimate
              ? parseFloat(estimatedata.estimate.estimateFare)
              : null}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            <span>Treatment Amount: </span>
            {settings ? settings.symbol : null}{" "}
            {estimatedata.estimate
              ? parseFloat(estimatedata.estimate.treatment_fee)
              : null}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            <span>Total: </span>
            {settings ? settings.symbol : null}{" "}
            {estimatedata.estimate
              ? parseFloat(estimatedata.estimate.bigGrand)
              : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEstimateModalClose} color="primary">
            {language.cancel}
          </Button>
          <Button onClick={confirmBooking} color="primary" autoFocus>
            {"Accept Estimate"}
          </Button>
        </DialogActions>
      </Dialog>

      <AlertDialog
        open={bookingdata.booking ? true : false}
        onClose={handleBookingAlertClose}
      >
        {bookingdata.booking
          ? language.booking_success + bookingdata.booking.booking_id
          : null}
      </AlertDialog>
      <AlertDialog
        open={bookingdata.error.flag}
        onClose={handleBookingErrorClose}
      >
        {bookingdata.error.msg}
      </AlertDialog>
      <AlertDialog
        open={estimatedata.error.flag}
        onClose={handleEstimateErrorClose}
      >
        {estimatedata.error.msg}
      </AlertDialog>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
}
