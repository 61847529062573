export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const USER_SIGN_IN = "USER_SIGN_IN";
export const USER_SIGN_IN_FAILED = "SIGN_IN_FAILED";
export const USER_SIGN_OUT = "USER_SIGN_OUT";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const USER_NOT_REGISTERED = "USER_NOT_REGISTERED";
export const USER_DELETED = "USER_DELETED";
export const REQUEST_OTP = "REQUEST_OTP";
export const REQUEST_OTP_SUCCESS = "REQUEST_OTP_SUCCESS";
export const REQUEST_OTP_FAILED = "REQUEST_OTP_FAILED";


export const EDIT_BOOKING_OFFERS = "EDIT_BOOKING_OFFERS";
export const NO_BOOKING_OFFERS = "NO_BOOKING_OFFERS";
export const FETCH_BOOKING_OFFERS = "FETCH_BOOKING_OFFERS";
export const FETCH_BOOKING_OFFERS_SUCCESS = "FETCH_BOOKING_OFFERS_SUCCESS";
export const FETCH_BOOKING_OFFERS_FAILED = "FETCH_BOOKING_OFFERS_FAILED";
export const CANCEL_BOOKING_OFFER = "CANCEL_BOOKING_OFFER";


export const FETCH_REFERRALS_TYPE = "FETCH_REFERRALS_TYPE";
export const FETCH_REFERRALS_TYPES = "FETCH_REFERRALS_TYPES";
export const FETCH_REFERRALS_TYPES_SUCCESS = "FETCH_REFERRALS_TYPES_SUCCESS";
export const FETCH_REFERRALS_TYPES_FAILED = "FETCH_REFERRALS_TYPES_FAILED";
export const EDIT_REFERRALS_TYPE = "EDIT_REFERRALS_TYPE";
export const UPDATE_REFERRALS_TYPE = "UPDATE_REFERRALS_TYPE";
export const UPDATE_REFERRALS_TYPE_SUCCESS = "UPDATE_REFERRALS_TYPE_SUCCESS";
export const UPDATE_REFERRALS_TYPE_FAILED = "UPDATE_REFERRALS_TYPE_FAILED";
export const DELETE_REFERRALS_TYPE = "DELETE_REFERRALS_TYPE";
export const DELETE_REFERRALS_TYPE_SUCCESS = "DELETE_REFERRALS_TYPE_SUCCESS";
export const DELETE_REFERRALS_TYPE_FAILED = "DELETE_REFERRALS_TYPE_FAILED";



export const ADD_CARD ="ADD_CARD";
export const ADD_CARD_SUCCESS ="ADD_CARD_SUCCESS";
export const ADD_CARD_FAILED ="ADD_CARD_FAILED";

export const ADD_MEDICAL_AID ="ADD_MEDICAL_AID";
export const ADD_MEDICAL_AID_SUCCESS ="ADD_MEDICAL_AID_SUCCESS";
export const ADD_MEDICAL_AID_FAILED ="ADD_MEDICAL_AID_FAILED";

export const FETCH_MEDICAL_HISTORY = 'FETCH_MEDICAL_HISTORY';
export const FETCH_MEDICAL_HISTORY_SUCCESS = 'FETCH_MEDICAL_HISTORY_SUCCESS';
export const FETCH_MEDICAL_HISTORY_FAILURE = 'FETCH_MEDICAL_HISTORY_FAILURE';
export const FETCH_MEDICAL_HISTORY_EDIT = 'FETCH_MEDICAL_HISTORY_EDIT'
export const NO_MEDICAL_HISTORY = "NO_MEDICAL_HISTORY";

export const FETCH_REFERRAL_TYPES = "FETCH_REFERRAL_TYPES";
export const FETCH_REFERRAL_TYPES_SUCCESS = "FETCH_REFERRAL_TYPES_SUCCESS";
export const FETCH_REFERRAL_TYPES_FAILED = "FETCH_REFERRAL_TYPES_FAILED";
export const EDIT_REFERRAL_TYPE = "EDIT_REFERRAL_TYPE";

export const FETCH_PRACTICE_TYPES = "FETCH_PRACTICE_TYPES";
export const FETCH_PRACTICE_TYPES_SUCCESS = "FETCH_PRACTICE_TYPES_SUCCESS";
export const FETCH_PRACTICE_TYPES_FAILED = "FETCH_PRACTICE_TYPES_FAILED";
export const EDIT_PRACTICE_TYPE = "EDIT_PRACTICE_TYPE";

export const FETCH_REFERRALDR_TYPES = "FETCH_REFERRALDR_TYPES";
export const FETCH_REFERRALDR_TYPES_SUCCESS = "FETCH_REFERRALDR_TYPES_SUCCESS";
export const FETCH_REFERRALDR_TYPES_FAILED = "FETCH_REFERRALDR_TYPES_FAILED";
export const EDIT_REFERRALDR_TYPE = "EDIT_REFERRALDR_TYPE";

export const FETCH_TREATMENT_TYPES = "FETCH_TREATMENT_TYPES";
export const FETCH_TREATMENT_TYPES_SUCCESS = "FETCH_TREATMENT_TYPES_SUCCESS";
export const FETCH_TREATMENT_TYPES_FAILED = "FETCH_TREATMENT_TYPES_FAILED";
export const EDIT_TREATMENT_TYPE = "EDIT_TREATMENT_TYPE";

export const FETCH_BOOKINGS= "FETCH_BOOKINGS";
export const FETCH_BOOKINGS_SUCCESS = "FETCH_BOOKINGS_SUCCESS";
export const FETCH_BOOKINGS_FAILED = "FETCH_BOOKINGS_FAILED";
export const UPDATE_BOOKING= "UPDATE_BOOKING";
export const CANCEL_BOOKING= "CANCEL_BOOKING";
export const FETCH_ALL_BOOKINGS= "FETCH_ALL_BOOKINGS";
export const FETCH_ALL_BOOKINGS_SUCCESS = "FETCH_ALL_BOOKINGS_SUCCESS";
export const FETCH_ALL_BOOKINGS_FAILED = "FETCH_ALL_BOOKINGS_FAILED";
export const UPDATE_ALL_BOOKING= "UPDATE_ALL_BOOKING";
export const CANCEL_ALL_BOOKING= "CANCEL_ALL_BOOKING";

export const FETCH_ESTIMATE= "FETCH_ESTIMATE";
export const FETCH_ESTIMATE_SUCCESS= "FETCH_ESTIMATE_SUCCESS";
export const FETCH_ESTIMATE_FAILED= "FETCH_ESTIMATE_FAILED";
export const CLEAR_ESTIMATE= "CLEAR_ESTIMATE";

// export const CREATE_MEETING= "CREATE_MEETING";
// export const CREATE_MEETING_SUCCESS= "CREATE_MEETING_SUCCESS";
// export const CREATE_MEETING_FAIL= "CREATE_MEETING_FAIL";
// export const DELETE_MEETING= "DELETE_MEETING";
// export const DELETE_MEETING_SUCCESS= "DELETE_MEETING_SUCCESS";
// export const DELETE_MEETING_FAIL= "DELETE_MEETING_FAIL";


export const CONFIRM_BOOKING = "CONFIRM_BOOKING";
export const CONFIRM_BOOKING_SUCCESS = "CONFIRM_BOOKING_SUCCESS";
export const CONFIRM_BOOKING_FAILED = "CONFIRM_BOOKING_FAILED";
export const CLEAR_BOOKING = "CLEAR_BOOKING";
//SPCIALIT TYPES

export const FETCH_SPECIALITY_TYPES = "FETCH_SPECIALITY_TYPES";
export const  FETCH_SPECIALITY_TYPES_SUCCESS = "FETCH_SPECIALITY_TYPES_SUCCESS";
export const FETCH_SPECIALITY_TYPES_FAILED = "FETCH_SPECIALITY_TYPES_FAILED";
export const  EDIT_SPECIALITY_TYPE= "EDIT_SPECIALITY_TYPE";

//DOCTOR TYPES

export const FETCH_DOCTOR_TYPES = "FETCH_DOCTOR_TYPES";
export const  FETCH_DOCTOR_TYPES_SUCCESS = "FETCH_DOCTOR_TYPES_SUCCESS";
export const FETCH_DOCTOR_TYPES_FAILED="FETCH_DOCTOR_TYPES_FAILED";
export const  EDIT_DOCTOR_TYPE="EDIT_DOCTOR_TYPE";

export const FETCH_CANCEL_REASONS = "FETCH_CANCEL_REASONS";
export const FETCH_CANCEL_REASONS_SUCCESS = "FETCH_CANCEL_REASONS_SUCCESS";
export const FETCH_CANCEL_REASONS_FAILED = "FETCH_CANCEL_REASONS_FAILED";
export const EDIT_CANCELLATION_REASON = "EDIT_CANCELLATION_REASON";

export const SEND_RESET_EMAIL = "SEND_RESET_EMAIL";
export const SEND_RESET_EMAIL_SUCCESS = "SEND_RESET_EMAIL_SUCCESS";
export const SEND_RESET_EMAIL_FAILED = "SEND_RESET_EMAIL_FAILED";

export const FETCH_PROMOS= "FETCH_PROMOS";
export const FETCH_PROMOS_SUCCESS = "FETCH_PROMOS_SUCCESS";
export const FETCH_PROMOS_FAILED = "FETCH_PROMOS_FAILED";
export const EDIT_PROMOS = "EDIT_PROMOS";

export const FETCH_ALL_USERS= "FETCH_ALL_USERS";
export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
export const FETCH_ALL_USERS_FAILED = "FETCH_ALL_USERS_FAILED";
export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILED = "EDIT_USER_FAILED";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

export const FETCH_ALL_DOCTORS = "FETCH_ALL_DOCTORS";
export const FETCH_ALL_DOCTORS_SUCCESS = "FETCH_ALL_DOCTORS_SUCCESS";
export const FETCH_ALL_DOCTORS_FAILED = "FETCH_ALL_DOCTORS_FAILED";

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILED = "FETCH_NOTIFICATIONS_FAILED";
export const EDIT_NOTIFICATIONS = "EDIT_NOTIFICATIONS";
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_FAILED = "SEND_NOTIFICATION_FAILED";

export const FETCH_DOCTORS_EARNING = "FETCH_DOCTORS_EARNING";
export const FETCH_DOCTORS__EARNING_SUCCESS = "FETCH_DOCTORS__EARNING_SUCCESS";
export const FETCH_DOCTORS__EARNING_FAILED = "FETCH_DOCTORS__EARNING_FAILED";

export const FETCH_BOOKING_DISCOUNT = "FETCH_BOOKING_DISCOUNT";

export const FETCH_BOOKING__DISCOUNT_SUCCESS = "FETCH_BOOKING__DISCOUNT_SUCCESS";
export const FETCH_BOOKING__DISCOUNT_FAILED = "FETCH_BOOKING__DISCOUNT_FAILED";
export const EDIT_BOOKING__DISCOUNT = "EDIT_BOOKING__DISCOUNT";

export const FETCH_SETTINGS = "FETCH_SETTINGS";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_FAILED = "FETCH_SETTINGS_FAILED";
export const EDIT_SETTINGS = "EDIT_SETTINGS";
export const CLEAR_SETTINGS_ERROR = "CLEAR_SETTINGS_ERROR";

export const FETCH_PAYMENT_METHODS = "FETCH_PAYMENT_METHODS";
export const FETCH_PAYMENT_METHODS_SUCCESS = "FETCH_PAYMENT_METHODS_SUCCESS";
export const FETCH_PAYMENT_METHODS_FAILED = "FETCH_PAYMENT_METHODS_FAILED";
export const UPDATE_WALLET_BALANCE = "UPDATE_WALLET_BALANCE";
export const UPDATE_WALLET_BALANCE_SUCCESS = "UPDATE_WALLET_BALANCE_SUCCESS";
export const UPDATE_WALLET_BALANCE_FAILED = "UPDATE_WALLET_BALANCE_FAILED";
export const CLEAR_PAYMENT_MESSAGES = "CLEAR_PAYMENT_MESSAGES";

export const UPDATE_EVENT_PICKUP = "UPDATE_EVENT_PICKUP";
export const UPDATE_EVENT_DROP = "UPDATE_EVENT_DROP";
export const UPDATE_EVENT_PRACTICE = "UPDATE_EVENT_PRACTICE";
export const UPDATE_SELECTED_POINT_TYPE = "UPDATE_SELECTED_POINT_TYPE";
export const CLEAR_EVENT_POINTS = "CLEAR_EVENT_POINTS";

export const FETCH_TASKS = "FETCH_TASKS";
export const FETCH_TASKS_SUCCESS = "FETCH_TASKS_SUCCESS";
export const FETCH_TASKS_FAILED = "FETCH_TASKS_FAILED";
export const ACCEPT_TASK = "ACCEPT_TASK";
export const CANCEL_TASK = "CANCEL_TASK";

export const FETCH_BOOKING_LOCATION = 'FETCH_BOOKING_LOCATION';
export const FETCH_BOOKING_LOCATION_SUCCESS = 'FETCH_BOOKING_LOCATION_SUCCESS';
export const FETCH_BOOKING_LOCATION_FAILED = 'FETCH_BOOKING_LOCATION_FAILED';
export const STOP_LOCATION_FETCH = 'STOP_LOCATION_FETCH';

export const FETCH_DEPENDENT = "FETCH_DEPENDENT";
export const FETCH_DEPENDENT_SUCCESS = "FETCH_DEPENDENT_SUCCESS";
export const FETCH_DEPENDENT_FAIL = "FFETCH_DEPENDENT_FAIL";
export const DELETE_DEPENDENT = "DELETE_DEPENDENT";
export const DELETE_DEPENDENT_SUCCESS = "DELETE_DEPENDENT_SUCCESS";
export const DELETE_DEPENDENT_FAIL = "DELETE_DEPENDENT_FAIL";
export const EDIT_DEPENDENT = "EDIT_DEPENDENT";
export const EDIT_DEPENDENT_SUCCESS = "EDIT_DEPENDENT_SUCCESS";
export const EDIT_DEPENDENT_FAIL = "EDIT_DEPENDENT_FAIL";

export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_FAILED = 'FETCH_MESSAGES_FAILED';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const STOP_FETCH_MESSAGES = 'STOP_FETCH_MESSAGES';

export const FETCH_CONSULATION_TYPES = 'FETCH_CONSULATION_TYPES';
export const FETCH_CONSULATION_TYPES_SUCCESS = 'FETCH_CONSULATION_TYPES_SUCCESS';
export const FETCH_CONSULATION_TYPES_FAILED = 'FETCH_CONSULATION_TYPES_FAILED';
export const ADD_CONSULATION_TYPES = 'ADD_CONSULATION_TYPES';
export const ADD_CONSULATION_TYPES_FAILED = 'ADD_CONSULATION_TYPES_FAILED';
export const ADD_CONSULATION_TYPES_SUCCESS = 'ADD_CONSULATION_TYPES_SUCCESS';

export const FETCH_NOTES = 'FETCH_NOTES';
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';
export const FETCH_NOTES_FAILED = 'FETCH_NOTES_FAILED';
export const SEND_NOTES = 'SEND_NOTES';
export const STOP_FETCH_NOTES = 'STOP_FETCH_NOTES';

export const FETCH_WITHDRAWS = 'FETCH_WITHDRAWS';
export const FETCH_WITHDRAWS_SUCCESS = 'FETCH_WITHDRAWS_SUCCESS';
export const FETCH_WITHDRAWS_FAILED = 'FETCH_WITHDRAWS_FAILED';
export const EDIT_WITHDRAWS = 'EDIT_WITHDRAWS';

export const UPDATE_GPS_LOCATION = 'UPDATE_GPS_LOCATION';

export const RECEIVE_APPOINTMENT = 'RECEIVE_APPOINTMENT';
export const RECEIVE_DOCTOR = 'RECEIVE_DOCTOR';
export const RECEIVE_SEARCH_RESULTS = 'RECEIVE_SEARCH_RESULTS';
export const RECEIVE_PATIENTS = 'RECEIVE_PATIENTS';
export const RECEIVE_APPOINTMENTS = 'RECEIVE_APPOINTMENTS';
///////////////////////////////////////////////////////////////////////////////////
export const CREATE_DOCTORCONFERENCE = 'CREATE_DOCTORCONFERENCE';
export const CREATE_DOCTORCONFERENCE_SUCCESS = 'CREATE_DOCTORCONFERENCE_SUCCESS';
export const CREATE_DOCTORCONFERENCE_FAIL = 'CREATE_DOCTORCONFERENCE_FAIL';

export const DELETE_DOCTORCONFERENCE = 'DELETE_DOCTORCONFERENCE';
export const DELETE_DOCTORCONFERENCE_SUCCESS = 'DELETE_DOCTORCONFERENCE_SUCCESS';
export const DELETE_DOCTORCONFERENCE_FAIL = 'DELETE_DOCTORCONFERENCE_FAIL';

export const CREATE_NURSECONFERENCE = 'CREATE_NURSECONFERENCE';
export const CREATE_NURSECONFERENCE_SUCCESS = 'CREATE_NURSECONFERENCE_SUCCESS';
export const CREATE_NURSECONFERENCE_FAIL = 'CREATE_NURSECONFERENCE_FAIL';

export const DELETE_NURSECONFERENCE = 'DELETE_NURSECONFERENCE';
export const DELETE_NURSECONFERENCE_SUCCESS = 'DELETE_NURSECONFERENCE_SUCCESS';
export const DELETE_NURSECONFERENCE_FAIL = 'DELETE_NURSECONFERENCE_FAIL';

export const CREATE_JOOMLAUSER = 'CREATE_JOOMLAUSER';
export const CREATE_JOOMLAUSER_SUCCESS = 'CREATE_JOOMLAUSER_SUCCESS';
export const CREATE_JOOMLAUSER_FAIL = 'CREATE_JOOMLAUSER_FAIL';

export const CREATE_OPENFIREUSER = 'CREATE_OPENFIREUSER';
export const CREATE_OPENFIREUSER_SUCCESS = 'CREATE_OPENFIREUSER_SUCCESS';
export const CREATE_OPENFIREUSER_FAIL = 'CREATE_OPENFIREUSER_FAIL';

export const CREATE_MEETINGROOM = 'CREATE_MEETINGROOM';
export const CREATE_MEETINGROOM_SUCCESS = 'CREATE_MEETINGROOM_SUCCESS';
export const CREATE_MEETINGROOM_FAIL = 'CREATE_MEETINGROOM_FAIL';

export const CREATE_DOCTORROOM = 'CREATE_MEETINGROOM';
export const CREATE_DOCTORROOM_SUCCESS = 'CREATE_MEETINGROOM_SUCCESS';
export const CREATE_DOCTORROOM_FAIL = 'CREATE_MEETINGROOM_FAIL';

export const DELETE_MEETINGROOM = 'DELETE_MEETINGROOM';
export const DELETE_MEETINGROOM_SUCCESS = 'DELETE_MEETINGROOM_SUCCESS';
export const DELETE_MEETINGROOM_FAIL = 'DELETE_MEETINGROOM_FAIL';
///////////////////////////////////////////////////////////////////////////////////
export const FETCH_DISEASES = "FETCH_DISEASES";
export const FETCH_DISEASES_SUCCESS = "FETCH_DISEASES_SUCCESS";
export const FETCH_DISEASES_FAILED = "FETCH_DISEASES_FAILED";
export const EDIT_DISEASES = "EDIT_DISEASES";
export const EDIT_DISEASES_SUCCESS = "EDIT_DISEASES_SUCCESS";
export const EDIT_DISEASES_FAILED = "EDIT_DISEASES_FAILED";
export const DELETE_DISEASES = "DELETE_DISEASES";
export const DELETE_DISEASES_SUCCESS = "DELETE_DISEASES_SUCCESS";
export const DELETE_DISEASES_FAILED = "DELETE_DISEASES_FAILED";
//////////////////////////////////////////////////////////////////////////////////
//ICD10 CODES
export const FETCH_ICDCODES = "FETCH_ICDCODES";
export const FETCH_ICDCODES_SUCCESS = "FETCH_ICDCODES_SUCCESS";
export const FETCH_ICDCODES_FAILED = "FETCH_ICDCODES_FAILED";
//////////////////////////////////////////////////////////////////////////////////
// DRUG EXPOSURE

export const FETCH_DRUGEXPO = "FETCH_DRUGEXPO";
export const FETCH_DRUGEXPO_SUCCESS = "FETCH_DRUGEXPO_SUCCESS";
export const FETCH_DRUGEXPO_FAILED = "FETCH_DRUGEXPO_FAILED";
export const ADD_DRUGEXPO = "ADD_DRUGEXPO";
export const ADD_DRUGEXPO_SUCCESS = "ADD_DRUGEXPO_SUCCESS";
export const ADD_DRUGEXPO_FAILED = "ADD_DRUGEXPO_FAILED";
export const EDIT_DRUGEXPO = "EDIT_DRUGEXPO";
export const EDIT_DRUGEXPO_SUCCESS = "EDIT_DRUGEXPO_SUCCESS";
export const EDIT_DRUGEXPO_FAILED = "EDIT_DRUGEXPO_FAILED";
export const DELETE_DRUGEXPO = "DELETE_DRUGEXPO";
export const DELETE_DRUGEXPO_SUCCESS = "DELETE_DRUGEXPO_SUCCESS";
export const DELETE_DRUGEXPO_FAILED = "DELETE_DRUGEXPO_FAILED";

//////////////////////////////////////////////////////////////////////////////////
// DEVICE EXPOSURE

export const FETCH_DEVICEEXPO = "FETCH_DEVICEEXPO";
export const FETCH_DEVICEEXPO_SUCCESS = "FETCH_DEVICEEXPO_SUCCESS";
export const FETCH_DEVICEEXPO_FAILED = "FETCH_DEVICEEXPO_FAILED";
export const ADD_DEVICEEXPO = "ADD_DEVICEEXPO";
export const ADD_DEVICEEXPO_SUCCESS = "ADD_DEVICEEXPO_SUCCESS";
export const ADD_DEVICEEXPO_FAILED = "ADD_DEVICEEXPO_FAILED";
export const EDIT_DEVICEEXPO = "EDIT_DEVICEEXPO";
export const EDIT_DEVICEEXPO_SUCCESS = "EDIT_DEVICEEXPO_SUCCESS";
export const EDIT_DEVICEEXPO_FAILED = "EDIT_DEVICEEXPO_FAILED";
export const DELETE_DEVICEEXPO = "DELETE_DEVICEEXPO";
export const DELETE_DEVICEEXPO_SUCCESS = "DELETE_DEVICEEXPO_SUCCESS";
export const DELETE_DEVICEEXPO_FAILED = "DELETE_DEVICEEXPO_FAILED";

//////////////////////////////////////////////////////////////////////////////////
// MED NOTES

export const FETCH_MEDNOTES = "FETCH_MEDNOTES";
export const FETCH_MEDNOTES_SUCCESS = "FETCH_MEDNOTES_SUCCESS";
export const FETCH_MEDNOTES_FAILED = "FETCH_MEDNOTES_FAILED";
export const ADD_MEDNOTES = "ADD_MEDNOTES";
export const ADD_MEDNOTES_SUCCESS = "ADD_MEDNOTES_SUCCESS";
export const ADD_MEDNOTES_FAILED = "ADD_MEDNOTES_FAILED";
export const EDIT_MEDNOTES = "EDIT_MEDNOTES";
export const EDIT_MEDNOTES_SUCCESS = "EDIT_MEDNOTES_SUCCESS";
export const EDIT_MEDNOTES_FAILED = "EDIT_MEDNOTES_FAILED";
export const DELETE_MEDNOTES = "DELETE_MEDNOTES";
export const DELETE_MEDNOTES_SUCCESS = "DELETE_MEDNOTES_SUCCESS";
export const DELETE_MEDNOTES_FAILED = "DELETE_MEDNOTES_FAILED";

/////////////////////////////////////////////////////////////////////////////////
//OBSERVATIONS

export const FETCH_OBSERVATIONS = "FETCH_OBSERVATIONS";
export const FETCH_OBSERVATIONS_SUCCESS = "FETCH_OBSERVATIONS_SUCCESS";
export const FETCH_OBSERVATIONS_FAILED = "FETCH_OBSERVATIONS_FAILED";
export const ADD_OBSERVATIONS = "FETCH_OBSERVATIONS";
export const ADD_OBSERVATIONS_SUCCESS = "FETCH_OBSERVATIONS_SUCCESS";
export const ADD_OBSERVATIONS_FAILED = "FETCH_OBSERVATIONS_FAILED";
export const EDIT_OBSERVATIONS = "EDIT_OBSERVATIONS";
export const EDIT_OBSERVATIONS_SUCCESS = "EDIT_OBSERVATIONS_SUCCESS";
export const EDIT_OBSERVATIONS_FAILED = "EDIT_OBSERVATIONS_FAILED";
export const DELETE_OBSERVATIONS = "DELETE_OBSERVATIONS";
export const DELETE_OBSERVATIONS_SUCCESS = "DELETE_OBSERVATIONS_SUCCESS";
export const DELETE_OBSERVATIONS_FAILED = "DELETE_OBSERVATIONS_FAILED";

/////////////////////////////////////////////////////////////////////////////////
//PRESCRIPTIONS

export const FETCH_PRESCRIPTIONS = "FETCH_PRESCRIPTIONS";
export const FETCH_PRESCRIPTIONS_SUCCESS = "FETCH_PRESCRIPTIONS_SUCCESS";
export const FETCH_PRESCRIPTIONS_FAILED = "FETCH_PRESCRIPTIONS_FAILED";
export const ADD_PRESCRIPTIONS = "FETCH_PRESCRIPTIONS";
export const ADD_PRESCRIPTIONS_SUCCESS = "FETCH_PRESCRIPTIONS_SUCCESS";
export const ADD_PRESCRIPTIONS_FAILED = "FETCH_PRESCRIPTIONS_FAILED";
export const EDIT_PRESCRIPTIONS = "EDIT_PRESCRIPTIONS";
export const EDIT_PRESCRIPTIONS_SUCCESS = "EDIT_PRESCRIPTIONS_SUCCESS";
export const EDIT_PRESCRIPTIONS_FAILED = "EDIT_PRESCRIPTIONS_FAILED";
export const DELETE_PRESCRIPTIONS = "DELETE_PRESCRIPTIONS";
export const DELETE_PRESCRIPTIONS_SUCCESS = "DELETE_PRESCRIPTIONS_SUCCESS";
export const DELETE_PRESCRIPTIONS_FAILED = "DELETE_PRESCRIPTIONS_FAILED";

/////////////////////////////////////////////////////////////////////////////////
//PROCEDURES

export const FETCH_PROCEDURES = "FETCH_PROCEDURES";
export const FETCH_PROCEDURES_SUCCESS = "FETCH_PROCEDURES_SUCCESS";
export const FETCH_PROCEDURES_FAILED = "FETCH_PROCEDURES_FAILED";
export const ADD_PROCEDURES = "FETCH_PROCEDURES";
export const ADD_PROCEDURES_SUCCESS = "FETCH_PROCEDURES_SUCCESS";
export const ADD_PROCEDURES_FAILED = "FETCH_PROCEDURES_FAILED";
export const EDIT_PROCEDURES = "EDIT_PROCEDURES";
export const EDIT_PROCEDURES_SUCCESS = "EDIT_PROCEDURES_SUCCESS";
export const EDIT_PROCEDURES_FAILED = "EDIT_PROCEDURES_FAILED";
export const DELETE_PROCEDURES = "DELETE_PROCEDURES";
export const DELETE_PROCEDURES_SUCCESS = "DELETE_PROCEDURES_SUCCESS";
export const DELETE_PROCEDURES_FAILED = "DELETE_PROCEDURES_FAILED";


/////////////////////////////////////////////////////////////////////////////////
//Conditions

//otc

export const FETCH_OTC = "FETCH_OTC";
export const FETCH_OTC_SUCCESS = "FETCH_OTC_SUCCESS";
export const FETCH_OTC_FAILED = "FETCH_OTC_FAILED";
export const FETCH_SPL = "FETCH_SPL";
export const FETCH_SPL_SUCCESS = "FETCH_SPL_SUCCESS";
export const FETCH_SPL_FAILED = "FETCH_SPL_FAILED";
export const FETCH_ICD10CM = "FETCH_ICD10CM";
export const FETCH_ICD10CM_SUCCESS = "FETCH_ICD10CM_SUCCESS";
export const FETCH_ICD10CM_FAILED = "FETCH_ICD10CM_FAILED";
